// REACT COMPONENTS
import React, { useEffect, useState } from "react";

// ANTD COMPONENTS
import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// REACT ICONS
import { FaMoneyBills } from "react-icons/fa6";

// PAGE COMPONENTS
import ListBillTable from "../../../Components/Table/MasterFolio/ListBill/Table";
import ListBillPayTable from "../../../Components/Table/MasterFolio/ListBillPay/Table";

export default function MasterFolioPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState("");
  const [articleData, setArticleData] = useState([]);
  const [returnArticleData, setReturnArticleData] = useState(null);
  const [guestData, setGuestData] = useState(null);
  // Search
  const [search, setSearch] = useState();

  // useEffect(() => {
  //   console.log("Article Data ->> ", articleData);
  // }, [articleData]);

  // useEffect(() => {
  //   console.log("Return Article Data ->> ", returnArticleData);
  // }, [returnArticleData]);

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="master-folio-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <FaMoneyBills
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Master Folio`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                className="col-input-search"
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  className="input-search"
                  allowClear
                  placeholder="Article Name"
                  onChange={handleSearch}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                className="col-search-btn"
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={search}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <Row gutter={[30, 30]} className="table-row">
            <Col
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="col-table"
            >
              <ListBillTable
                returnArticleData={returnArticleData}
                getArticleData={setArticleData}
                getGuestData={setGuestData}
                getReturnArticleData={setReturnArticleData}
                is_search={setSearch}
                searchKey={value}
              />
            </Col>

            <Col
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="col-table"
            >
              <ListBillPayTable
                articleData={articleData}
                guestData={guestData}
                getReturnArticleData={setReturnArticleData}
                getArticleData={setArticleData}
              />
            </Col>
          </Row>
        </Col>
      </Content>
    </Layout>
  );
}
