// Import React Components
import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";

// AUTH
import { token } from "../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import { Divider, Radio, Table, Space, Button, Col, Row } from "antd";

// Import Page Components
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import { PlusOutlined, RetweetOutlined } from "@ant-design/icons/lib/icons";

// CODE
export default function ReservationDepositModalTable(props) {
  // PROPS
  const {
    // Modal State
    modalClose,
    resvId,
    getDataDepo,
    is_fetch,
    fetchFalse,
    selected,
    // Guest Name
    guestName,
  } = props;

  // Currency
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [select, setSelect] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    setLoading(true);

    await axios
      .get(`${baseurl}/reservation/deposit-stay`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("RES DEP >> ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (
              (filt.is_deleted == false || filt.is_deleted == null) &&
              filt.reservation_id == resvId
            ) {
              return filt;
            }
          });

          // console.log(filtered);

          setData(filtered);
          guestName(filtered);
        } else {
          setData([]);
        }
        setLoading(false);
        // console.log(filtered);
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [resvId]);

  useEffect(() => {
    if (is_fetch == true) {
      fetchData();
      fetchFalse(false);
    }
  }, [is_fetch, resvId, fetchFalse]);

  useEffect(() => {
    // console.log("USEEFFECT = ", selected);
    // console.log("Modal Close state = ", modalClose);

    setSelect([]);
  }, [modalClose, selected]);

  // HANDLE SELECTION
  const rowSelection = {
    selectedRowKeys: select,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );

      setSelect(selectedRowKeys);
      getDataDepo(selectedRows);
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === "Disabled User",
    //   // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  // Column's Table
  const columns = [
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.create_date.localeCompare(b.create_date),
      render: (date, record) => {
        return moment(date).format("DD MMM YYYY");
      },
    },
    {
      title: `Amount ( ${currency} )`,
      dataIndex: "deposit_amount",
      key: "deposit_amount",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.amount - b.amount,
      render: (deposit_amount, record) => {
        return `${deposit_amount > 0 ? deposit_amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      key: "payment_type",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.payment_type.localeCompare(b.payment_type),
    },
  ];

  // console.log(data);
  // console.log("SELECTED", select);

  return (
    <>
      <Table
        className="guest-deposit-table"
        name="guest-deposit-table"
        key="guest-deposit-table"
        loading={loading}
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        size={"small"}
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: 5,
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} entries`,
        }}
        scroll={{
          y: 500,
        }}
        rowKey={(record) => (record.id ? record.id : record.key)}
      />
    </>
  );
}
