// Import React Components
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// API
import { baseurl } from "../../../API/Config/Api";

// Import Ant-D Components
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
  notification,
} from "antd";

// Import Icons Components
import { IoClose } from "react-icons/io5";
import { HiMiniSquaresPlus } from "react-icons/hi2";
import { PlusCircleFilled } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";

// Import Master Components
import MasterRateAudit from "../../Reusable/Master/RateCode/RateAudit";
import ReservationRoomRateTable from "../../Table/FrontOffice/AddReservation/Table";

// Import Functions
import { MaxCapacity } from "../../Reusable/Functions/MaxCapacity/MaxCapacity";
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import ParametersBreakfast from "../../Reusable/Parameters/ParamsBF";
import MasterRateCode from "../../Reusable/Master/RateCode/RateCode";

// Import Notifications
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../Reusable/Notification/Notification";

// Global Styles
import { failedMaster, textForm } from "../../../Style/Global/Global";
import { RateCodeFinder } from "../../Reusable/Functions/RateCodeFinder/RateCodeFinder";

// Modals
const { confirm } = Modal;

// Styles Const
// STYLE TEXT
const textSum = {
  fontSize: 12,
  fontWeight: 750,
  textAlign: "right",
  display: "flex",
  justifyContent: "flex-end",
};

const formStyles = {
  margin: 0,
  padding: 0,
  height: "22px",
};

const { open } = notification;

// Notifications
const maxCapNotif = (error) => {
  notification.open({
    className: "notif-failed",
    message: null,
    description: (
      <>
        <Row align="middle" className="login-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <IoClose className="success-icon" style={{ fontSize: 50 }} />
          </Col>

          <Col span={17}>
            <Row
              className="login-notif"
              style={{
                width: "100%",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              {<div style={{ color: "#FFFFFF" }}>{`WARNING !`}</div>}
            </Row>

            <Row
              className="failed-notif"
              style={{ width: "100%", fontSize: 12 }}
            >
              {`Message: ${error.message}`}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "top",
    duration: 30,
    style: failedMaster,
  });
};

// CODE
export default function ExtendNightModal(props) {
  // PROPS
  const { is_open, is_close, data_guest, is_success } = props;

  // CONTEXT
  const { getMasterBillTrx, getGuest } = useContext(MainContext);
  const { getMasterAvail } = useContext(MasterContext);

  // Params
  const currency = CurrencySymbols().code;
  const breakfast = ParametersBreakfast().breakfast;

  // Table's Column
  const columns = [
    {
      title: "Room Category",
      dataIndex: "room_category_name_1",
      key: "room_category_name_1",
      // fixed: "left",
      width: 200,
      className: "room-category",
    },
    {
      title: "Total Room",
      dataIndex: "number_of_rooms_1",
      key: "number_of_rooms_1",
      // fixed: "left",
      width: 200,
      className: "number-rooms",
      render: (_, record) => {
        const _rows = [record];
        // console.log("Row Numbers: ", record);

        let selectedCap = _rows.reduce((acc, cur) => {
          Object.keys(cur).some((key) => {
            if (key.includes("number_of_rooms")) {
              if (acc < cur[key]) acc = cur[key];
            }
          });

          return acc;
        }, 0);

        return selectedCap;
      },
      // sortDirections: ["descend", "ascend"],
    },
  ];

  // IS GROUP
  const [isGroup, setIsGroup] = useState(false);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [room, setRoom] = useState([]);
  const [roomGroup, setRoomGroup] = useState([]);
  const [newRate, setNewRate] = useState(null);
  const [oldRate, setOldRate] = useState(null);
  const [roomPrice, setRoomPrice] = useState();
  const [roomList, setRoomList] = useState([]);
  // New Room
  const [finalDetail, setFinalDetail] = useState([]);
  const [finalRoom, setFinalRoom] = useState([]);
  const [isComply, setIsComply] = useState({
    compliment_id: 2,
    compliment_value: false,
    compliment_label: "No Compliment",
  });
  const [companyInfo, setCompanyInfo] = useState(null);
  const [maxCap, setMaxCap] = useState(0);
  const [maxCapGroup, setMaxCapGroup] = useState(null);
  // Data Room Availablity
  const [roomAvail, setRoomAvail] = useState([]);
  const [available, setAvailable] = useState([]);
  const [allAvail, setAllAvail] = useState([]);

  // Dynamic Columns
  const [newColumns, setNewColumns] = useState([]);
  const [columnWidth, setColumnWidth] = useState(1250);
  // Bool
  const [open, setOpen] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadTable, setLoadTable] = useState(false);
  const [extendNight, setExtendNight] = useState(1);
  const [extendDate, setExtendDate] = useState(null);
  const [isMaxGroup, setIsMaxGroup] = useState(true);
  // ALL RATE CODE
  const [allRateCode, setAllRateCode] = useState([]);

  // Form
  const [form] = Form.useForm();

  // FETCH ALL ROOMS
  const fetchRoom = async (dataGuest) => {
    await getMasterBillTrx({
      bill: "bill",
      type: "article-master-bill-guest",
      res_id: dataGuest?.reservation_id
        ? dataGuest.reservation_id
        : data?.reservation_id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Master => ", response);

        if (response?.data?.length > 0) {
          const _res = response.data;

          const _filtered = _res[0].get_article_room_master_bill_guest;

          const _filteredRoom = _filtered.filter((items) => {
            return items.price_id == dataGuest.price_id;
          });

          console.log("Room: ", _filteredRoom);
          console.log("Room All: ", _filtered);

          // setRoom(_filteredRoom);
          setRoom(
            _filteredRoom.length > 0 ? [_filteredRoom[0]] : _filteredRoom
          );
          handleMergeRoom(_filtered);
        } else {
          setRoom([]);
          setRoomGroup([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        failedFetch(error);
        setLoading(false);
      },
    });

    // FETCH GUEST DATA
    await getGuest({
      guest: "guest",
      type: "list",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest Master => ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return val?.id == dataGuest?.guest_id;
          });

          let newData = filtered.map((gst) => ({
            value: gst?.full_name,
            label: gst?.full_name,
            // first_name: gst?.first_name,
            nationality: gst?.nationality,
            id: gst?.id,
            birthdate: gst?.birthdate,
            rate_code: gst?.rate_code,
            rate_id: gst?.rate_code_id,
            segment_name: gst?.main_segment_name,
            segment_id: gst?.main_segment_id,
            company_name: gst?.company_name,
            company_id: gst?.master_comp_id,
            is_allow: gst?.allow_ledger,
            credit_limit: gst?.credit_limit,
          }));

          setCompanyInfo(newData?.length > 0 ? newData[0] : newData);
          // console.log("GUEST LIST == ", newData);
        } else {
          setCompanyInfo(null);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // Update Room Availability
  const fetchRoomAvailibility = async (values) => {
    await getMasterAvail({
      type: "category/availability",
      start_date: moment(data_guest?.departure).format("YYYY-MM-DD"),
      end_date: values?.departure
        ? moment(values.departure).add(2, "days").format("YYYY-MM-DD")
        : moment(values).format("YYYY-MM-DD"),
      onAwait: () => {
        setLoadTable(true);
      },
      onSuccess: (response) => {
        console.log("Update Room Availability => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filter = _res.filter((val) => {
            if (
              !val.is_deleted &&
              val?.room_category_id == data_guest?.room_category_id
            ) {
              return val;
            }
          });

          let filteredAll = _res.filter((val) => {
            if (!val.is_deleted) {
              return val;
            }
          });

          console.log("Availability FILTERED => ", filter);

          if (isGroup) {
            handleGroup({ data: filteredAll, isGroup: isGroup });
          } else {
            modifyData(filter);
          }

          setAvailable(filter);
          setAllAvail(filteredAll);
        } else {
          setAvailable([]);
          setAllAvail([]);
          setLoadTable(false);
        }
        // if (response?.data?.data_get_arr?.length > 0) {
        //   const _res = response.data.data_get_arr;
        //   setAvrg(_res);
        // } else {
        //   setAvrg([]);
        // }
      },
      onReject: (error) => {
        failedFetch(error);
        console.log("ERROR ==> ", error);
      },
    });
  };

  // Disable Date
  const disabledDate = (current) => {
    // console.log(current < moment(data_guest?.departure));

    return current < moment(data_guest?.departure);
  };

  // Handle Date
  const handleDate = async (val) => {
    const _date = moment(val);
    let ngt = moment(_date).diff(data_guest?.departure, "days");

    console.log("Extend Date: ", _date);
    console.log("Extend Night: ", ngt);

    await fetchRoomAvailibility(_date);

    setExtendDate(_date);
    setExtendNight(ngt + 1);

    form.setFieldsValue({
      extended_night: ngt + 1,
      extended_date: _date,
    });
  };

  // Night
  const handleNight = (val) => {
    const night = val;
    setExtendNight(night);

    if (data_guest?.departure) {
      let _newEnd = moment(data_guest?.departure).add(night, "days");
      setExtendDate(_newEnd);

      console.log("New Date: ", _newEnd);
      console.log("New Night: ", night);

      form.setFieldsValue({
        extended_night: night,
        extended_date: moment(_newEnd),
      });
    }
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      setOpen(true);
      setIsRender(true);
    } else {
      setOpen(false);
    }

    if (data_guest && !cleanUp) {
      console.log("Data about to Extend: ", data_guest);

      fetchRoom(data_guest);
      fetchRoomAvailibility(data_guest);
      setData(data_guest);
      handleFormFields(data_guest);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  // USE EFFECTS ROOM
  useEffect(() => {
    let cleanUp = false;

    // console.log("this happened: ", newRate, room);

    if (newRate && extendDate && room.length > 0 && !cleanUp) {
      handleRoom(newRate, room);
    } else {
      setRoomList([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [newRate, room, extendDate]);

  // COLUMNS
  useEffect(() => {
    let cleanUp = false;

    if (roomAvail?.length > 0 && !cleanUp) {
      // console.log("New Room Available: ", roomAvail);

      let _newCol = dynamicColumns(roomAvail);
      // console.log("New Room Columns: ", _newCol);

      const _columns = columns.concat(_newCol);

      let _width = 100;
      let _length = _columns?.length;
      let _newWidth = _width * _length;

      setNewColumns(_columns);

      if (_columns?.length > 13) {
        setColumnWidth(_newWidth);
      } else {
        setColumnWidth(_newWidth);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [roomAvail]);

  // Handle Merge Room
  const handleMergeRoom = (val) => {
    const _total = val;

    let _current = 0;

    const result = _total.filter((items) => {
      if (_current != items.price_id) {
        // console.log(items);
        _current = items.price_id;

        return items;
      }
    });

    console.log("Merge Results: ", result);

    setRoomGroup(result);
  };

  // GENERATE ROOM PRICE
  const handleRoom = (res, params) => {
    // Array Container
    let rate = [];
    let group = [];

    // Assign Variables
    const _field = form.getFieldsValue();
    const _fixed = res;
    const _params = params;

    console.log("Res: ", _fixed);
    console.log("Parameters: ", _params);

    if (_field.is_all == true && _field.is_same == false && _field.rate_code) {
      console.log("All Members: ", roomGroup);

      for (let j = 0; j < roomGroup.length; j++) {
        let newRate = {
          ...roomGroup[j],
          ..._fixed,
          date: moment(data?.departure),
          room_category_name: roomGroup[j]?.room_category
            ? roomGroup[j].room_category
            : _field.room_category,
          room_category_id:
            roomGroup[j]?.room_category_id > 0
              ? roomGroup[j].room_category_id
              : 0,
          room_no:
            roomGroup[j]?.room_number > 0 ? roomGroup[j]?.room_number : 0,
          id: roomGroup[j]?.id + j,
          rate_code_id: _fixed?.id,
          room_number_id: roomGroup[j]?.room_id > 0 ? roomGroup[j]?.room_id : 0,
          rate_code: _fixed?.value,
          chart_of_account: _params[j]?.chart_of_account,
          price_id: roomGroup[j]?.price_id,
          room_price: _fixed?.nett > 0 ? _fixed.nett : 0,
          nett: _fixed?.nett > 0 ? _fixed.nett : 0,
          service: _fixed?.service > 0 ? _fixed.service : 0,
          tax: _fixed?.tax > 0 ? _fixed.tax : 0,
          gross: _fixed?.gross > 0 ? _fixed.gross : 0,
          room_rate_id: [
            _fixed?.value,
            roomGroup[j]?.main_segment_id,
            _fixed?.id,
          ],
          // night: _params?.extended_night > 1 ? _params.extended_night : 1,
        };

        rate.push(newRate);
      }
    } else if (
      _field.is_all == true &&
      _field.is_same == true &&
      _field.rate_code
    ) {
      console.log("Is Same: ", allRateCode);

      for (let j = 0; j < roomGroup.length; j++) {
        const _rate = RateCodeFinder({
          allRate: allRateCode,
          rate_id: roomGroup[j]?.rate_code_id,
        });

        console.log(`Rate of ${roomGroup[j]?.rate_code_id} : `, _rate);

        let newRate = {
          ...roomGroup[j],
          ..._rate,
          date: moment(data?.departure),
          room_category_name: roomGroup[j]?.room_category
            ? roomGroup[j].room_category
            : _field.room_category,
          room_category_id:
            roomGroup[j]?.room_category_id > 0
              ? roomGroup[j].room_category_id
              : 0,
          room_no:
            roomGroup[j]?.room_number > 0 ? roomGroup[j]?.room_number : 0,
          id: roomGroup[j]?.id + j,
          rate_code_id: roomGroup[j]?.rate_code_id,
          rate_code: _rate?.rate_code,
          room_number_id: roomGroup[j]?.room_id > 0 ? roomGroup[j]?.room_id : 0,
          chart_of_account: _params[j]?.chart_of_account,
          price_id: roomGroup[j]?.price_id,
          room_price: _rate?.nett > 0 ? _rate.nett : 0,
          nett: _rate?.nett > 0 ? _rate.nett : 0,
          service: _rate?.service > 0 ? _rate.service : 0,
          tax: _rate?.tax > 0 ? _rate.tax : 0,
          gross: _rate?.gross > 0 ? _rate.gross : 0,
          room_rate_id:
            _field.is_same == true
              ? roomGroup[j]?.room_rate_id
              : [_fixed?.value, roomGroup[j]?.main_segment_id, _fixed?.id],
          // night: _params?.extended_night > 1 ? _params.extended_night : 1,
        };

        rate.push(newRate);
      }
    } else if (_fixed?.gross > 0) {
      let newRate = {
        ..._fixed,
        date: moment(data?.departure),
        room_category_name: _params[0]?.room_category
          ? _params[0].room_category
          : _field.room_category,
        room_category_id:
          _fixed?.room_category_id > 0 ? _fixed.room_category_id : 0,
        room_no: _params[0].room_number > 0 ? _params[0].room_number : 0,
        id: 1,
        rate_code_id: _fixed?.id,
        room_number_id: _params[0]?.room_id ? _params[0]?.room_id : 0,
        rate_code: _fixed?.value,
        chart_of_account: _params[0]?.chart_of_account,
        // night: _params?.extended_night > 1 ? _params.extended_night : 1,
      };

      rate.push(newRate);
    } else {
      rate = [];
    }

    console.log("Final Rate --> ", rate);

    setRoomList(rate);
  };

  // Handle Form Fields
  const handleFormFields = (val) => {
    const _record = val;

    form.setFieldsValue({
      ..._record,
      arrival: moment(_record?.arrival).format("DD-MM-YYYY"),
      departure: moment(_record?.departure).format("DD-MM-YYYY"),
      checkin_date: moment(LocalizationDate(_record?.checkin_date)).format(
        "HH:mm:ss, DD-MM-YYYY"
      ),
      is_same: false,
      is_all: false,
      rate_code: null,
    });
  };

  // Get Rate Code
  const getRateCode = (value) => {
    console.log("New Rate Code: ", value);

    setNewRate(value);

    form.setFieldsValue({
      rate_code: value?.value,
    });

    if (extendDate) {
      handleRoom(value, room);
    }
  };

  // Get Old Rate Code
  const getOldRate = (value) => {
    console.log("Old Rate Code: ", value);

    setOldRate(value);
  };

  // Handle Rate Code Same
  const handleSame = (value) => {
    const _checked = value.target.checked;
    console.log("Same: ", _checked);
    console.log("Old Rate: ", oldRate);

    setNewRate(
      _checked == true ? { ...oldRate, value: oldRate?.rate_code } : null
    );

    form.setFieldsValue({
      is_same: _checked,
      rate_code: _checked == true ? oldRate?.rate_code : null,
    });
  };

  // Handle Rate Code Same
  const handleAll = (value) => {
    const _checked = value.target.checked;
    console.log("Same: ", _checked);

    setIsGroup(_checked);

    if (_checked == true) {
      handleGroup({
        isGroup: _checked,
        data: allAvail,
      });
    } else {
      let newData = transMart(available, "room_category_id");

      console.log("Data Individual: ", newData);
      setRoomAvail(newData);
    }

    form.setFieldsValue({
      is_all: _checked,
      // rate_code: _checked == true ? oldRate?.rate_code : null,
    });

    if (extendDate) {
      handleRoom(newRate, roomGroup);
    }
  };

  // Handle All Rate
  const handleAllRate = (value) => {
    console.log("ALL RATE CODE: ", value);

    setAllRateCode(value);
  };

  // Handle Group
  const handleGroup = (value) => {
    const _array = value?.data?.length > 0 ? value.data : allAvail;
    const _result = [];

    // console.log("All Category: ", roomGroup);
    console.log("All Avail: ", allAvail);
    console.log("All Avail Room: ", roomAvail);

    if (_array.length > 0) {
      const newData = transMart(_array, "room_category_id");
      setRoomAvail(newData);

      console.log("Data All Group: ", newData);

      if (isGroup || value?.isGroup) {
        setRoomAvail(newData);
      } else {
        modifyData(available);
      }

      // Merge Room
      const merged = roomGroup.reduce((acc, init) => {
        let key = init.room_category_id;

        acc[key] = acc[key] || [];
        acc[key].push(init.room_number);

        return acc;
      }, {});

      // Merged
      if (merged) {
        for (var key in merged) {
          let _maximum = 0;

          const _max = newData.find((items) => {
            if (items.room_category_id_1 == key) {
              let res = MaxCapacity(items);

              return (_maximum = res);
            }
            return _maximum;
          });

          // console.log("MAX: ", _maximum);

          _result.push({
            id: parseInt(key),
            room_category: parseInt(key),
            room_category_name: newData.find((x) => {
              return x.room_category_id_1 == key;
            })?.room_category_name_1,
            room_number: merged[key],
            room_qty: merged[key]?.length,
            reservation_id: data?.reservation_id,
            maximum: _maximum,
          });
        }
      }

      // console.log("Handle Group: ", merged);
      console.log("Result Capacity Group: ", _result);

      const _isMaxed = _result.map((x) => {
        if (x.room_qty > x.maximum) {
          // setIsMaxGroup(true);

          return true;
        } else {
          return false;
        }
      });

      const _maxCap = _isMaxed.includes(true);

      console.log("Maxed arr: ", _isMaxed);
      console.log("Maxed boolean: ", _maxCap);

      setIsMaxGroup(_maxCap);
      setMaxCapGroup(_result);
    } else {
      fetchRoomAvailibility(extendDate);
    }
  };

  // On Finish
  const onFinish = (val) => {
    const _data = val;

    console.log("Finish: ", _data);

    showModalConfirm(_data);
    setLoading(true);
  };

  // On Finish Failed
  const onFinishFailed = (val) => {
    const _data = val.values;
    console.log("Failed: ", _data);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (val) => {
    const dataModal = val;

    confirm({
      // icon: <ExclamationCircleTwoTone />,
      className: "modal-confirm",
      title: `Are you sure want to Extend the Reservations of ${
        dataModal?.reservation_id
      } ${dataModal?.is_all ? "and with ALL of ITS MEMBER" : ""} for ${
        dataModal?.extended_night || 0
      } Night?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(dataModal);
      },

      onCancel() {
        setLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    const content = e;
    var _arrivals = moment(data.arrival);
    var _newDepartures = moment(data?.departure).add(extendNight, "days");

    var _night = _newDepartures.diff(_arrivals, "days");
    // const field = form.getFieldsValue();
    // e.preventDefault();

    console.log("Departures: ", _newDepartures);
    console.log("Submit: ", content);
    console.log("Details: ", finalDetail);
    console.log("BF : ", breakfast);

    // All Body
    let body = {
      reservation_id: data?.reservation_id,
      created_by: user_name,
      guest_in_house: [],
      article_room: [],
      article_stay: [],
    };

    // Guest In House
    const guest_in_house =
      content?.is_all == true
        ? finalDetail.map((item, index) => {
            return {
              room_number: item?.room_number > 0 ? item.room_number : 0,
              departure:
                data?.departure && extendNight > 1
                  ? moment(data?.departure)
                      .add(extendNight, "days")
                      .format("YYYY-MM-DD")
                  : "",
              rate_code: item?.rate_code,
              price_id: item?.price_id,
              room_category: item?.room_category_name
                ? item.room_category_name
                : "",
              room_category_id:
                item?.room_category_id > 0 ? item.room_category_id : 0,
              segment: item?.main_segment_name ? item.main_segment_name : "",
              night: _night,
            };
          })
        : [
            {
              room_number:
                finalDetail[0]?.room_number > 0
                  ? finalDetail[0].room_number
                  : 0,
              departure: extendDate
                ? moment(extendDate)
                    .add(extendNight, "days")
                    .format("YYYY-MM-DD")
                : "",
              rate_code: finalDetail[0]?.rate_code
                ? finalDetail[0].rate_code
                : "",
              price_id: data?.price_id,
              room_category: content?.room_category,
              room_category_id: data?.room_category_id,
              segment: finalDetail[0]?.main_segment_name
                ? finalDetail[0].main_segment_name
                : "",
              night: _night,
            },
          ];

    // Article Room
    const articleRoom = finalDetail.map((item, index) => {
      console.log(
        `${item.gross} - ${breakfast.gross} * ${item.room_breakfast} = ${
          item.gross - breakfast?.gross * item?.room_breakfast
        }`
      );

      return {
        // created_by: user_name,
        gross:
          item?.main_compliment_id == 2 && item?.gross > 0
            ? item.gross - breakfast?.gross * item?.room_breakfast
            : 0,
        service:
          item?.main_compliment_id == 2 && item?.service > 0
            ? item.service - breakfast?.service * item?.room_breakfast
            : 0,
        tax:
          item?.main_compliment_id == 2 && item?.tax > 0 > 0
            ? item.tax - breakfast?.tax * item?.room_breakfast
            : 0,
        room_price:
          item?.main_compliment_id == 2 && item?.nett > 0
            ? item.nett - breakfast?.unit_price * item?.room_breakfast
            : 0,
        date: item?.date ? moment(item.date).format("YYYY-MM-DD") : null,
        room_rate_id: [
          item?.rate_code,
          item?.main_segment_id,
          item?.rate_code_id,
        ],
        room_number: item?.room_number,
        price_id: item?.price_id,
        is_tax: true,
        is_service: true,
        chart_of_account: item?.chart_of_account,
        main_segment_id: item?.main_segment_id,
        main_compliment_id: item?.main_compliment_id,
        rate_code_id: item?.rate_code_id,
        room_category_id:
          item?.room_category_id > 0 ? item.room_category_id : 0,
      };
    });

    // CHECK BF
    let is_abf = finalDetail.some((item) => item.room_breakfast > 0);
    let articleStay = [];

    // Article Stay
    if (is_abf) {
      articleStay = finalDetail
        .filter((item) => item?.room_breakfast > 0)
        .map((item, index) => {
          return {
            article_id: breakfast?.id,
            article_sub_name: breakfast?.article_sub_name,
            article_sub_id: breakfast?.article_sub_id,
            article_name: breakfast?.article_name,
            unit_price: data?.compliment_type != 2 ? 0 : breakfast?.unit_price,
            gross: data?.compliment_type == 2 ? breakfast?.gross : 0,
            tax: data?.compliment_type == 2 ? breakfast?.tax : 0,
            service: data?.compliment_type == 2 ? breakfast?.service : 0,
            total_price:
              data.compliment_type != 2
                ? 0
                : item?.room_breakfast * breakfast?.gross,
            item_quantity: item?.room_breakfast > 0 ? item.room_breakfast : 0,
            room_number: item?.room_number,
            posting_type: "",
            total_posting: 1,
            chart_of_account: breakfast?.chart_of_account,
            start_date: extendDate
              ? moment(extendDate).format("YYYY-MM-DD")
              : "",
            end_date: extendDate
              ? moment(extendDate).add(extendNight, "days").format("YYYY-MM-DD")
              : "",
            is_tax: breakfast?.is_tax,
            is_service: breakfast?.is_service,
            price_id: item?.price_id,
            total_tax:
              data?.compliment_type == 2
                ? breakfast?.tax * item?.room_breakfast
                : 0,
            total_service:
              data?.compliment_type == 2
                ? breakfast?.service * item?.room_breakfast
                : 0,
            order_date: item?.date
              ? moment(item.date).format("YYYY-MM-DD")
              : null,
          };
        });
    } else {
      articleStay = [];
    }

    body = {
      ...body,
      guest_in_house: guest_in_house,
      article_room: articleRoom,
      article_stay: articleStay,
    };

    await axios
      .post(`${baseurl}/guest-in-house/extended-room`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("Extended Reservations: ", response);

        if (response.status === 200) {
          masterSuccessNotification(response, {
            method: 0,
            source: "Extended Reservations",
          });

          is_success(true);
          handleCancel();
        } else {
          masterFailedNotification(response, {
            method: 0,
            source: "Extended Reservations",
          });
        }
      })
      .catch((error) => {
        console.log("Error Extended: ", error);
        masterFailedNotification(error, {
          method: 0,
          source: "Extended Reservations",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Handle Cancel
  const handleCancel = () => {
    form.resetFields();

    setIsGroup(false);
    setIsRender(false);
    setData(null);

    setMaxCap(0);
    setMaxCapGroup(null);
    setIsMaxGroup(true);

    setAllAvail([]);
    setAvailable([]);
    setRoomAvail([]);

    setNewRate(null);
    setOldRate(null);
    setCompanyInfo(null);

    setRoom([]);
    setRoomGroup([]);
    setRoomList([]);
    setRoomPrice([]);

    setAllRateCode([]);

    is_close(false);
    setLoading(false);
    setOpen(false);
  };

  // GET REFRESH RATE CODE
  const getRefreshRate = async (e) => {
    console.log(`Refresh Rate ${e}`);

    // setSegmentID(e);
  };

  // HANDLE FINAL ROOM LIST
  const handleGetFinalRoomList = (data) => {
    console.log("Final Room List: ", data);

    setFinalRoom(data);

    form.setFieldsValue({
      room_price: data,
    });
  };

  // HANDLE FINAL DETAIL ROOM LIST
  const handleGetFinalDetailRoomList = (data) => {
    console.log("Final Detail: ", data);

    setFinalDetail(data);
  };

  // GET PRICE
  const getPrice = (value) => {
    console.log("ROOM PRICE === ", value);

    form.setFieldsValue({
      room_price: value,
    });
  };

  // CREATE DYNAMICS COLUMNS
  const dynamicColumns = (value) => {
    const _data = value;
    const _arr = [];
    let _number = 0;
    let _newCol = [];

    for (let i = 1; i <= extendNight; i++) {
      _arr.push({
        id: i,
      });
    }

    const _newColumns = _data.map((items, index) => {
      Object.keys(items).forEach((key) => {
        if (key.indexOf("room_available_") == 0 && index == 0) {
          _number++;

          return (
            <span>
              {_newCol.push({
                title: moment(data?.departure)
                  .add(_number - 1, "days")
                  .format("DD-MM-YYYY"), //items["date_availability_" + _number], // moment(dateRange[0]).format("DD-MM-YYYY"),
                dataIndex: moment(data?.departure)
                  .add(_number - 1, "days")
                  .format("YYYY-MM-DD"), // key,
                key: moment(data?.departure)
                  .add(_number - 1, "days")
                  .format("YYYY-MM-DD"), //key,
                align: "center",
                render: (record, recordAll, index) => {
                  // console.log("Record click: ", record);

                  return (
                    <Tag
                      key={recordAll?.id_1}
                      color={
                        record == 0 ? "red" : record == 1 ? "warning" : "green"
                      }
                      style={{
                        fontWeight: 750,
                      }}
                    >
                      {record}
                    </Tag>
                  );
                },
              })}
            </span>
          );
        }
      });
    });

    // console.log("New Columns: ", _newColumns);
    // console.log("New Columns: ", _newCol);
    // console.log("New Arr: ", _arr);

    return _newCol;
  };

  // TABLE DATA TRANSFORMATION
  const transMart = (arr) => {
    // Group By
    let _merge = [];
    let _group = Object.groupBy(arr, (e) => e.room_category_id);

    for (const key in _group) {
      const element = _group[key];

      let _newObj = {};

      const res = element.forEach(function (_objects, _index) {
        for (let _keys in _objects) {
          if (_keys == "date_availability") {
            _newObj[_objects[_keys]] = _objects?.room_available;
            _newObj[_keys + "_" + (_index + 1)] = _objects[_keys];
          }

          if (_objects[_keys] !== null) {
            _newObj[_keys + "_" + (_index + 1)] = _objects[_keys];
          }
        }
      });

      _merge.push(_newObj);
    }

    // console.log("Group By --> ", _group);
    console.log("Array Merge --> ", _merge);

    setTimeout(() => {
      setLoadTable(false);
    }, 750);

    return _merge;
  };

  // TRIGGER MODIFY TABLE DATA
  const modifyData = (value) => {
    console.log("Modify: ", value);
    // setLoading(true);

    let newData = transMart(value, "room_category_id");

    console.log("New Data: ", newData);

    const maxCap = newData.filter((cat) => {
      if (cat?.room_category_id_1 == data?.room_category_id) {
        return cat;
      }
    });

    console.log("Max Cap => ", maxCap);

    let selectedCap = maxCap.reduce((acc, cur) => {
      Object.keys(cur).some((key) => {
        if (key.includes("room_available_")) {
          if (acc > cur[key]) acc = cur[key];
        }
      });

      return acc;
    }, maxCap[0]?.room_available_1);

    console.log("Capacity => ", selectedCap);

    setRoomAvail(newData);
    setMaxCap(selectedCap);
  };

  // CRETE TABLE
  const TableContainer = () => {
    return (
      <>
        <Table
          title={() => `Room Availability`}
          className="room-avail-table"
          name="room-avail-table"
          key="room-avail-table"
          bordered
          size={"small"}
          columns={newColumns}
          dataSource={roomAvail}
          loading={loadTable}
          pagination={false}
          scroll={{
            x: columnWidth,
          }}
          rowClassName={(record, index) => {
            return index % 2 == 0 ? "even" : "odd";
          }}
          rowKey={(record) => {
            return record.id ? record.id : record.id_1;
          }}
          style={{
            border: "2px solid #000000",
            borderRadius: 3,
          }}
        />
      </>
    );
  };

  return (
    <div aria-modal="true" aria-hidden="false">
      <Modal
        className="extend-modal"
        key="extend-modal"
        title={
          <>
            <Row>
              <HiMiniSquaresPlus
                className="icon-exclam"
                style={{ color: "#faad14", fontSize: 24 }}
              />
              <Typography style={{ color: "#faad14", marginLeft: 15 }}>
                {`Extend Night Guest In-House`}
              </Typography>
            </Row>
          </>
        }
        closable
        centered
        open={open}
        width={1200}
        footer={null}
        onCancel={handleCancel}
        // bodyStyle={{
        //   padding: "10px 54px",
        // }}
        // style={{
        //   padding: "0px 36px",
        // }}
      >
        <Form
          className="extend-form"
          name="extend-form"
          key="extend-form"
          layout="horizontal"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          labelAlign="left"
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 12,
          }}
          labelWrap={true}
          style={{
            padding: "0px 36px",
          }}
        >
          <Row gutter={30} className="row-header">
            <Col span={24} className="col-guest-name">
              <Row
                gutter={30}
                className="row-header"
                style={{
                  height: 40,
                }}
              >
                <Col span={8} className="col-guest-name">
                  <Form.Item
                    label="Guest Name"
                    name="guest_name"
                    style={formStyles}
                  >
                    <Input
                      placeholder="Guest's Name"
                      disabled
                      bordered={false}
                      style={textForm}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-res-id">
                  <Form.Item
                    label="Reservation ID"
                    name="reservation_id"
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <Input
                      placeholder="Reservation ID"
                      disabled
                      bordered={false}
                      style={textForm}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-room-cat">
                  <Form.Item
                    label="Room Category"
                    name="room_category"
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <Input
                      placeholder="Room Category"
                      disabled
                      bordered={false}
                      style={textForm}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                gutter={30}
                className="row-header"
                style={{
                  height: 40,
                }}
              >
                <Col span={8} className="col-details">
                  <Form.Item
                    label="Arrival Date"
                    name="arrival"
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <Input
                      placeholder="Arrival Date"
                      disabled
                      bordered={false}
                      style={textForm}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-res-id">
                  <Form.Item
                    label="Departure Date"
                    name="departure"
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <Input
                      placeholder="Departure"
                      disabled
                      bordered={false}
                      style={textForm}
                    />
                  </Form.Item>
                </Col>

                <Col span={4} className="col-room-cat">
                  <Form.Item label="Night" name="night" style={formStyles}>
                    <InputNumber
                      placeholder="Night"
                      disabled
                      bordered={false}
                      style={textForm}
                    />
                  </Form.Item>
                </Col>

                <Col span={4} className="col-room-bf">
                  <Form.Item
                    // label="Night"
                    name="is_abf"
                    style={{
                      marginTop: 0,
                      padding: 0,
                    }}
                  >
                    <Tag
                      className="tag-room"
                      color={data_guest?.is_abf == true ? "green" : "geekblue"}
                      style={{
                        fontSize: 25,
                        padding: "5px 10px",
                        fontWeight: 650,
                      }}
                    >
                      {data_guest?.is_abf == true ? "RB" : "RO"}
                    </Tag>
                  </Form.Item>
                </Col>
              </Row>

              <Row
                gutter={30}
                className="row-header"
                style={{
                  height: 40,
                }}
              >
                <Col span={8} className="col-details">
                  <Form.Item
                    label="Reservation Status"
                    name="reservation_status"
                    style={{
                      marginTop: 0,
                      padding: 0,
                    }}
                  >
                    <Input
                      placeholder="Reservation Status"
                      disabled
                      bordered={false}
                      style={textForm}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-res-id">
                  <Form.Item
                    label="Check-In By"
                    name="checkin_by"
                    style={{
                      marginTop: 0,
                      padding: 0,
                    }}
                  >
                    <Input
                      placeholder="Arrival"
                      disabled
                      bordered={false}
                      style={textForm}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-room-cat">
                  <Form.Item
                    label="Check-In Time"
                    name="checkin_date"
                    style={{
                      marginTop: 0,
                      padding: 0,
                    }}
                  >
                    <Input
                      placeholder="Departure"
                      disabled
                      bordered={false}
                      style={textForm}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px" }}
              />

              <Row className="rate-desc" style={{ width: "100%" }}>
                <Alert
                  className="desc-alert"
                  type="info"
                  closable={false}
                  showIcon
                  message={"Old Segments & Rate Code Descriptions:"}
                  description={
                    <Row justify="space-between">
                      <Typography
                        className="desc-text"
                        style={{ fontWeight: 500 }}
                      >
                        {`RATE CODE: ${
                          oldRate?.rate_code
                            ? oldRate?.rate_code.toUpperCase()
                            : ""
                        } --- SEGMENTS: ${
                          oldRate?.main_segment_name
                            ? oldRate?.main_segment_name.toUpperCase()
                            : ""
                        } --- ${oldRate?.description || ""} `}
                      </Typography>

                      <strong>
                        {` Price: ${
                          oldRate?.gross > 0
                            ? `${oldRate.gross}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            : 0
                        } 
                      `}
                      </strong>
                    </Row>
                  }
                  style={{ width: "100%" }}
                />
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px" }}
              />

              <TableContainer />

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px" }}
              />

              <Row
                gutter={30}
                className="row-header"
                style={{
                  // height: 40,
                  width: "100%",
                }}
              >
                <Col span={6} className="col-details">
                  <Form.Item
                    label="Extended Date"
                    name="extended_date"
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please, Select a Date!",
                      },
                      {
                        validator: async (value, record) => {
                          const error = {
                            message: `You can not Extend the Reservation on Date ${moment(
                              record
                            ).format(
                              "DD-MM-YYYY"
                            )} because there's a partially or fully booked Room Category!`,
                          };
                          // console.log("Value: ", value);
                          // console.log("Value Record: ", record);
                          console.log("Max Capacity: ", maxCap);
                          console.log("Max Group Capacity: ", maxCapGroup);
                          console.log("Is group Maxed: ", isMaxGroup);

                          if (isGroup) {
                            if (isMaxGroup) {
                              maxCapNotif(error);
                              return Promise.reject(new Error("Max Capacity!"));
                              //   new Error(
                              //     `You can not Extend the Reservation on Date ${moment(
                              //       record
                              //     ).format(
                              //       "DD-MM-YYYY"
                              //     )} because there's a partially or fully booked Room Category!`
                              //   )
                              // );
                            }
                          } else {
                            if (maxCap == 0) {
                              maxCapNotif(error);
                              return Promise.reject(new Error("Max Capacity!"));
                              // return Promise.reject(
                              //   new Error(
                              //     `You can not Extend the Reservation on Date ${moment(
                              //       record
                              //     ).format(
                              //       "DD-MM-YYYY"
                              //     )} because there's a partially or fully booked Room Category!`
                              //   )
                              // );
                            }
                          }
                        },
                      },
                    ]}
                    style={{
                      width: "100%",
                    }}
                  >
                    <DatePicker
                      placeholder="Extended To"
                      disabledDate={disabledDate}
                      onChange={handleDate}
                      allowClear
                      format={"YYYY-MM-DD"}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={4} className="col-details">
                  <Form.Item
                    label="Extended Night"
                    name="extended_night"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{
                      width: "100%",
                    }}
                  >
                    <InputNumber
                      placeholder="Extended Night"
                      min={1}
                      onChange={handleNight}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-details">
                  <Form.Item
                    label="Rate Code"
                    name="rate_code"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please, Select a Rate Code!",
                      },
                    ]}
                    style={{
                      width: "100%",
                    }}
                  >
                    <MasterRateAudit
                      getRateCode={getRateCode}
                      getOldRate={getOldRate}
                      is_abf={data?.is_abf == true ? true : false}
                      old_rate={room.length > 0 ? room[0]?.room_rate_id : 0}
                      segment_id={
                        room.length > 0 ? room[0]?.room_rate_id[1] : 0
                      }
                      room_cat={
                        data?.room_category_id > 0 ? data.room_category_id : 0
                      }
                      rate_name={newRate?.rate_code ? newRate.rate_code : null}
                      is_permitted={true}
                      is_render={isRender}
                      is_rerender={setIsRender}
                      is_find={true}
                      rateFinder={handleAllRate}
                    />
                  </Form.Item>
                </Col>

                <Col span={6} className="col-details">
                  <Form.Item
                    // label="Rate Code"
                    name="is_same"
                    valuePropName="checked"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{
                      margin: "15px 0px 0px",
                    }}
                  >
                    <Checkbox
                      onChange={handleSame}
                    >{`Same Rate Code`}</Checkbox>
                  </Form.Item>

                  <Form.Item
                    // label="Rate Code"
                    name="is_all"
                    valuePropName="checked"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{
                      margin: 0,
                    }}
                  >
                    <Checkbox
                      onChange={handleAll}
                      disabled={data?.group == 0 ? true : false}
                    >{`Includes Member`}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px" }}
              />

              <Row className="rate-desc" style={{ width: "100%" }}>
                <Alert
                  className="desc-alert"
                  type="success"
                  closable={false}
                  showIcon
                  message={"New Segments & Rate Code Descriptions:"}
                  description={
                    <Row justify="space-between">
                      <Typography
                        className="desc-text"
                        style={{ fontWeight: 500 }}
                      >
                        {`RATE CODE: ${
                          newRate?.value ? newRate?.value.toUpperCase() : ""
                        } --- SEGMENTS: ${
                          newRate?.main_segment_name
                            ? newRate?.main_segment_name.toUpperCase()
                            : ""
                        } --- ${newRate?.description || ""} `}
                      </Typography>

                      <strong>
                        {` Price: ${
                          newRate?.gross > 0
                            ? `${newRate.gross}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            : 0
                        } `}
                      </strong>
                    </Row>
                  }
                  style={{ width: "100%" }}
                />
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "10px 0px" }}
              >
                {`Table Extend Room`}
              </Divider>

              <Form.Item
                // label="Rate Code"
                name="room_price"
                noStyle
              >
                <ReservationRoomRateTable
                  roomList={roomList}
                  setRoomPrice={setRoomPrice}
                  getPrice={getPrice}
                  night={extendNight > 0 ? extendNight : 1}
                  qty={1}
                  is_breakfast={true}
                  is_compliment={isComply}
                  is_permit={true}
                  is_dynamic={true}
                  is_authorized={false}
                  compliment={2}
                  optionValue={companyInfo}
                  getRefreshRate={getRefreshRate}
                  getFinalRoomList={handleGetFinalRoomList}
                  getFinalDetailRoomList={handleGetFinalDetailRoomList}
                  defaultRate={null}
                />
              </Form.Item>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px" }}
              />

              <Row
                justify="end"
                align="middle"
                // style={{ padding: "15px 0px 0px" }}
                className="modal-btn-form"
              >
                <Button
                  className="submit-btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  //   disabled={data?.is_audit == true ? true : false}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleCancel}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
