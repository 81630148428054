// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// BASEAPI
// import { baseurl } from "../../../../../API/Config/Api";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Checkbox,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaMoneyBillWave } from "react-icons/fa";

// Import Notification
import {
  failedRoomCat,
  successRoomCat,
} from "../../../../Reusable/Notification/MasterNotif/Notification";

// Import Functions
import { CurrencySymbols } from "../../../../Reusable/Functions/Currency/Currency";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// Modal Form
const { confirm } = Modal;

// CODE
export default function JournalDetailTable(props) {
  // PROPS
  const { searchKey, is_search, dataDetail } = props;

  // Params
  const currency = CurrencySymbols().code;

  // CONTEXT
  const { getBillDetail, getJournalDetails } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  // ID
  const [editData, setEditData] = useState(null);
  const [roomCode, setRoomCode] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = () => {
    setLoading(true);

    getJournalDetails({
      type: "detail-reservation",
      // bill_as: dataDetail?.bill_alias,
      journal_as: dataDetail?.journal_alias,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Bill Detail Data => ", response);

        let res = response.data.msg;

        if (res != null) {
          let filtered = res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });
          console.log(filtered);
          setData(filtered);
        } else {
          setData([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
      },
    });
    /*
    getBillDetail({
      billDetail: "detail-reservation",
      res_id: dataDetail,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Bill Detail Data => ", response);

        let filtered = response.data.msg.filter((filt) => {
          if (filt.is_deleted == false || filt.is_deleted == null) {
            return filt;
          }
        });
        console.log(filtered);
        setData(filtered);
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
      },
    });
    */
  };

  // USE EFFECT Data
  useEffect(() => {
    if (dataDetail) {
      fetchData();
    }
  }, [dataDetail]);

  // Use Effect Search
  useEffect(() => {
    // is_search(() => handleSearch);
  }, [searchKey]);

  // Table Columns
  const columnsArticle = [
    /*
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-poscashier-bill-table-btn"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FormOutlined
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(true);
                      setEdit(true);
                      setEditData(record);
                      form.setFieldsValue({
                        room_category_code: record.room_category_code,
                        room_category_description:
                          record.room_category_description,
                        room_category_id: record.room_category_id,
                        room_category_name: record.room_category_name,
                        created_by: record.created_by,
                        created_date: record.created_date,
                        modified_by: record.updated_by,
                        modified_date: record.updated_date,
                      });
                    }}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    */
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    /*
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sortDirections: ["descend", "ascend"],
    },
    */
    {
      title: "Trx Date",
      dataIndex: "trx_date",
      key: "trx_date",
      // sortDirections: ["descend", "ascend"],
      render: (trx, record) => {
        return trx ? moment(trx).format("DD-MM-YYYY") : "-";
      },
    },
    {
      title: `Debit ( ${currency} )`,
      dataIndex: "debit",
      key: "debit",
      // sortDirections: ["descend", "ascend"],
      render: (debt) => {
        return `${debt > 0 ? debt : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Credit ( ${currency} )`,
      dataIndex: "credit",
      key: "credit",
      // sortDirections: ["descend", "ascend"],
      render: (credit) => {
        return `${credit > 0 ? credit : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "COA No.",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
      sortDirections: ["descend", "ascend"],
    },
  ];

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    form.resetFields();
    setEditData(null);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (record) => {
    let del = record;

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to delete room ${record.room_category_name.toUpperCase()} from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(del);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    console.log(value);
    let content = value;

    handleSubmit(content);
  };
  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    console.log("ON OK ==> ", e);
    let contentSubmit = e;

    // if (edit == false) {
    //   axios
    //     .post(`${baseurl}/room/category`, {
    //       room_category_id: contentSubmit.room_category_id,
    //       room_category_code: contentSubmit.room_category_code,
    //       room_category_name: contentSubmit.room_category_name,
    //       room_category_description: contentSubmit.room_category_description,
    //       created_by: "DEVELOPER",
    //       created_date: moment().format("YYYY-MM-DD"),
    //       // updated_by: "17",
    //       // deleted_by: "NULL",
    //       // is_deleted: null,
    //       // deleted_date: null,
    //       // updated_date: null,
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       successRoomCat(response, { method: 0 });
    //       form.resetFields();
    //       handleCancel();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       failedRoomCat(error, { method: 0 });
    //     })
    //     .finally(() => {
    //       fetchData();
    //     });
    // } else {
    //   axios
    //     .put(`${baseurl}/room/category`, {
    //       room_category_id: contentSubmit.room_category_id,
    //       room_category_code: contentSubmit.room_category_code,
    //       room_category_name: contentSubmit.room_category_name,
    //       room_category_description: contentSubmit.room_category_description,
    //       id: editData.id,
    //       created_by: editData.created_by,
    //       created_date: editData.created_date,
    //       updated_by: "DEVELOPER",
    //       updated_date: moment().format("YYYY-MM-DD"),
    //       // is_deleted: false,
    //       // created_by: "DEVELOPER",
    //       // chart_of_account: contentsubmit.chart_of_account,
    //       // departement_id: contentsubmit.departement_id,
    //       // is_transaction: "",
    //       // liquiditas: "",
    //     })
    //     .then((response) => {
    //       console.log("Add Resp => ", response);
    //       successRoomCat(response, { method: 1 });
    //       form.resetFields();
    //       handleCancel();
    //     })
    //     .catch((error) => {
    //       console.log("Error Add => ", error);
    //       failedRoomCat(error, { method: 1 });
    //     })
    //     .finally(() => {
    //       fetchData();
    //     });
    // }
  };

  // HANDLE DELETE
  const handleDelete = (record) => {
    const contentdelete = record.id;

    console.log("contentDelete >>>>>>", contentdelete);

    // var obj = {
    //   ["id"]: contentdelete,
    //   ["deleted_by"]: "DEVELOPER",
    // };

    // axios
    //   .delete(`${baseurl}/room/category`, {
    //     data: obj,
    //   })
    //   .then((response) => {
    //     console.log("Del Room Cat => ", response);
    //     successRoomCat(response, { method: 2 });
    //   })
    //   .catch((error) => {
    //     failedRoomCat(error, { method: 2 });
    //     console.log("Del Room => ", error);
    //   })
    //   .finally(() => {
    //     fetchData();
    //   });
  };

  // HANDLE SEARCH
  //   const handleSearch = () => {
  //     const query = searchKey;
  //     // console.log("Type = ", query);

  //     var updatedList = [...data];
  //     // console.log("Update List = ", updatedList);

  //     updatedList = updatedList.filter((item) => {
  //       // console.log("ITEM => ", item)
  //       return (
  //         item.room_category_name.toLowerCase().indexOf(query.toLowerCase()) !==
  //         -1
  //       );
  //     });
  //     // Trigger render with updated values
  //     setData(updatedList);
  //     // console.log("FILTERED => ", updatedList);
  //   };

  console.log(dataDetail);

  return (
    <>
      <Row
        className="poscashier-bill-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Typography
            className="text-journal-details"
            style={{
              fontSize: 15,
              fontWeight: 750,
              color: "#3F4254",
            }}
          >
            {`Journal Detail: ${
              dataDetail?.journal_alias ? dataDetail.journal_alias : ""
            }`}
          </Typography>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="journal-details-cat"
              name="journal-details-cat"
              key="journal-details-cat"
              columns={columnsArticle}
              dataSource={data}
              bordered
              loading={loading}
              size="small"
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              // scroll={{
              //   x: 1000,
              // }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              rowKey={(record) =>
                record.id ? record.id : record?.article_name || record.key
              }
            />
          </Row>

          {/* <Row justify="end" className="row-bill-btn" style={{ width: "100%" }}>
            <Button
              className="submit-btn"
              type="primary"
              onClick={handleOpen}
              //   icon={<PlusOutlined />}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                margin: "15px 15px 0px",
              }}
            >
              {`Process Bill`}
            </Button>
          </Row> */}
        </Col>
      </Row>

      <Modal
        className="add-edit-room-cat-modal"
        title={
          <Row>
            <FaMoneyBillWave
              type="primary"
              style={{ fontSize: 24, color: "#3699FF" }}
            />
            <Typography style={{ marginLeft: 15 }}>Pay</Typography>
          </Row>
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 15,
        }}
        footer={null}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={(e) => onFinish(e)}
          // onFinishFailed={onFinishFailed}
          name="poscashier-bill-modal-form"
          className="poscashier-bill-modal-form"
          style={{ padding: "36px 36px 0px 36px" }}
        >
          <Row gutter={30} justify="start">
            <Col span={24}>
              <Form.Item label="Table / Room" name="table_room">
                <Select
                  style={{ width: "100%" }}
                  options={[
                    {
                      value: "t1",
                      label: "T1",
                    },
                    {
                      value: "t2",
                      label: "T2",
                    },
                    {
                      value: "EXE401",
                      label: "EXE401",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Table
              className="poscashier-bill-cat"
              name="poscashier-bill-cat"
              key="poscashier-bill-cat"
              columns={columnsArticle}
              dataSource={data}
              bordered
              loading={loading}
              size="small"
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row gutter={30} justify="start">
            <Col span={8}>
              <Form.Item label="Receipient" name="receipient">
                <Select
                  style={{ width: "100%" }}
                  options={[
                    {
                      value: "hanah",
                      label: "Hanah",
                    },
                    {
                      value: "jonas",
                      label: "Jonas",
                    },
                    {
                      value: "george",
                      label: "George",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="PPN Entire Sale" name="ppn">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Service Entire Sale" name="service">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} justify="start">
            <Col span={8}>
              <Form.Item label="Discount" name="discount">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Amount Due" name="amount_due">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} justify="start">
            <Col span={16}>
              <Form.Item label="Notes" name="notes">
                <Input.TextArea placeholder="Notes" rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row gutter={30} justify="start">
            <Col span={24}>
              <Form.Item name="nonstay_guest">
                <Checkbox>Non Stay Guest</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} justify="start">
            <Col span={8}>
              <Form.Item label="Payment Type" name="payment_type">
                <Select
                  style={{ width: "100%" }}
                  options={[
                    {
                      value: "cash",
                      label: "Cash",
                    },
                    {
                      value: "transfer",
                      label: "Transfer",
                    },
                    {
                      value: "credit",
                      label: "Credit",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Payment Type Detail" name="payment_detail">
                <Select
                  style={{ width: "100%" }}
                  options={[
                    {
                      value: "bni",
                      label: "BNI",
                    },
                    {
                      value: "mandiri",
                      label: "Mandiri",
                    },
                    {
                      value: "bca",
                      label: "BCA",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Transfer Ref" name="transfer_ref">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row gutter={30} justify="start">
            <Col span={8}>
              <Form.Item label="Sub Total" name="sub_total">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Total Bill" name="total">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
          </Row>

          {edit == true ? (
            <>
              <Divider style={{ margin: "0px 0px 15px" }} />
              <Row gutter={30} className="created_date-room-cat">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input disabled placeholder="Created by" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input disabled placeholder="Created Date" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input disabled placeholder="Modified by" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-room-cat">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input disabled placeholder="Modified Date" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null}

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              icon={<PrinterOutlined />}
              // onClick={onFinish}
              className="print-btn"
              style={{
                marginRight: 30,
              }}
            >
              {`Print Bill`}
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Finish Transaction`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
