// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { ArrowLeftOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Table, Tag, Typography } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../Style/Global/Global";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// REACT ICONS
import { PiFileArchiveFill } from "react-icons/pi";
import { render } from "@testing-library/react";
import MasterFolioForm from "../../../Forms/MasterFolio/Form";

export default function ListBillPayTable({
  articleData,
  guestData,
  getReturnArticleData,
  getArticleData,
}) {
  // PARAMS
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  const [data, setData] = useState([]);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (articleData.length > 0 && !cleanUp) {
      setData(articleData);
    } else {
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [articleData]);

  // HANDLE MOVE
  const handleMove = (record) => {
    const filtered = data.filter((item) => item.key !== record.key);

    setData(filtered);
    getArticleData(filtered);
    getReturnArticleData(record);
  };

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 30,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-master-folio-table-btn"
              style={{ width: "100%" }}
            >
              <Button
                className="submit-btn"
                type="primary"
                onClick={() => {
                  handleMove(record);
                  console.log(record);
                }}
              >
                {`Move`}
                <ArrowLeftOutlined />
              </Button>
            </Row>
          </>
        );
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit, record) => {
        return `${unit > 0 ? unit : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    // {
    //   title: "Total Price",
    //   dataIndex: "unit_price",
    //   key: "unit_price",
    // },
  ];

  return (
    <>
      <Row
        className="master-folio-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} className="col-master-folio" style={{ padding: 30 }}>
          <Row className="row-modal-btn" justify="end">
            <Col span={8} className="col-exp-btn">
              <Typography
                style={{
                  fontSize: 17,
                  fontWeight: 750,
                }}
              >
                Bill Pay List
              </Typography>
            </Col>

            <Col span={16}>
              <Row justify="end">
                <Button
                  className="submit-btn"
                  onClick={() => {
                    setOpen((state) => !state);
                  }}
                  type="primary"
                  disabled={data.length > 0 ? false : true}
                  icon={
                    <PiFileArchiveFill
                      style={{
                        margin: "0px 5px 0px 0px",
                        fontSize: 20,
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 10px 10px 5px",
                    marginRight: "15px",
                    minWidth: 100,
                    borderColor: "transparent",
                  }}
                >
                  {`Submit Bill`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  // onClick={fetchData}
                >
                  Refresh
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="master-folio-table"
              key="master-folio-table"
              name="master-folio-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              //onChange={onChange}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                // y: 270,
                x: true,
              }}
              rowKey={(record) => record.key}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>

      <MasterFolioForm
        openModal={open}
        closeModal={setOpen}
        billData={data}
        guestData={guestData}
      />
    </>
  );
}
