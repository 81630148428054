// Import React Components
import React from "react";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { MdFastfood } from "react-icons/md";
import { SearchOutlined } from "@ant-design/icons";

// Import Page Components
import KitchenOrderTable from "../../../Components/Table/Outlet/Kitchen/Table";

// CODE
export default function KitchenOrderPage() {
  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="kitchen-order-main-layout">
            <Row
              className="kitchen-order-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                marginBottom: 1,
                // height: 500,
              }}
            >
              <MdFastfood
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                KITCHEN ORDER
              </Typography>
            </Row>

            <Row
              className="kitchen-order-content"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                // height: 500,
                padding: 30,
              }}
            >
              <KitchenOrderTable />
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}
