// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Typography,
  Card,
  Tooltip,
  Checkbox,
  DatePicker,
} from "antd";

// Import React Icons
import {
  SearchOutlined,
} from "@ant-design/icons/lib/icons";
import { MdOutlineDashboardCustomize } from "react-icons/md";

// Import Page Components
import MasterSalesType from "../../../Reusable/Outlet/Master/SalesType/SalesType";
import POSOutletPopover from "../../../Popover/POSOutlet/Popover";
import ModalTableOrder from "../../../Modals/Outlet/Order/Modal";
import ModalGuestInHouse from "../../../Modals/Outlet/Guest/GuestInHouse";
import ModalChangeTable from "../../../Modals/Outlet/Order/ChangeTableModal";
import ModalCancelOrder from "../../../Modals/Outlet/Order/CancelModal";

// Import Notification
import {
  failedFetch,
  failedPOSOrder,
  incomplete,
  successPOSOrder,
  successPOSChangeTable,
  failedPOSChangeTable,
  successPOSPayment,
  failedPOSPayment,
} from "../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import POSPopover from "../../../Popover/POSOutlet/POSPopover";
import ItemNewOrder from "../../../Modals/Outlet/Order/ItemNewOrder";
import POSSplitBill from "../../../Modals/Outlet/Order/POSSplitBill";

// Modal
const { confirm } = Modal;

// CODE
export default function PerTablePOS(props) {
  // Props
  const {
    tableSelected,
    articleItems,
    is_room_service,
    is_created,
    data_from_resv,
    tableAssign,
    selectedData,
  } = props;


  // USE REF
  const componentRef = useRef();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [tableKey, setTableKey] = useState(null);
  const [dataOrder, setDataOrder] = useState(null);
  // Edit
  const [dataEdit, setDataEdit] = useState(null);
  const [guest, setGuest] = useState(null);
  // Loading
  const [isRoomService, setIsRoomService] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cardLoad, setCardLoad] = useState(true);

  const [saveState, setSaveState] = useState(false);
  const [changeTableState, setChangeTableState] = useState(false);
  const [chargeToRoomState, setChargeToRoomState] = useState(false);
  const [deleteHeader, setDeleteHeader] = useState(false);

  // Selected Data & Keys
  const [select, setSelect] = useState(null);
  const [editingKey, setEditingKey] = useState("");
  const [billStatus, setBillStatus] = useState(0);

  const [edit, setEdit] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  // Modal
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openModalChangeTable, setOpenModalChangeTable] = useState(false);
  const [modalDeleteOrder, setModalDeleteOrder] = useState(false);
  const [modalSplit, setModalSplit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname } = useLocation();

  const isEditing = (record, index) => index === editingKey;

  // SET FIELDS EDIT
  const editData = (record, index) => {
    setEditingKey(index);

    form.setFieldsValue({
      item_quantity: record?.item_quantity ?? 0,
      gross: record?.gross ?? 0,
      total_price: record?.gross * record?.item_quantity ?? 0,
      // ...record,
    });
  };

  // FETCH ID
  const fetchTable = async () => {
    await axios
      .post(`${baseurl}/pos/order-table`, {
        // table_id: tableKey ? tableKey?.table_id : selectedData?.table_id ? selectedData?.table_id : tableSelected?.table_id,
        table_id: selectedData?.table_id,
      })
      .then((response) => {
        console.log("Table Selected: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg[0]?.i_bill_detail_pos_temp.filter(
            (e) => {
              if (e.is_deleted == false) {
                return e;
              }
            }
          );

          const _resMap = _res.map((x, i) => {
            return {
              ...x,
              serving_que: x.serving_order,
              key: i,
            }
          })

          setData(_resMap);
          setDataOrder(response.data.msg[0]);
          handleFormField(response.data.msg[0]);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {
        setCardLoad(false);
      });
  };

  const fetchOrder = async () => {
    await axios
      .post(`${baseurl}/pos/detail-order-id`, {
        // order_id_alias: tableKey?.order_id_alias,
        order_id_alias: selectedData?.order_id_alias,
      })
      .then((response) => {
        console.log("Bill Selected: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg[0]?.i_bill_detail_pos_temp.filter(
            (e) => {
              if (
                e.is_deleted == false &&
                e.is_paid == false &&
                e.bill_status == 0
              ) {
                return e;
              }
            }
          );

          const _resMap = _res.map((x, i) => {
            return {
              ...x,
              serving_que: x.serving_order,
              key: i,
            }
          });

          console.log("_resMap", _resMap)

          setData(_resMap);
          setDataOrder(response.data.msg[0]);
          handleFormField(response.data.msg[0]);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {
        setCardLoad(false);
      });
  };

  useEffect(() => {
    console.log("selectedData on useEffect PerTable", selectedData);
    if (selectedData?.table_id) {
      fetchTable();
    } else {
      fetchOrder();
    }

    if (selectedData?.id) {
      setBillStatus(1);
    }

    return () => {};
  }, [selectedData]);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (tableSelected && !cleanUp) {
      // console.log("Table Selected: ", tableSelected);

      setTableKey(tableSelected);
      // fetchTable();
    } else {
      setData([]);
    }

    if (articleItems && !cleanUp) {
      // console.log("Per Table: ", articleItems);

      setLoading(true);
      handleData(articleItems);
    } else {
      setData([]);
    }

    if (is_room_service === true && !cleanUp) {
      // console.log("Room Service: ", is_room_service);

      setIsRoomService(true);
    } else {
      setIsRoomService(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [tableSelected, articleItems, is_room_service]);

  useEffect(() => {
    let cleanUp = false;

    if (saveState == true && !cleanUp) {
      onFinish();
    }

    return () => {
      cleanUp = true;
    };
  }, [saveState]);

  useEffect(() => {
    form.setFieldsValue({ name: data_from_resv?.name });
  }, [data_from_resv]);

  const save = async (e, x) => {
    let index = e;
    let artName = x;
    try {
      const row = await form.validateFields();
      const newData = [...data];
      let find = data.findIndex((v) => v.article_name == artName);
      if (find > -1) {
        const item = newData[e];
        newData.splice(find, 1, {
          ...item,
          ...row,
        });
        localStorage.clear();
        setData(newData);
        setEditingKey("");
        setEdit(false);
        if (newData[find]?.id != null) {
          handleUpdateDetail(newData[find]);
        }
      } else {
        newData.push(row);
        localStorage.clear();
        setData(newData);
        setEditingKey("");
        setEdit(false);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const cancel = () => {
    localStorage.clear();
    setEditingKey("");
    setEdit(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex == "request_date" ? (
          <DatePicker
            ref={searchInput}
            format={"YYYY-MM-DD"}
            style={{
              width: "100%",
              marginBottom: 8,
              display: "block",
            }}
            onChange={(e) => {
              setSelectedKeys(e ? [moment(e).format("YYYY-MM-DD")] : []);
              // console.log(e);
            }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  // COLUMNS
  const columns = [
    {
      title: "Items",
      dataIndex: "article_name",
      key: "article_name",
      ...getColumnSearchProps("article_name"),
    },
    {
      title: "Serving Que",
      dataIndex: "serving_que",
      key: "serving_que",
    },
    {
      title: "Qty",
      dataIndex: "item_quantity",
      key: "item_quantity",
      editable: true,
      render: (qty, record) => {
        return qty;
      },
    },
    {
      title: "Split Qty",
      dataIndex: "split_qty",
      key: "split_qty",
      hidden: billStatus == 2 ? false : true,
      render: (text, record) => {
        return <InputNumber onChange={((val) => handleSplitBill(val, record.key))} defaultValue={0} min={0} max={record.item_quantity} />;
      },
    },
    {
      title: "Unit Price",
      dataIndex: "gross",
      key: "gross",
      editable: true,
      hidden: billStatus == 2 ? true : false,
      render: (price) => {
        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
      editable: true,
      hidden: billStatus == 2 ? true : false,
      render: (price, record) => {
        const total = price > 0 ? price : record.item_quantity * record.gross;

        return `${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ].filter(item => !item.hidden);

  // PRINT COL
  const printCol = [
    {
      title: "Items",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Qty",
      dataIndex: "item_quantity",
      key: "item_quantity",
    },
    {
      title: "Serving Que",
      dataIndex: "serving_id",
      key: "serving_id",
    },
    {
      title: "Unit Price",
      dataIndex: "gross",
      key: "gross",
      render: (price) => {
        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Unit Price",
      dataIndex: "gross",
      key: "gross",
      render: (price) => {
        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // Handle Data
  const handleData = (value) => {
    let _prev = value;

    if (data.length > 0) {
      console.log("Data Per-Table Length: ", data);

      let _notDoubled = [];

      let _double = data
        .filter((item) => {
          if (item?.id === _prev?.id) {
            return item;
          } else {
            _notDoubled.push(item);
          }
        })
        .map((value) => {
          let _newQty = value?.item_quantity + _prev?.item_quantity;
          // console.log("New QTY: ", _newQty);

          return {
            ...value,
            item_quantity: _newQty,
            serving_id: _prev?.serving_id,
            serving_que: _prev?.serving_que,
          };
        });

      // console.log("DOUBLE: ", _double);
      // console.log("DOUBLE is NOT: ", _notDoubled);

      if (_double?.length > 0) {
        setData([..._double, ..._notDoubled]);
      } else {
        setData([...data, { ..._prev, article_id: _prev?.id, id: null }]);
      }
    } else {
      // console.log("Data Per-Table: ", _prev);
      setData(_prev ? [{ ..._prev, article_id: _prev?.id, id: null }] : []);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // console.log(data);

  // HANDLE EDIT
  const handleEdit = (value) => {
    console.log("Data Edited: ", value);

    let _notUpdated = [];

    let _updated = data.filter((item) => {
      if (item?.id === value?.id) {
        return value;
      } else {
        _notUpdated.push(item);
      }
    });

    // console.log("Updated: ", _updated);
    // console.log("Not Updated: ", _notUpdated);

    if (_updated?.length > 0) {
      setData([value, ..._notUpdated]);
    } else {
      setData(data);
    }
  };

  // GET GUEST NAME
  const getGuestName = (value) => {
    const _guest = value;
    console.log(_guest);

    setGuest(_guest);

    setChargeToRoomState(true);

    form.setFieldsValue({
      name: _guest?.length > 0 ? _guest[0]?.guest_name : _guest.guest_name,
      reservation_id:
        _guest?.length > 0 ? _guest[0]?.reservation_id : _guest.reservation_id,
      payment_status: true,
    });
  };

  // SHOW MODAL
  const showConfirmModal = (value) => {
    const _data = value;

    confirm({
      className: "confirm",
      title: `POS Order Confirmation`,
      content: `Are you sure you want to add an Order for ${(
        _data?.name || " NON STAY GUEST "
      ).toUpperCase()} with ${tableKey?.table_code.toUpperCase()}`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_data);
        setSaveState(false);
        Modal.destroyAll();
      },

      onCancel() {
        setIsLoading(false);
        setSaveState(false);
        Modal.destroyAll();
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // SHOW MODAL
  const showConfirmChargeToRoomModal = (value) => {
    const _data = value;

    confirm({
      className: "confirm",
      title: `POS Order CHARGE TO ROOM Confirmation`,
      content: `Are you sure you want to add an Order for ${(
        _data?.name || " NON STAY GUEST "
      ).toUpperCase()} with CHARGE TO ROOM`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_data);
        setChargeToRoomState(false);
        Modal.destroyAll();
      },

      onCancel() {
        setIsLoading(false);
        setChargeToRoomState(false);
        Modal.destroyAll();
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // MODAL CONFIRM DELETE
  const showModalUpdate = (e, v) => {
    const _content = e;
    const _index = v;

    confirm({
      className: "modal-confrim",
      title: `Edit POS Article Confirmation`,
      content: `Are you sure want to edit this article ?`,
      closable: true,
      centered: true,
      okText: "Confirm",
      width: 800,

      onCancel() {
        setIsLoading(false);
      },

      onOk() {
        save(_content, _index);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },
      cancelButtonProps: {
        className: "refresh-btn",
        type: "default",
      },
    });
  };

  // MODAL CONFIRM DELETE
  const showModalDelete = (value) => {
    const _content = value;

    confirm({
      className: "modal-confrim",
      title: `Delete POS Article Confirmation`,
      content: `Are you sure want to delete this article ?`,
      closable: true,
      centered: true,
      okText: "Confirm",
      width: 800,

      onCancel() {
        setIsLoading(false);
      },

      onOk() {
        if (_content) {
          handleDelete(_content);
        } else {
          handleCancel();
        }
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },
      cancelButtonProps: {
        className: "refresh-btn",
        type: "default",
      },
    });
  };

  // Handle Form Field
  const handleFormField = (value) => {
    const _record = value;

    form.setFieldsValue({
      name: _record?.name,
      sales_type: _record?.sales_type,
      payment_status: _record?.payment_status != 1 ? true : false,
    });
    if (_record?.payment_status != 1) {
      setChargeToRoomState(true);
    }
  };

  // Modal Open Edit Modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Modal Show Modal
  const handleShow = () => {
    // setIsShow(true);
  };

  // Handle Sales Type
  const getSalesType = (value) => {
    console.log(value);

    form.setFieldsValue({
      sales_type: value?.value ?? 2,
    });

    if (value?.value == 1) {
      setOpenModalChangeTable(true);
    }
  };

  // Open Modal Guest In House
  const handleGuestName = () => {
    setModalOpen(true);
  };

  const handleChargeToRoom = async (e) => {
    const _data = e;
    let val = data.map((e, i) => {
      return {
        cust_amount: null,
        bill_status: null,
        order_id_alias: data[0]?.order_id_alias,
        created_by: null,
      };
    });
    await axios
      .post(
        `${baseurl}/pos/order-payment`,
        {
          data: val,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("POS Payment: ", response);

        if (response.status === 200) {
          successPOSPayment(response, { method: 2 });

          // successArticleStay(response, { method: 0 });
          // navigate(`${pathname}/payment/success`, {
          //   state: {
          //     headerData: headerData,
          //     detailData: e,
          //     payment_id: response?.data?.msg,
          //     selectedTable: tableKey,
          //   },
          // });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedPOSPayment(error, { method: 2 });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  // Handle Cancel
  const handleCancel = async (value) => {
    const _data = value;
    // console.log(data);

    if (data.length > 0) {
      console.log("ABOUT TO DELETE ", data[0]?.order_id_alias);

      let obj = {
        order_id_alias: data[0]?.order_id_alias,
        deleted_by: user_name,
      };

      await axios
        .delete(`${baseurl}/pos/order-header`, {
          data: obj,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("DEL POS Bill Header >> ", response);

          successPOSOrder(response, { method: 2 });
          fetchTable();
        })
        .catch((error) => {
          console.log("ERROR DEL POS Bill Header >> ", error);
          failedPOSOrder(error, { method: 2 });
        });
    } else {
      let deleted = data.filter((item) => item.id !== _data.id);

      console.log("Deleted Arr: ", deleted);
      setData(deleted);

      // setIsShow(false);
      setIsLoading(false);
      setSaveState(false);

      form.resetFields();
      setData([]);
    }
  };

  // ON FINISH
  const onFinish = (value) => {
    const _data = value ?? form.getFieldsValue();
    setIsLoading(true);

    console.log("On Finish: ", _data);

    if (saveState != true && chargeToRoomState != true) {
      navigate(`${pathname}/payment`, {
        state: {
          headerData: {
            ..._data,
            payment_status: _data?.payment_status != true ? 1 : 2,
          },
          detailData: data,
          selectedTable: selectedData,
          is_split: false,
          is_join: false,
        },
      });
    } else if (saveState != true && chargeToRoomState == true) {
      showConfirmChargeToRoomModal(_data);
    } else {
      showConfirmModal(_data);
    }
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const _error = value;

    console.log("On Finish FAILED: ", _error);
    incomplete(_error);
  };

  // Handle Delete
  const handleDelete = async (value) => {
    const _data = value;
    // console.log(data);

    if (_data.id != null) {
      console.log("ABOUT TO DELETE ", _data);

      let obj =
        // {
        //   id: _data?.id,
        //   deleted_by: user_name,
        // }
        {
          order_id_alias: _data?.order_id_alias,
          deleted_by: user_name,
          deleted_reason: "delete detail",
        };
      await axios
        .delete(`${baseurl}/pos/order-detail`, {
          data: obj,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("DEL POS Bill Detail >> ", response);

          successPOSOrder(response, { method: 2 });
          fetchTable();
        })
        .catch((error) => {
          console.log("ERROR DEL POS Bill Detail >> ", error);
          failedPOSOrder(error, { method: 2 });
        });
    } else {
      let deleted = data.filter((item) => item.id !== _data.id);

      console.log("Deleted Arr: ", deleted);
      setData(deleted);
    }
  };

  // Handle Print
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Bill No. `,
    // onAfterPrint: () => alert("Print Success"),
  });

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const _data = value;
    let _totalNett = 0;
    let _totalTax = 0;
    let _totalSer = 0;
    let _totalGross = 0;

    data.forEach(({ unit_price, tax, service, gross, item_quantity }) => {
      let _tGross = gross * item_quantity;
      let _tTax = tax * item_quantity;
      let _tSer = service * item_quantity;
      let _tNett = unit_price * item_quantity;

      _totalNett += _tNett;
      _totalSer += _tSer;
      _totalTax += _tTax;
      _totalGross += _tGross;
    });

    // console.log("About TO ORDER: ", _data);
    // console.log("TABLE SELECTED: ", tableKey);

    if (tableKey?.status_table === 1) {
      await axios
        .post(
          `${baseurl}/pos/order-header`,
          {
            name: _data?.name ?? "NON STAY GUEST",
            table_code: tableKey?.table_code,
            table_id: tableKey?.table_id,
            payment_type: null,
            payment_details: null,
            payment_status: _data?.payment_status != true ? 1 : 2,
            total_nett: _totalNett,
            total_gross: _totalGross,
            total_tax: _totalTax,
            total_service: _totalSer,
            order_step: 1,
            is_open_bill: 1,
            sales_type: _data?.sales_type,
            order_id_alias: data_from_resv?.order_id_alias ?? null,
            reservation_id: _data?.reservation_id,
            created_by: user_name,
          },
          {
            headers: { Authorization: `Bearer ${token ? token : ""}` },
          }
        )
        .then(async (response) => {
          console.log("Add Headers: ", response);
          if (response.status === 200) {
            successPOSOrder(response, { method: 0 });
            await axios
              .get(`${baseurl}/pos/last-order-id-header`)
              .then((res) => {
                console.log("Res Last ID: ", res);

                if (res?.data?.length > 0) {
                  handleDetail(res.data[0], _data);
                }
              })
              .catch((error) => {
                console.log("Res Last Err: ", error);
              });
          } else {
            failedPOSOrder(response, { method: 0 });
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          failedPOSOrder(error, { method: 0 });
        });
    } else {
      await axios
        .get(`${baseurl}/pos/last-order-id-header`)
        .then(async (res) => {
          if (res.status === 200) {
            console.log("Res Last ID: ", res);

            if (res?.data?.length > 0) {
              console.log("Get Last Order Id Header: ", res);
              console.log("DATA >>>", data);

              await axios
                .put(
                  `${baseurl}/pos/order-header`,
                  {
                    id: data[0]?.header_id,
                    name: _data?.name ?? "NON STAY GUEST",
                    table_code: data[0]?.table_code,
                    table_id: data[0]?.table_id,
                    payment_type: null,
                    payment_details: null,
                    payment_status: _data?.payment_status != true ? 1 : 2,

                    total_nett: data
                      .map((e) => ({
                        nett: e.unit_price * e.item_quantity ?? 0,
                      }))
                      .reduce((prev, v) => prev + v.nett, 0),

                    total_gross: data
                      .map((e) => ({
                        gross: e.gross * e.item_quantity ?? 0,
                      }))
                      .reduce((prev, v) => prev + v.gross, 0),

                    total_tax: data
                      .map((e) => ({
                        tax: e.tax * e.item_quantity ?? 0,
                      }))
                      .reduce((prev, v) => prev + v.tax, 0),

                    total_service: data
                      .map((e) => ({
                        service: e.service * e.item_quantity ?? 0,
                      }))
                      .reduce((prev, v) => prev + v.service, 0),

                    order_step: data[0]?.order_step,
                    is_open_bill: null,
                    sales_type: _data?.sales_type,
                    updated_by: user_name,
                    old_table_code: "Table 6",
                    old_table_id: "T6",
                    reservation_id: _data?.reservation_id,
                  },
                  {
                    headers: { Authorization: `Bearer ${token ? token : ""}` },
                  }
                )

                .then(async (response) => {
                  if (response.status === 200) {
                    console.log("Edit Header: ", response);
                    successPOSOrder(response, { method: 1 });
                    handleDetail(res.data[0], _data);
                  } else {
                    failedPOSOrder(response, { method: 1 });
                  }
                })
                .catch((error) => {
                  console.log("Error: ", error);
                  failedPOSOrder(error, { method: 1 });
                });
            } else {
              // failedPOSOrder(response, { method: 0 });
            }
          }
        })

        .catch((error) => {
          console.log("Res Last Err: ", error);
        });

      // await axios
      //   .put(
      //     `${baseurl}/pos/order-header`,
      //     {
      //       id: _data?.id,
      //       name: _data?.name,
      //       tableCode: _data?.table_code,
      //       tableId: _data?.table_id,
      //       payment_type: _data?.payment_type === true ? 2 : 1,
      //       payment_details: _data?.payment_details,
      //       payment_status: _data?.payment_status,
      //       total_nett: _totalNett,
      //       total_gross: _totalGross,
      //       total_tax: _totalTax,
      //       total_service: _totalSer,
      //       order_step: _data?.order_step,
      //       is_open_bill: _data?.is_open_bill,
      //       sales_type: _data?.sales_type,
      //       updated_by: user_name,
      //       old_table_code: "T6",
      //       old_table_id: 6,
      //     },
      //     {
      //       headers: { Authorization: `Bearer ${token ? token : ""}` },
      //     }
      //   )
      //   .then(async (response) => {
      //     console.log("Edit Headers: ", response);

      //     if (response.status === 200) {
      //       successArticleStay(response, { method: 0 });

      //       await axios
      //         .get(`${baseurl}/pos/last-order-id-header`)
      //         .then((res) => {
      //           console.log("Res Last ID: ", res);

      //           if (res?.data?.length > 0) {
      //             handleDetail(res.data[0], _data);
      //           } else {
      //             failedArticleStay(res, { method: 0 });
      //           }
      //         })
      //         .catch((error) => {
      //           console.log("Res Last Err: ", error);
      //         });
      //     }
      //   })
      //   .catch((error) => {
      //     console.log("Error: ", error);
      //     failedArticleStay(error, { method: 0 });
      //   });
    }
  };

  // Handle Details
  const handleDetail = async (value, record) => {
    const _bill_id = value;
    let bulks = [];

    for (let i = 0; i < data.length; i++) {
      if (tableKey?.status_table == 1) {
        bulks.push({
          id: data[i]?.id,
          article_id: data[i]?.article_id,
          article_sub_name: data[i]?.article_sub_name,
          article_sub_id: data[i]?.article_sub_id,
          article_name: data[i]?.article_name,
          unit_price: data[i]?.unit_price,
          item_quantity: data[i]?.item_quantity,
          total_price: data[i]?.gross * data[i]?.item_quantity,
          table_code: tableKey?.table_code,
          table_id: tableKey?.table_id,
          serving_order: data[i]?.serving_order ?? "no Option",
          guest_name: record?.name ?? "NON STAY GUEST",
          chart_of_account: data[i]?.chart_of_account,
          tax: data[i]?.tax,
          service: data[i]?.service,
          is_tax: true,
          is_service: true,
          order_step: 0,
          gross: data[i]?.gross,
          add_ons: data[i]?.note || null,
          notes: data[i]?.note || null,
          payment_status: record?.payment_status !== true ? 1 : 2,
          order_id_alias: _bill_id?.order_id_alias,
          header_id: _bill_id?.id,
          created_by: user_name,
        });
      } else {
        if (data[i]?.id == null) {
          bulks.push({
            id: data[i]?.id,
            article_id: data[i]?.article_id,
            article_sub_name: data[i]?.article_sub_name,
            article_sub_id: data[i]?.article_sub_id,
            article_name: data[i]?.article_name,
            unit_price: data[i]?.unit_price,
            item_quantity: data[i]?.item_quantity,
            total_price: data[i]?.gross * data[i]?.item_quantity,
            table_code: tableKey?.table_code,
            table_id: tableKey?.table_id,
            serving_order: data[i]?.serving_que,
            guest_name: record?.name ?? "NON STAY GUEST",
            chart_of_account: data[i]?.chart_of_account,
            tax: data[i]?.tax,
            service: data[i]?.service,
            is_tax: true,
            is_service: true,
            order_step: 0,
            gross: data[i]?.gross,
            add_ons: data[i]?.note || null,
            notes: data[i]?.note || null,
            payment_status: record?.payment_status !== true ? 1 : 2,
            order_id_alias: data[0]?.order_id_alias,
            header_id: data[0]?.header_id,

            created_by: user_name,
          });
        }
      }
    }

    // console.log("LAST RES ID: ", _bill_id);

    await axios
      .post(
        `${baseurl}/pos/order-detail`,
        {
          data: bulks.length > 0 ? bulks : null,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Add Detail: ", response);

        if (response.status === 200) {
          successPOSOrder(response, { method: 0 });
          if (chargeToRoomState == true && saveState != true) {
            handleChargeToRoom();
          }
          setTimeout(() => {
            is_created(true);
          }, 1000);
        } else {
          failedPOSOrder(response, { method: 0 });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedPOSOrder(error, { method: 0 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateDetail = async (e, v) => {
    const val = e;
    await axios
      .put(
        `${baseurl}/pos/order-detail`,
        // {
        //   unit_price: val.unit_price,
        //   item_quantity: val.item_quantity,
        //   total_price: val.total_price,
        //   serving_order: val.serving_order,
        //   guest_name: val.guest_name,
        //   updated_by: user_name,
        //   tax: val.tax,
        //   service: val.service,
        //   gross: val.gross,
        //   add_ons: val.add_ons,
        //   id: val.id,
        //   article_id: val.article_id,
        // },
        {
          deleted_reason: "test delete reason",
          notes: val.notes,
          id: val.id,
          created_by: user_name,
          item_quantity: val.item_quantity,
          serving_order: val.serving_order,
          total_price: val.total_price,
          tax: val.tax,
          service: val.service,
          is_tax: val.is_tax,
          is_service: val.is_service,
          gross: val.gross,
          add_ons: val.add_ons,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Update Detail: ", response);

        if (response.status === 200) {
          successPOSOrder(response, { method: 1 });

          // setTimeout(() => {
          //   is_created(true);
          // }, 1000);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedPOSOrder(error, { method: 1 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClear = () => {
    setBillStatus(0);
    setData([]);
    setChargeToRoomState(false);
    setDataOrder(null);
    is_created(true);
    form.resetFields();
  };

  const handleSplitBill = (splitQty, item) => {
    console.log("SPLIT BILL", splitQty);
    let splitBill = data.map((x) => {
      if(x.key === item) {
        return {
          ...x,
          split_quantity: splitQty,
        }
      } else {
        return x
      }
    });

    console.log("splitBill", splitBill);
    setData(splitBill);
  }

  return (
    <>
      <Row className="row-pertable" style={mainBody}>
        <Col
          ref={componentRef}
          className="table-col"
          span={24}
          style={{ width: "100%", padding: "0px 30px 30px" }}
        >
          <Card
            className="table-card"
            bordered
            loading={cardLoad}
            style={{
              width: "100%",
              // marginTop: 16,
            }}
            title={
              <Row justify="center" align="middle" className="title-row">
                <Col span={24} className="title-col">
                  <Typography
                    className="bill-txt"
                    key={"txt-bill"}
                    style={{ fontSize: 20 }}
                  >
                    {selectedData?.order_id_alias || data_from_resv?.order_id_alias || dataOrder?.order_id_alias || "New Bill"}
                  </Typography>
                </Col>
              </Row>
            }
            headStyle={{
              textAlign: "center",
              background: "#e4e6ef",
            }}
            bodyStyle={{
              textAlign: "center",
              display: tableKey?.table_id || tableKey?.room_id || selectedData?.table_id ?  "block" : "none",
            }}
          >
            <Typography
              className="bill-txt"
              key={"txt-bill"}
              style={{ fontSize: 20, fontWeight: 500 }}
            >
              {tableKey?.room_id || tableKey?.table_id || selectedData?.table_id || "No Table"}
              {/* {tableKey?.status_table === 1
                ? data_from_resv?.order_id_alias ?? "New Bill"
                : tableKey?.status_table === 2
                ? dataOrder?.order_id_alias || data_from_resv?.order_id_alias
                : `${tableKey?.reservation_id || "New Reservations!"}`} */}
            </Typography>
          </Card>

          <Form
            form={form}
            className="order-form"
            key="order-form"
            name="order-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            // initialValues={{
            //   payment_type: false,
            // }}
            layout="vertical"
            style={{
              padding: "15px 0px 0px",
              margin: 0,
            }}
          >
            <Row className="mainmodal-row" justify="start">
              <Col className="mainmodal-col" span={24}>
                <Row className="name-row" justify="start" gutter={30}>
                  <Col span={12} className="name-col">
                    <Form.Item
                      label="Name"
                      name="name"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, Input a Name!",
                      //   },
                      // ]}
                    >
                      <Input
                        className="name-input"
                        placeholder="Name"
                        // disabled={chargeToRoomState ? true : false}
                        disabled={true}
                        addonAfter={
                          <Tooltip
                            className="modal-tooltip"
                            title="Find Guest In-House"
                          >
                            <MdOutlineDashboardCustomize
                              className="suffix-icons"
                              onClick={handleGuestName}
                              style={{
                                fontSize: 20,
                                color: "#3699FF",
                                marginTop: 3,
                              }}
                            />
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                    <Form.Item label="" name="reservation_id" hidden />
                  </Col>

                  <Col span={8} className="sales-col">
                    <Form.Item
                      label="Sales Type"
                      name="sales_type"
                      rules={[
                        {
                          required: true,
                          message: "Please, Input the Sales Type!",
                        },
                      ]}
                    >
                      <MasterSalesType
                        getSalesType={getSalesType}
                        sales_type={
                          {
                            1: "Dine-In",
                            2: "Take Away",
                            3: "Online Order",
                          }[form.getFieldsValue().sales_type] ?? "Take Away"
                        }
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Row justify="end" align="bottom" style={{ marginTop: 35 }}>
                      <Space>
                        {/* <Tooltip
                          title="Saved Order List"
                          className="option-tooltip"
                        >
                          <TfiMenuAlt
                            className="menu-btn"
                            style={{
                              fontSize: 20,
                              color: "#000000",
                              display: "flex",
                              justifyContent: "center",
                              // marginRight: 15,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpenModalSavedBill(true);
                            }}
                          />
                        </Tooltip> */}
                        <Tooltip title="Option Menu" className="option-tooltip">
                          {/* old_version */}
                          {/* <POSOutletPopover
                            headers={{
                              ...form.getFieldsValue(),
                              payment_status:
                                form.getFieldsValue().payment_status != true
                                  ? 1
                                  : 2,
                            }}
                            details={data.map((e) => ({
                              ...e,
                              payment_status: e.payment_status != true ? 1 : 2,
                            }))}
                            selectedTable={(e) => {
                              setTableKey(e);
                              tableAssign(e);
                            }}
                            is_refresh={null}
                            is_save={(e) => {
                              form.submit();
                              setSaveState(e);
                            }}
                          /> */}
                          {/* end_old_version */}
                          <POSPopover
                            headers={{
                              ...form.getFieldsValue(),
                              payment_status:
                                form.getFieldsValue().payment_status != true
                                  ? 1
                                  : 2,
                            }}
                            details={data.map((e) => ({
                              ...e,
                              payment_status: e.payment_status != true ? 1 : 2,
                            }))}
                            selectedTable={(e) => {
                              setTableKey(e);
                              tableAssign(e);
                            }}
                            is_refresh={null}
                            is_save={(e) => {
                              form.submit();
                              setSaveState(e);
                            }}
                            getAddNewState={() => {setBillStatus(2)}}
                            selectedData = {selectedData}
                            getClear={handleClear}
                          />
                        </Tooltip>
                      </Space>
                    </Row>
                  </Col>
                </Row>

                <Row
                  className="pax-row"
                  justify="start"
                  align="top"
                  gutter={30}
                >
                  {/* <Col span={8} className="name-col">
                    <Form.Item
                      label="Pax"
                      name="pax"
                      rules={[
                        {
                          required: true,
                          message: "Please, Input the Pax!",
                        },
                      ]}
                    >
                      <InputNumber
                        className="pax-input"
                        placeholder="Total Pax"
                        min={0}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col> */}

                  <Col span={8} className="check-col">
                    <Form.Item
                      label=""
                      name="payment_status"
                      valuePropName="checked"
                      // defaultChecked={true}
                      // initialValue={false}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, Choose a Type!",
                      //   },
                      // ]}
                    >
                      <Checkbox
                        className="pax-input"
                        placeholder="Total Pax"
                        disabled
                        min={0}
                        onChange={(e) => {
                          if (e == true) {
                            setChargeToRoomState(true);
                          }
                        }}
                        style={{
                          width: "100%",
                        }}
                      >
                        {`Charge To Room`}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Form.Item
              name="article_list"
              label=""
              rules={[
                {
                  validator: async (keys) => {
                    form.setFieldsValue({
                      article_list: data,
                    });

                    if (data.length === 0) {
                      return Promise.reject("Please, Select a Menu!");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Table
                className="ordertable-tbl"
                name="ordertable-tbl"
                key="ordertable=tbl"
                loading={loading}
                columns={columns}
                dataSource={data}
                bordered
                size="medium"
                pagination={false}
                //   {
                //   pageSize: 10,
                //   showTotal: (total, range) =>
                //     `Showing ${range[0]}-${range[1]} of ${total} entries`,
                // }
                scroll={{
                  x: true,
                }}
                // rowKey={(record) => record?.article_id}
                rowKey={(record) => record?.key}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "odd" : "even";
                }}
                summary={(data) => {
                  let total = data.reduce((acc, init) => {
                    let _total = init?.gross * init?.item_quantity;

                    return acc + _total;
                  }, 0);

                  return (
                    <> 
                      {billStatus == 2 ? null : (<Table.Summary
                        fixed
                        key="summary-tables"
                        className="sum-table"
                      >
                        <Table.Summary.Row
                          className="sum-order"
                          key="sum-order"
                        >
                          <Table.Summary.Cell
                            className="cum-cell"
                            key="sum-cell"
                            colSpan={3}
                          >
                            <Typography.Text strong>
                              {`Grand Total`}
                            </Typography.Text>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell
                            className="cum-cell"
                            key="sum-total"
                            colSpan={1}
                          >
                            <Typography.Text strong>
                              {`${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography.Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>)}
                    </>
                  );
                }}
              />
            </Form.Item>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row className="row-modal-btn" justify="end" align="middle">

              <Button
                type="primary"
                htmlType="submit"
                key={"submit-charge"}
                onClick={(e) => {}}
                // disabled={data.some((e) => e.id === null) ? true : false}
                hidden={billStatus == 1 ? false : true}
                loading={isLoading}
                className="submit-btn"
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Submit & Charge`}
              </Button>

              <Button
                type="primary"
                hidden={billStatus == 2 ? false : true}
                onClick={() => {
                  if(data.filter((x) => {
                    if(x.split_quantity > 0) {
                      return true
                    } else {
                      return false
                    }
                    }).length > 0) {
                      setModalSplit(true);
                  } else {
                    incomplete({errorFields:[{errors: ["Please, set Split Quantity!"]}]});
                  }
                }
                }
                loading={isLoading}
                className="submit-btn"
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Split Bill`}
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                disabled={billStatus == 1 ? false : true}
                onClick={(e) => {
                  setDataEdit({
                    order_id_alias:
                      dataOrder?.order_id_alias ||
                      data_from_resv?.order_id_alias,
                  });
                  setModalDeleteOrder(true);
                  setDeleteHeader(true);
                }}
              >
                {`Cancel Bill`}
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>

      <ModalTableOrder
        openModal={open}
        closeModal={setOpen}
        articleItems={dataEdit}
        is_edit={true}
        articleTable={handleEdit}
        is_refresh={(e) => {
          fetchTable();
          // if (e == true) {
          //   setTimeout(() => {
          //     is_created(true);
          //   }, 1000);
          // }
        }}
      />

      <ModalCancelOrder
        openModal={modalDeleteOrder}
        closeModal={setModalDeleteOrder}
        deleteItem={dataEdit}
        isHeader={deleteHeader}
        is_refresh={(e) => {
          if (deleteHeader) {
            if (e == true) {
              setTimeout(() => {
                is_created(true);
                handleClear();
              }, 1000);
            }
          } else {
            fetchTable();
          }
        }}
      />

      <ModalGuestInHouse
        openModal={modalOpen}
        closeModal={setModalOpen}
        getGuestName={getGuestName}
      />

      {/* <ModalSavedBill
        openModal={openModalSavedBill}
        closeModal={setOpenModalSavedBill}
        selectedTable={(e) => {
          setTableKey(e);
          tableAssign(e);
        }}
      /> */}

      <ModalChangeTable
        openModal={openModalChangeTable}
        closeModal={setOpenModalChangeTable}
        selectedTable={async (e) => {
          setChangeTableState(true);
          await axios
            .put(
              `${baseurl}/pos/order-header`,
              {
                id: data[0]?.header_id,
                name: form.getFieldsValue(["name"]).name ?? "NON STAY GUEST",
                table_code: e?.table_code,
                table_id: e?.table_id,
                payment_type: null,
                payment_details: null,
                payment_status: data[0]?.payment_status != true ? 1 : 2,

                total_nett: data
                  .map((e) => ({
                    nett: e.unit_price * e.item_quantity ?? 0,
                  }))
                  .reduce((prev, v) => prev + v.nett, 0),

                total_gross: data
                  .map((e) => ({
                    gross: e.gross * e.item_quantity ?? 0,
                  }))
                  .reduce((prev, v) => prev + v.gross, 0),

                total_tax: data
                  .map((e) => ({
                    tax: e.tax * e.item_quantity ?? 0,
                  }))
                  .reduce((prev, v) => prev + v.tax, 0),

                total_service: data
                  .map((e) => ({
                    service: e.service * e.item_quantity ?? 0,
                  }))
                  .reduce((prev, v) => prev + v.service, 0),

                order_step: data[0]?.order_step,
                is_open_bill: null,
                sales_type: form.getFieldsValue(["sales_type"]).sales_type,
                updated_by: user_name,
                old_table_code: data[0]?.table_code,
                old_table_id: data[0]?.table_id,
              },
              {
                headers: { Authorization: `Bearer ${token ? token : ""}` },
              }
            )
            .then(async (response) => {
              console.log("Edit Header: ", response);
              successPOSChangeTable(response, { method: 0 });
            })
            .catch((error) => {
              console.log("Error: ", error);
              failedPOSChangeTable(error, { method: 0 });
            });
        }}
      />
      <POSSplitBill
        openModal={modalSplit}
        closeModal={setModalSplit}
        getClear={handleClear}
        data={data}
      />
    </>
  );
}
