// Import React Components
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// AUTH
import { authorized_by } from "../../../API/Global/Permission";
import { token, user_name } from "../../../API/Global/Payload";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// BASE API
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Col,
  Row,
  Tabs,
  Space,
  Divider,
  Input,
  DatePicker,
  TimePicker,
  Select,
  InputNumber,
  Button,
  Checkbox,
  Modal,
  Form,
  Radio,
  Typography,
  Alert,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { MdOutlineDashboardCustomize } from "react-icons/md";

// Import Notification
import {
  failedAddReserv,
  failedArticleStay,
  failedFetch,
  incomplete,
  successAddReserv,
  successArticleStay,
} from "../../Reusable/Notification/Notification";

// Import Page Components
import ReservationRoomRateTable from "../../Table/FrontOffice/AddReservation/Table";
import ModalRoomPick from "../../Modals/RoomPick/RoomPick";

// Import Functions
import ParametersBreakfast from "../../Reusable/Parameters/ParamsBF";
import ResBody from "../../Reusable/Functions/ReservationBody/ResBody";
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";

// Master Components
import MasterPaymentCardEdit from "../../Reusable/Master/PaymentCard/PaymentCardEdit";
import MasterPaymentTypeEdit from "../../Reusable/Master/PaymentType/PaymentTypeEdit";
import MasterGuestCat from "../../Reusable/Master/GuestCat/GuestCat";
import MasterCurrency from "../../Reusable/Master/Currency/Currency";
import MasterReservationStatus from "../../Reusable/Master/ResStatus/ResStatus";
import MasterRateCode from "../../Reusable/Master/RateCode/RateCode";
import MasterComplimentType from "../../Reusable/Master/Compliment/Compliment";
import MasterSegment from "../../Reusable/Master/Segment/Segment";
import MasterSource from "../../Reusable/Master/Source/Source";
import MasterSalesman from "../../Reusable/Master/Salesman/Salesman";

import ReservationForm from "../../Forms/Reservation/Form";

// STYLING
import {
  styleActive,
  styleInactive,
  styleError,
} from "../../../Style/Global/Global";

// CONFIRM MODAL
const { confirm } = Modal;

// TAB PANE of TABS
const { TabPane } = Tabs;
const { TextArea } = Input;

// ON CHANGE
const onChangeTime = (time, timeString) => {
  console.log(time, timeString);
};

// CODE
export default function AddMemberGroupTabs() {
  // CONTEXT
  const { getGuest, getRatePrice, getReservation } = useContext(MainContext);
  const { getMasterPricePeriod, getMasterRatePrice, getMasterRoomCat } =
    useContext(MasterContext);

  // PARAMS
  const currency = CurrencySymbols().code;

  // GET DATE
  const date = new Date(Date.now());
  const disabledDate = (current) => {
    // Can not select days before today and today
    // console.log(current);
    return current <= moment(date);
  };

  // STATE MANAGEMENT
  // ADD NEW RESERVATION
  const [data, setData] = useState([]);
  const [reservationData, setReservationData] = useState([]);
  const [guestMaster, setGuestMaster] = useState([]);
  // Date
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  // Payment
  const [payCode, setPayCode] = useState({
    name: "",
    type_id: 0,
  });
  const [payId, setPayId] = useState({
    name: "",
    card_id: 0,
  });
  const [payment_status, setPayment_status] = useState(null);
  // Room
  const [roomCode, setRoomCode] = useState([]);
  // Active Key Tab Pane
  const [activeKey, setActiveKey] = React.useState("1");
  // MODAL STATE
  const [open, setOpen] = useState(false);
  const [fieldsValue, setFieldsValue] = useState(null);
  // PRICE ID
  const [lastPriceId, setLastPriceId] = useState(0);
  // Guest ID
  const [guestId, setGuestId] = useState(null);
  // ROOM RATE
  const [segmentID, setSegmentID] = useState(null);
  const [subSegment, setSubSegment] = useState({
    sales_id: 0,
    source_id: 0,
  });
  const [roomList, setRoomList] = useState([]);
  const [finalRoomList, setFinalRoomList] = useState([]);
  const [finalDetailRoomList, setFinalDetailRoomList] = useState([]);
  const [roomQty, setRoomQty] = useState(0);
  const [roomPrice, setRoomPrice] = useState();
  const [selectedPrice, setSelectedPrice] = useState([]);
  // Adult Value
  const [people, setPeople] = useState(0);
  // PRICE ROOM
  const [periodEmpty, setPeriodEmpty] = useState(false);
  const [rCode, setRcode] = useState([]);
  const [rateCode, setRateCode] = useState(null);
  const [segmentation, setSegmentation] = useState(null);
  const [informations, setInformations] = useState(null);
  // Boolean State
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [isBreakfast, setIsBreakfast] = useState(false);
  const [isComply, setIsComply] = useState({
    compliment_id:
      reservationData?.compliment_type > 0
        ? reservationData.compliment_type
        : 2,
    compliment_value:
      reservationData?.compliment_type === 1
        ? true
        : reservationData?.compliment_type === 3
        ? true
        : false,
  });
  const [isLoading, setIsLoading] = useState(false);

  // DEFAULT RATE CODE
  const [defaultRate, setDefaultRate] = useState(null);

  // SEGMENT CONDITION
  const [dynamic, setDynamic] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  // COMPLIMENT
  const [compliment, setCompliment] = useState(null);

  // Option Value
  const [optionValue, setOptionValue] = useState([]);

  // Type Group
  const [typeGroup, setTypeGroup] = useState(0);

  // Form
  const [show, setShow] = useState(false);
  const [created, setCreated] = useState(false);
  // ERROR STATE
  const [error, setError] = useState(false);

  // TOTAL NIGHT
  let totalNight = moment(endDate).diff(startDate, "days");

  // USE FORM
  const [form] = Form.useForm();

  // USE NAVIGATE & LOCATION
  const navigate = useNavigate();
  const { state, key } = useLocation();

  // PARAMS
  const breakfast = ParametersBreakfast().breakfast;

  // Fetch Room
  const fetchPriceId = async (value) => {
    const _data = value;

    await getReservation({
      reservation: "reservation",
      type: "stay",
      onAwait: () => {
        // setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Master => ", response);

        // let arr = [];
        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;
          handleFilter(_res);
        }
        // arr.push(newRoom);
        //   console.log(newRoom);
        //   setArticleRoom(newRoom);
        //   console.log("TESTTTT >>>", response);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        failedFetch(error);
        // setLoading(false);
      },
    });

    await getGuest({
      guest: "guest",
      type: "list",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest Master => ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return val?.id === _data?.guest_id;
          });

          let newData = filtered.map((gst) => ({
            value: gst?.full_name,
            label: gst?.full_name,
            nationality: gst?.nationality,
            id: gst?.id,
            birthdate: gst?.birthdate,
            rate_code: gst?.rate_code,
            rate_id: gst?.rate_code_id,
            segment_name: gst?.main_segment_name,
            segment_id: gst?.main_segment_id,
            company_name: gst?.company_name,
            company_id: gst?.master_comp_id,
            is_allow: gst?.allow_ledger,
            credit_limit: gst?.credit_limit,
          }));

          console.log("GUEST LIST == ", newData);
          setGuestMaster(newData?.length > 0 ? newData[0] : []);
        } else {
          setGuestMaster([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });

    // await axios
    //   .post(`${baseurl}/reservation/stay-all`, allBodyReq, {
    //     headers: { Authorization: `Bearer ${token}` },
    //   })
    //   .then(async (response) => {
    //     console.log("Create >> ", response);

    //     // NOTIFS
    //     successAddReserv(response, { method: 0 });
    //     successArticleStay(response, { method: 0 });
    //     move();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     failedAddReserv(error, { method: 0 });
    //     failedArticleStay(error, { method: 3 });
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (state?.state && !cleanUp) {
      console.log("State Leader Data --> ", state?.state);

      fetchPriceId(state?.state);

      setReservationData(state?.state);
      handleData(state?.state);
    }

    return () => {
      cleanUp = true;
    };
    // console.log("Res Edit Data: ", state.state.payment_status);
  }, [state]);

  // CREATED DATA
  useEffect(() => {
    let cleanUp = false;

    if (created == true && !cleanUp) {
      if (breakfast) {
        // handleUpdate(data);
        handleAddMember(data);
        setCreated(false);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [created]);

  // Rate Code
  // useEffect(() => {
  //   let cleanUp = false;

  //   if ((rCode || rateCode) && !cleanUp) {
  //     handleRate(rateCode);
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [rCode, rateCode]);

  // SET ACTIVE KEY TABS
  const onKeyChange = (key) => {
    // console.log(key);
    setActiveKey(key);
  };

  // Handle Data
  // Handle Data
  const handleData = (val) => {
    const _data = val;

    setStartDate(_data?.arrival);
    setEndDate(_data?.departure);
    setPayment_status(_data?.payment_status);
    setTypeGroup(_data?.is_abf == true ? 1 : 0);
    setIsBreakfast(_data?.is_abf);
    // setArrangement(_data?.arrangement);

    // SET INFO & CONFIRM
    setSegmentation(_data?.segment);
    // setCategoryId(_data?.arrangement?.room_category_id);

    setPayCode({
      type_id: _data?.arrangement?.payment_type_id,
      name: _data?.payment_type,
    });

    setPayId({
      card_id: _data?.arrangement?.payment_card_id,
      name: _data?.payment_card,
    });

    setSubSegment({
      sales_id: _data?.arrangement?.sales_id,
      source_id: _data?.arrangement?.source_id,
    });
  };

  // HANDLE FILTER PRICE ID
  const handleFilter = (values) => {
    const _response = values;
    let _id = 0;

    console.log("Data Res", _response);

    const _totalRes = _response.filter((items) => {
      return items.reservation_id == state?.state?.reservation_id;
    });

    // if (_response[0].get_article_room_master_bill_guest?.length) {
    // console.log("Loop ", i);

    // let _filteredRoom = _response[0].get_article_room_master_bill_guest;

    // let _newId = _filteredRoom.reduce((acc, init) => {
    //   console.log("Last Price ID: ", acc, " > ", init?.price_id);

    //   if (init?.price_id > acc) {
    //     acc = init?.price_id;
    //   }

    //   return acc;
    // }, 0);

    console.log("New ID: ", _totalRes);
    // console.log("New ID FIXED: ", _id);
    // console.log("Last : ", _id);
    setLastPriceId(_totalRes?.length);
    // }
  };

  // HANDLE START DATE AND END DATE
  // Get Start Date
  const handleFirstDate = (first) => {
    const valueOfInput1 = moment(first.format()).toArray();
    setStartDate(valueOfInput1);
    // console.log("start date", valueOfInput1);

    if (endDate) {
      var ngt = moment(endDate).diff(first, "days");
      form.setFieldsValue({
        night: ngt,
        room_qty: 1,
        adult: 1,
      });
      console.log("Start = ", ngt);
    }
  };

  // Get End Date
  const handleLastDate = (last) => {
    const valueOfInput2 = moment(last.format()).toArray();
    setEndDate(valueOfInput2);
    // console.log("last date", valueOfInput2);
    var ngt = moment(last).diff(startDate, "days");

    console.log(ngt);

    form.setFieldsValue({
      night: ngt,
      room_qty: 1,
      adult: 1,
    });
  };

  // HANDLE MASTER
  // Arrangement
  const getArrange = (value) => {
    form.setFieldsValue({
      arrangement: value,
    });
  };
  // Guest Status
  const getCategory = (value) => {
    form.setFieldsValue({
      guest_category: value,
    });
  };
  // Payment Type
  const getPaymentType = (value) => {
    console.log("Payment type: ", value);

    setPayCode({
      name: value?.name,
      type_id: value?.id,
    });

    form.setFieldsValue({
      payment_type: value?.name,
    });
  };
  // Payment Details/Card
  const getPayCard = (value, code, name) => {
    console.log("payment Detals:", value, code, name);

    setPayId({
      card_id: value?.id,
      name: value?.name,
    });

    form.setFieldsValue({
      payment_details: value?.name,
    });
  };
  // Currency
  const getCurrency = (value) => {
    form.setFieldsValue({
      currency: value,
    });
  };
  // Reservation Status
  const getResStatus = (value) => {
    form.setFieldsValue({
      reservation_status: value,
    });
  };

  // Source
  const getSource = (value) => {
    setSubSegment({
      sales_id: subSegment.sales_id,
      source_id: value?.source_id,
    });

    form.setFieldsValue({
      source: value,
    });
  };
  // Room Category
  const setRoom = (value) => {
    // const room_set = [];

    // room_set.push(value);
    const room_set = value;
    console.log("SET ROOM: ", room_set);

    let cat = [];
    let r_code = [];
    let r_id = [];
    let r_num = [];
    let r_qty = [];

    for (let i = 0; i < room_set?.length; i++) {
      // const element = value[i];

      cat.push(room_set[i].room_category);

      r_code.push(room_set[i].room_code);

      r_num.push(room_set[i].room_no);

      r_qty.push(room_set[i].room_qty);

      r_id.push(room_set[i].room_category_id);

      // console.log("ISI ROOM CAT >> ", cat);
      // console.log("ISI ROOM QUANTITY >> ", r_qty);
    }
    setRcode(r_code);

    console.log(r_code);
    console.log("TEST RUN SET RCODE IS RUNNING....");

    // Total Room
    let toy = r_qty.reduce(function (s, v) {
      return s + (v || 0);
    }, 0);

    setRoomQty(toy);

    state.state.room_category = cat;
    reservationData.room_category = cat;

    // Set Fields
    form.setFieldsValue({
      room_category: cat,
      room_code: r_code,
      room_qty: toy,
      room_category_id: r_id,
      room_number: r_num.map((e) => e).flat(),
      room_modal: room_set,
      // adult: cat.length,
    });

    setIsRender(true);
    setRoomList([]);
    setInformations(null);
    setSegmentation(null);

    // setPeople(cat.length);
  };

  // Segment
  const getSegment = (value, id, code, dynamic, authorized) => {
    console.log(
      "Segment: ",
      value,
      " ID: ",
      id,
      " is_dynamic: ",
      dynamic,
      " is_authorized: ",
      authorized
    );

    setSegmentID(id);
    setSegmentation(value);
    setDynamic(dynamic);
    setAuthorized(authorized);

    if (roomList?.length > 0) {
      setRoomList([]);
    }

    setInformations(null);

    form.setFieldsValue({
      segment: value,
      compliment_type: 2,
      abf_total: 0,
    });
  };

  // const getSegment = (value, code) => {
  //   console.log("Values: ", value);
  //   console.log("ID's: ", code);

  //   setSegmentID(code);
  //   setSegmentation(value);

  //   if (roomList?.length > 0) {
  //     setRoomList([]);
  //   }

  //   setInformations(null);

  //   form.setFieldsValue({
  //     segment: value,
  //     compliment_type: 2,
  //     abf_total: 0,
  //   });
  // };
  const getSubSegment = (value, id) => {
    console.log(value, id);

    setSubSegment(id);

    if (roomList?.length > 0) {
      setRoomList([]);
    }

    form.setFieldsValue({
      sub_segment: value,
    });
  };

  // Compliment Type
  const getCompliment = (value, key, allKey) => {
    console.log("Key: ", key, value);
    console.log("Compliment Allkey ->> ", allKey);

    if (value) {
      setCompliment(value);
    } else {
      null;
    }

    if (key == 1 || key == 3) {
      setIsComply({
        compliment_id: key,
        compliment_value: true,
      });
    } else {
      setIsComply({
        compliment_id: key,
        compliment_value: false,
      });
    }

    form.setFieldsValue({
      compliment_type: value,
    });
  };

  // Rate Code
  const getRateCode = (value, code) => {
    console.log("RATE CODE ID = ", code);
    console.log("RATE CODE ID = ", code);

    // console.log("TEST getRateCode IS RUNNING....");
    setRateCode(code);

    if (code?.description) {
      setInformations(code?.description);
    }

    handleRate(value, code);
    setDefaultRate(code);

    form.setFieldsValue({
      rate_code: value,
    });
  };

  // GUEST NAME, BILL RECEICER & BILL INSTRUCTION
  const getGuestName = (value, code) => {
    console.log(value, code);

    setGuestId(code);

    form.setFieldsValue({
      guest_name: value,
      bill_receiver: value,
      bill_instruction: value,
    });
  };
  // GROUP CODE
  const getGroup = (value) => {
    form.setFieldsValue({
      group: value,
    });
  };
  // GET PRICE
  const getPrice = (value) => {
    console.log("ROOM PRICE === ", value);

    form.setFieldsValue({
      room_price: value,
    });
  };

  // RADIO STATUS PAYMENTS
  const onRadioChange = async (e) => {
    console.log("radio checked", e.target.value);

    setPayment_status(e.target.value);

    form.setFieldsValue({
      payment_status: e.target.value,
    });
  };

  // GET ADULT
  const getAdult = (value) => {
    console.log(value);
    setPeople(value);

    form.setFieldsValue({
      // child: value,
      adult: value,
      // infant: value,
    });
  };
  // GET CHILD
  const getChild = (value) => {
    console.log(value);

    form.setFieldsValue({
      child: value,
      // adult: value,
      // infant: value,
    });
  };
  // GET ADULT
  const getInfant = (value) => {
    console.log(value);

    form.setFieldsValue({
      // child: value,
      // adult: value,
      infant: value,
    });
  };

  // Get Night Value
  var countNight = moment(endDate).diff(startDate, "days");

  // Navigate to Page
  const move = () => {
    // console.log(location);

    navigate(`/front-office/reservation`);
  };

  // Sales
  const getSalesman = (value, opt) => {
    console.log("Value: ", value);
    // console.log("OPT: ", opt);

    setSubSegment({
      sales_id: opt?.sales_id,
      source_id: subSegment.source_id,
    });

    form.setFieldsValue({
      sales: value,
    });
  };

  // GET ROOM PRICE
  const getRoomPrice = (val) => {
    console.log("VALUE: ", val);

    setRoomPrice(val);
  };

  // HANDLE PRICE
  const handleRate = async (value, code) => {
    const content = value;

    console.log("Handle Rate --> ", content);

    // await getMasterRatePrice({
    //   rate: "rate-code",
    //   category: "category-res",
    //   rate_code: content,
    //   room_cat_code: rCode.join("|"),
    //   segment: segmentID,
    //   sub_segment: subSegment,

    if (content) {
      await getRatePrice({
        rate: "rate-code",
        price: "master-room-price",
        rate_code: content,
        onAwait: () => {
          "on Await";
        },
        onSuccess: (response) => {
          console.log("Rate Room Category >> ", response);

          // if (response?.data !== null) {
          if (response?.data?.msg?.length > 0) {
            const _res = response.data.msg;
            const ngt = form.getFieldsValue();

            console.log("Res Fixed => ", _res);
            console.log("PARAMS => ", ngt);

            handleRoom(_res, ngt);
          }
        },
        onReject: (error) => {
          console.log("ERROR >>>> ", error);
        },
      });
    }
  };

  // GENERATE ROOM PRICE
  const handleRoom = (res, params) => {
    // Array Container
    var rate = [];
    var group = [];
    // Assign Variables
    const _fixed = res;
    const _params = params;

    if (_fixed[0]?.room_breakfast > 0) {
      setTypeGroup(1);
    } else {
      setTypeGroup(0);
    }

    if (
      (_params?.room_category?.length == 1 && _params?.room_qty == 1) ||
      (_params?.room_category?.length > 1 && _params?.room_qty == 1)
    ) {
      console.log("First Condition Rate 1 Category 1 Night");
      // for (let i = 0; i < ngt.night; i++) {
      for (let j = 0; j < _fixed?.length; j++) {
        let newRate = {
          ..._fixed[j],
          date: moment(startDate).add(j, "day"),
          room_category_name: _fixed[j]?.room_category_name
            ? _fixed[j]?.room_category_name
            : _params.room_category,
          id: _fixed[j].id + j,
          room_no:
            _params?.room_modal[j]?.room_no?.length > 0
              ? _params?.room_modal[j]?.room_no[j]
              : 0,
          rate_code_id: _fixed[0]?.id,
          room_number_id:
            _params?.room_modal[j]?.room_number_id?.length > 0
              ? _params.room_modal[j].room_number_id[j]
              : 0,
          room_category_id:
            _params?.room_modal[j]?.room_category_id > 0
              ? _params.room_modal[j].room_category_id
              : 0,
        };

        rate.push(newRate);
      }
      // }
    } else if (_params?.room_qty > 1 && _params?.room_category?.length == 1) {
      console.log("Second Condition Rate 1 Category more than 1 Room");
      for (let i = 0; i < _params?.room_qty; i++) {
        for (let j = 0; j < _fixed?.length; j++) {
          let newRate = {
            ..._fixed[j],
            date: moment(startDate),
            room_category_name: _fixed[j]?.room_category_name
              ? _fixed[j]?.room_category_name
              : _params.room_category[0],
            id: _fixed[j].id + i,
            room_number_id:
              _params?.room_modal[j]?.room_number_id?.length > 0
                ? _params.room_modal[j].room_number_id[i]
                : 0,
            room_no:
              _params?.room_modal[j]?.room_no?.length > 0
                ? _params.room_modal[j].room_no[i]
                : 0,
            room_number:
              _params?.room_modal[j]?.room_no?.length > 0
                ? _params.room_modal[j].room_no[i]
                : 0,
            rate_code_id: _fixed[0]?.id,
            room_category_id:
              _params?.room_modal[j]?.room_category_id > 0
                ? _params.room_modal[j].room_category_id
                : 0,
          };

          // console.log(newRate);
          rate.push(newRate);
        }
      }
    } else if (
      // ngt.night == 1 &&
      _params?.room_qty >= 1 &&
      _params?.room_category?.length >= 1
    ) {
      for (let h = 0; h < _params?.room_modal?.length; h++) {
        group.push({ ...params.room_modal[h], ..._fixed[0] });
      }

      console.log("Group: ", group);

      // _fixed.sort(
      //   (a, b) =>
      //     _params?.room_category.indexOf(a.room_category_name) -
      //     _params?.room_category.indexOf(b.room_category_name)
      // );

      console.log(
        "Third Condition Rate More than 1 Category and Night: ",
        _fixed
      );

      for (let i = 0; i < group?.length; i++) {
        for (let j = 0; j < _params.room_modal[i].room_qty; j++) {
          let toy = {
            ..._fixed[0],
            id: parseInt(_fixed[0].id++),
            room_category_name: group[i]?.room_category
              ? group[i]?.room_category
              : _params.room_category[i],
            room_number_id:
              group[i]?.room_number_id?.length > 0
                ? group[i].room_number_id[j]
                : 0,
            room_no: group[i]?.room_no?.length > 0 ? group[i].room_no[j] : 0,
            room_number:
              group[i]?.room_number?.length > 0 ? group[i]?.room_number[j] : 0,
            rate_code_id: _fixed[0]?.id,
            date: moment(startDate),
            room_category_id:
              _params?.room_category_id?.length > 0
                ? _params.room_category_id[i]
                : 0,
          };

          // rate = [...rate, toy];
          rate.push(toy);
          // console.log("Loop: ", rate);
          // console.log("Toy (New Object in Loops): ", toy);
        }
      }
    }

    console.log("Final Rate --> ", rate);
    setRoomList(rate);

    if (!_fixed[0]?.description) {
      setInformations(
        `${_fixed[0]?.room_category_name || "GROUP"} for ${
          _fixed[0]?.room_occupancy || 0
        } Adult(s) and ${_fixed[0]?.room_breakfast || 0} Pax of Breakfast(s)`
      );
    }

    form.setFieldsValue({
      room_price: rate,
      abf_total: _fixed[0]?.room_breakfast > 0 ? _fixed[0].room_breakfast : 0,
      is_abf: _fixed[0]?.room_breakfast > 0 ? true : false,
      segment: _fixed[0]?.main_segment_name,
      // rate_code: rate.length > 0 ? rate[0].rate_code_name : "",
      // compliment_type: "Room Only",
    });
  };

  // HANDLE ADULT
  const handleAdult = (value) => {
    const _adult = value.adult;
    const _qty = value.room_qty;

    let ad = 0;
    let last_ad = 0;

    ad = (_adult / _qty).toFixed(0);
    last_ad = _adult - _qty;

    console.log("Adulting: ", ad, last_ad);

    return { adult: ad, last_adult: last_ad };
  };

  // Handle Close
  const handleClose = () => {
    setIsLoading(false);
    setShow(false);
  };

  // ON SUBMIT FINISH
  const onFinish = (e) => {
    const value = e;
    setIsLoading(true);
    console.log("On Finish >>> ", e);

    setData(value);
    setError(false);

    // HANDLE ADULTS TOTAL
    if (value?.room_qty > 1 && value?.room_category?.length >= 1) {
      let _adult = handleAdult(value);
      console.log("Handle Adult", _adult);

      value.adult = parseInt(_adult.adult);
      value.last_adult = _adult.last_adult;
    }

    if (value?.room_price?.length > 0) {
      setShow(true);
    } else {
      onFinishFailed({
        errorFields: [
          {
            errors: [
              "Please, make sure Room Category, Segment, Sub-Segment and Rate Code to Get Rate!",
            ],
          },
        ],
      });
    }
  };

  // ON SUBMIT FINISH FAILED
  const onFinishFailed = (error) => {
    console.log("DATA SUBMIT >>> ", error);
    // setReservationData(e);
    setError(true);

    incomplete(error);
    setIsLoading(false);
  };

  // HANDLE DETAIL ROOM LIST
  const handleDetailRoomList = (arrRoomList) => {
    console.log("Arr Room List ->> ", arrRoomList);
    const flatResult = arrRoomList.flatMap((item) => {
      let arrDetails = [];
      let startDate = moment(item.date, "DD-MM-YYYY");

      for (let i = 0; i < totalNight; i++) {
        let currentDate = startDate.clone().add(i, "days");

        arrDetails.push({
          ...item,
          key: i,
          date: currentDate,
        });
      }

      return arrDetails;
    });

    return flatResult;
  };

  // OPTIONS VALUE
  const getOptions = (value) => {
    console.log("optionValue on Tabs", value);
    setOptionValue(value);
  };

  // GET REFRESH RATE CODE
  const getRefreshRate = async (e) => {
    console.log(`getRefreshRate ${e}`);
    setSegmentID(e);
  };

  // HANDLE FINAL ROOM LIST
  const handleGetFinalRoomList = (data) => {
    setFinalRoomList(data);
  };

  // HANDLE FINAL DETAIL ROOM LIST
  const handleGetFinalDetailRoomList = (data) => {
    setFinalDetailRoomList(data);
  };

  // HANDLE ADD MEMBER
  const handleAddMember = async (value) => {
    const submitData = value;

    let arr_key = [];
    let foreign_key = {
      payment_type_id: 0,
      payment_card_id: 0,
      room_category_id: 0,
      segment_id: 0,
      rate_code_id: 0,
      room_id: 0,
      sales_id: 0,
      source_id: 0,
      rate_code: "",
    };

    if (submitData?.rate_code != null || submitData?.rate_code != undefined) {
      arr_key.push(submitData?.rate_code);
      arr_key.push(submitData?.room_price[0]?.main_segment_id);
      arr_key.push(submitData?.room_price[0]?.id);

      foreign_key = {
        segment_id:
          submitData?.room_price[0]?.main_segment_id > 0
            ? submitData.room_price[0].main_segment_id
            : 0,
        payment_card_id: payId.card_id,
        payment_type_id: payCode.pay_id,
        rate_code: submitData?.rate_code || "",
        rate_code_id: defaultRate?.id || 0,
        room_category_id:
          submitData?.room_modal?.length > 0
            ? submitData.room_modal[0].room_category_id
            : 0,
        room_id:
          submitData?.room_modal[0]?.room_number_id?.length > 0
            ? submitData.room_modal[0].room_number_id[0]
            : 0,
        sales_id: subSegment.sales_id,
        source_id: subSegment.source_id,
      };
    }

    const detailRoomList =
      finalDetailRoomList.length > 0
        ? finalDetailRoomList
        : totalNight > 1
        ? handleDetailRoomList(roomList)
        : roomList;

    console.log("Detail RoomList ", detailRoomList);
    console.log("Final Room List ", finalRoomList);

    let allBodyReq = {};

    // MEMBER CHECKING
    if (roomList.length > 0) {
      const _member = (finalRoomList.length > 0 ? finalRoomList : roomList).map(
        (item, index) => {
          return {
            arrival: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            departure: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            night: submitData?.night ? submitData.night.toString() : 0,
            room_category: item?.room_category_id ? item.room_category_id : 0,
            room_number: item?.room_no > 0 ? item.room_no : 0,
            room_qty: 1,
            adult:
              submitData?.last_adult > 0
                ? submitData.adult + submitData.last_adult
                : parseInt(submitData.adult),
            child: submitData?.child > 0 ? parseInt(submitData.child) : 0,
            infant: submitData?.infant > 0 ? parseInt(submitData.infant) : 0,
            reservation_status: submitData?.reservation_status
              ? submitData.reservation_status
              : "",
            arrangement: foreign_key,
            rate_code: item?.details[0]?.rate_code || "",
            compliment_type: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            currency: submitData?.currency ? submitData.currency : "IDR",
            guest_name: submitData?.guest_name
              ? `${submitData.guest_name + " " + (lastPriceId + index + 1)}`
              : "",
            bill_receiver: submitData?.bill_receiver
              ? submitData.bill_receiver
              : "",
            bill_instruction: submitData?.bill_instruction
              ? submitData.bill_instruction
              : "",
            arrival_flight: submitData?.arrival_flight
              ? submitData.arrival_flight
              : "",
            eta: submitData?.eta ? submitData.eta : null,
            departure_flight: submitData?.departure_flight
              ? submitData.departure_flight
              : "",
            etd: submitData?.etd ? submitData.etd : null,
            group: 2,
            segment: submitData?.segment ? submitData.segment : "",
            source: submitData?.source ? submitData.source : "",
            letter: submitData?.letter ? submitData.letter : "",
            sales: submitData?.sales ? submitData.sales : "",
            deposit: submitData.deposit ? parseInt(submitData.deposit) : 0,
            payment_type: submitData?.payment_type
              ? submitData.payment_type
              : "",
            payment_details: submitData?.payment_details
              ? submitData.payment_details
              : "",
            memo_room: submitData?.memo_room ? submitData.memo_room : "",
            payment_status: submitData?.payment_status || null,
            is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
            is_group:
              submitData?.room_category?.length == 1 &&
              submitData?.room_qty == 1
                ? false
                : true,
            group_name: submitData?.group_name ? submitData.group_name : "",
            guest_category: submitData?.guest_category
              ? submitData.guest_category
              : "REGULAR",
            room_rate_id:
              item?.details?.map((detail) =>
                detail?.rate_code !== null && detail?.rate_code !== undefined
                  ? detail.rate_code
                  : defaultRate?.value
              ) || submitData?.rate_code,
            price_id: item.price_id,
            guest_id: reservationData?.guest_id,
            created_by: authorized_by ? authorized_by : user_name,
            is_abf: item?.details?.some(
              (detail) => detail.room_details === "Breakfast"
            ),
          };
        }
      );

      allBodyReq = {
        reservation_id: reservationData?.reservation_id
          ? reservationData?.reservation_id
          : "",
        group_name: submitData?.group_name ? submitData.group_name : "",
        is_abf:
          detailRoomList.some((item) => item.room_details === "Breakfast") ||
          submitData?.is_abf,
        member: _member,
      };
    }

    // MAPPING DETAIL ROOM RESERVATION FOR SUBMIT DATA
    const _roomReservation = detailRoomList?.map((item, index) => {
      return {
        room_price: item?.nett ? item.nett : 0,
        room_number: item?.room_no,
        date: item?.date ? moment(item.date).format("YYYY-MM-DD") : "",
        room_rate_id: item?.room_rate_id?.some(
          (item) => item !== null || item !== undefined
        )
          ? item.room_rate_id
          : arr_key,
        price_id: item?.price_id,
        is_tax: true,
        tax: item?.tax ? item.tax : 0,
        is_service: true,
        service: item?.service ? item.service : 0,
        created_by: authorized_by ? authorized_by : user_name,
        main_segment_id: item?.main_segment_id,
        main_compliment_id: 1,
        gross: item?.gross ? item.gross : 0,
        room_category: item?.room_category_id,
        chart_of_account: "400-01-010",
        rate_code_id: item?.rate_code_id
          ? item.rate_code_id
          : defaultRate?.value,
      };
    });

    // CHECK DETAIL ROOM LIST DATA CONTAINS THE VALUE "Breakfast" or NOT
    let is_abf = detailRoomList.some(
      (item) => item.room_details === "Breakfast"
    );

    // ABF CHECKING
    if (is_abf) {
      // IF TRUE
      // RESERVATIONS WITH BREAKFAST
      const _abfReservation = detailRoomList
        .filter((item) => item.room_details === "Breakfast")
        .map((item, index) => {
          return {
            article_id: breakfast?.id,
            article_sub_name: breakfast?.article_sub_name,
            article_sub_id: breakfast?.article_sub_id,
            article_name: breakfast?.article_name,
            unit_price:
              submitData.compliment_type != 2 ? 0 : breakfast?.unit_price,
            created_by: authorized_by ? authorized_by : user_name,
            item_quantity: item?.room_breakfast ? item.room_breakfast : 0,
            total_price:
              submitData.compliment_type != 2
                ? 0
                : item?.room_breakfast * breakfast?.gross,
            // gross breakfast * qty breakfast
            room_number: item?.room_no,
            posting_type: "",
            total_posting: "",
            start_date: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            end_date: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            chart_of_account: breakfast?.chart_of_account,
            is_tax: breakfast?.is_tax,
            tax: submitData?.compliment_type == 2 ? breakfast?.tax : 0,
            is_service: breakfast?.is_service,
            service: submitData?.compliment_type == 2 ? breakfast?.service : 0,
            price_id: item?.price_id,
            gross: submitData?.compliment_type == 2 ? breakfast?.gross : 0,
            order_date: item?.date
              ? moment(item?.date).format("YYYY-MM-DD")
              : "",
          };
        });

      allBodyReq = {
        ...allBodyReq,
        room_reservasi: _roomReservation,
        abf_reservasi: _abfReservation,
      };
    } else {
      // IF FALSE
      // RESERVATIONS W/O BREAKFAST
      allBodyReq = {
        ...allBodyReq,
        room_reservasi: _roomReservation,
      };
    }

    console.log("All Body Req ->> ", allBodyReq);

    // API POST
    await axios
      .post(`${baseurl}/reservation/stay-add-member`, allBodyReq, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("ALL BODY RES => ", response);

        successAddReserv(response, { method: 3 });
        successArticleStay(response, { method: 4 });

        navigate("/front-office/reservation");
      })
      .catch((error) => {
        console.log("ALL BODY ERR => ", error);

        failedAddReserv(error, { method: 3 });
        failedArticleStay(error, { method: 4 });
        setLoadingBtn(false);
      })
      .finally(() => {
        setLoadingBtn(false);
      });
  };

  // HANDLE UPDATE
  const handleUpdate = async (e) => {
    const submitData = e;
    console.log("Submit Data ==> ", submitData);
    // console.log("Price ==> ", roomList);

    // New Price & Key
    let arr_key = [];
    let prc = 0;
    let room_no = 0;
    let room_cate = [];

    console.log("Rate Code/Periodic = ", arr_key);
    console.log("Rate price = ", prc);
    console.log("Room Number >>> ", room_no);
    console.log("Room Category >>> ", room_cate);
    console.log("RES ID => ", lastPriceId);
    console.log("SUBMIT DATA => ", submitData);
    console.log("TYPE GROUP => ", typeGroup);
    console.log("Auth By: ", authorized_by);

    let _bodyToPost = ResBody({
      data: submitData,
      params_bf: breakfast,
      group: typeGroup,
      auth: authorized_by,
      price_id: lastPriceId,
      payment: { ...payCode, ...payId },
      subSegment: subSegment,
      arrange: reservationData?.arrangement,
    });

    console.log("BODY ABOUT TO POST: ", (await _bodyToPost).data);

    // if (submitData?.room_price != undefined) {
    //   arr_key.push(
    //     roomList[0]?.rate_code_id
    //       ? roomList[0].rate_code_id
    //       : roomList[0].promo_name
    //   );
    // } else {
    //   arr_key.push(0);
    // }

    /*
    switch (typeGroup) {
      case 1:
        for (let i = 0; i < submitData?.room_price?.length; i++) {
          allBody.push({
            reservation_id: reservationData.reservation_id
              ? reservationData?.reservation_id
              : "",
            arrival: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            departure: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            night: submitData?.night ? parseInt(submitData.night) : 0,
            room_category: submitData?.room_price[i]
              ? submitData.room_price[i]?.room_category_name
              : "",
            room_number:
              submitData?.room_number[i] != undefined
                ? submitData.room_number[i]
                : 0,
            deposit: 0,
            room_qty: 1,
            adult: 1,
            child: submitData?.child ? parseInt(submitData.child) : 0,
            infant: submitData?.infant ? parseInt(submitData.infant) : 0,
            reservation_status: submitData?.reservation_status
              ? submitData.reservation_status
              : "",
            arrangement: "",
            guest_category: submitData?.guest_category
              ? submitData.guest_category
              : "REGULAR",
            rate_code: submitData?.rate_code ? submitData.rate_code : "",
            compliment_type: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            currency: submitData?.currency ? submitData.currency : "IDR",
            // room_type: submitData?.room_type ? submitData.room_type : "",
            guest_name: submitData?.guest_name ? submitData.guest_name : "",
            bill_receiver: submitData?.bill_receiver
              ? submitData.bill_receiver
              : "",
            bill_instruction: submitData?.bill_instruction
              ? submitData.bill_instruction
              : "",
            arrival_flight: submitData?.arrival_flight
              ? submitData.arrival_flight
              : "",
            eta: submitData?.eta ? submitData.eta : null,
            departure_flight: submitData?.departure_flight
              ? submitData.departure_flight
              : "",
            etd: submitData?.etd ? submitData.etd : null,
            group: 2,
            segment: submitData?.segment ? submitData.segment : "",
            source: submitData?.source ? submitData.source : "",
            letter: submitData?.letter ? submitData.letter : "",
            payment_type: submitData?.payment_type
              ? submitData.payment_type
              : "",
            payment_details: submitData?.payment_details
              ? submitData.payment_details
              : "",
            memo_room: submitData?.memo_room ? submitData.memo_room : "",
            group_name: submitData?.group_name ? submitData.group_name : "",
            room_price: submitData.room_price[i]?.price
              ? submitData.room_price[i].price - 75000
              : 0,
            room_rate_id: arr_key ? arr_key : [0],
            price_id: lastPriceId + (1 + i),
            payment_status: submitData?.payment_status || null,
            sales: submitData?.sales ? submitData.sales : "",
            is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
            is_group: true,
            guest_id: guestId ? guestId : reservationData?.guest_id,
            created_by: user_name,

            main_compliment_id: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            main_segment_id: segmentID > 0 ? segmentID : null,
            // subsegment_id: subSegment > 0 ? subSegment : null,

            article_sub_id: 9,
            article_sub_name: "Main Course",
            article_id: 2194,
            article_name: "Additional Breakfast",
            unit_price: 75000,
            item_quantity: submitData?.night ? parseInt(submitData.night) : 0,
            total_price:
              (submitData?.night ? parseInt(submitData.night) : 0) * 75000,
            posting_type: "",
            total_posting: "",
            start_date: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            end_date: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            coa_abf: "410-01-020",
            is_tax_abf: false,
            tax_abf: 0,
            is_service_abf: false,
            service_abf: 0,
          });
        }

        console.log("DATA ARRAY GROUP WITH ABF ==== ", allBody);

        await axios
          .post(
            `${baseurl}/reservation/stay-and-abf`,
            { data: allBody },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            console.log("ALL BODY RES CASE 1 => ", response);

            successAddReserv(response, { method: 3 });
            successArticleStay(response, { method: 4 });

            navigate("/front-office/reservation");
          })
          .catch((error) => {
            console.log("ALL BODY ERR => ", error);

            failedAddReserv(error, { method: 3 });
            failedArticleStay(error, { method: 4 });
          });
        break;

      default:
        for (let i = 0; i < submitData?.room_price?.length; i++) {
          allBody.push({
            reservation_id: reservationData.reservation_id
              ? reservationData?.reservation_id
              : "",
            arrival: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            departure: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            night: submitData?.night ? parseInt(submitData.night) : 0,
            room_category: submitData?.room_price[i]
              ? submitData.room_price[i]?.room_category_name
              : "",
            room_number:
              submitData?.room_number[i] != undefined
                ? submitData.room_number[i]
                : 0,
            deposit: 0,
            room_qty: 1,
            adult: 1,
            child: submitData?.child ? parseInt(submitData.child) : 0,
            infant: submitData?.infant ? parseInt(submitData.infant) : 0,
            reservation_status: submitData?.reservation_status
              ? submitData.reservation_status
              : "",
            arrangement: "",
            guest_category: submitData?.guest_category
              ? submitData.guest_category
              : "REGULAR",
            rate_code: submitData?.rate_code ? submitData.rate_code : "",
            compliment_type: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            currency: submitData?.currency ? submitData.currency : "IDR",
            // room_type: submitData?.room_type ? submitData.room_type : "",
            guest_name: submitData?.guest_name ? submitData.guest_name : "",
            bill_receiver: submitData?.bill_receiver
              ? submitData.bill_receiver
              : "",
            bill_instruction: submitData?.bill_instruction
              ? submitData.bill_instruction
              : "",
            arrival_flight: submitData?.arrival_flight
              ? submitData.arrival_flight
              : "",
            eta: submitData?.eta ? submitData.eta : null,
            departure_flight: submitData?.departure_flight
              ? submitData.departure_flight
              : "",
            etd: submitData?.etd ? submitData.etd : null,
            group: 2,
            segment: submitData?.segment ? submitData.segment : "",
            source: submitData?.source ? submitData.source : "",
            letter: submitData?.letter ? submitData.letter : "",
            // deposit: submitData.deposit ? parseInt(submitData.deposit) : 100000,
            payment_type: submitData?.payment_type
              ? submitData.payment_type
              : "",
            payment_details: submitData?.payment_details
              ? submitData.payment_details
              : "",
            memo_room: submitData?.memo_room ? submitData.memo_room : "",
            group_name: submitData?.group_name ? submitData.group_name : "",
            room_price: submitData.room_price[i]?.price
              ? submitData.room_price[i].price
              : 0,
            room_rate_id: arr_key ? arr_key : [0],
            price_id: lastPriceId + (1 + i),
            payment_status: submitData?.payment_status || null,
            sales: submitData?.sales ? submitData.sales : "",
            is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
            is_group: true,
            guest_id: guestId ? guestId : reservationData?.guest_id,

            main_compliment_id: submitData?.compliment_type
              ? submitData.compliment_type
              : reservationData?.compliment_type,
            main_segment_id: segmentID > 0 ? segmentID : null,
            // subsegment_id: subSegment > 0 ? subSegment : submitData?.sub_segment,

            created_by: user_name,
          });
        }

        console.log("DATA ARRAY GROUP ROOM ONLY ==== ", allBody);

        await axios
          .post(
            `${baseurl}/reservation/stay`,
            { data: allBody },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            console.log("ALL BODY RES CASE 0 (DEFAULTS) => ", response);

            successAddReserv(response, { method: 3 });

            navigate("/front-office/reservation");
          })
          .catch((error) => {
            console.log("ALL BODY ERR => ", error);
            failedAddReserv(error, { method: 3 });
          });

        break;
    }
    */

    const allBody = (await _bodyToPost)?.data;

    if (allBody?.length > 0) {
      switch (typeGroup) {
        case 1:
          await axios
            .post(
              `${baseurl}/reservation/new-member-stay-abf`,
              { data: allBody },
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
              console.log("ALL BODY RES CASE 1 => ", response);

              successAddReserv(response, { method: 3 });
              successArticleStay(response, { method: 4 });

              navigate("/front-office/reservation");
            })
            .catch((error) => {
              console.log("ALL BODY ERR => ", error);

              failedAddReserv(error, { method: 3 });
              failedArticleStay(error, { method: 4 });
              setLoadingBtn(false);
            })
            .finally(() => {
              setLoadingBtn(false);
            });
          break;

        default:
          await axios
            .post(
              `${baseurl}/reservation/new-member-stay-normal`,
              { data: allBody },
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
              console.log("ALL BODY RES CASE 0 (DEFAULTS) => ", response);

              successAddReserv(response, { method: 3 });

              navigate("/front-office/reservation");
            })
            .catch((error) => {
              console.log("ALL BODY ERR => ", error);
              failedAddReserv(error, { method: 3 });
              setLoadingBtn(false);
            })
            .finally(() => {
              setLoadingBtn(false);
            });
          break;
      }
    }
  };

  // CHECK ON TAB CLICK
  const disabled = (e) => {
    console.log(e);
  };

  // CONSOLE DATA
  // console.log("DATA ADD RESERV ==>>> ", reservationData);
  // console.log("Room Modal => ", roomCode);
  // console.log("Start Date => ", startDate);
  // console.log("STATE => ", reservationData);
  // console.log("ROOM LIST => ", rCode);
  // console.log("sement_id >>>>", segmentID);
  // console.log("Location => ", location);
  // console.log("Type => ", state.type);
  // console.log("Selected Room Price = ", selectedPrice);
  // console.log("Selected Room = ", selectedRoom);
  // console.log("Selected Room Category = ", rCode);
  // console.log("PAY CODE >>> ", payCode);
  // console.log("PAY DETAILS CODE >>> ", payId);
  // console.log("Price ID Last => ", lastPriceId);

  // TAB CONTENTS
  const items = [
    {
      label: (
        <>
          <Row
            justify="start"
            align="middle"
            style={{ width: 297, height: 80 }}
          >
            <Col style={{ margin: "10px 20px 10px 10px" }}>
              <Button
                className="1st-icon-tab-reservation-btn"
                disabled
                // icon={<RiNumber1 />}
                type="primary"
                style={
                  activeKey == 1
                    ? styleActive
                    : error == true
                    ? styleError
                    : styleInactive
                }
              >
                1
              </Button>
            </Col>

            <Col>
              <Row style={{ height: 20 }}>
                <p style={error == true ? { color: "#f64e60" } : {}}>
                  {"Add Member Group"}
                </p>
              </Row>

              <Row style={{ height: 20 }}>
                <h4>Date and Guest Details</h4>
              </Row>
            </Col>
          </Row>
        </>
      ),
      key: "1",
      disabled: true,
      forceRender: true,
      children: (
        <>
          <Content
            className="tab-pane-layout"
            key={"tab-pane-layout"}
            style={{
              //padding: "60px 249px",
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 5px 5px",
            }}
          >
            <Row gutter={30} className="row-reserve-date">
              <Col span={8}>
                <Form.Item
                  label="Arrival"
                  name="arrival"
                  style={{ margin: "0px 30px 0px 0px", width: "100%" }}
                >
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    onChange={handleFirstDate}
                    disabledDate={disabledDate}
                    placeholder={"Start Date"}
                    style={{
                      width: "100%",
                    }}
                    disabled
                  />
                </Form.Item>

                <Form.Item label="Reservation ID" name="res_id" hidden={true}>
                  <Input disabled />
                </Form.Item>

                <Form.Item label="Guest ID" name="guest_id" hidden={true}>
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Departure"
                  name="departure"
                  style={{ margin: "0px 30px 0px 0px", width: "100%" }}
                >
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    placeholder={"End Date"}
                    disabledDate={disabledDate}
                    onChange={handleLastDate}
                    style={{
                      width: "100%",
                    }}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Night"
                  name="night"
                  rules={[
                    {
                      required: true,
                      message: "Please, Pick the Date!",
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder="Qty"
                    disabled
                    style={{ width: 100 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />

            <Row gutter={30} className="row-reserv-guest">
              <Col xxl={8} xl={8} lg={16} md={16}>
                <Form.Item
                  label="Guest Name"
                  name="guest_name"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input Guest Name!",
                    },
                  ]}
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <Input placeholder="Guest Member Group Name" />
                  {/* <MdOutlineDashboardCustomize /> */}
                </Form.Item>
              </Col>

              <Col xxl={8} xl={8} lg={12} md={12}>
                <Form.Item
                  label="Payment Type"
                  name="payment_type"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select the Payment Type!",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <MasterPaymentTypeEdit
                    getPaymentType={getPaymentType}
                    pay={
                      reservationData?.payment_type
                        ? reservationData.payment_type
                        : null
                    }
                    // getPayTypeId={setPayCode}
                  />
                </Form.Item>
              </Col>

              <Col xxl={8} xl={8} lg={12} md={12}>
                <Form.Item
                  label="Payment Details"
                  name="payment_details"
                  style={{ width: "100%" }}
                >
                  <MasterPaymentCardEdit
                    getPayCard={getPayCard}
                    getPayTypeId={payCode.type_id}
                    payCard={
                      reservationData?.payment_details
                        ? reservationData.payment_details
                        : null
                    }
                    // getCode={setPayId}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />

            <Row gutter={30} className="row-reserv-room-info">
              <Col xxl={8} xl={8} lg={16} md={16}>
                <Form.Item
                  label="Room Category"
                  name="room_category"
                  rules={[
                    {
                      required: true,
                      message: "Please, Pick a Room!",
                    },
                  ]}
                  // onChange={form.setFieldsValue({
                  //   room_category: state.room_category
                  //     ? state.room_category
                  //     : "",
                  // })}
                >
                  <Input
                    placeholder="Room Category"
                    disabled
                    addonAfter={
                      <MdOutlineDashboardCustomize
                        className="nav-icon"
                        onClick={() => {
                          // Data Fields
                          const fieldValue = form.getFieldsValue();
                          setFieldsValue(fieldValue);

                          console.log("Field Values >>> ", fieldValue);
                          setOpen(!open);
                        }}
                        style={{
                          fontSize: 20,
                          color: "#3699FF",
                          marginTop: 3,
                        }}
                      />
                    }
                    // onClick={() => {
                    //   setOpen(!open);
                    // }}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Room Category ID"
                  name="room_category_id"
                  hidden
                >
                  <Input />
                </Form.Item>
              </Col>

              <Form.Item name="room_modal">
                <ModalRoomPick
                  countNight={totalNight}
                  modalOpen={open}
                  setRoomCode={setRoomCode}
                  modalClose={setOpen}
                  setRoom={setRoom}
                  field={fieldsValue}
                />
              </Form.Item>

              <Col xxl={8} xl={8} lg={12} md={12}>
                <Form.Item label="Room Number" name="room_number">
                  <Input
                    placeholder="Choose an option"
                    disabled
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Room Quantity" name="room_qty">
                  <Input
                    // size="large"
                    placeholder="Qty"
                    disabled
                    style={{ width: 100 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="row-reserv-guest">
              <Col span={4}>
                <Form.Item
                  label="Adult"
                  name="adult"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input Total Guest!",
                    },
                    {
                      validator: async (_, adult) => {
                        let qty = form.getFieldValue("room_qty");
                        // console.log("ARRIVAL => ", arrival);
                        // console.log("DEPART => ", departure);
                        // console.log("DIFF", (departure = arrival));

                        if (qty != null)
                          if (adult <= qty - 1) {
                            return Promise.reject(
                              "Adult guest must be greater or equals to Room Quantity."
                            );
                          }
                      },
                    },
                  ]}
                  style={{ margin: "0px 30px 0px 0px" }}
                >
                  <InputNumber
                    size="large"
                    min={1}
                    // max={10}
                    placeholder="Total Adult"
                    style={{ width: 100 }}
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item size="large" label="Child" name="child">
                  <InputNumber
                    size="large"
                    min={0}
                    // max={10}
                    placeholder="Total Child"
                    style={{ width: 100 }}
                  />
                  {/* <MasterInputChild getChild={getChild} /> */}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Infant" name="infant">
                  <InputNumber
                    min={0}
                    size="large"
                    // max={10}
                    placeholder="Total Infant"
                    style={{ width: 90 }}
                  />
                  {/* <MasterInputInfant getInfant={getInfant} /> */}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Guest Status"
                  name="guest_category"
                  style={{ width: "100%" }}
                >
                  <MasterGuestCat
                    getCategory={getCategory}
                    guest_status={
                      reservationData?.guest_category
                        ? reservationData.guest_category
                        : null
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="form-divider"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />

            <Row
              justify="space-btn"
              align="top"
              className="row-button-next-tab-pane"
            >
              <Col span={12} className="text-col">
                <Typography
                  className="res-text"
                  style={{ fontSize: 15, fontWeight: 750 }}
                >
                  {reservationData?.reservation_id}
                </Typography>
              </Col>

              <Col
                span={12}
                className="text-col"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={move}
                  style={{ marginRight: 30 }}
                >
                  {`Cancel`}
                </Button>

                <Button
                  className="next-btn"
                  type="primary"
                  onClick={() => onKeyChange("2")}
                >
                  {`Next`}
                </Button>
              </Col>
            </Row>
          </Content>
        </>
        // </TabPane>
      ),
    },
    {
      label: (
        <>
          <Row
            justify="start"
            align="middle"
            style={{ width: 297, height: 80 }}
          >
            <Col style={{ margin: "10px 20px 10px 10px" }}>
              <Button
                className="2nd-icon-tab-reservation-btn"
                disabled
                // icon={<RiNumber1 />}
                type="primary"
                style={activeKey == 2 ? styleActive : styleInactive}
              >
                2
              </Button>
            </Col>

            <Col>
              <Row style={{ height: 20 }}>
                <p style={error == true ? { color: "#f64e60" } : {}}>
                  Add Reservation
                </p>
              </Row>
              <Row style={{ height: 20 }}>
                <h4>Room Details</h4>
              </Row>
            </Col>
          </Row>
        </>
      ),
      key: "2",
      disabled: true,
      forceRender: true,
      children: (
        <>
          <Content
            className="tab-pane-layout"
            key={"tab-pane-layout"}
            style={{
              // padding: "60px 249px",
              // padding: screen.lg == false ? "60px 249px" : 30,
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 5px 5px",
            }}
          >
            <Row gutter={30} className="room-segmentations">
              <Col span={6}>
                <Form.Item
                  label="Compliment Type"
                  name="compliment_type"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Compliment Type!",
                    },
                  ]}
                  style={{
                    marginRight: 30,
                    minWidth: "100%",
                  }}
                >
                  <MasterComplimentType
                    getCompliment={getCompliment}
                    compl={"No Compliment"}
                    is_disable={true}
                  />
                </Form.Item>
              </Col>
              {/* </Row>

            <Row gutter={30} className="room-segmentations"> */}
              <Col span={6}>
                <Form.Item
                  label="Segment"
                  name="segment"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Segment!",
                    },
                  ]}
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <MasterSegment
                    getSegment={getSegment}
                    sgmnt={segmentation}
                    is_render={isRender}
                    is_rerender={setIsRender}
                    is_company={guestMaster?.segment_id || []}
                  />
                </Form.Item>
              </Col>

              {/* <Col span={8}>
                <Form.Item
                  label="Sub Segment"
                  name="sub_segment"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Sub Segment!",
                    },
                  ]}
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <MasterSubSegment
                    getSubSegment={getSubSegment}
                    subSeg={
                      reservationData?.sub_segment_id
                        ? reservationData.sub_segment_id
                        : null
                    }
                    is_render={isRender}
                    is_rerender={setIsRender}
                  />
                </Form.Item>
              </Col> */}

              <Col span={12} className="abf-room-row">
                <Row
                  className="row-room-details"
                  justify="space-between"
                  // gutter={30}
                >
                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={24}
                    sm={24}
                    xs={24}
                    className="rate-col"
                  >
                    <Form.Item
                      label="Rate Code"
                      name="rate_code"
                      rules={[
                        {
                          required: true,
                          message: "Please, Select a Rate Code!",
                        },
                      ]}
                      style={{
                        marginRight: 15,
                        minWidth: "100%",
                      }}
                    >
                      <MasterRateCode
                        getRateCode={getRateCode}
                        segment_id={segmentID}
                        segment_name={segmentation}
                        // sub_segment_id={subSegment}
                        rate_code={null}
                        qty={roomQty}
                        roomCat={roomCode}
                        is_company={
                          guestMaster?.rate_id?.length > 0
                            ? guestMaster.rate_id
                            : []
                        }
                        allSegment={
                          guestMaster?.segment_id?.length > 0
                            ? guestMaster.segment_id
                            : []
                        }
                        // is_permitted={permitted}
                        is_render={isRender}
                        is_rerender={setIsRender}
                        getOptions={getOptions}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={24}
                    sm={24}
                    xs={24}
                    className="abf-col"
                  >
                    <Form.Item label="Total Breakfast" name="abf_total">
                      <InputNumber
                        placeholder="Total Breakfast"
                        min={0}
                        style={{
                          width: "75%",
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="IS ABF?"
                      // valuePropName="checked"
                      hidden
                      name="is_abf"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="rate-desc" style={{ width: "100%" }}>
              <Alert
                className="desc-alert"
                type="info"
                closable={false}
                showIcon
                message={"Segments & Rate Code Descriptions:"}
                description={
                  <Typography className="desc-text" style={{ fontWeight: 500 }}>
                    {`SEGMENTS: ${
                      segmentation ? segmentation.toUpperCase() : ""
                    } --- ${informations || ""}`}
                  </Typography>
                }
                style={{ width: "100%" }}
              />
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />

            <Row className="row-room-price">
              <Col span={24}>
                <Form.Item
                  name="room_price"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please, make sure Room Category, Segment, Sub-Segment and Rate Code to Get Rate!",
                    },
                  ]}
                >
                  <ReservationRoomRateTable
                    roomList={roomList}
                    setRoomPrice={getRoomPrice}
                    getPrice={getPrice}
                    night={totalNight}
                    is_compliment={isComply}
                    qty={roomQty}
                    is_breakfast={isBreakfast}
                    is_dynamic={dynamic ? true : false}
                    is_authorized={authorized ? true : false}
                    compliment={compliment}
                    optionValue={optionValue}
                    getRefreshRate={getRefreshRate}
                    getFinalRoomList={handleGetFinalRoomList}
                    getFinalDetailRoomList={handleGetFinalDetailRoomList}
                    defaultRate={defaultRate}
                    is_add_member={true}
                    lastPriceId={lastPriceId}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />

            <Row gutter={30} className="guest-name-reservation">
              <Col span={8}>
                <Form.Item
                  label="Reservation Status"
                  name="reservation_status"
                  style={{
                    marginRight: 30,
                    minWidth: "100%",
                  }}
                >
                  <MasterReservationStatus
                    getResStatus={getResStatus}
                    res={
                      reservationData
                        ? reservationData.reservation_status
                        : null
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Bill Receiver" name="bill_receiver">
                  <Input
                    placeholder="Insert Bill Receiver"
                    style={{ minWidth: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Bill Instruction" name="bill_instruction">
                  <Input
                    placeholder="Insert Bill Instruction"
                    style={{ minWidth: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="flight-arrival-reservation">
              <Col span={8}>
                <Form.Item
                  label="Currency"
                  name="currency"
                  style={{
                    minWidth: "100%",
                  }}
                >
                  <MasterCurrency
                    getCurrency={getCurrency}
                    pay={reservationData ? reservationData.currency : null}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Payment Status"
                  name="payment_status"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Payment Status!",
                    },
                  ]}
                  style={{
                    marginRight: 30,
                    minWidth: "100%",
                  }}
                >
                  <Radio.Group
                    onChange={onRadioChange}
                    value={payment_status}
                    disabled
                  >
                    <Space direction="vertical">
                      <Radio value={1}>{`Cash Basis`}</Radio>
                      <Radio value={2}>{`Master Bill Active`}</Radio>
                      {/* <Radio value={3}>{`Deposit`}</Radio> */}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />

            <Row
              justify="space-btn"
              align="top"
              className="row-button-next-tab-pane"
            >
              <Col span={12} className="text-col">
                <Typography
                  className="res-text"
                  style={{ fontSize: 15, fontWeight: 750 }}
                >
                  {reservationData?.reservation_id}
                </Typography>
              </Col>

              <Col
                span={12}
                className="text-col"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={move}
                  style={{ marginRight: 30 }}
                >
                  {`Cancel`}
                </Button>

                <Button
                  type="primary"
                  className="next-btn"
                  onClick={() => onKeyChange("1")}
                  style={{ marginRight: 30 }}
                >
                  {`Back`}
                </Button>

                <Button
                  className="next-btn"
                  type="primary"
                  onClick={() => onKeyChange("3")}
                >
                  {`Next`}
                </Button>
              </Col>
            </Row>
          </Content>
        </>
      ),
    },
    {
      label: (
        <>
          <Row
            justify="start"
            align="middle"
            style={{ width: 297, height: 80 }}
          >
            <Col style={{ margin: "10px 20px 10px 10px" }}>
              <Button
                className="3rd-icon-tab-reservation-btn"
                disabled
                // icon={<RiNumber1 />}
                type="primary"
                style={activeKey == 3 ? styleActive : styleInactive}
              >
                3
              </Button>
            </Col>

            <Col>
              <Row style={{ height: 20 }}>
                <p>Add Reservation</p>
              </Row>

              <Row style={{ height: 20 }}>
                <h4>Room Memo Details</h4>
              </Row>
            </Col>
          </Row>
        </>
      ),
      key: "3",
      disabled: true,
      forceRender: true,
      children: (
        <>
          <Content
            className="tab-pane-layout"
            key={"tab-pane-layout"}
            style={{
              // padding: "60px 249px",
              // padding: screen.lg == false ? "60px 249px" : 30,
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 5px 5px",
            }}
          >
            <Row gutter={30} className="group-segment-reservation">
              <Col span={8}>
                <Form.Item
                  label="Group Name"
                  name="group_name"
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <Input placeholder="Group Name" />
                  {/* <MasterGroup getGroup={getGroup} /> */}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Deposit"
                  name="deposit"
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <InputNumber
                    placeholder="Input Deposit"
                    addonBefore={currency}
                    formatter={(deposit) => {
                      return ` ${deposit}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      );
                    }}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Source"
                  name="source"
                  style={{ minWidth: "100%" }}
                >
                  <MasterSource
                    getSource={getSource}
                    srce={reservationData ? reservationData.source : null}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />

            <Row gutter={30} className="sales-info-reservation">
              <Col xxl={16} xl={16} lg={16} md={24} sm={24}>
                <Row className="flight-row" gutter={30}>
                  <Col span={12}>
                    <Form.Item
                      label="Arrival Flight"
                      name="arrival_flight"
                      style={{
                        marginRight: 30,
                        minWidth: "100%",
                      }}
                    >
                      <Input placeholder="Arrival Flight" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="ETA" name="eta">
                      <TimePicker
                        onChange={onChangeTime}
                        value={moment(
                          state?.state?.eta ? state.state.eta : "",
                          "HH:mm:ss"
                        ).subtract(7, "hours")}
                        format={"HH:mm:ss"}
                        // defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="flight-row" gutter={30}>
                  <Col span={12}>
                    <Form.Item
                      label="Departure Flight"
                      name="departure_flight"
                      style={{
                        marginRight: 30,
                        minWidth: "100%",
                      }}
                    >
                      <Input placeholder="Departure Flight" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="ETD" name="etd">
                      <TimePicker
                        onChange={onChangeTime}
                        value={moment(
                          state?.state?.etd ? state.state.etd : "",
                          "HH:mm:ss"
                        ).subtract(7, "hours")}
                        format={"HH:mm:ss"}
                        // defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Letter"
                  name="letter"
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <Select
                    showSearch
                    value={reservationData ? reservationData.source : null}
                    placeholder="Select an Option"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: "1",
                        label: "1",
                      },
                      {
                        value: "2",
                        label: "2",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  label="Sales"
                  name="sales"
                  style={{ minWidth: "100%" }}
                >
                  <MasterSalesman
                    getSalesman={getSalesman}
                    salesman={reservationData?.sales || null}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />

            <Row gutter={30} className="row-reserve-guest-memo">
              <Col span={16}>
                <Form.Item label="Memo" name="memo_room">
                  <Input.TextArea
                    showCount
                    maxLength={100}
                    style={{
                      height: 100,
                      width: "100%",
                    }}
                    // onChange={onChangeTextArea}
                    placeholder="Descriptions"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />

            <Row
              justify="space-btn"
              align="top"
              className="row-button-next-tab-pane"
            >
              <Col span={12} className="text-col">
                <Typography
                  className="res-text"
                  style={{ fontSize: 15, fontWeight: 750 }}
                >
                  {reservationData?.reservation_id}
                </Typography>
              </Col>

              <Col
                span={12}
                className="text-col"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={move}
                  style={{ marginRight: 30 }}
                >
                  {`Cancel`}
                </Button>

                <Button
                  className="next-btn"
                  type="primary"
                  onClick={() => onKeyChange("2")}
                  style={{ marginRight: 30 }}
                >
                  {`Back`}
                </Button>

                <Button
                  className="submit-btn"
                  type="primary"
                  key={"submit"}
                  htmlType="submit"
                  loading={isLoading}
                >
                  {`Submit`}
                </Button>
              </Col>
            </Row>
          </Content>
        </>
      ),
    },
  ];

  // RETURN
  return (
    <>
      {/* FORM */}
      <Form
        form={form}
        name="guest-modal-form"
        onFinish={(e) => onFinish(e)}
        onFinishFailed={(e) => onFinishFailed(e)}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          arrival: state?.state.arrival ? moment(state.state.arrival) : null,
          departure: state?.state.departure
            ? moment(state.state.departure)
            : null,
          night: state?.state.night
            ? state.state.night
            : reservationData?.night,
          // room_category: "",
          // room_number: state?.state.room_number
          //   ? state.state.room_number
          //   : reservationData.room_number,
          room_qty: state?.state.room_qty
            ? state.state.room_qty
            : reservationData.room_qty,
          // deposit: state.type == 0
          //   ? state.state.deposit
          //   : reservationData.deposit,
          adult: state?.state.adult ? state.state.adult : reservationData.adult,
          child: state?.state.child ? state.state.child : 0,
          infant: state?.state.infant ? state.state.infant : 0,
          reservation_status: state?.state.reservation_status
            ? state.state.reservation_status
            : reservationData?.reservation_status || "",
          arrangement: state?.state.arrangement
            ? state.state.arrangement
            : reservationData.arrangement || "",
          // rate_code: state?.state?.rate_code
          //   ? state.state.rate_code
          //   : reservationData?.rate_code || null,
          compliment_type: state?.state?.compliment_type
            ? state.state.compliment_type
            : reservationData.compliment_type || null,
          currency: state?.state.currency
            ? state.state.currency
            : reservationData.currency,
          room_type: state?.state.room_type
            ? state.state.room_type
            : reservationData.room_type,
          guest_name: state?.state.guest_name
            ? state.state.guest_name
            : reservationData.guest_name,
          bill_receiver: state?.state.guest_name
            ? state.state.guest_name
            : reservationData.guest_name,
          bill_instruction: state.state?.guest_name
            ? state.state.guest_name
            : reservationData.guest_name,
          group: state?.state.group
            ? state.state.group
            : reservationData?.group || 0,
          // segment: state?.state?.segment
          //   ? state.state.segment
          //   : reservationData?.segment || null,
          // sub_segment: state?.state?.sub_segment_id
          //   ? state.state.sub_segment_id
          //   : reservationData?.sub_segment_id || null,
          source: state?.state.source
            ? state?.state.source
            : reservationData?.source || "",
          letter: state?.state.letter
            ? state.state.letter
            : reservationData?.letter || "",
          sales: state?.state?.sales
            ? state.state.sales
            : reservationData?.sales || null,
          payment_type: state?.state?.payment_type
            ? state.state.payment_type
            : reservationData.payment_type,
          payment_details: state?.state.payment_details
            ? state.state.payment_details
            : reservationData.payment_details,
          guest_category: state?.state.guest_category
            ? state.state.guest_category
            : reservationData.guest_category || "",

          payment_status: state?.state?.payment_status
            ? state.state.payment_status
            : reservationData?.payment_status || payment_status,
          is_abf: state?.state?.is_abf
            ? state.state.is_abf
            : reservationData?.is_abf || false,

          group_name: state?.state?.group_name
            ? state.state.group_name
            : reservationData?.group_name || null,
          memo_room: state?.state.memo_room
            ? state.state.memo_room
            : reservationData?.memo_room || null,

          arrival_flight:
            state?.state?.arrival_flight ||
            reservationData?.arrival_flight ||
            "",
          eta: state?.state?.eta
            ? moment(LocalizationDate(state?.state?.eta), "HH:mm").subtract(
                7,
                "hours"
              )
            : null,

          departure_flight:
            state?.state?.departure_flight ||
            reservationData?.departure_flight ||
            "",
          etd: state?.state?.etd
            ? moment(LocalizationDate(state?.state?.etd), "hh:mm:ss").subtract(
                7,
                "hours"
              )
            : null,

          res_id: state?.state?.reservation_id
            ? state.state.reservation_id
            : reservationData?.reservation_id || null,
          guest_id: state?.state?.guest_id
            ? state.state.guest_id
            : reservationData?.guest_id || null,
          // is_dummy: true,
          // is_group: false,
          // nationality: null,
          // created_at: "2023-03-14T10:21:23.198951",
        }}
        style={{ padding: 0 }}
      >
        <Tabs
          defaultActiveKey="1"
          type="card"
          activeKey={activeKey}
          onChange={onKeyChange}
          // onTabClick={disabled}
          items={items}
        />
      </Form>

      <ReservationForm
        openModal={show}
        closeModal={handleClose}
        dataGuest={show == true ? data : null}
        is_created={setCreated}
        is_saved={false}
        is_member={true}
        is_loading={loadingBtn}
        detailResv={finalDetailRoomList}
      />
    </>
  );
}
