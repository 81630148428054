// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// AUTH
import { token } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Parameters
import ParametersFrontOffice from "../../../Reusable/Parameters/ParamsFO";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
  DatePicker,
  Typography,
  Select,
  Dropdown,
  Tag,
} from "antd";

// Import React Icons
import { SyncOutlined } from "@ant-design/icons/lib/icons";
import { PiFastForwardCircleFill } from "react-icons/pi";
import { ImExit } from "react-icons/im";

// Notification
import {
  failedCheckOut,
  failedFetch,
  successCheckOut,
} from "../../../Reusable/Notification/Notification";

// Import Page Components
import DeparturePopover from "../../../Popover/Departure/Popover";
import ModalCheckOutForm from "../../../Forms/CheckOut/Form";
import MasterSegment from "../../../Reusable/Master/Segment/Segment";
// import ModalAddArticle from "../../../Modals/Article/Modal";

// Import Functions
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";
import { FrontOfficeParams } from "../../../Reusable/Functions/Parameters/ParamsFO";
import DepartureForm from "../../../Forms/Departure/Form";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function ExpectedDepartureTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // CONST TIME NOW
  const timeNow = moment().hour();
  const dateNow = moment().format("YYYY-MM-DD");

  // CONTEXT
  const { getExpectedDeparture } = useContext(MainContext);

  // FO PARAMS
  let params = ParametersFrontOffice().params;
  const param_fo = FrontOfficeParams();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [bulksGroup, setBulksGroup] = useState([]);
  const [disable, setDisable] = useState(true);
  const [selectedData, setSelectedData] = useState([]);
  // Group State
  const [isGroup, setIsGroup] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  // Modal
  const [open, setOpen] = useState(false);
  const [isPrint, setisPrint] = useState(false);
  // Is Check Out
  const [checkOut, setCheckOut] = useState(false);
  const [disableEarly, setDisableEarly] = useState(true);
  const [refresh, setRefresh] = useState(false);
  // Loading
  const [skeleton, setSkeleton] = useState(false);
  const [loading, setLoading] = useState(false);

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      fixed: "left",
      align: "left",
      width: 150,
      hidden: isGroup ? true : false,
      render: (_, record) => {
        var _able = true;
        var _complete = checkAvailability(record);
        const _departToday = moment(record?.departure).format("YYYY-MM-DD");
        var _isToday = moment(_departToday).isSameOrAfter(dateNow);

        // console.log("Tolong Cek dong ini Disable nya: ", disable);
        // console.log(
        //   "Tanggal Depart Today: ",
        //   _departToday,
        //   " = ",
        //   dateNow,
        //   " : ",
        //   _isToday
        // );

        if (disable == false) {
          // console.log("Tolong Cek dong ini Disable nya: ", disable);
          if (_complete == true && _isToday == true) {
            _able = false;
          } else {
            _able = true;
          }
        } else {
          // console.log("Tolong Cek dong ini Disable nya: ", disable);
          if (record.is_late_checkout == true && _complete == true) {
            _able = false;
          } else {
            _able = true;
          }
        }

        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={24}>
                {record?.night > 1 ? (
                  <DeparturePopover
                    details={record}
                    is_refresh={handleRefresh}
                    disabled={_complete}
                    time_disable={disable}
                  />
                ) : (
                  <Tooltip title="Check-Out" className="check-out-popover">
                    <Button
                      className="checkout-btn"
                      type="danger"
                      disabled={_able}
                      icon={
                        <ImExit
                          className="co-icon"
                          style={{
                            margin: "0px 10px 0px 0px",
                          }}
                        />
                      }
                      onClick={() => {
                        console.log("About to Checked Out >> ", record);
                        handleCheckOut(record);
                      }}
                      // style={{ backgroundColor: "#13c2c2", borderColor: "#13c2c2" }}
                    >
                      {`Check-Out`}
                    </Button>
                  </Tooltip>
                )}
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
      fixed: "left",
      width: 175,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.reservation_id.localeCompare(b.reservation_id),
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      fixed: "left",
      width: 200,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.guest_name.localeCompare(b.guest_name),
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">{`PRIVATE`}</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 80,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.night - b.night,
    },
    {
      title: "Check-In Date",
      dataIndex: "checkin_date",
      key: "checkin_date",
      sortDirections: ["descend", "ascend"],
      render: (checkin, record) => {
        return moment(LocalizationDate(checkin)).format(
          "HH:mm:ss - DD MMM YYYY"
        );
      },
      sorter: (a, b) => moment(a.checkin_date) - moment(b.checkin_date),
    },
    {
      title: "Arrivals",
      dataIndex: "arrival",
      key: "arrival",
      // sortDirections: ["descend", "ascend"],
      render: (arr, record) => {
        return moment(arr).format("DD MMM YYYY");
      },
      //sorter: (a, b) => a.departure.localeCompare(b.departure),
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      // sortDirections: ["descend", "ascend"],
      render: (depart, record) => {
        return moment(depart).format("DD MMM YYYY");
      },
      sorter: (a, b) => a.departure.localeCompare(b.departure),
    },
    {
      title: "Adult",
      dataIndex: "adult",
      key: "adult",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.adult - b.adult,
    },
    {
      title: "Guest Status",
      dataIndex: "guest_category",
      key: "guest_category",
      // width: 150,
      filters: [
        {
          text: "REGULAR",
          value: "REGULAR",
        },
        {
          text: "VVIP",
          value: "VVIP",
        },
        {
          text: "VIP",
          value: "VIP",
        },
        {
          text: "INCOGNITO",
          value: "INCOGNITO",
        },
        {
          text: "RED ALERT",
          value: "RED ALERT",
        },
        {
          text: "UNASSIGNED",
          value: "",
        },
      ],
      onFilter: (value, record) => {
        // console.log("VALUE = ", value);
        return record.guest_category === value;
      },
      render: (category, record) => {
        let gst = "";
        let clr = "";

        switch (category) {
          case "VIP":
            gst = "VIP";
            clr = "geekblue";
            break;

          case "VVIP":
            gst = "VVIP";
            clr = "green";
            break;

          case "INCOGNITO":
            gst = "INCOGNITO";
            clr = "default";
            break;

          case "RED ALERT":
            gst = "RED ALERT";
            clr = "magenta";
            break;

          case "REGULAR":
            gst = "REGULAR";
            clr = "gold";
            break;

          case "":
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          case null:
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          default:
            break;
        }

        return (
          <Tag
            color={clr}
            style={{
              fontSize: 13,
              fontWeight: 500,
              borderColor: "transparent",
            }}
          >
            {gst}
          </Tag>
        );
      },
    },
    // {
    //   title: "Child",
    //   dataIndex: "child",
    //   key: "child",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.child.localeCompare(b.child),
    // },
    // {
    //   title: "Infant",
    //   dataIndex: "infant",
    //   key: "infant",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.infant.localeCompare(b.infant),
    // },
    {
      title: "Room Rate",
      dataIndex: "rate_code",
      key: "rate_code",
    },
    {
      title: "Memo Room",
      dataIndex: "memo_room",
      key: "memo_room",
    },
  ].filter((item) => !item.hidden);

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname, state } = useLocation();

  // MODALS
  const openModal = () => {
    setOpen(!open);
  };

  // Fetch Data
  const fetchData = async () => {
    await getExpectedDeparture({
      expectedDeparture: "guest-in-house",
      type: "stay",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Expected Departure => ", response);
        console.log("Parameters FO: ", params);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          dataConfig(_res);
        } else {
          setData([]);
          setBulks([]);
          setBulksGroup([]);
        }

        if (params?.checkout_time) {
          handleDisable(params);
        } else {
          setDisable(true);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (params) {
      fetchData();
    }
    // is_search(() => handleSearch);

    if (refresh == true && !cleanUp) {
      fetchData();
      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh, params]);

  // USE EFFECT CHECK OUT
  useEffect(() => {
    let cleanUp = false;

    if (state?.length > 0 && !cleanUp) {
      console.log("Return from CO: ", state);

      if (isGroup) {
        selectedRows(state);
        setSelectedData(state);
      } else {
        selectedRows([]);
        setSelectedData([]);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [state]);

  // Use Effect Search
  useEffect(() => {
    let cleanUp = false;

    if (selectedData.length > 0 && !cleanUp) {
      handleDisableEarly(selectedData);
    } else {
      setDisableEarly(true);
    }

    return () => {
      cleanUp = true;
    };
  }, [selectedData]);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // CHANGE PAGE SIZE
  const changePageSize = (val, size) => {
    // console.log(val);
    // console.log(size);
    // console.log(pageSize);
    setPageSize(size);
  };
  // const onShowSizeChange = (current, size) => {
  //   console.log("Current: ", current);
  //   console.log("Page Size: ", size);
  //   console.log(pageSize);

  // setPageSize(size);
  // };

  // HANDLE DISABLE
  const handleDisable = (param) => {
    const _max = moment(param.checkout_time).hour();

    // console.log("Maximals Check-Out Time: ", _max);
    // console.log("Time Now: ", timeNow);
    // console.log(`${12} < ${12}`, 12 < 12);
    // console.log(param);
    console.log(_max);
    console.log(`${timeNow} < ${_max}`, timeNow < _max);

    if (timeNow < _max) {
      console.log("Disable is: ", timeNow < _max);
      setDisable(false);
    }
  };

  // Disable Check-Out Early

  // DATA CONFIG
  const dataConfig = async (value) => {
    const _data = value;
    let arr1 = [];
    let arr2 = [];

    let filtered = _data.filter((val) => {
      if (val.group > 0) {
        arr1.push(val);
      } else {
        arr2.push(val);
      }
    });

    setBulksGroup(arr1);
    setBulks(arr2);

    if (isGroup) {
      setData(arr1);
    } else {
      setData(arr2);
    }
  };

  // HANDLE CHANGE
  const handleChange = async (value) => {
    const content = value;
    const start = moment(value?.length > 0 ? value[0] : "").format(
      "YYYY-MM-DD"
    );
    const end = moment(value?.length > 0 ? value[1] : "").format("YYYY-MM-DD");

    console.log("Date Range --> ", content);

    if (data?.length > 0 && content?.length > 0) {
      var updatedData = data.filter((val) => {
        return moment(val?.departure).isBetween(start, end, undefined, "[]");
      });

      console.log("Filter Date Range => ", updatedData);
      setData(updatedData);
    }
  };

  // HANDLE SEGMENT
  const getSegment = (value) => {
    console.log(value);
  };

  // HANDLE GROUP
  const handleGroup = async (e) => {
    const _ind = e;
    setLoading(true);

    if (_ind === 1) {
      let _data = bulks.filter((val) => {
        return val.group > 0;
      });

      setData(bulksGroup);
      setIsGroup(true);
      setPageSize(10);
    } else {
      setData(bulks);
      setIsGroup(false);
      setPageSize(5);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // HANDLE ROW KEYS
  const selectedRows = (value) => {
    const _keys = value;

    let filteredKey = _keys.map((val) => {
      return val.id;
    });

    console.log("KEYS --> ", filteredKey);
    setSelectedKeys(filteredKey);
  };

  // HANDLE ROW SELECTIONS
  const rowSelection = {
    type: "checkbox",
    columnTitle: "Actions",
    //   return <Dropdown className="dropdown-check" trigger={"click"} />;
    // },
    columnWidth: 100,
    checkStrictly: true,
    selectedRowKeys: selectedKeys,
    onSelect: (selectedRowKeys, _selected, selectedRows) => {
      console.log(`Selected Row Keys: `, selectedRowKeys);
      console.log("Selected Rows: ", selectedRows);
      // console.log("Selected Type: ", _selected);

      const _type = _selected;

      // handleDisableEarly(selectedRows);

      // setSelectedData(selectedRows);
      // setSelectedKeys(selectedRowKeys);

      if (_type == false) {
        // console.log("This is Hit!", _type);
        setSelectedKeys([]);
        setSelectedData([]);
      } else {
        onSelectChange(selectedRowKeys, selectedRows);
      }
    },
    getCheckboxProps: (record) => {
      var _able = true;
      var _complete = checkAvailability(record);

      // console.log("Tolong Cek dong ini Disable nya: ", disable);
      // console.log("Tolong Cek dong ini Disable nya: ", record);

      if (disable == false) {
        if (_complete == true) {
          _able = false;
        } else {
          _able = true;
        }
      } else {
        if (record.is_late_checkout == true && _complete == true) {
          _able = false;
        } else {
          _able = true;
        }
      }

      // console.log(`ABLES ${record?.reservation_id}:`, _able);

      if (selectedData?.length > 0) {
        return {
          disabled: _able
            ? record.reservation_id !== selectedData[0]?.reservation_id
            : false,
        };
      } else {
        return {
          disabled: _able,
        };
      }
      // return {
      //   disabled:
      //     _able || selectedData?.length > 0
      //       ? record.reservation_id !== selectedData[0]?.reservation_id
      //       : false, //disable the first 4 rows only
      // };
    },
    // selections: [
    //   Table.SELECTION_NONE,
    //   {
    //     key: "grup",
    //     text: "Select Group Member",
    //     onSelect: (changableRowKeys) => {
    //       let newSelectedRowKeys = [];

    //       console.log("Sel: ", selectedData);

    //       if (selectedData?.length > 0) {
    //         newSelectedRowKeys = changableRowKeys.filter((record) => {
    //           return record.reservation_id !== selectedData[0]?.reservation_id;
    //         });

    //         console.log(
    //           "Selected Res ID: ",
    //           selectedData[0],
    //           newSelectedRowKeys
    //         );
    //         setSelectedKeys(newSelectedRowKeys);
    //       } else {
    //         setSelectedKeys([]);
    //       }
    //     },
    //   },
    // ],
  };

  // ON SELECT CHANGE
  const onSelectChange = (newSelectedRowKeys, recordAll, type, index) => {
    const _allKeys = recordAll;
    const _allBulks = data;
    const _result = [];

    // console.log("Selected Key: ", newSelectedRowKeys);
    console.log("Selected Data: ", _allKeys);
    // console.log("Type: ", type);
    // console.log("Index Data: ", index);
    // setSelectedRowKeys(newSelectedRowKeys);

    if (_allBulks.length > 0) {
      // All Selected Keys
      let keys = _allBulks
        .filter((val) => {
          return val?.reservation_id == _allKeys[0]?.reservation_id;
        })
        .map((value) => value?.id);

      // : data
      //     .filter((i) => i?.reservation_id == recordAll[0]?.reservation_id)
      //     .map((i) => i?.id);

      // All Selected Data
      let _selected = _allBulks.filter((value) => {
        return value?.reservation_id === recordAll[0]?.reservation_id;
      });

      // Total Category Room
      // Merge Room
      const merged = _selected.reduce((acc, init) => {
        let key = init.room_category;

        // let room_number = [];
        // room_number.push(init.room_number);

        acc[key] = acc[key] || [];
        acc[key].push(init.room_number);

        return acc;
      }, {});

      if (merged) {
        for (var key in merged) {
          // console.log(key);
          // console.log(merged);

          _result.push({
            room_category: key,
            room_number: merged[key],
            room_qty: merged[key].length,
            reservation_id: _selected[0].reservation_id,
          });
        }
      }

      console.log("All Keys = ", keys);
      console.log("All Selected = ", _selected);
      // console.log("Hasil Merged = ", _merged);
      console.log("Hasil Results = ", _result);
      // console.log("Room Category Total = ", merged);

      setSelectedKeys(keys);
      setSelectedData(_selected);

      // setModalData(_result);
    }
  };

  // Handle Hide
  const handleHide = () => {
    let _hide = true;

    const _record = moment(selectedData[0]?.departure).format("YYYY-MM-DD");
    var _isToday = moment(dateNow).isSameOrAfter(_record, "days");

    const _findLate =
      selectedData.length > 0
        ? selectedData.filter((x) => {
            x.is_late_checkout == true;
          })
        : false;

    // console.log("Disable CO: ", _isToday, _findLate);

    if (disable) {
      if (_isToday && selectedData.length > 0 && _findLate.length > 0) {
        _hide = false;
      } else {
        _hide = true;
      }
    } else {
      if (_isToday && selectedData.length > 0) {
        _hide = false;
      } else {
        _hide = true;
      }
    }

    // console.log("CO: ", _hide);

    return _hide;
  };

  // Handle Disable Early
  const handleDisableEarly = (value) => {
    const _record = value;

    var _date =
      moment(_record[0]?.departure).format("YYYY-MM-DD") >
      moment().format("YYYY-MM-DD");

    console.log(
      "Date Departure: ",
      _date,
      moment(_record[0]?.departure).format("YYYY-MM-DD"),
      " > ",
      moment().format("YYYY-MM-DD")
    );

    if (_date && _record[0]?.night > 1) {
      setDisableEarly(false);
    } else {
      setDisableEarly(true);
    }
  };

  // Handle Check Out
  const handleCheckOut = async (value) => {
    const _record = [value];
    console.log("Navigate => ", pathname, state);
    console.log("Record => ", _record);

    navigate(`${pathname}/check-out`, {
      state: isGroup ? selectedData : _record,
    });
  };

  // Handle Check Out
  const handleEarly = async (value) => {
    const _record = [value];
    console.log("Navigate => ", pathname, state);
    console.log("Record => ", _record);

    navigate(`${pathname}/early-check-out`, {
      state: isGroup ? selectedData : _record,
    });
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    // setDataCheck(null);
  };

  // CHECK COMPLETENESS
  const checkAvailability = (param) => {
    return !Object.keys(
      (({
        group_name,
        // guest_category,
        arrival_flight,
        eta,
        departure_flight,
        etd,
        is_dummy,
        is_group,
        //tak tambah iki sitik kelewat ning ngisor
        letter,
        sales,
        created_by,
        created_date,
        nationality, ///???
        // reservation_id, ///???
        memo_room,
        room_type,
        arrangement,
        deposit,
        room_rate_id,
        is_no_show,

        // guest_id,
        // res_created_by,
        // res_created_date,

        updated_by,
        updated_date,
        sub_segment_id,
        rate_code,

        is_checkin,
        checkin_at,

        step_date,
        step_by,

        is_cm,
        is_step,
        is_deleted,
        is_checkout,

        checkout_by,
        checkout_date,

        deleted_by,
        deleted_date,

        is_nonstay,
        nonstay_by,
        nonstay_count,
        nonstay_date,

        is_audit,
        audit_at,
        audit_by,
        // room_type,
        is_late_checkout,
        late_checkout_by,
        late_checkout_date,

        ...o
      }) => o)(param)
    ).some((key) => {
      var late = false;
      // console.log("FILTERED ==> ", key, param[key]);
      if (key === "is_late_checkout") {
        late = param[key] == true ? true : false;
      }
      // console.log("IS LATE CO? ==> ", key, param[key], late);

      return (
        param[key] === "" || param[key] === null || param[key] === undefined
      );
    });
  };

  // HANDLE REFRESH
  const handleRefresh = (e) => {
    // console.log("refresh is Hit!", e);
    fetchData();
    setSelectedKeys([]);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;

    // console.log("Type = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item.guest_name.toLowerCase();
        // console.log("ITEM => ", item)
        return (
          item.guest_category != "INCOGNITO" && _name.indexOf(query) !== -1
        );
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  // console.log("Page Size changes = ", pageSize);

  return (
    <>
      <Row className="reservation-main-table" justify="center" style={mainBody}>
        <Col className="col-main-table" span={24} style={{ padding: "30px" }}>
          <Row
            justify="end"
            className="row-refresh-btn"
            align="top"
            gutter={[0, 30]}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="daterange-col"
            >
              <Typography style={{ fontWeight: 750 }}>
                {`CHOOSE DEPARTURE DATE:`}
              </Typography>

              <DatePicker.RangePicker
                className="daterange"
                onChange={handleChange}
                style={{
                  width: "100%",
                }}
              />
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="daterange-col"
            >
              <Typography style={{ fontWeight: 750 }}>
                {`CHOOSE CHECK-OUT TYPE:`}
              </Typography>

              <Select
                key="type-select"
                className="type-select"
                allowClear
                showSearch
                defaultValue={0}
                onChange={(e) => {
                  // console.log(e);
                  handleGroup(e);
                }}
                options={[
                  {
                    label: "Individual",
                    value: 0,
                  },
                  {
                    label: "Group",
                    value: 1,
                  },
                ]}
                style={{
                  minWidth: 205,
                }}
              />
            </Col>

            <Col
              span={8}
              className="refresh-btn-col"
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Space className="btn-space" size="large" direction="horizontal">
                <Button
                  className="checkout-btn"
                  type="danger"
                  icon={
                    <ImExit
                      className="co-icon"
                      style={{
                        margin: "3px 10px 0px 0px",
                      }}
                    />
                  }
                  hidden={!isGroup}
                  disabled={handleHide()}
                  style={{
                    width: "100%",
                  }}
                  onClick={handleCheckOut}
                >
                  {`Check-Out Group`}
                </Button>

                <Button
                  className="checkout-btn"
                  type="danger"
                  icon={
                    <PiFastForwardCircleFill
                      className="co-icon"
                      style={{
                        margin: "3px 10px 0px 0px",
                      }}
                    />
                  }
                  hidden={!isGroup}
                  disabled={disableEarly}
                  style={{
                    width: "100%",
                  }}
                  onClick={handleEarly}
                >
                  {`Early Check-Out Group`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={handleRefresh}
                >
                  {`Refresh`}
                </Button>
              </Space>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="expected-depart-table"
              key="expected-depart-table"
              name="expected-depart-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered={true}
              rowSelection={isGroup ? { ...rowSelection } : false}
              pagination={{
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 20, 50, 100],
                showSizeChanger: true,
                onChange: changePageSize,
                // onShowSizeChange: onShowSizeChange,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 2000,
              }}
              rowClassName={(record, index) => {
                return checkAvailability(record) ? "completed" : "incompleted";
                //&& (record.reservation_status == "Guaranteed" ||
                //record.reservation_status == "Not Guaranteed" )
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      {/* <ModalCheckOutForm
        openModal={open}
        closeModal={setOpen}
        submitDataCkOutForm={open == true ? dataCheck : ""}
        is_early={false}
        is_checkOut={setCheckOut}
        is_refresh={setRefresh}
      /> */}
    </>
  );
}
