// Import React Components
import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";
import { authorized_by } from "../../../API/Global/Permission";

// PRINT
import { useReactToPrint } from "react-to-print";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Functions
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";
import { RestructureArticle } from "../../Reusable/Functions/Article/Restructure";
import { PreTotalPriceFunction } from "../../Reusable/Functions/TotalPrice/PreTotalPrice";
import { PrintCount } from "../../Reusable/Functions/PrintCount/PrintCount";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Image,
  Space,
  Checkbox,
  Card,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { BiSolidFilePdf } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { textForm } from "../../../Style/Global/Global";

// Import Page Components
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";
import { failedFetch } from "../../Reusable/Notification/Notification";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function ReservationForm(props) {
  const {
    // MODAL STATE
    openModal,
    closeModal,
    // Data Guest
    dataGuest,
    // Reservation already saved?
    is_saved,
    // Is Data Already Created?
    is_created,
    is_member,
    is_loading,
    // Detail Data Reservations (Before Submit)
    detailResv,
    is_failed,
    reset_failed,
  } = props;

  // CONTEXT
  const { getGuest, getPreBillArticle, getArticleRoom } =
    useContext(MainContext);
  const { getMasterRoom, getMasterPrint } = useContext(MasterContext);

  // USE REF
  const componentRef = useRef();

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Modal
  const [open, setOpen] = useState(false);
  // Data
  const [data, setData] = useState([]);
  const [guest, setGuest] = useState(null);
  const [room, setRoom] = useState(null);
  const [category, setCategory] = useState(null);
  // Room Price
  const [articleRoom, setArticleRoom] = useState([]);
  const [articleList, setArticleList] = useState([]);
  const [price, setPrice] = useState(null);
  // Print Count
  const [countPrint, setCountPrint] = useState(0);
  // Loading
  const [isLoad, setIsLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Total Resv
  const [totalResv, setTotalResv] = useState(0);

  // ABF RESV
  const [resvAbf, setResvAbf] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Room
  const fetchRoom = async () => {
    const getDataGuest = dataGuest;
    console.log("HASIL DATA GUEST >>>", getDataGuest);

    await axios
      .post(
        `${baseurl}/reservation/stay-before-update`,
        {
          reservation_id: getDataGuest?.reservation_id
            ? getDataGuest.reservation_id
            : data.reservation_id,
          price_id: getDataGuest?.price_id
            ? getDataGuest.price_id
            : data.price_id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("STAY BEFORE UPDATE = ", response);

        const _res = response?.data?.msg;

        if (_res?.a_article_room?.length > 0) {
          let _articleRoom = _res.a_article_room.map((value) => {
            return {
              ...value,
              total_price: value?.gross,
              unit_price: value?.room_price,
            };
          });

          setArticleRoom(_articleRoom);
          // handleTotalReservation(_articleRoom);
        } else {
          setArticleRoom([]);
        }

        if (_res?.a_article_stay?.length > 0) {
          let _articleList = _res.a_article_stay.map((value) => {
            return {
              ...value,
              total_price: value?.total_price,
              unit_price: value?.unit_price * value?.item_quantity,
              tax: value.total_tax,
              service: value.total_service,
            };
          });

          setArticleList(_articleList);
        } else {
          setArticleList([]);
        }

        // setRoomList([_res[0]]);
        // }
      })
      .catch((error) => {
        console.log("ERORR = ", error);
        failedFetch(error);
      });
  };

  // Fetch Room
  // const fetchRoom = async () => {
  //   await getArticleRoom({
  //     articleRoomPrice: "reservation",
  //     type: "article-room-single",
  //     res_id: dataGuest?.reservation_id
  //       ? dataGuest.reservation_id
  //       : data.reservation_id,
  //     prc_id: dataGuest?.price_id ? dataGuest.price_id : data.price_id,
  //     onAwait: () => {},
  //     onSuccess: (response) => {
  //       console.log("Article Room => ", response);

  //       if (response?.data?.length > 0) {
  //         let _response = response?.data;
  //         let _mapped = _response.map((value) => {
  //           return { ...value, total_price: value?.gross };
  //         });

  //         console.log("MAPPED: ", _mapped);
  //         setArticleRoom(_mapped);
  //         handleTotalReservation(_mapped);
  //       }
  //     },
  //     onReject: (error) => {
  //       console.log(error);
  //       // setLoading(false);
  //     },
  //   });

  //   await getPreBillArticle({
  //     articleStay: "bill",
  //     type: "article-res-stay-guest",
  //     res_id: dataGuest?.reservation_id
  //       ? dataGuest.reservation_id
  //       : data.reservation_id,
  //     prc_id: dataGuest?.price_id >= 0 ? dataGuest.price_id : data?.price_id,
  //     onAwait: () => {},
  //     onSuccess: (response) => {
  //       console.log("Response Article List => ", response);

  //       if (response?.data?.length > 0 || response?.data) {
  //         const _response = response.data;
  //         const _results = RestructureArticle(_response);

  //         setArticleList(_results);
  //       } else {
  //         setArticleList([]);
  //       }
  //     },
  //     onReject: (error) => {
  //       console.log(error);
  //     },
  //   });
  // };

  // Fetch Guest
  const fetchGuest = async () => {
    await getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Res Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let guest_name = _res.filter((value) => {
            if (value.id == dataGuest.guest_id) {
              return value;
            } else if (value.full_name.includes(dataGuest.guest_name)) {
              return value;
            }
          });

          console.log("Guest --> ", guest_name);
          setGuest(guest_name?.length > 0 ? guest_name[0] : []);
        }
      },
      onReject: (error) => {
        console.log("Error => ", error);
      },
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 1250);
  };

  // Fetch Room Type
  const fetchRoomType = async () => {
    await getMasterRoom({
      main: "main",
      onAwait: () => {
        "on Await";
      },
      onSuccess: (response) => {
        // console.log("ISI Room List >> ", response);

        if (response?.data?.msg) {
          let _filtered = response.data.msg;

          let filtered = _filtered.filter((val) => {
            return val.room_number == dataGuest.room_number && !val?.is_deleted;
          });

          console.log("Filtered Room >> ", filtered);
          setRoom(filtered?.length > 0 ? filtered[0] : null);
        }
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // FETCH PRINT FORM
  const fetchPrint = async () => {
    // third;
    await getMasterPrint({
      reservation_id: dataGuest?.reservation_id || data?.reservation_id,
      price_id: dataGuest?.price_id > 0 ? dataGuest.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Count Print: ", response);

        if (response?.data?.msg?.length > 0) {
          setCountPrint(response.data.msg[0]);
        } else {
          PrintCount({
            data: dataGuest || data,
            is_update: false,
          });
        }
      },
      onReject: (error) => {
        console.log("Failed get Print Count: ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      setOpen(openModal);
    }

    if (dataGuest && !cleanUp) {
      console.log("Data Guest -->>> ", dataGuest);

      if (is_saved == true) {
        if (detailResv) {
          let is_breakfast = detailResv.some(
            (item) => item.room_details === "Breakfast"
          );

          let manipulate = {
            ...dataGuest,
            is_abf: is_breakfast,
          };

          setData(manipulate);
        } else {
          setData(dataGuest);
        }

        fetchRoom();
        fetchGuest();
        fetchRoomType();
        fetchPrint();
        setCategory(dataGuest?.room_category);
      } else {
        if (detailResv) {
          let is_breakfast = detailResv.some(
            (item) => item.room_details === "Breakfast"
          );

          let manipulate = {
            ...dataGuest,
            is_abf: is_breakfast,
          };

          setData(manipulate);
        } else {
          setData(dataGuest);
        }

        fetchGuest();
        fetchRoomType();
        setArticleRoom(
          dataGuest?.room_price?.length > 0 ? dataGuest?.room_price : []
        );

        setCategory(dataGuest?.room_category.join(", "));

        // if (dataGuest?.room_price?.length > 0) {
        //   setCategory(dataGuest?.room_price?.room_category_code.join(", "));
        // }
      }
      // console.log("DATA FORM >> ", dataGuest);
    }

    if (detailResv) {
      console.log("Detail Resv ->> ", detailResv);
      // HANDLE TOTAL FOR FORM RESV
      handleTotalReservation(detailResv);
      console.log(detailResv.some((item) => item.room_details === "Breakfast"));
      setResvAbf(detailResv.some((item) => item.room_details === "Breakfast"));
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataGuest, is_saved, detailResv]);

  useEffect(() => {
    let cleanUp = false;

    // console.log("loading BTN: ", is_loading);
    // console.log("Failed Create: ", is_failed);
    // setIsLoad(is_loading);

    if (is_failed == true && !cleanUp) {
      setIsLoad(false);
      is_created(false);
      reset_failed(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_loading, is_failed]);

  // USE EFFECT ROOM ARTICLE
  useEffect(() => {
    let cleanUp = false;

    if ((articleRoom?.length > 0 || articleList?.length > 0) && !cleanUp) {
      // console.log(articleRoom);
      if (detailResv) {
        console.log("Detail Resv ->> ", detailResv);
        // HANDLE TOTAL FOR FORM RESV
        handleTotalReservation(detailResv);
        // console.log(
        //   detailResv.some((item) => item.room_details === "Breakfast")
        // );
        setResvAbf(
          detailResv.some((item) => item.room_details === "Breakfast")
        );
      } else {
        handleTotal(articleRoom, articleList);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [articleRoom, articleList, room, guest, is_saved]);

  // HANDLE TOTAL PRICE
  const handleTotalReservation = (roomData, articleData) => {
    // setTotalResv(roomData.reduce((prev, curr) => prev + curr.gross, 0)); // ini
    const totalRoom = roomData.reduce((prev, curr) => prev + curr.gross, 0);

    if (articleData) {
      const totalArticle = articleData.reduce(
        (prev, curr) => prev + curr.gross,
        0
      );

      setPrice(totalRoom + totalArticle);
    } else {
      setPrice(totalRoom);
    }
  };

  // ON FINISH
  const onFinish = () => {
    showSubmitModal();
    setIsLoad(true);
  };

  // HANDLE TOTAL PRICE
  const handleTotal = (arr, art) => {
    let _room = arr;
    let _article = art;

    let _joined = [..._room, ..._article];

    if (dataGuest.compliment_type != 2) {
      setPrice(0);
    } else {
      if (is_saved == true) {
        // let total = _joined.reduce((accumulator, initValues) => {
        //   return accumulator + initValues.total_price;
        // }, 0);
        let total = PreTotalPriceFunction(_joined);

        console.log("Joined Array >> ", _joined);
        console.log("Total Price >> ", total);

        setPrice(total.nett);
      } else {
        let total = _joined.reduce((accumulator, initValues) => {
          return accumulator + initValues.gross;
        }, 0);

        console.log("Join: ", _joined);
        console.log("Total: ", total);

        setPrice(totalResv);
      }
    }
  };

  // SHOW SUBMIT CONFIRMATION
  const showSubmitModal = async () => {
    const submitContent = dataGuest;

    // console.log(deleteContent);

    confirm({
      className: "submit-reservation-confirm",
      title: `Are you sure want to ${
        is_member == true ? `Add New Member(s) to Group` : `Add New Reservation`
      } with guest named ${
        submitContent?.guest_name?.toUpperCase() || "Unknown"
      } ?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Submit",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        // handleSubmit(submitContent);

        is_created(true);
        // console.log("Guest deleted");
      },
      onCancel() {
        setIsLoad(false);
        // setCounter(0);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);

    setData([]);
    setArticleRoom([]);
    setArticleList([]);
    setGuest(null);

    form.resetFields();
    setIsLoad(false);
    setIsLoading(true);
    // setEdit(null);
  };

  // HANDLE REACT PRINT
  const handlePrint = () => {
    PrintCount({
      data: data,
      is_update: true,
      counter_list: {
        reservation_letter: countPrint?.reservation_letter + 1,
        guarantee_letter: countPrint?.guarantee_letter,
        registration_form: countPrint?.registration_form,
        pre_bill: countPrint?.pre_bill,
        thankyou_letter: countPrint?.thankyou_letter,
        checkout_letter_history: countPrint?.checkout_letter_history,
        bill: countPrint?.bill,
        master_bill: countPrint?.master_bill,
        pre_master_bill: countPrint?.pre_master_bill,
      },
    });

    handlePrintOut();
  };

  // Handle Print
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Reservation Form ${
      data?.reservation_id || data?.guest_name
    }`,
    // onAfterPrint: () => alert("Print Success"),
  });

  // CONSOLE LOG
  // console.log("DATA Guest >>>> ", data);
  // console.log("DATA Room Guest >>>> ", articleRoom);
  // console.log("Data Guest Card >>> ", guest);
  // console.log("Data Room >>> ", room);
  // console.log("Price >>> ", price);
  // console.log("IS SAVED? ", is_saved);
  // console.log("STATE RESV ID? ", stateResvId);
  //   console.log("Is Array? ", typeof dataGuest.room_category);

  return (
    <>
      <Modal
        className="res-form-modal"
        name="res-form-modal"
        key="res-form-modal"
        title={
          <>
            <Row className="row-modal-title">
              <BiSolidFilePdf style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography
                className="text-modal-title"
                style={{ marginLeft: 15 }}
              >
                {`View Reservation Form`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          name="resv-form"
          key="resv-form"
          className="resv-form"
          form={form}
          labelCol={{ span: 10 }}
          labelWrap={{ span: 14 }}
          labelAlign="left"
          autoComplete="off"
          layout="horizontal"
          // wrapperCol={{
          //   span: 16,
          // }}
          // style={{
          //   maxWidth: 1000,
          // }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
        >
          {!isLoading ? (
            <Content
              className="content-reservation-form"
              ref={componentRef}
              style={{ padding: "30px 80px 30px 80px" }}
            >
              <Row gutter={30} className="row-header">
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Room Reservation`}
                  </Typography.Title>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    // src="/looklogo(color).png"
                    src="/ideas.png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "20%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Idea's Hotel`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                gutter={30}
                className="row-guest-date"
                style={{ width: "100%", fontWeight: 500 }}
              >
                <Col span={12} className="col-arrival-details">
                  <Row
                    style={{ width: "100%", height: 22 }}
                    className="row-arrival-details"
                  >
                    <Form.Item
                      label="Arrival Date"
                      className="arrival"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Typography className="arrival-text" style={textForm}>
                        {data?.arrival
                          ? moment(data?.arrival).format("DD MMM YYYY")
                          : "Arrival Date"}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row
                    className="row-eta"
                    style={{ width: "100%", height: 22 }}
                  >
                    <Form.Item
                      label="Arrival Time"
                      className="eta"
                      style={{ width: "100%" }}
                    >
                      <Typography className="eta-text" style={textForm}>
                        {data?.eta
                          ? moment(LocalizationDate(data.eta)).format("HH:mm")
                          : "15.00"}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row
                    className="arrival_flight"
                    style={{ width: "100%", height: 22 }}
                  >
                    <Form.Item
                      label="Arrival Flight"
                      className="arrival_flight"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        className="arrival-flight-text"
                        style={textForm}
                      >
                        {data?.arrival_flight ? data.arrival_flight : ""}
                      </Typography>
                    </Form.Item>
                  </Row>
                </Col>

                <Col span={12} className="col-depart-details">
                  <Row
                    className="row-depart-date"
                    style={{ width: "100%", height: 22 }}
                  >
                    <Form.Item
                      label="Dep. Date"
                      className="departure"
                      style={{ width: "100%" }}
                    >
                      <Typography className="departure-text" style={textForm}>
                        {data?.departure
                          ? moment(data.departure).format("DD MMM YYYY")
                          : "Departure Date"}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row
                    className="row-etd"
                    style={{ width: "100%", height: 22 }}
                  >
                    <Form.Item
                      label="Dep. Time"
                      className="etd"
                      style={{ width: "100%" }}
                    >
                      <Typography className="etd-text" style={textForm}>
                        {data?.etd
                          ? moment(LocalizationDate(data.etd)).format("HH:mm")
                          : "12.00"}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row
                    className="row-depart-flight"
                    style={{ width: "100%", height: 22 }}
                  >
                    <Form.Item
                      label="Dep. Flight"
                      className="departure_flight"
                      style={{ width: "100%" }}
                    >
                      <Typography className="dept-flight-text" style={textForm}>
                        {data?.departure_flight ? data.departure_flight : ""}
                      </Typography>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>

              <Card
                // gutter={30}
                className="card-guest-details"
                style={{
                  width: "100%",
                  fontWeight: 500,
                  textAlign: "start",
                  margin: "15px 0px",
                  border: "1px solid #000000",
                }}
                bodyStyle={{
                  padding: "5px 0px 10px 30px",
                }}
              >
                {/* <Col span={24} className="col-guest-details"> */}
                <Row
                  className="row-res-id"
                  style={{ width: "100%", height: 25 }}
                >
                  <Form.Item
                    label="Reservation ID"
                    className="reservation_id"
                    labelCol={{ span: 5 }}
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {`${
                        data?.length > 0
                          ? data[0].reservation_id
                          : data?.res_id || data.reservation_id || ""
                      }`}
                    </Typography>
                  </Form.Item>
                </Row>

                <Row
                  span={24}
                  className="row-guest-name"
                  style={{ width: "100%", height: 25 }}
                >
                  <Form.Item
                    label="Guest Name"
                    className="guest_name"
                    labelCol={{ span: 5 }}
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Typography className="text-guest_name" style={textForm}>
                      {data?.guest_name ? data.guest_name.toUpperCase() : ""}
                    </Typography>
                  </Form.Item>
                </Row>

                <Row
                  span={24}
                  className="row-group_name"
                  style={{ width: "100%", height: 25 }}
                >
                  <Form.Item
                    label="Group Name"
                    className="group_name"
                    labelCol={{ span: 5 }}
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Typography className="text-group-name" style={textForm}>
                      {data?.group_name ? data.group_name : ""}
                    </Typography>
                  </Form.Item>
                </Row>

                <Row
                  span={24}
                  className="row-phone"
                  style={{ width: "100%", height: 25 }}
                >
                  <Form.Item
                    label="Phone"
                    className="phone"
                    labelCol={{ span: 5 }}
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Typography className="text-phone" style={textForm}>
                      {`(+) ${guest?.phone_number ? guest.phone_number : ""}`}
                    </Typography>
                  </Form.Item>
                </Row>

                <Row
                  span={24}
                  className="row-segment"
                  style={{ width: "100%", height: 25 }}
                >
                  <Form.Item
                    label="Company / Agent"
                    className="company_agent"
                    labelCol={{ span: 5 }}
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Typography className="text-company" style={textForm}>
                      {`${guest?.master_comp ? guest.master_comp : ""}`}
                    </Typography>
                  </Form.Item>
                </Row>

                <Row
                  span={24}
                  className="row-email"
                  style={{ width: "100%", height: 25 }}
                >
                  <Form.Item
                    label="Email"
                    className="email"
                    labelCol={{ span: 5 }}
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Typography className="text-email" style={textForm}>
                      {`${guest?.email ? guest.email : ""}`}
                    </Typography>
                  </Form.Item>
                </Row>
                {/* </Col> */}
              </Card>

              <Row
                className="row-room-config"
                style={{
                  width: "100%",
                  backgroundImage:
                    countPrint?.reservation_letter >= 1
                      ? "url(/assets/logos/copyopac.png)"
                      : null,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "auto",
                  backgroundPosition: "center",
                }}
              >
                <Col
                  span={12}
                  className="col-room-config"
                  style={{
                    padding: "0px 10px 0px 0px",
                    width: "100%",
                  }}
                >
                  <Typography
                    className="text-title-room"
                    style={{
                      fontSize: 20,
                      fontWeight: 750,
                      margin: "10px 0px",
                    }}
                  >
                    {`Room Configuration`}
                  </Typography>

                  <Row style={{ width: "100%" }}>
                    <Form.Item
                      label="Room Number"
                      className="room_number"
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Typography className="text-room-no" style={textForm}>
                        {data?.room_number?.length > 0
                          ? data.room_number.join(", ")
                          : data?.room_number}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row style={{ width: "100%" }}>
                    <Form.Item
                      label="Room Category"
                      className="room_category"
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Typography className="text-room-cat" style={textForm}>
                        {category != null ? category : ""}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row style={{ width: "100%" }}>
                    <Form.Item
                      label="Room Type"
                      className="room_type"
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Typography className="text-room-type" style={textForm}>
                        {room?.room_bedsetup ? room.room_bedsetup : ""}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row style={{ width: "100%" }}>
                    <Form.Item
                      label="Room Quantity"
                      className="room_qty"
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Typography className="text-room-qty" style={textForm}>
                        {data?.room_qty ? data.room_qty : ""}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row style={{ width: "100%" }}>
                    <Form.Item
                      label="Total Night"
                      className="night"
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Typography className="text-night" style={textForm}>
                        {data?.night ? data.night : ""}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row style={{ width: "100%" }}>
                    <Form.Item
                      label="Total Price"
                      className="total_price"
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Typography className="text-tot-price" style={textForm}>
                        {`${currency} ${price > 0 ? price : 0}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row style={{ width: "100%" }}>
                    <Form.Item
                      label="Compliment Type"
                      className="cmpliment_type"
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Typography className="text-comply" style={textForm}>
                        {`${
                          data?.compliment_type == 1
                            ? "COMPLIMENT"
                            : data?.compliment_type == 2
                            ? "NO COMPLIMENT"
                            : "HOUSE USES"
                        }`}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row style={{ width: "100%" }}>
                    <Form.Item
                      label="Rate Code"
                      className="rate_code"
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Typography className="text-comply" style={textForm}>
                        {data?.rate_code ? data.rate_code : ""}
                      </Typography>
                    </Form.Item>
                  </Row>
                </Col>

                <Col
                  span={12}
                  className="col-payment"
                  style={{ width: "100%" }}
                >
                  <Typography
                    className="text-title-pay"
                    style={{
                      fontSize: 20,
                      fontWeight: 750,
                      margin: "10px 0px",
                    }}
                  >
                    {`Payments`}
                  </Typography>

                  <Row style={{ width: "100%" }}>
                    <Form.Item
                      label="Payment Type"
                      className="payment_type"
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Typography
                        className="text-box"
                        style={{
                          fontWeight: 750,
                          textAlign: "center",
                          height: 25,
                          border: "1px solid #000000",
                        }}
                      >
                        {` ${data?.payment_type ? data.payment_type : ""}`}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row style={{ width: "100%" }}>
                    <Form.Item
                      label="Payment Details"
                      className="payment_details"
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Typography
                        className="text-box"
                        style={{
                          fontWeight: 750,
                          textAlign: "center",
                          height: 25,
                          border: "1px solid #000000",
                        }}
                      >
                        {` ${
                          data?.payment_details ? data.payment_details : ""
                        }`}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row style={{ width: "100%" }}>
                    <Form.Item
                      label="Payment Status"
                      className="payment_status"
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Typography
                        className="text-tot-price"
                        style={{
                          fontWeight: 750,
                          margin: 0,
                          padding: "5px 0px 0px",
                        }}
                      >
                        {`${
                          data?.payment_status == 1
                            ? "Cash Basis"
                            : "Charge To Room"
                        }`}
                      </Typography>
                    </Form.Item>
                  </Row>

                  {/*
                 <Row style={{ width: "100%" }}>
                  <Form.Item
                    label="CO/TA"
                    className="co-ta"
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Typography
                      className="text-box"
                      style={{
                        fontWeight: 750,
                        textAlign: "center",
                        height: 25,
                        border: "1px solid #000000",
                      }}
                    >
                      {` ${data?.payment_type ? data.payment_type : ""}`}
                    </Typography>
                  </Form.Item>
                </Row>

                <Row style={{ width: "100%" }}>
                  <Form.Item
                    label="PA"
                    className="pa"
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Typography
                      className="text-box"
                      style={{
                        fontWeight: 750,
                        height: 25,
                        border: "1px solid #000000",
                        textAlign: "center",
                      }}
                    >
                      {data?.payment_details ? data.payment_details : ""}
                    </Typography>
                  </Form.Item>
                </Row>

                <Divider
                  className="divider-form"
                  style={{
                    margin: "10px 0px",
                    backgroundColor: "#d9d9d9",
                  }}
                />

                <Row className="late-check" style={{ width: "100%" }}>
                  <Typography
                    className="text-late-check"
                    style={{
                      fontSize: 20,
                      fontWeight: 750,
                      // margin: "10px 0px",
                    }}
                  >
                    Late Check Out/DU
                  </Typography>
                </Row>

                <Typography
                  className="text-box"
                  style={{ height: 30, border: "1px solid #000000" }}
                >
                </Typography> 
                */}

                  <Divider
                    className="divider-form"
                    style={{
                      margin: "10px 0px",
                      backgroundColor: "#d9d9d9",
                    }}
                  />

                  <Row className="row-meal-config" style={{ width: "100%" }}>
                    <Typography
                      className="text-meal-config"
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                        // margin: "10px 0px",
                      }}
                    >
                      Meal Configuration
                    </Typography>
                  </Row>

                  <Row
                    className="row-room-only"
                    justify="space-between"
                    style={{ width: "100%", margin: "5px 0px" }}
                  >
                    <Typography className="text-room-only">{`Room Only`}</Typography>

                    <Checkbox
                      className="checkbox-room-only"
                      // disabled
                      checked={data.is_abf == true ? false : true}
                    />
                  </Row>

                  <Row
                    className="row-abf"
                    justify="space-between"
                    style={{ width: "100%", margin: "5px 0px" }}
                  >
                    <Typography className="text-room-abf">{`Room & Breakfast`}</Typography>

                    <Checkbox
                      className="checkbox-room-abf"
                      // disabled
                      checked={data.is_abf == true ? true : false}
                    />
                  </Row>

                  <Row
                    className="row-lunch"
                    justify="space-between"
                    style={{ width: "100%", margin: "5px 0px" }}
                  >
                    <Typography className="text-lunch">{`Lunch`}</Typography>

                    <Checkbox
                      className="checkbox-lunch"
                      // disabled
                      checked={
                        false
                        // data?.compliment_type == "Room Only" ? false : true
                      }
                    />
                  </Row>

                  <Row
                    className="row-dinner"
                    justify="space-between"
                    style={{ width: "100%", margin: "5px 0px" }}
                  >
                    <Typography className="text-dinner">Dinner</Typography>

                    <Checkbox
                      className="checkbox-dinner"
                      // disabled
                      checked={
                        false
                        // data?.compliment_type == "Room Only" ? false : true
                      }
                    />
                  </Row>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{
                  margin: "10px 0px",
                  backgroundColor: "#d9d9d9",
                }}
              />

              <Row className="row-title-remarks">
                <Typography
                  className="text-title-remarks"
                  style={{
                    fontSize: 20,
                    fontWeight: 750,
                    margin: "0px 0px 10px",
                  }}
                >
                  Special Notes / Remark
                </Typography>
              </Row>

              <Row
                className="row-remarks"
                // gutter={30}
                style={{
                  // backgroundColor: "grey",
                  width: "100%",
                }}
              >
                <Col
                  span={24}
                  className="col-remarks"
                  style={{
                    // backgroundColor: "red",
                    width: "100%",
                  }}
                >
                  <Typography className="text-check-in-notes">
                    Check-In Notes
                  </Typography>

                  <Form.Item
                    // label="Memo"
                    className="memo_room"
                    style={{
                      margin: 4,
                      width: "100%",
                    }}
                  >
                    <Input.TextArea
                      // disabled
                      value={data?.memo_room ? data.memo_room : ""}
                      placeholder="Notes"
                      rows={5}
                      style={{
                        // fontSize: 15,
                        fontWeight: 750,
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                {/* <Col
                span={12}
                className="col-remarks"
                style={{
                  // backgroundColor: "red",
                  width: "100%",
                }}
              >
                <Typography className="text-cashier">Cashier</Typography>

                <Form.Item
                  // label="Memo"
                  className="cashier"
                  style={{
                    margin: 4,
                    width: "100%",
                  }}
                >
                  <Input.TextArea
                    // disabled
                    value={"Cashier 1"}
                    placeholder="Cashier Notes"
                    rows={5}
                    style={{
                      // fontSize: 15,
                      fontWeight: 750,
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
            */}
              </Row>

              <Divider
                className="divider-form"
                style={{
                  margin: "10px 0px",
                  backgroundColor: "#d9d9d9",
                }}
              />

              <Row className="row-by" style={{ width: "100%", height: 25 }}>
                <Form.Item
                  className="entered-by"
                  label="Entered by"
                  labelCol={{ span: 5 }}
                  style={{ width: "100%", height: 25 }}
                >
                  <Typography className="text-roles" style={textForm}>
                    {is_saved == true
                      ? data.created_by
                      : authorized_by
                      ? authorized_by
                      : user_name}
                  </Typography>
                </Form.Item>
              </Row>

              {/* <Row className="row-by" style={{ width: "100%", height: 25 }}>
                <Form.Item
                  className="folio-no"
                  label="Folio Number"
                  labelCol={{ span: 5 }}
                  style={{ width: "100%", margin: 0 }}
                >
                  <Typography className="text-folio-no" style={textForm}>
                    {`0`}
                  </Typography>
                </Form.Item>
              </Row> */}

              <Row className="row-by" style={{ width: "100%", height: 25 }}>
                <Form.Item
                  label="Date"
                  className="date_time"
                  labelCol={{ span: 5 }}
                  style={{ width: "100%", margin: 0 }}
                >
                  <Typography className="text-date" style={textForm}>
                    {moment().format("DD-MM-YYYY HH:MM:ss")}
                  </Typography>
                </Form.Item>
              </Row>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            className="row-res-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 0px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="print-btn"
              icon={<PrinterOutlined />}
              type="primary"
              onClick={handlePrint}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
              // disabled={counter === 0 ? true : false}
            >
              {`Save & Download`}
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              loading={isLoad}
              hidden={is_saved == true ? true : false}
              disabled={isLoading}
              // onClick={myclick}
              // icon={counter == 0 ? <></> : <PrinterOutlined />}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {/* {counter === 0 ? "Submit" : "Back To Reservation Menu"} */}
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
