// Import React's Components
import React from "react";
import { useNavigate } from "react-router-dom";

// Import Ant Design Components
import { Button, Card, Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React's Icons
import {
  FaCalendarCheck,
  FaCalendarPlus,
  FaConciergeBell,
} from "react-icons/fa";
import { GiCapitol, GiTheater } from "react-icons/gi";
import { BiCabinet } from "react-icons/bi";

// Import Global Style
import {
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../Style/Global/Global";

// CODE
export default function MICEPages() {
  // USENAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="article-sales-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <GiCapitol
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`MICE (MEETING, INCENTIVE, CONVENTION, and EXHIBITION) PAGES`}
              </Typography>
            </Row>

            <Row className="row-body" justify="start" style={mainBody}>
              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("mice-reservations")}
                    // type="primary"
                    icon={<GiCapitol style={globalIcon} />}
                    style={linkStyle}
                  >
                    {/* <GiOpenBook style={globalIcon} /> */}
                    <Typography className="text-page" style={globalText}>
                      {`MICE Reservations`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move(`history`)}
                    icon={<FaCalendarCheck style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`MICE Reservations History`}</Typography>
                  </Button>
                </Card>
              </Col>

              {/* <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("")}
                    // type="primary"
                    icon={<MdLayers style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Front Office`}</Typography>
                  </Button>
                </Card>
              </Col> */}
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}
