const PreBillArticleShow = (props) => {
  const { article_stay, article_room, bill_type, bill, tax, service } = props;

  let _newBill = [];
  let _ser = service / 100;
  let _taxes = tax / 100;

  console.log("About to Mapping: ", props);

  const newRoom = article_room.map((e) => ({
    id: e?.id,
    article_name: `Room ${e?.room_category} ${e?.room_number}`,
    quantity: 1,
    unit_price: bill_type ? e?.room_price : e?.gross,
    total_price: bill_type ? e?.room_price : e?.gross,
    price_id: e?.price_id,
    gross: e?.gross,
    tax: e?.tax,
    service: e?.service,
  }));

  const newArticles = article_stay.map((e) => {
    return {
      id: e?.id,
      article_name: `${e.article_name} ${e.room_number}`,
      unit_price: bill_type ? e?.unit_price : e?.gross,
      total_price: bill_type
        ? e?.unit_price * e?.item_quantity
        : e?.total_price,
      gross: e?.total_price,
      price_id: e?.price_id,
      quantity: e?.item_quantity || 0,
      tax: e?.tax * e?.item_quantity,
      service: e?.service * e?.item_quantity,
    };
  });

  if (bill?.length > 0) {
    const newBill = bill.map((e) => {
      const _service = e?.unit_price * _ser;
      const _tax = (_service + e?.unit_price) * _taxes;

      const _service_total = e?.total_price * _ser;
      const _tax_total = (_service_total + e?.total_price) * _taxes;

      return _newBill.push({
        ...e,
        price: _service + _tax + e?.unit_price,
        total_price: _tax_total + _service_total + e?.total_price,
      });
    });

    console.log("New Bill: ", newBill);
  }
  console.log("Article Room: ", newRoom);
  console.log("Article Stay: ", newArticles);
  console.log("Article Bill: ", _newBill);

  return { room: newRoom, article: newArticles, bill: _newBill };
};

const BillArticleShow = (props) => {
  const { article, bill_type, bill, tax, service } = props;

  let _ser = 0;
  let _taxes = 0;
  let _nett = 0;
  let _gross = 0;

  console.log("About to Mapping: ", props);

  const newArticles = article.map((e) => ({
    id: e?.id,
    article_id: e?.article_id,
    article_name: e?.article_name,
    quantity: e?.quantity || 0,
    unit_price: bill_type ? e?.unit_price : e?.total_price,
    total_price: bill_type
      ? e?.unit_price * e?.quantity
      : e?.total_price * e?.quantity,
    price_id: e?.price_id,
    gross: e?.total_price,
    tax: e?.tax,
    service: e?.service,
  }));

  const taxservice = newArticles.forEach(({ tax, service, total_price }) => {
    _ser += service;
    _taxes += tax;
    _nett += total_price;
  });

  console.log("Article Room: ", newArticles);
  // console.log("Article Stay: ", newArticles);
  // console.log("Article Bill: ", _newBill);

  return {
    article: newArticles,
    total: {
      service: _ser,
      tax: _taxes,
      unit_price: _nett,
      total_price: _ser + _taxes + _nett,
    },
  };
};

export { PreBillArticleShow, BillArticleShow };
