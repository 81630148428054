// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// API
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
  DatePicker,
  Typography,
  Select,
  Input,
  Layout,
  Form,
  InputNumber,
  Result,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { BsUiChecksGrid } from "react-icons/bs";
import { FaPlaneDeparture } from "react-icons/fa";
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

// Master Components
import MasterPaymentTypeEdit from "../../../Components/Reusable/Master/PaymentType/PaymentTypeEdit";
import MasterPaymentCardEdit from "../../../Components/Reusable/Master/PaymentCard/PaymentCardEdit";
import POSPreBillFormTrx from "../../../Components/Forms/POSPreBill/POSPreBillTrx";

// IMPORT GLOBAL
import { cardBody, cardHeader, mainBody } from "../../../Style/Global/Global";

// Import Notifications
import { masterIncomplete } from "../../../Components/Reusable/Notification/MasterNotif/Notification";
import {
  failedFetch,
  failedPOSOrder,
  failedPOSPayment,
  successPOSOrder,
  successPOSPayment,
} from "../../../Components/Reusable/Notification/Notification";

// CONST
const { confirm } = Modal;
const { Paragraph, Text } = Typography;

// CODE
export default function POSPaymentPage() {
  // CONTEXT
  const { getDeposit, getReservationLeader } = useContext(MainContext);

  // USE LOCATION
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [dataArt, setDataArt] = useState(null);
  const [headerData, setHeaderData] = useState(null);
  const [detailData, setDetailData] = useState([]);
  const [tableKey, setTableKey] = useState([]);
  const [isSplit, setIsSplit] = useState(false);
  const [isJoin, setIsJoin] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  // Payment
  const [payCode, setPayCode] = useState(null);
  const [payId, setPayId] = useState(null);
  const [payment_status, setPayment_status] = useState(null);
  const [coa, setCoa] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  // Is Exist
  const [isExist, setIsExist] = useState(true);

  // FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;
    console.log("Total Transactions: ", totalAmount);
    console.log("Total Deposit: ", depositAmount);

    form.setFieldsValue({
      total_amount: totalAmount,
      deposit_amount: 0,
      //   payment_type: leaders?.payment_type,
      //   payment_details: leaders?.payment_details,
    });

    return () => {
      cleanUp = true;
    };
  }, [totalAmount, depositAmount]);

  useEffect(() => {
    let cleanUp = false;

    console.log(state?.headerData);
    console.log(state?.detailData);
    console.log("state.selectedTable", state?.selectedTable);
    console.log(state?.is_split);
    console.log(state?.is_join);

    setHeaderData(state?.headerData);
    setDetailData(state?.detailData);
    setTableKey(state?.selectedTable);
    setIsSplit(state?.is_split);
    setIsJoin(state?.is_join);

    let totalAmount = 0;

    state?.detailData.forEach((e) => {
      totalAmount += (e.quantity || e.item_quantity) * e.gross;
    });

    console.log(totalAmount);

    setTotalAmount(totalAmount);

    if (state?.headerData != null && !cleanUp) {
      setButtonLoading(true);
      setIsExist(true);
    } else {
      setIsExist(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [state]);

  // HANDLE RETURN
  const handleReturn = (e) => {
    navigate(`${pathname.substring(0, pathname.length - 8)}`, {});
  };

  // HANDLE SUCCESS
  const handleSuccess = (e) => {
    setTimeout(() => {
      setLoading(false);

      // handleSuccess(response);
      navigate(`${pathname}/success`, {});
    }, 1000);
  };

  // Payment Type
  const getPaymentType = (value, code, name) => {
    let _value = value;
    let _code = code;
    let _name = name;
    console.log(value, code, name);

    console.log(payCode);

    setPayId(value.id);

    form.setFieldsValue({
      payment_type: _value,
      payment_details: null,
    });
  };
  // Payment Details/Card
  const getPayCard = (value, code, acc) => {
    console.log(value, code, acc);
    let _value = value;
    let _code = code;
    let _acc = acc;

    const fieldvalue = form.getFieldsValue();

    // console.log(fieldvalue);

    if (code) {
      setPayCode(_code);
    }

    if (acc) {
      setCoa(_acc);
    }

    form.setFieldsValue({
      payment_details: _value,
    });
  };

  // HANDLE CHANGE
  const handleChange = (val) => {
    // console.log("Value Change: ", val);

    let _val = val;
    let _total = totalAmount;
    let _depo = 0;

    let _pay = _depo + _val;
    let _change = _pay - _total;

    // console.log("Change Payment: ", _change);

    form.setFieldsValue({
      change_amount: _change,
    });
  };

  // ON FINISH
  const onFinish = async (e) => {
    // e.preventDefault();
    const _content = e;

    console.log("ON-FINISH FORM >>>", e);

    let allBody = [];
    showModalConfirm(_content);
  };

  // ON SUBMIT FINISH FAILED
  const onFinishFailed = async (error) => {
    // console.log("LOCATION STATE => ", state);
    // console.log("Pathname => ", pathname);
    console.log("Data Incomplete >>> ", error?.values);

    masterIncomplete(error);
  };

  // HANDLE FINISH
  const setFormFields = async () => {
    // const _forms = 0;
    console.log("Set Total Amounts: ", totalAmount);
    console.log("Set Total Deposit: ", depositAmount);

    form.setFieldsValue({
      total_amount: totalAmount?.gross,
      deposit_amount: 0,
      //   payment_type: leaders?.payment_type,
      //   payment_details: leaders?.payment_details,
    });

    if (totalAmount > 0) {
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  // SHOW MODAL POS PAYMENT
  const showModalConfirm = async (record) => {
    const _data = record;
    var bulks = [];

    console.log("Data Articles => ", _data);
    // console.log("Data State => ", state);

    // for (let i = 0; i < dataArt.length; i++) {
    //   reservation_id, price_id, created_by, cust_amount;
    // }

    confirm({
      className: "pos-payment-confirm",
      title: `POS Payment Confirmations`,
      icon: (
        <ExclamationCircleOutlined
          className="confirm-icon"
          style={{ color: "#faad14" }}
        />
      ),
      content: `Are you sure want to pay this bill ?`,
      okText: "Submit",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      okButtonProps: {
        type: "primary",
        className: "submit-btn",
        danger: true,
        style: { backgroundColor: "#1BC5BD", borderColor: "#1BC5BD" },
        // loading: loading,
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      onOk() {
        handleSubmit(_data);
        // return new Promise((resolve, reject) => {
        // handleSubmit(_data);
        //   if (_data) {
        //     console.log("Resolves: ", resolve);
        //   } else {
        //     console.log("Rejects: ", reject);
        //   }
        // }).catch((err) => console.log("Oops errors Promise!", err));
        // console.log("Article deleted");
      },

      onCancel() {
        setLoading(false);
        // console.log("Confirmation Cancelled");
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const _data = value;
    let _totalNett = 0;
    let _totalTax = 0;
    let _totalSer = 0;
    let _totalGross = 0;

    detailData.forEach(({ unit_price, tax, service, gross, item_quantity }) => {
      let _tGross = gross * item_quantity;
      let _tTax = tax * item_quantity;
      let _tSer = service * item_quantity;
      let _tNett = unit_price * item_quantity;

      _totalNett += _tNett;
      _totalSer += _tSer;
      _totalTax += _tTax;
      _totalGross += _tGross;
    });

    console.log("About TO ORDER: ", headerData);
    console.log("TABLE SELECTED: ", tableKey);

    if (isJoin == true) {
      handlePOSPayment(detailData, null);
    } else {
      if (tableKey?.status_table === 1) {
        let obj = {
          name: headerData?.name ?? "NON STAY GUEST",
          table_code: tableKey?.table_code,
          table_id: tableKey?.table_id,
          payment_type: _data?.payment_type,
          payment_details: _data?.payment_details,
          payment_status: headerData?.payment_status != true ? 1 : 2,
          total_nett: _totalNett,
          total_gross: _totalGross,
          total_tax: _totalTax,
          total_service: _totalSer,
          order_step: 1,
          is_open_bill: 1,
          sales_type: headerData?.sales_type,
          order_id_alias: null,

          created_by: user_name,
        };

        console.log("handleSubmit obj:", obj);

        await axios
          .post(
            `${baseurl}/pos/order-header`,
            obj,
            {
              headers: { Authorization: `Bearer ${token ? token : ""}` },
            }
          )
          .then(async (response) => {
            console.log("Add Headers: ", response);

            if (response.status === 200) {
              successPOSOrder(response, { method: 0 });

              await axios
                .get(`${baseurl}/pos/last-order-id-header`)
                .then((res) => {
                  console.log("Res Last ID: ", res);

                  if (res?.data?.length > 0) {
                    if (isSplit) {
                      handleSplit(detailData, res?.data[0], _data);
                    } else {
                      handleDetail(detailData, res?.data[0]);
                    }
                  } else {
                    // failedArticleStay(res, { method: 0 });
                  }
                })
                .catch((error) => {
                  console.log("Res Last Err: ", error);
                });
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
            failedPOSOrder(error, { method: 0 });
          });
      } else {
        await axios
          .get(`${baseurl}/pos/last-order-id-header`)
          .then(async (res) => {
            if (res.status === 200) {
              console.log("Res Last ID: ", res);

              // if (res?.data?.length > 0 && isSplit != true) {
              if (isSplit == false) {
                console.log("Get Last Order Id Header: ", res);
                console.log("DATA >>>", res?.data);

                await axios
                  .put(
                    `${baseurl}/pos/order-header`,
                    {
                      id: detailData[0]?.header_id,
                      name: headerData?.name ?? "NON STAY GUEST",
                      table_code: detailData[0]?.table_code,
                      table_id: detailData[0]?.table_id,
                      payment_type: _data?.payment_type,
                      payment_details: _data?.payment_details,
                      payment_status:
                        headerData?.payment_status != true ? 1 : 2,

                      total_nett: detailData
                        .map((e) => ({
                          nett: e.unit_price * e.item_quantity ?? 0,
                        }))
                        .reduce((prev, v) => prev + v.nett, 0),

                      total_gross: detailData
                        .map((e) => ({
                          gross: e.gross * e.item_quantity ?? 0,
                        }))
                        .reduce((prev, v) => prev + v.gross, 0),

                      total_tax: detailData
                        .map((e) => ({
                          tax: e.tax * e.item_quantity ?? 0,
                        }))
                        .reduce((prev, v) => prev + v.tax, 0),

                      total_service: detailData
                        .map((e) => ({
                          service: e.service * e.item_quantity ?? 0,
                        }))
                        .reduce((prev, v) => prev + v.service, 0),

                      order_step: detailData[0]?.order_step,
                      is_open_bill: null,
                      sales_type: headerData?.sales_type,
                      updated_by: user_name,
                      old_table_code: "Table 6",
                      old_table_id: "T6",
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token ? token : ""}`,
                      },
                    }
                  )

                  .then(async (response) => {
                    if (response.status == 200) {
                      console.log("Edit Header: ", response);
                      successPOSOrder(response, { method: 1 });
                      handleDetail(detailData, res?.data[0]);
                    } else {
                      failedPOSOrder(response, { method: 1 });
                    }
                  })
                  .catch((error) => {
                    console.log("Error: ", error);
                    failedPOSOrder(error, { method: 1 });
                  });
              } else {
                handleSplit(detailData, res?.data[0], _data);
              }
            }
          })

          .catch((error) => {
            console.log("Res Last Err: ", error);
          });
      }
    }
  };

  // Handle Details
  const handleDetail = async (value, e) => {
    const lastResHeader = e;
    console.log("LAST RES HEADER >>>", lastResHeader);
    let bulks = [];

    for (let i = 0; i < detailData.length; i++) {
      detailData[i]?.id == null
        ? bulks.push({
            id: detailData[i]?.id,
            article_id: detailData[i]?.article_id,
            article_sub_name: detailData[i]?.article_sub_name,
            article_sub_id: detailData[i]?.article_sub_id,
            article_name: detailData[i]?.article_name,
            unit_price: detailData[i]?.unit_price,
            item_quantity: detailData[i]?.item_quantity,
            total_price: detailData[i]?.gross * detailData[i]?.item_quantity,
            table_code: tableKey?.table_code,
            table_id: tableKey?.table_id,
            serving_order: detailData[i]?.serving_order ?? "no Option",
            guest_name: headerData?.name ?? "NON STAY GUEST",
            chart_of_account: detailData[i]?.chart_of_account,
            tax: detailData[i]?.tax,
            service: detailData[i]?.service,
            is_tax: true,
            is_service: true,
            order_step: 0,
            gross: detailData[i]?.gross,
            add_ons: detailData[i]?.note || null,
            notes: detailData[i]?.note || null,
            payment_status: headerData?.payment_status !== true ? 1 : 2,
            order_id_alias: detailData[0]?.order_id_alias
              ? detailData[0]?.order_id_alias
              : lastResHeader?.order_id_alias,
            header_id: detailData[0]?.header_id
              ? detailData[0]?.header_id
              : lastResHeader?.id,
            created_by: user_name,
          })
        : null;
    }

    console.log("DETAIL DATA: ", detailData);

    await axios
      .post(
        `${baseurl}/pos/order-detail`,
        {
          data: bulks.length > 0 ? bulks : null,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Add Detail: ", response);

        if (response.status === 200) {
          successPOSOrder(response, { method: 0 });
          handlePOSPayment(bulks, lastResHeader);
        } else {
          failedPOSOrder(response, { method: 0 });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedPOSOrder(error, { method: 0 });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  // Handle Split Bill
  const handleSplit = async (value, e, val) => {
    const lastResHeader = e;
    console.log("LAST RES HEADER >>>", lastResHeader);
    let bulks = [];

    for (let i = 0; i < detailData.length; i++) {
      bulks.push({
        id: detailData[i]?.id,
        order_id_alias: detailData[i]?.order_id_alias,
        created_by: user_name,
        bill_status: 1,
        cust_amount: val?.payment_amount,
        payment_type: val?.payment_type,
        payment_details: val?.payment_details,
        total_nett: detailData[i]?.unit_price * detailData[i]?.item_quantity,
        total_gross: detailData[i]?.gross * detailData[i]?.item_quantity,
        total_tax: detailData[i]?.tax * detailData[i]?.item_quantity,
        total_service: detailData[i]?.service * detailData[i]?.item_quantity,
        item_split: detailData[i]?.item_quantity,
      });
    }

    console.log("DETAIL SPLIT DATA: ", detailData);

    await axios
      .post(
        `${baseurl}/pos/order-payment-split`,
        {
          data: bulks.length > 0 ? bulks : null,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Add Split Detail: ", response);

        if (response.status === 200) {
          successPOSPayment(response, { method: 0 });
          // handlePOSPayment(bulks, lastResHeader);
          navigate(`${pathname}/success`, {
            state: {
              headerData: headerData,
              detailData: bulks,
              payment_id: response?.data?.msg,
              selectedTable: tableKey,
            },
          });
        } else {
          failedPOSPayment(response, { method: 0 });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedPOSPayment(error, { method: 0 });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const handlePOSPayment = async (e, v) => {
    let val = detailData.map((e, i) => {
      return {
        cust_amount: form.getFieldsValue(["payment_amount"]).payment_amount,
        bill_status: isJoin == true ? 2 : isSplit == true ? 1 : 0,
        order_id_alias:
          isJoin == true
            ? detailData[i]?.order_id_alias
            : detailData[0]?.order_id_alias
            ? detailData[0]?.order_id_alias
            : v.order_id_alias,
        created_by: user_name,
      };
    });
    await axios
      .post(
        `${baseurl}/pos/order-payment`,
        {
          data: val,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("POS Payment: ", response);

        if (response.status === 200) {
          if (isSplit == true) {
            successPOSPayment(response, { method: 0 });
          } else if (isJoin == true) {
            successPOSPayment(response, { method: 1 });
          } else {
            successPOSPayment(response, { method: 3 });
          }
          navigate(`${pathname}/success`, {
            state: {
              headerData: headerData,
              detailData: e,
              payment_id: response?.data?.msg,
              selectedTable: tableKey,
            },
          });
        } else {
          if (isSplit == true) {
            failedPOSPayment(response, { method: 0 });
          } else if (isJoin == true) {
            failedPOSPayment(response, { method: 1 });
          } else {
            failedPOSPayment(response, { method: 3 });
          }
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        if (isSplit == true) {
          failedPOSPayment(error, { method: 0 });
        } else if (isJoin == true) {
          failedPOSPayment(error, { method: 1 });
        } else {
          failedPOSPayment(error, { method: 3 });
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  // // HANDLE SUBMIT
  // const handleSubmit = async (value) => {
  //   const _headerData = value;

  //   await axios
  //     .post(
  //       `${baseurl}/guest/checkout-price-stay`,
  //       { data: allBody },
  //       { headers: { Authorization: `Bearer ${token}` } }
  //     )
  //     .then(async (response) => {
  //       console.log("Response Check-Out = ", response);

  //       successCheckOut(response, { method: 0 });

  //       if (response.status === 200) {
  //         await handleCheckOut(_submitted);
  //         // setTimeout(() => {
  //         //   handleSuccess(response);
  //         //   // setLoading(false);
  //         // }, 1000);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Failed to Check-Out = ", error);

  //       failedCheckOut(error, { method: 0 });
  //       // failedBill(error, { method: 0 });
  //       setLoading(false);
  //     });
  // };

  return (
    <Layout>
      <Content>
        <Col span={24} className="expected-arrival-main-layout">
          <Row className="row-title" justify="start" style={mainBody}>
            <BsUiChecksGrid
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`POS Payment Page`}
            </Typography>
          </Row>

          <Row className="space" size="large" style={{ height: 30 }} />

          {isExist ? (
            <>
              <Row
                className="row-search-component"
                justify="start"
                gutter={[30, 30]}
                // style={mainBody}
              >
                <Col
                  className="bill-col"
                  xxl={16}
                  xl={16}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={0}
                >
                  <Row style={mainBody}>
                    {/* HERE'S THE BILL */}
                    <POSPreBillFormTrx
                      totalPayment={0}
                      dataArticle={state?.detailData.map((e) => {
                        return {
                          ...e,
                          name: state?.headerData.name ?? "NON STAY GUEST",
                          payment_status: state?.headerData.payment_status,
                          sales_type: state?.headerData.sales_type,
                        };
                      })}
                    />
                  </Row>
                </Col>

                <Col
                  className="btn-col"
                  xxl={8}
                  xl={8}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <Row
                    className="row-payment"
                    justify="center"
                    align="middle"
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 5,
                      // width: "100%",
                      // minHeight: "100%",
                    }}
                  >
                    <Typography.Title
                      level={4}
                      className="text-form-title"
                      style={{
                        height: 67,
                        display: "flex",
                        alignItems: "center",
                        fontSize: 20,
                        fontWeight: 750,
                      }}
                    >
                      {`PAYMENTS`}
                    </Typography.Title>

                    <Form
                      name="resv-form-modal"
                      className="resv-form-modal"
                      form={form}
                      labelCol={{ span: 8 }}
                      labelWrap={{ span: 16 }}
                      labelAlign="left"
                      //   onChange={handleChange}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      layout="horizontal"
                      style={{
                        padding: 15,
                        width: "100%",
                      }}
                    >
                      <Divider
                        className="divider-form"
                        style={{
                          backgroundColor: "#EBEDF3",
                        }}
                      />
                      <Row
                        className="row-cashier"
                        // style={{
                        //   padding: "0px 30px 0px",
                        // }}
                      >
                        <Col span={24}>
                          <Form.Item
                            label="Total Amount"
                            name="total_amount"
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={"Rp"}
                              disabled
                              formatter={(unit_price) => {
                                return ` ${unit_price}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row justify="start">
                        <Col span={24}>
                          <Form.Item
                            label="Payment Amount"
                            name="payment_amount"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input the payment amount from customer!",
                              },
                            ]}
                            style={{
                              //   margin: "30px 0px 15px",
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={"Rp"}
                              // disabled
                              formatter={(unit_price) => {
                                return ` ${unit_price}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                              }}
                              style={{ width: "100%" }}
                              onChange={handleChange}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Change Amount"
                            name="change_amount"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input the payment amount from customer!",
                            //   },
                            // ]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={"Rp"}
                              disabled
                              formatter={(unit_price) => {
                                return ` ${unit_price}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Deposit Amount"
                            name="deposit_amount"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input the payment amount from customer!",
                            //   },
                            // ]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={"Rp"}
                              disabled
                              formatter={(unit_price) => {
                                return ` ${unit_price}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Form.Item
                          label="Payment Type"
                          name="payment_type"
                          rules={[
                            {
                              required: true,
                              message: "Please, Select the Payment Type!",
                            },
                          ]}
                          style={{ minWidth: "100%" }}
                        >
                          <MasterPaymentTypeEdit
                            getPaymentType={getPaymentType}
                            pay={null}
                          />
                        </Form.Item>
                      </Row>

                      <Row>
                        <Form.Item
                          label="Payment Details"
                          name="payment_details"
                          rules={[
                            {
                              required: true,
                              message: "Please, Select the Payment Details!",
                            },
                          ]}
                          style={{ minWidth: "100%" }}
                        >
                          <MasterPaymentCardEdit
                            getPayCard={getPayCard}
                            getPayTypeId={payId}
                            payCard={null}
                            getCode={setPayCode}
                          />
                        </Form.Item>
                      </Row>

                      <Divider
                        className="divider-form"
                        style={{
                          margin: "15px 0px",
                          backgroundColor: "#EBEDF3",
                        }}
                      />

                      <Row
                        justify="end"
                        align="middle"
                        className="row-form-btn"
                        style={{ padding: 15 }}
                        gutter={30}
                      >
                        {/* <Button
                      className="print-btn"
                      type="primary"
                      // htmlType="submit"
                      icon={<PrinterOutlined />}
                      // onClick={() => {
                      //   handlePrint();
                      //   if (counter == 0) {
                      //     myclick();
                      //   }
                      // }}
                      style={{
                        // backgroundColor: "#1BC5BD",
                        // borderColor: "#1BC5BD",
                        marginRight: 30,
                      }}
                    >
                      Print & Download
                    </Button> */}

                        <Button
                          className="submit-btn"
                          type="primary"
                          danger
                          htmlType="submit"
                          loading={loading}
                          style={{
                            backgroundColor: "#1BC5BD",
                            borderColor: "#1BC5BD",
                            margin: "0px 30px 0px 0px",
                          }}
                        >
                          {`Submit`}
                        </Button>

                        <Button
                          className="cancel-btn"
                          type="default"
                          onClick={handleReturn}
                        >
                          {`Cancel`}
                        </Button>
                      </Row>
                    </Form>
                  </Row>
                </Col>
                {/* <MasterBillTrxForm  /> */}
              </Row>
            </>
          ) : (
            <>
              <Result
                status={"500"}
                title={"ERROR!"}
                subTitle={`Sorry, something went wrong!`}
                extra={[
                  <Row
                    className="extra-row"
                    key={"extra-row"}
                    justify="center"
                    gutter={30}
                  >
                    <Col className="return-col" span={24}>
                      <Space className="btn-space" size="large">
                        <Tooltip
                          className="return-tooltip"
                          title="Return to Expected Departure Page"
                          color="geekblue"
                          placement="right"
                        >
                          <Button
                            className="cancel-btn"
                            key="return-btn"
                            onClick={handleReturn}
                          >
                            {`Return`}
                          </Button>
                        </Tooltip>
                      </Space>
                    </Col>
                  </Row>,
                ]}
                style={mainBody}
              >
                <>
                  <Space className="result-space" direction="vertical" size={5}>
                    <Paragraph>
                      <Text
                        strong
                        style={{
                          fontSize: 16,
                        }}
                      >
                        {`The content you submitted may has the following error:`}
                      </Text>
                    </Paragraph>

                    <Paragraph>
                      <CloseCircleOutlined
                        className="site-result-error-icon"
                        style={{
                          color: "#E73827",
                          margin: "0px 10px 0px 0px",
                        }}
                      />
                      {`You are trying to reach this page without doing any process!`}
                    </Paragraph>

                    <Paragraph>
                      <CloseCircleOutlined
                        className="site-result-error-icon"
                        style={{
                          color: "#E73827",
                          margin: "0px 10px 0px 0px",
                        }}
                      />
                      {`You're request is not valid!`}
                    </Paragraph>

                    <Paragraph>
                      <CloseCircleOutlined
                        className="site-result-error-icon"
                        style={{
                          color: "#E73827",
                          margin: "0px 10px 0px 0px",
                        }}
                      />
                      {`Please, check and modify the following information before resubmitting!`}
                    </Paragraph>
                  </Space>
                </>
              </Result>
            </>
          )}
        </Col>
      </Content>
    </Layout>
  );
}
