// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons

// CODE
export default function MasterRoomNumber(props) {
  // PROPS
  const {
    name,
    // Get Room Number
    getRoomNumber,
    room_category,
    // Room Number Name Edit
    room_number,
    // Room Cat
    rateRoomCatg,
    // is Group
    isGroup,
    is_disable,
  } = props;

  // CONTEXT
  const { getMasterRoom, getMasterRoomStatus } = useContext(MasterContext);
  const { getRoomStatus } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [room, setRoom] = useState(isGroup ? [] : null);
  const [optionValue, setOptionValue] = useState([]);
  const [optInfo, setOptInfo] = useState([]);
  // Bulks
  const [bulks, setBulks] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getMasterRoom({
      main: "main-arrival",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Room Number => ", response);

        // ALL Without Room Category Selected
        if (response?.data?.msg?.length > 0) {
          const _res = response?.data?.msg;

          let filtered = _res.filter((filt) => {
            return !filt.is_deleted;
          });

          let optionAll = filtered.map((dev) => ({
            value: dev?.room_id,
            label: dev?.room_id,
            code: dev?.room_category_code,
            room: dev?.room_number,
            room_category_name: dev?.room_category_name,
            category_id: dev?.room_category_id,
            id: dev?.id,
            coa: dev?.chart_of_account,
            info: {
              label: dev?.room_id,
              number: dev?.room_number,
              status: dev?.room_status_id,
              occupants: dev?.guest_name,
              updated_date: dev?.updated_date,
              occ_status: dev?.occupancy_status || false,
              room_type: dev?.room_bedsetup,
              room_overlook: dev?.room_overlook,
              is_connecting: dev?.connecting_room == 1 ? true : false,
              is_smoking: dev?.smoking == 1 ? true : false,
              floor: dev?.room_floor > 0 ? dev.room_floor : 0,
            },
          }));

          // Sorting Option by Number
          optionAll.sort((a, b) => a.room - b.room);

          // console.log("All Room No >>> ", optionAll);

          if (rateRoomCatg === true) {
            setOptionValue(optionAll);
          } else {
            setBulks(optionAll);
          }
        } else {
          setBulks([]);
        }
        // setOptionValue(rateRoomCatg ? option : optionAll);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // Room Number Occupancy
  const fetchOccupy = async () => {
    await getRoomStatus({
      status: "status",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Room Status => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt.is_deleted;
          });

          // console.log("OCC by Cat --> ", filtered);

          let option = filtered.map((dev) => ({
            label: dev?.room_id,
            number: dev?.room_number > 0 ? dev.room_number : null,
            category: dev?.room_category_name,
            status: dev?.room_status_id,
            occupants: dev?.guest_name || null,
            updated_date: dev?.updated_date,
            occ_status: dev?.occupancy_status,
            category_id: dev?.room_category_id,
          }));

          // console.log("OCC --> ", option);

          setOptInfo(option);
        } else {
          setOptInfo([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECT DATA
  useEffect(() => {
    fetchData();
    // fetchOccupy();
  }, []);

  // USE EFFECT ROOM CAT
  useEffect(() => {
    let cleanUp = false;

    console.log("Room Category ID = ", room_category);
    if (room_category > 0 && bulks.length > 0 && !cleanUp) {
      console.log("Rate Room Category = ", rateRoomCatg);

      // setRoom(room_number);
      handleFilter();
    }

    if ((room_number > 0 || room_number?.length > 0) && !cleanUp) {
      console.log("Room Number = ", room_number);
      setRoom(room_number);
    } else {
      setRoom(isGroup ? [] : null);
    }

    return () => {
      cleanUp = true;
    };
  }, [room_category, room_number, bulks]);

  // Handle Filter
  const handleFilter = () => {
    if (bulks.length > 0) {
      let filtered = bulks.filter((items) => {
        return items.category_id == room_category;
      });

      setOptionValue(filtered);
    } else {
      setOptionValue([]);
    }
  };

  // Handle Change
  const handleChange = (val) => {
    const _value = val.values;
    const _allkeys =
      val.allKeys?.length > 0 ? val.allKeys : val?.allKeys ? [val.allKeys] : [];
    const _info = _allkeys[_allkeys?.length - 1]?.info;

    console.log("Changes: ", val);
    console.log("Info List: ", _allkeys);

    // const _info = optInfo.filter((val) => {
    //   if (val.number == _allkeys[_allkeys?.length - 1]?.room) {
    //     return val;
    //   }
    // });

    const _allNumbers =
      _allkeys?.length > 0
        ? _allkeys.map(({ room }) => {
            return room;
          })
        : _allkeys?.room;

    const _allID =
      _allkeys?.length > 0
        ? _allkeys.map(({ id }) => {
            return id;
          })
        : _allkeys?.id;

    setRoom(_value);

    getRoomNumber({
      form_index: name,
      number: _allNumbers,
      room_id: _value,
      id: _allID,
      info: _info,
      category_id: _allkeys[0]?.category_id || 0,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setRoom(isGroup === true ? [] : null);
  };

  return (
    <>
      <Select
        key="room-number"
        mode={isGroup ? "multiple" : ""}
        style={{ width: "100%" }}
        placeholder="Select Room Numbers!"
        showSearch
        allowClear
        value={room}
        onChange={(e, opt, index) => {
          handleChange({
            values: e,
            allKeys: opt,
          });
        }}
        onClear={handleClear}
        options={optionValue}
        disabled={is_disable}
      />
    </>
  );
}
