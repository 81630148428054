import { Decoder } from "../../../../API/Global/Decoder";

// CODE
export const FrontOfficeParams = () => {
  // Get Params
  let params = sessionStorage.getItem("par-3");

  const _decode = Decoder(params);

  // console.log("Params FO: ", _decode);

  // return _decode;
  return {
    check_in_sign_by: _decode.check_in_sign_by,
    check_out_sign_by: _decode.check_out_sign_by,
    registration_sign_by: _decode.registration_sign_by,
    checkin_time: _decode.checkin_time,
    checkout_time: _decode.checkout_time,
    group_count: _decode.group_count,
    article_id: _decode.article_id,
    deposit_coa: _decode?.deposit_coa,
    revenue_coa: _decode?.revenue_coa,
    bill_type: _decode?.bill_type,
  };
};
