// Import React Components
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// BASE API
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Col,
  Row,
  Tabs,
  Space,
  Divider,
  Input,
  DatePicker,
  TimePicker,
  Select,
  InputNumber,
  Button,
  Checkbox,
  Modal,
  Form,
  Radio,
  Typography,
  Alert,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import {
  PlusOutlined,
  DeleteOutlined,
  DeleteFilled,
  SwitcherFilled,
  SwitcherTwoTone,
} from "@ant-design/icons";
import { MdOutlineDashboardCustomize } from "react-icons/md";

// Import Page Components
import ReservationRoomRateTable from "../../Table/FrontOffice/AddReservation/Table";
import ModalRoomEdit from "../../Modals/RoomPick/RoomEdit";
import ModalRoomPick from "../../Modals/RoomPick/RoomPick";
import ReservationForm from "../../Forms/Reservation/Form";
import RoomavailabilityTable from "../../Table/FrontOffice/RoomAvailability/Table";
import ReservationTable from "../../Table/FrontOffice/Reservation/Table";

// Master Components
import MasterGuestCat from "../../Reusable/Master/GuestCat/GuestCat";
import MasterCurrency from "../../Reusable/Master/Currency/Currency";
import MasterReservationStatus from "../../Reusable/Master/ResStatus/ResStatus";
import MasterRateCode from "../../Reusable/Master/RateCode/RateCode";
import MasterComplimentType from "../../Reusable/Master/Compliment/Compliment";
import MasterSegment from "../../Reusable/Master/Segment/Segment";
import MasterGuest from "../../Reusable/Master/Guest/Guest";
import MasterSource from "../../Reusable/Master/Source/Source";
import MasterPaymentTypeEdit from "../../Reusable/Master/PaymentType/PaymentTypeEdit";
import MasterPaymentCardEdit from "../../Reusable/Master/PaymentCard/PaymentCardEdit";
import MasterSalesman from "../../Reusable/Master/Salesman/Salesman";
import MasterRoomNumber from "../../Reusable/Master/RoomNumber/RoomNumber";
//
import EditRoomRateTable from "../../Table/FrontOffice/EditReservation/Table";
import MasterSubSegment from "../../Reusable/Master/Segment/SubSegment";

import MasterGroup from "../../Reusable/Master/Group/Group";
import MasterArrange from "../../Reusable/Master/Arrange/Arrange";
import MasterPaymentType from "../../Reusable/Master/PaymentType/PaymentType";
import MasterPaymentCard from "../../Reusable/Master/PaymentCard/PaymentCard";
import MasterRoomCat from "../../Reusable/Master/RoomCat/RoomCat";

// Import Notification
import {
  failedAddReserv,
  failedFetch,
  incomplete,
  successAddReserv,
} from "../../Reusable/Notification/Notification";
import {
  failedDeposit,
  successDeposit,
} from "../../Reusable/Notification/MasterNotif/Notification";

// Import Functions
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";

// STYLING
import {
  styleActive,
  styleInactive,
  styleError,
} from "../../../Style/Global/Global";

// CONFIRM MODAL
const { confirm } = Modal;

// TAB PANE of TABS
const { TabPane } = Tabs;
const { TextArea } = Input;

// ON CHANGE
const onChangeTime = (time, timeString) => {
  console.log(time, timeString);
};

// CODE
export default function EditReservationTabs() {
  // CONTEXT
  const { getBillRoom } = useContext(MainContext);
  const {
    getMasterPricePeriod,
    getMasterRatePrice,
    getMasterRoomCat,
    getMasterRateSegment,
  } = useContext(MasterContext);

  // Params
  const currency = CurrencySymbols().code;

  // GET DATE
  const date = new Date(Date.now());
  const disabledDate = (current) => {
    // Can not select days before today and today
    // console.log(current);
    return current <= moment(date);
  };

  // STATE MANAGEMENT
  // ADD NEW RESERVATION
  const [data, setData] = useState([]);
  const [reservationData, setReservationData] = useState(null);
  // Date
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  // Payment
  const [payCode, setPayCode] = useState({
    name: "",
    id: 0,
  });
  const [paymentCard, setPaymentCard] = useState({
    card_id: 0,
    card_name: "",
  });
  const [payment_status, setPayment_status] = useState(null);
  // Room
  const [roomCode, setRoomCode] = useState([]);
  // Active Key Tab Pane
  const [activeKey, setActiveKey] = React.useState("1");
  // MODAL STATE
  // Room Pick
  const [open, setOpen] = useState(false);
  // Guest ID
  const [guestId, setGuestId] = useState(null);
  // ROOM RATE
  const [subSegment, setSubSegment] = useState({
    source_id: 0,
    sales_id: 0,
  });
  const [segmentID, setSegmentID] = useState(null);
  const [roomList, setRoomList] = useState([]);
  const [roomQty, setRoomQty] = useState(0);
  const [roomPrice, setRoomPrice] = useState();
  // Adult Value
  const [people, setPeople] = useState(0);
  // PRICE ROOM
  const [segmentation, setSegmentation] = useState(null);
  const [informations, setInformations] = useState(null);
  const [rCode, setRcode] = useState(null);
  const [categoryId, setCategoryId] = useState(0);
  const [companyInfo, setCompanyInfo] = useState({
    value: "",
    guest_id: 0,
    rate_code: [],
    segment: [],
    rate_id: [],
    segment_id: [],
    company_name: "",
    company_id: 0,
    is_allow: false,
    credit_limit: 0,
  });
  const [rateCode, setRateCode] = useState(null);
  const [isRender, setIsRender] = useState(false);
  // Breakfast
  const [isBreakfast, setIsBreakfast] = useState(false);
  const [totalAbf, setTotalAbf] = useState(0);
  // Type Group
  const [typeGroup, setTypeGroup] = useState(0);
  const [isComply, setIsComply] = useState({
    compliment_id:
      reservationData?.compliment_type > 0
        ? reservationData.compliment_type
        : 2,
    compliment_value:
      reservationData?.compliment_type === 1
        ? true
        : reservationData?.compliment_type === 3
        ? true
        : false,
  });
  const [arrangement, setArrangement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // ERROR STATE
  const [error, setError] = useState(false);

  // TOTAL NIGHT
  let totalNight = moment(endDate).diff(startDate, "days");

  // USE FORM
  const [form] = Form.useForm();

  // USE NAVIGATE & LOCATION
  const navigate = useNavigate();
  const { state, key } = useLocation();

  const fetchStayBeforeUpdate = async () => {
    await axios
      .post(
        `${baseurl}/reservation/stay-before-update`,
        {
          reservation_id: reservationData?.reservation_id
            ? reservationData.reservation_id
            : state?.state?.reservation_id,
          price_id: reservationData?.price_id
            ? reservationData.price_id
            : state?.state?.price_id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("STAY BEFORE UPDATE = ", response);

        if (response?.data?.msg?.a_article_room?.length > 0) {
          const _res = response.data.msg.a_article_room;

          setRoomList(_res);
        }
      })
      .catch((error) => {
        console.log("ERORR = ", error);
      });
  };

  // GET PRICE
  const fetchPrice = async () => {
    // await axios
    //   .post(
    //     `${baseurl}/reservation/article-room-single`,
    //     {
    //       res_id: reservationData?.reservation_id
    //         ? reservationData.reservation_id
    //         : state?.state?.reservation_id,
    //       prc_id: reservationData?.price_id
    //         ? reservationData.price_id
    //         : state?.state?.price_id,
    //     },
    //     { headers: { Authorization: `Bearer ${token}` } }
    //   )
    //   .then((response) => {
    //     console.log("DATA ROOM RATE = ", response);

    //     if (response?.data?.length > 0) {
    //       const _res = response.data;
    //       setRoomList([_res[0]]);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("ERORR = ", error);
    //   });

    await getMasterRateSegment({
      segment_compl: "master-segment",
      segment: state?.state?.arrangement?.segment_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Rate Status => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((filt) => {
            return filt.rate_code == state?.state?.rate_code;
          });

          console.log("Rate Selected --> ", filtered);
          setInformations(
            filtered?.length > 0
              ? filtered[0]?.description
              : "DESCRIPTION NOT FOUND"
          );

          setTotalAbf(filtered?.length > 0 ? filtered[0]?.room_breakfast : 0);
          setRoomCode(filtered);

          handleBreakfast(filtered?.length > 0 ? filtered[0] : null);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // GET ROOM CAT
  const fetchRoomCat = async () => {
    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response GET MASTER ROOM CATEGORY => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (
              (filt.is_deleted == false || filt.is_deleted == null) &&
              filt.room_category_name == state?.state?.room_category
            ) {
              return filt;
            }
          });

          // let room_cat_code = [];
          // rCode.push(filtered[0].room_category_code);
          // console.log(rCode);

          console.log("ROOM CATEGORY => ", filtered);
          setRcode([filtered[0]?.room_category_code]);
          setCategoryId(filtered?.length > 0 ? filtered[0] : 0);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (state && !cleanUp) {
      fetchPrice();
      fetchStayBeforeUpdate();
      // fetchRoomCat();

      console.log("State --> ", state);

      setReservationData(state?.state);

      handleData(state.state);
    }

    // console.log("Res Edit Data: ", state.state.payment_status);
    return () => {
      cleanUp = true;
    };
  }, [state]);

  useEffect(() => {
    let cleanUp = false;

    if (rateCode && rCode && !cleanUp) {
      console.log("Handle Rate Code: ", rateCode, rCode);
      handleRate(rateCode);
    }

    return () => {
      cleanUp = true;
    };
  }, [rateCode, rCode]);

  // ON CHANGE KEY
  const onKeyChange = (key) => {
    // console.log(key);
    setActiveKey(key);
  };

  // Handle Data
  const handleData = (val) => {
    const _data = val;

    setStartDate(_data?.arrival);
    setEndDate(_data?.departure);
    setPayment_status(_data?.payment_status);
    setTypeGroup(_data?.is_abf == true ? 1 : 0);
    setIsBreakfast(_data?.is_abf);
    setArrangement(_data?.arrangement);

    // SET INFO & CONFIRM
    setSegmentation(_data?.segment);
    setCategoryId(_data?.arrangement?.room_category_id);

    setPayCode({
      id: _data?.arrangement?.payment_type_id,
      name: _data?.payment_type,
    });

    setPaymentCard({
      card_id: _data?.arrangement?.payment_card_id,
      card_name: _data?.payment_card,
    });

    setSubSegment({
      sales_id: _data?.arrangement?.sales_id,
      source_id: _data?.arrangement?.source_id,
    });
  };

  // HANDLE START DATE AND END DATE
  // Get Start Date
  const handleFirstDate = (first) => {
    const valueOfInput1 = moment(first.format()).toArray();
    setStartDate(valueOfInput1);
    // console.log("start date", valueOfInput1);

    if (endDate) {
      var ngt = moment(endDate).diff(first, "days");
      form.setFieldsValue({
        night: ngt,
        room_qty: 1,
        adult: 1,
      });
      console.log("Start = ", ngt);
    }
  };

  // Get End Date
  const handleLastDate = (last) => {
    const valueOfInput2 = moment(last.format()).toArray();
    setEndDate(valueOfInput2);
    // console.log("last date", valueOfInput2);
    var ngt = moment(last).diff(startDate, "days");

    console.log(ngt);

    form.setFieldsValue({
      night: ngt,
      room_qty: 1,
      adult: 1,
    });
  };

  // HANDLE MASTER
  // Handle Room Number
  const getRoomNumber = (val) => {
    console.log("Room Number: ", val);
    const number = val?.number;

    form.setFieldsValue({
      room_number: number?.length > 0 ? number[0] : number,
      room_number_id: val?.id?.length > 0 ? val.id[0] : val.id,
    });
  };
  // Handle Breakfast
  const handleBreakfast = (value) => {
    const _abf = value;

    form.setFieldsValue({
      abf_total: _abf?.room_breakfast,
    });
  };
  // Arrangement
  const getArrange = (value) => {
    form.setFieldsValue({
      arrangement: value,
    });
  };
  // Guest Status
  const getCategory = (value) => {
    form.setFieldsValue({
      guest_category: value,
    });
  };
  // Payment Type
  const getPaymentType = (value, id) => {
    console.log("TYPES: ", value, id);

    setPayCode({
      name: value?.name,
      id: value?.id,
    });

    form.setFieldsValue({
      payment_type: value?.name,
    });
  };
  // Payment Details/Card
  const getPayCard = (value, code, name) => {
    console.log("Cards: ", value, code, name);

    // setPayCode(code);

    form.setFieldsValue({
      payment_details: value?.name,
    });
  };
  // Currency
  const getCurrency = (value) => {
    form.setFieldsValue({
      currency: value,
    });
  };
  // Reservation Status
  const getResStatus = (value) => {
    form.setFieldsValue({
      reservation_status: value,
    });
  };

  // Source
  const getSource = (value, opt) => {
    console.log(value);
    console.log(opt);

    setSubSegment({
      source_id: value?.source_id,
      sales_id: subSegment.sales_id,
    });

    form.setFieldsValue({
      source: value?.name,
    });
  };
  // Sales
  const getSalesman = (value, opt) => {
    console.log("Value: ", value);
    console.log("OPT: ", opt);

    setSubSegment({
      source_id: subSegment.source_id,
      sales_id: opt?.sales_id,
    });

    form.setFieldsValue({
      sales: value,
    });
  };
  // Room Category
  const setRoom = (value) => {
    // const room_set = [];

    // room_set.push(value);
    const room_set = value;
    console.log("SET ROOM: ", room_set);

    let cat = [];
    let r_code = [];
    let r_num = [];
    let r_qty = [];

    for (let i = 0; i < room_set.length; i++) {
      // const element = value[i];

      cat.push(room_set[i].room_category);

      r_code.push(room_set[i].room_code);

      r_num.push(room_set[i].room_no);

      r_qty.push(room_set[i].room_qty);

      // console.log("ISI ROOM CAT >> ", cat);
      // console.log("ISI ROOM QUANTITY >> ", r_qty);
    }
    setRcode(r_code);
    console.log(r_code);
    console.log("TEST RUN SET RCODE IS RUNNING....");

    let toy = r_qty.reduce(function (s, v) {
      return s + (v || 0);
    }, 0);

    setRoomQty(toy);

    state.state.room_category = cat;
    reservationData.room_category = cat;

    // Set Fields
    form.setFieldsValue({
      room_category: cat,
      room_code: r_code,
      room_qty: toy,
      room_number: r_num.map((e) => e).flat(),
      room_modal: room_set,
      // adult: cat.length,
    });

    // setPeople(cat.length);
  };

  // Segment
  const getSegment = (value, code) => {
    console.log(code);

    form.setFieldsValue({
      segment: value,
    });
    setSegmentID(code);
  };
  const getSubSegment = (value, id) => {
    console.log(value, id);

    setSubSegment(id);

    if (roomList?.length > 0) {
      setRoomList([]);
    }

    form.setFieldsValue({
      sub_segment: value,
    });
  };

  // Compliment Type
  const getCompliment = (value) => {
    form.setFieldsValue({
      compliment_type: value,
    });
  };

  // Rate Code
  const getRateCode = (value, code) => {
    console.log("RATE CODE ID = ", code);
    console.log("TEST getRateCode IS RUNNING....");
    setRateCode(code);
    // setPeriodEmpty(true);
    console.log("TEST RCODE >>>", rCode);
    // if (rCode.length > 0) {
    //   handleRate(code);
    // }

    form.setFieldsValue({
      rate_code: value,
    });
  };

  // GUEST NAME, BILL RECEICER & BILL INSTRUCTION
  const getGuestName = (value, code) => {
    const _guestData = value;
    // console.log(value, code);

    console.log("Guest Master Data: ", _guestData, code);

    setIsRender(true);

    setGuestId(_guestData.guest_id);
    // setSegmentation(_guestData?.segment ? _guestData?.segment[0] : null);

    setIsRender(true);
    setSegmentation(
      _guestData?.segment?.length > 0
        ? _guestData.segment[0]
        : state?.state?.segment
    );

    if (roomList?.length > 0) {
      setRoomList([]);
    }

    if (value) {
      setCompanyInfo({
        value: _guestData?.value,
        guest_id: _guestData?.guest_id > 0 ? _guestData.guest_id : 0,
        rate_code:
          _guestData?.rate_code?.length > 0 ? _guestData.rate_code : [],
        rate_id: _guestData?.rate_id?.length > 0 ? _guestData.rate_id : [],
        segment_id:
          _guestData?.segment_id?.length > 0 ? _guestData.segment_id : [],
        segment: _guestData?.segment?.length > 0 ? _guestData?.segment : [],
        company_name: _guestData?.company_name || null,
        company_id: _guestData?.company_id > 0 ? _guestData.company_id : 0,
        is_allow: _guestData?.is_allow,
        credit_limit:
          _guestData?.credit_limit > 0 ? _guestData.credit_limit : 0,
      });
    } else {
      setCompanyInfo({
        value: "",
        guest_id: 0,
        rate_code: [],
        segment: [],
        rate_id: [],
        segment_id: [],
        company_name: "",
        company_id: 0,
        is_allow: false,
        credit_limit: 0,
      });
    }

    form.setFieldsValue({
      guest_name: _guestData.value,
      bill_receiver: _guestData.value,
      bill_instruction: _guestData.value,
      // segment:
      //   _guestData?.segment.length > 0
      //     ? _guestData?.segment[0]
      //     : state?.state?.segment,
    });
  };
  // GROUP CODE
  const getGroup = (value) => {
    form.setFieldsValue({
      group: value,
    });
  };
  // GET PRICE
  const getPrice = (value) => {
    console.log("ROOM PRICE === ", value);

    form.setFieldsValue({
      room_price: value,
    });
  };

  // RADIO STATUS PAYMENTS
  const onRadioChange = async (e) => {
    console.log("radio checked", e.target.value);

    setPayment_status(e.target.value);

    form.setFieldsValue({
      payment_status: e.target.value,
    });
  };

  // GET ADULT
  const getAdult = (value) => {
    console.log(value);
    setPeople(value);

    form.setFieldsValue({
      // child: value,
      adult: value,
      // infant: value,
    });
  };
  // GET CHILD
  const getChild = (value) => {
    console.log(value);

    form.setFieldsValue({
      child: value,
      // adult: value,
      // infant: value,
    });
  };
  // GET ADULT
  const getInfant = (value) => {
    console.log(value);

    form.setFieldsValue({
      // child: value,
      // adult: value,
      infant: value,
    });
  };

  // Get Night Value
  var countNight = moment(endDate).diff(startDate, "days");

  // Navigate to Page
  const move = () => {
    // console.log(location);

    navigate(`/front-office/reservation`);
  };

  // HANDLE PRICE
  const handleRate = async (value) => {
    const content = value;

    console.log("Handle Rate --> ", content);
    console.log("Handle rCode >>>", rCode);

    await getMasterRatePrice({
      rate: "rate-code",
      category: "category-res",
      rate_code: content,
      room_cat_code: rCode.join("|"),
      segment: segmentID,
      // sub_segment: subSegment,
      onAwait: () => {
        "on Await";
      },
      onSuccess: (response) => {
        console.log("Rate Room Category >> ", response);

        if (response?.data !== null) {
          let fixed = response.data.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) {
              return val;
            }
          });

          console.log("Publish Rate => ", fixed);

          ///price = fixed;
          // price.push(fixed);
          let rate = [];
          let ngt = form.getFieldsValue();
          console.log("PARAMS => ", ngt);

          if (
            (ngt.room_category.length == 1 && ngt.room_qty == 1) ||
            (ngt.room_category.length > 1 && ngt.room_qty == 1)
          ) {
            console.log("first condition FR");
            // for (let i = 0; i < ngt.night; i++) {
            for (let j = 0; j < fixed.length; j++) {
              let newRate = {
                ...fixed[j],
                date: moment(startDate).add(j, "day"),
                id: fixed[j].id + j,
              };
              rate.push(newRate);
            }
            // }
          } else if (ngt.room_qty > 1 && ngt.room_category.length == 1) {
            console.log("second condition FR");
            for (let i = 0; i < ngt.room_qty; i++) {
              for (let j = 0; j < fixed.length; j++) {
                let newRate = {
                  ...fixed[j],
                  date: moment(startDate),
                  id: fixed[j].id + i,
                };
                rate.push(newRate);
              }
            }
          } else if (
            // ngt.night == 1 &&
            ngt.room_qty > 1 &&
            ngt.room_category.length > 1
          ) {
            console.log("third condition FR");

            fixed.sort(
              (a, b) =>
                ngt.room_category.indexOf(a.room_category_name) -
                ngt.room_category.indexOf(b.room_category_name)
            );

            for (let i = 0; i < fixed.length; i++) {
              for (let j = 0; j < ngt.room_modal[i].room_qty; j++) {
                let toy = {
                  ...fixed[i],
                  id: parseInt(
                    (((i + j) * (i + i + 1)) / 2 + i + 1).toFixed(0)
                  ),
                  date: moment(startDate),
                };
                rate = [...rate, toy];
              }
            }
          }

          console.log("rate --> ", rate);
          setRoomList(rate);

          form.setFieldsValue({
            room_price: rate,
            // rate_code: rate.length > 0 ? rate[0].rate_code_name : "",
            // compliment_type: "Room Only",
          });
        }
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // ON SUBMIT FINISH
  const onFinish = (e) => {
    const value = e;
    setIsLoading(true);

    console.log("On Finish >>> ", value);
    console.log("State >>> ", reservationData);

    setData(e);
    setError(false);

    if (value?.room_price?.length > 0) {
      showModalConfirm(value);
    } else {
      onFinishFailed({
        errorFields: [
          {
            errors: [
              "Please, make sure Room Category, Segment, Sub-Segment and Rate Code to Get Rate!",
            ],
          },
        ],
      });
    }
  };

  // ON SUBMIT FINISH FAILED
  const onFinishFailed = (error) => {
    console.log("DATA SUBMIT >>> ", error?.values);
    // setReservationData(e);
    setError(true);

    incomplete(error);
    setIsLoading(false);
  };

  // SHOW SUBMIT CONFIRMATION
  const showModalConfirm = (value) => {
    const submitContent = value;

    // console.log(deleteContent);

    confirm({
      className: "submit-reservation-confirm",
      title: `Are you sure want to Update the Reservation Data of guest named ${
        submitContent?.guest_name?.toUpperCase() || "Unknown"
      } with Reservation ID ${reservationData?.reservation_id || " --- "} ?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Submit",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        // handleUpdate(submitContent);
        handleUpdate2(submitContent);
        // console.log("Guest deleted");
      },
      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE UPDATE
  const handleUpdate2 = async (e) => {
    const submitData = e;
    const _room = roomList?.length > 0 ? roomList[0] : roomList;

    console.log("Submit Data ==> ", submitData);
    console.log("Price ==> ", _room);
    console.log("Room Code: ", roomCode);
    console.log("Room List: ", roomList);
    console.log("Room Category: ", rCode);
    console.log("Arrangement: ", arrangement);

    let foreign_key = {
      segment_id: _room?.main_segment_id || arrangement?.segment_id,
      payment_card_id: paymentCard.card_id || arrangement?.payment_card_id,
      payment_type_id: payCode.id || arrangement?.payment_type_id,
      rate_code:
        roomCode?.length > 0 ? roomCode[0].rate_code : arrangement?.rate_code,
      rate_code_id:
        roomCode?.length > 0 ? roomCode[0].id : arrangement?.rate_id,
      room_category_id:
        roomCode?.length > 0
          ? roomCode[0].room_category_id
          : arrangement?.room_category_id,
      room_id:
        submitData?.room_number_id > 0
          ? submitData.room_number_id
          : arrangement?.room_id,
      sales_id: subSegment.sales_id || arrangement?.sales_id,
      source_id: subSegment.source_id || arrangement?.source_id,
    };

    await axios
      .put(
        `${baseurl}/reservation/stay`,
        {
          room_number: submitData?.room_number
            ? submitData?.room_number
            : reservationData?.room_number,
          adult: submitData?.adult
            ? parseInt(submitData.adult)
            : reservationData?.adult,
          child: submitData?.child
            ? parseInt(submitData.child)
            : reservationData?.child,
          infant: submitData?.infant
            ? parseInt(submitData.infant)
            : reservationData?.infant,
          reservation_status: submitData?.reservation_status
            ? submitData.reservation_status
            : reservationData?.reservation_status,
          arrangement: foreign_key,
          rate_code: submitData?.rate_code ? submitData.rate_code : "",
          compliment_type: submitData?.compliment_type
            ? submitData.compliment_type
            : reservationData?.compliment_type,
          currency: submitData?.currency
            ? submitData.currency
            : reservationData?.currency,
          room_type: submitData?.room_type ? submitData.room_type : "",
          guest_name: submitData?.guest_name
            ? submitData.guest_name
            : reservationData?.guest_name,
          bill_receiver: submitData?.bill_receiver
            ? submitData.bill_receiver
            : reservationData?.bill_receiver,
          bill_instruction: submitData?.bill_instruction
            ? submitData.bill_instruction
            : reservationData?.bill_instruction,
          arrival_flight: submitData?.arrival_flight
            ? submitData.arrival_flight
            : reservationData?.arrival_flight,
          eta: submitData?.eta ? submitData.eta : reservationData?.eta,
          departure_flight: submitData?.departure_flight
            ? submitData.departure_flight
            : "",
          etd: submitData?.etd ? submitData.etd : reservationData?.etd,
          group: reservationData?.group
            ? reservationData.group
            : state?.state?.group,
          segment: reservationData?.segment
            ? reservationData.segment
            : state?.state?.segment,
          source: submitData?.source
            ? submitData.source
            : reservationData?.source,
          letter: submitData?.letter
            ? submitData.letter
            : reservationData?.letter,
          sales: submitData?.sales ? submitData.sales : reservationData?.sales,
          payment_type: submitData?.payment_type
            ? submitData.payment_type
            : reservationData?.payment_type,
          payment_details: submitData?.payment_details
            ? submitData.payment_details
            : reservationData?.payment_details,
          group_name: submitData?.group_name
            ? submitData.group_name
            : reservationData?.group_name,
          memo_room: submitData?.memo_room
            ? submitData.memo_room
            : reservationData?.memo_room,
          updated_by: user_name,
          payment_status:
            submitData?.payment_status || reservationData?.payment_status,
          sub_segment_id: state?.state?.sub_segment_id
            ? state.state.sub_segment_id
            : reservationData?.sub_segment_id || null,
          reservation_id: reservationData?.reservation_id
            ? reservationData.reservation_id
            : state?.state?.reservation_id,
          price_id: reservationData?.price_id
            ? reservationData?.price_id
            : state?.state?.price_id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("UPDATE >> ", response);
        successAddReserv(response, { method: 1 });

        navigate("/front-office/reservation");
      })
      .catch((error) => {
        console.log(error);
        failedAddReserv(error, { method: 1 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdate = async (e) => {
    const submitData = e;
    const _room = roomList?.length > 0 ? roomList[0] : roomList;

    console.log("Submit Data ==> ", submitData);
    console.log("Price ==> ", _room);
    console.log("Room Code: ", roomCode);
    console.log("Room List: ", roomList);
    console.log("Room Category: ", rCode);
    console.log("Arrangement: ", arrangement);

    let foreign_key = {
      segment_id: _room?.main_segment_id || arrangement?.segment_id,
      payment_card_id: paymentCard.card_id || arrangement?.payment_card_id,
      payment_type_id: payCode.id || arrangement?.payment_type_id,
      rate_code:
        roomCode?.length > 0 ? roomCode[0].rate_code : arrangement?.rate_code,
      rate_code_id:
        roomCode?.length > 0 ? roomCode[0].id : arrangement?.rate_id,
      room_category_id:
        roomCode?.length > 0
          ? roomCode[0].room_category_id
          : arrangement?.room_category_id,
      room_id:
        submitData?.room_number_id > 0
          ? submitData.room_number_id
          : arrangement?.room_id,
      sales_id: subSegment.sales_id || arrangement?.sales_id,
      source_id: subSegment.source_id || arrangement?.source_id,
    };

    await axios
      .put(
        `${baseurl}/reservation/stay`,
        {
          reservation_id: reservationData?.reservation_id
            ? reservationData.reservation_id
            : state?.state?.reservation_id,
          arrival: submitData?.arrival
            ? moment(submitData.arrival).format("YYYY-MM-DD")
            : reservationData?.arrival,
          departure: submitData?.departure
            ? moment(submitData.departure).format("YYYY-MM-DD")
            : reservationData?.departure,
          night: submitData?.night
            ? parseInt(submitData.night)
            : reservationData?.night,
          room_category: submitData?.room_category
            ? submitData.room_category
            : reservationData?.room_category,
          room_number: submitData?.room_number
            ? submitData?.room_number
            : reservationData?.room_number,
          deposit: submitData?.deposit
            ? submitData.deposit
            : reservationData?.deposit,
          room_qty:
            submitData?.room_qty > 0
              ? submitData.room_qty
              : reservationData?.room_qty,
          adult: submitData?.adult
            ? parseInt(submitData.adult)
            : reservationData?.adult,
          child: submitData?.child
            ? parseInt(submitData.child)
            : reservationData?.child,
          infant: submitData?.infant
            ? parseInt(submitData.infant)
            : reservationData?.infant,
          reservation_status: submitData?.reservation_status
            ? submitData.reservation_status
            : reservationData?.reservation_status,
          arrangement: foreign_key,
          guest_category: submitData?.guest_category
            ? submitData.guest_category
            : reservationData?.guest_category,
          rate_code: submitData?.rate_code ? submitData.rate_code : "",
          compliment_type: submitData?.compliment_type
            ? submitData.compliment_type
            : reservationData?.compliment_type,
          currency: submitData?.currency
            ? submitData.currency
            : reservationData?.currency,
          // room_type: submitData?.room_type ? submitData.room_type : "",
          guest_name: submitData?.guest_name
            ? submitData.guest_name
            : reservationData?.guest_name,
          bill_receiver: submitData?.bill_receiver
            ? submitData.bill_receiver
            : reservationData?.bill_receiver,
          bill_instruction: submitData?.bill_instruction
            ? submitData.bill_instruction
            : reservationData?.bill_instruction,
          arrival_flight: submitData?.arrival_flight
            ? submitData.arrival_flight
            : reservationData?.arrival_flight,
          eta: submitData?.eta ? submitData.eta : reservationData?.eta,
          departure_flight: submitData?.departure_flight
            ? submitData.departure_flight
            : "",
          etd: submitData?.etd ? submitData.etd : reservationData?.etd,
          group: reservationData?.group
            ? reservationData.group
            : state?.state?.group,
          segment: reservationData?.group
            ? reservationData.group
            : state?.state?.group,
          source: submitData?.source
            ? submitData.source
            : reservationData?.source,
          letter: submitData?.letter
            ? submitData.letter
            : reservationData?.letter,
          // deposit: submitData.deposit ? parseInt(submitData.deposit) : 100000,
          payment_type: submitData?.payment_type
            ? submitData.payment_type
            : reservationData?.payment_type,
          payment_details: submitData?.payment_details
            ? submitData.payment_details
            : reservationData?.payment_details,
          memo_room: submitData?.memo_room
            ? submitData.memo_room
            : reservationData?.memo_room,
          group_name: submitData?.group_name
            ? submitData.group_name
            : reservationData?.group_name,
          // room_price: prc ? prc : roomList[0]?.room_price,
          room_rate_id:
            reservationData?.room_rate_id?.length > 0
              ? reservationData?.room_rate_id
              : state?.state?.room_rate_id,
          price_id: reservationData?.price_id
            ? reservationData?.price_id
            : state?.state?.price_id,
          payment_status:
            submitData?.payment_status || reservationData?.payment_status,
          sales: submitData?.sales ? submitData.sales : reservationData?.sales,
          is_dummy: submitData?.is_dummy
            ? submitData.is_dummy
            : reservationData?.is_dummy,
          is_group: reservationData?.is_group,

          main_compliment_id: submitData?.compliment_type
            ? submitData.compliment_type
            : reservationData?.compliment_type,
          main_segment_id:
            segmentID > 0
              ? segmentID
              : submitData?.room_price[0]?.main_segment_id,
          // subsegment_id: subSegment > 0 ? subSegment : submitData?.sub_segment,

          room_price: _room?.price > 0 ? _room.price : roomList[0]?.price || 0,
          // room_price: submitData?.room_price[0]?.price,
          tax_room: _room?.tax > 0 ? _room.tax : roomList[0]?.tax || 0,
          is_tax_room: _room?.is_tax ? _room.is_tax : roomList[0]?.is_tax,
          service_room:
            _room?.service > 0 ? _room.service : roomList[0]?.service || 0,
          is_service_room: _room?.is_service
            ? _room.is_service
            : roomList[0]?.is_service,
          is_step: 0,
          gross_room: _room?.gross > 0 ? _room.gross : roomList[0]?.gross || 0,

          updated_by: user_name,
          // created_by: reservationData?.created_by
          //   ? reservationData.created_by
          //   : state?.state.created_by,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("UPDATE >> ", response);
        successAddReserv(response, { method: 1 });

        navigate("/front-office/reservation");
      })
      .catch((error) => {
        console.log(error);
        failedAddReserv(error, { method: 1 });
      })
      .finally(() => {
        setIsLoading(false);
      });

    // BODY
    // let body = {
    //   reservation_id: reservationData?.reservation_id
    //     ? reservationData.reservation_id
    //     : state?.state?.reservation_id,
    //   arrival: submitData?.arrival
    //     ? moment(submitData.arrival).format("YYYY-MM-DD")
    //     : reservationData?.arrival,
    //   departure: submitData?.departure
    //     ? moment(submitData.departure).format("YYYY-MM-DD")
    //     : reservationData?.departure,
    //   night: submitData?.night
    //     ? parseInt(submitData.night)
    //     : reservationData?.night,
    //   room_category: submitData?.room_category
    //     ? submitData.room_category
    //     : reservationData?.room_category,
    //   room_number: submitData?.room_number
    //     ? submitData?.room_number
    //     : reservationData?.room_number,
    //   deposit: submitData?.deposit
    //     ? submitData.deposit
    //     : reservationData?.deposit,
    //   room_qty:
    //     submitData?.room_qty > 0
    //       ? submitData.room_qty
    //       : reservationData?.room_qty,
    //   adult: submitData?.adult
    //     ? parseInt(submitData.adult)
    //     : reservationData?.adult,
    //   child: submitData?.child
    //     ? parseInt(submitData.child)
    //     : reservationData?.child,
    //   infant: submitData?.infant
    //     ? parseInt(submitData.infant)
    //     : reservationData?.infant,
    //   reservation_status: submitData?.reservation_status
    //     ? submitData.reservation_status
    //     : reservationData?.reservation_status,
    //   arrangement: foreign_key,
    //   guest_category: submitData?.guest_category
    //     ? submitData.guest_category
    //     : reservationData?.guest_category,
    //   rate_code: submitData?.rate_code ? submitData.rate_code : "",
    //   compliment_type: submitData?.compliment_type
    //     ? submitData.compliment_type
    //     : reservationData?.compliment_type,
    //   currency: submitData?.currency
    //     ? submitData.currency
    //     : reservationData?.currency,
    //   // room_type: submitData?.room_type ? submitData.room_type : "",
    //   guest_name: submitData?.guest_name
    //     ? submitData.guest_name
    //     : reservationData?.guest_name,
    //   bill_receiver: submitData?.bill_receiver
    //     ? submitData.bill_receiver
    //     : reservationData?.bill_receiver,
    //   bill_instruction: submitData?.bill_instruction
    //     ? submitData.bill_instruction
    //     : reservationData?.bill_instruction,
    //   arrival_flight: submitData?.arrival_flight
    //     ? submitData.arrival_flight
    //     : reservationData?.arrival_flight,
    //   eta: submitData?.eta ? submitData.eta : reservationData?.eta,
    //   departure_flight: submitData?.departure_flight
    //     ? submitData.departure_flight
    //     : "",
    //   etd: submitData?.etd ? submitData.etd : reservationData?.etd,
    //   group: reservationData?.group
    //     ? reservationData.group
    //     : state?.state?.group,
    //   segment: submitData?.segment
    //     ? submitData.segment
    //     : reservationData?.segment,
    //   source: submitData?.source ? submitData.source : reservationData?.source,
    //   letter: submitData?.letter ? submitData.letter : reservationData?.letter,
    //   // deposit: submitData.deposit ? parseInt(submitData.deposit) : 100000,
    //   payment_type: submitData?.payment_type
    //     ? submitData.payment_type
    //     : reservationData?.payment_type,
    //   payment_details: submitData?.payment_details
    //     ? submitData.payment_details
    //     : reservationData?.payment_details,
    //   memo_room: submitData?.memo_room
    //     ? submitData.memo_room
    //     : reservationData?.memo_room,
    //   group_name: submitData?.group_name
    //     ? submitData.group_name
    //     : reservationData?.group_name,
    //   // room_price: prc ? prc : roomList[0]?.room_price,
    //   room_rate_id:
    //     reservationData?.room_rate_id?.length > 0
    //       ? reservationData?.room_rate_id
    //       : state?.state?.room_rate_id,
    //   price_id: reservationData?.price_id
    //     ? reservationData?.price_id
    //     : state?.state?.price_id,
    //   payment_status:
    //     submitData?.payment_status || reservationData?.payment_status,
    //   sales: submitData?.sales ? submitData.sales : reservationData?.sales,
    //   is_dummy: submitData?.is_dummy
    //     ? submitData.is_dummy
    //     : reservationData?.is_dummy,
    //   is_group: reservationData?.is_group,

    //   main_compliment_id: submitData?.compliment_type
    //     ? submitData.compliment_type
    //     : reservationData?.compliment_type,
    //   main_segment_id:
    //     segmentID > 0 ? segmentID : submitData?.room_price[0]?.main_segment_id,
    //   // subsegment_id: subSegment > 0 ? subSegment : submitData?.sub_segment,

    //   room_price: _room?.price > 0 ? _room.price : roomList[0]?.price || 0,
    //   tax_room: _room?.tax > 0 ? _room.tax : roomList[0]?.tax || 0,
    //   is_tax_room: _room?.is_tax ? _room.is_tax : roomList[0]?.is_tax,
    //   service_room:
    //     _room?.service > 0 ? _room.service : roomList[0]?.service || 0,
    //   is_service_room: _room?.is_service
    //     ? _room.is_service
    //     : roomList[0]?.is_service,
    //   is_step: 0,
    //   gross_room: _room?.gross > 0 ? _room.gross : roomList[0]?.gross || 0,

    //   updated_by: user_name,
    //   created_by: reservationData?.created_by
    //     ? reservationData.created_by
    //     : state?.state.created_by,
    // };
    // console.log("BODY PUT: ", body);
    // setIsLoading(false);
  };

  // CHECK ON TAB CLICK
  const disabled = (e) => {
    console.log(e);
  };

  // if (rCode != null) {
  //   handleRate(rateCode);
  // }

  // CONSOLE DATA
  // console.log("DATA ADD RESERV ==>>> ", reservationData);
  // console.log("Room Modal => ", roomCode);
  // console.log("Start Date => ", startDate);
  // console.log("STATE => ", reservationData);
  // console.log("ROOM LIST => ", rCode);
  // console.log("sement_id >>>>", segmentID);
  // console.log("Location => ", location);
  // console.log("Type => ", state?.type);
  // console.log("Selected Room = ", selectedRoom);
  // console.log("Selected Room Category = ", rCode);
  // console.log("PAY CODE >>> ", payCode);
  // console.log("PAY DETAILS CODE >>> ", payId);
  // console.log("Price ID Last => ", lastPriceId);

  // TAB CONTENTS
  const items = [
    {
      label: (
        <>
          <Row
            justify="start"
            align="middle"
            style={{ width: 297, height: 80 }}
          >
            <Col style={{ margin: "10px 20px 10px 10px" }}>
              <Button
                className="1st-icon-tab-reservation-btn"
                disabled
                // icon={<RiNumber1 />}
                type="primary"
                style={
                  activeKey == 1
                    ? styleActive
                    : error == true
                    ? styleError
                    : styleInactive
                }
              >
                1
              </Button>
            </Col>

            <Col>
              <Row style={{ height: 20 }}>
                <p style={error == true ? { color: "#f64e60" } : {}}>
                  {state?.type == 0 ? "Edit Reservation" : "Add Member Group"}
                </p>
              </Row>

              <Row style={{ height: 20 }}>
                <h4>Date and Guest Details</h4>
              </Row>
            </Col>
          </Row>
        </>
      ),
      key: "1",
      disabled: true,
      forceRender: true,
      children: (
        <>
          <Content
            className="tab-pane-layout"
            key={"tab-pane-layout"}
            style={{
              // padding: "60px 249px",
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 5px 5px",
            }}
          >
            <Row gutter={30} className="row-reserve-date">
              <Col span={8}>
                <Form.Item
                  label="Arrival"
                  name="arrival"
                  style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                >
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    onChange={handleFirstDate}
                    disabledDate={disabledDate}
                    placeholder={"Start Date"}
                    style={{
                      minWidth: "100%",
                    }}
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Departure"
                  name="departure"
                  // rules={[
                  // {
                  //   required: true,
                  //   message: "Please, Select Departure Date!",
                  // },
                  // {
                  //   validator: async (_, departure) => {
                  //     var arrival =
                  //       form.getFieldValue("arrival") || reservationData.arrival;
                  //     // console.log("ARRIVAL => ", arrival);
                  //     // console.log("DEPART => ", departure);
                  //     // console.log("DIFF", (departure = arrival));

                  //     if (arrival != null)
                  //       if (departure <= moment(arrival).add(1, "days")) {
                  //         return Promise.reject(
                  //           "Departure Date Must be Greater than Arrival Date."
                  //         );
                  //       }
                  //   },
                  // },
                  // ]}
                  // onChange={form.setFieldsValue({
                  //   departure: moment(state.departure),
                  // })}
                  style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                >
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    placeholder={"End Date"}
                    disabledDate={disabledDate}
                    onChange={handleLastDate}
                    style={{
                      minWidth: "100%",
                    }}
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Night"
                  name="night"
                  rules={[
                    {
                      required: true,
                      message: "Please, Pick the Date!",
                    },
                  ]}
                  // onChange={form.setFieldsValue({
                  //   night: countNight | state.night,
                  // })}
                >
                  <InputNumber
                    size="large"
                    placeholder="Qty"
                    disabled
                    style={{ width: 100 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
            />

            <Row gutter={30} className="row-reserv-guest">
              <Col xxl={8} xl={8} lg={16} md={16}>
                <Form.Item
                  label="Guest Name"
                  name="guest_name"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input Guest Name!",
                    },
                  ]}
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  {state?.state?.group == 2 ? (
                    <Input placeholder="Guest Member Group Name" />
                  ) : (
                    <MasterGuest
                      getGuestName={getGuestName}
                      name={
                        reservationData?.guest_name
                          ? reservationData.guest_name
                          : null
                      }
                    />
                  )}
                </Form.Item>
              </Col>

              {/* <Col span={8}>
                <Form.Item
                  label="Arrangement"
                  name="arrangement"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select Room Arrangement!",
                    },
                  ]}
                  style={{ marginRight: 30, width:  '100%' }}
                >
                  <MasterArrange getArrange={getArrange} />
                </Form.Item>
              </Col> */}

              <Col xxl={8} xl={8} lg={12} md={12}>
                <Form.Item
                  label="Payment Type"
                  name="payment_type"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select the Payment Type!",
                    },
                  ]}
                  style={{ minWidth: "100%" }}
                >
                  <MasterPaymentTypeEdit
                    getPaymentType={getPaymentType}
                    pay={
                      reservationData?.payment_type
                        ? reservationData.payment_type
                        : null
                    }
                    // getPayTypeId={setPayCode}
                  />
                </Form.Item>
              </Col>

              <Col xxl={8} xl={8} lg={12} md={12}>
                <Form.Item
                  label="Payment Details"
                  name="payment_details"
                  style={{ minWidth: "100%" }}
                >
                  <MasterPaymentCardEdit
                    getPayCard={getPayCard}
                    getPayTypeId={payCode.id}
                    payCard={
                      reservationData?.payment_details
                        ? reservationData.payment_details
                        : null
                    }
                    // getCode={setPayId}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider style={{ margin: "0px 0px 15px" }} />

            <Row gutter={30} className="row-reserv-room-info">
              <Col xxl={8} xl={8} lg={16} md={16}>
                <Form.Item
                  label="Room Category"
                  name="room_category"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please, Pick a Room!",
                  //   },
                  // ]}
                  // onChange={form.setFieldsValue({
                  //   room_category: state.room_category
                  //     ? state.room_category
                  //     : "",
                  // })}
                >
                  <Input
                    placeholder="Room Category"
                    disabled={true}
                    // addonAfter={
                    //   <MdOutlineDashboardCustomize
                    //     className="nav-icon"
                    //     onClick={() => {
                    //       // Data Fields
                    //       const fieldValue = form.getFieldsValue();
                    //       setFieldsValue(fieldValue);

                    //       // console.log("Field Values >>> ", fieldValue);
                    //       setOpen(!open);
                    //     }}
                    //     style={{
                    //       fontSize: 20,
                    //       color: "#3699FF",
                    //       marginTop: 3,
                    //     }}
                    //   />
                    // }
                    // // onClick={() => {
                    // //   setOpen(!open);
                    // // }}
                    // style={{
                    //   minWidth: "100%",
                    // }}
                  />
                </Form.Item>
              </Col>

              {/* 
              <Form.Item name="room_modal">
                <ModalRoomEdit
                  modalData={open == true ? reservationData : []}
                  countNight={countNight}
                  modalOpen={open}
                  // setRoomCode={setRoomCode}
                  modalClose={setOpen}
                  setRoom={setRoom}
                  room_cat_code={rCode}
                  is_edited={state?.type == 0 ? true : false}
                />
              </Form.Item>
                 */}

              <Col xxl={8} xl={8} lg={16} md={16}>
                <Form.Item label="Room Number" name="room_number">
                  <MasterRoomNumber
                    isGroup={false}
                    room_category={
                      state?.state?.room_category_id > 0
                        ? state.state.room_category_id
                        : 0
                    }
                    getRoomNumber={getRoomNumber}
                    room_number={
                      reservationData?.room_number > 0
                        ? reservationData.room_number
                        : 0
                    }
                  />
                </Form.Item>

                <Form.Item label="Room Number" name="room_number_id" hidden>
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Room Quantity" name="room_qty">
                  <Input
                    // size="large"
                    placeholder="Qty"
                    disabled
                    style={{ width: 90 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="row-reserv-guest">
              <Col span={4}>
                <Form.Item
                  label="Adult"
                  name="adult"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input Total Guest!",
                    },
                  ]}
                  // onChange={form.setFieldsValue({
                  //   adult: state.adult ? state.adult : 0,
                  // })}
                  style={{ margin: "0px 30px 0px 0px" }}
                >
                  <InputNumber
                    size="large"
                    min={1}
                    // max={10}
                    placeholder="Total Adult"
                    style={{ width: 90 }}
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item
                  size="large"
                  label="Child"
                  name="child"
                  // onChange={form.setFieldsValue({
                  //   child: state.child ? state.child : 0,
                  // })}
                  // style={{ margin: "0px 30px 0px 0px" }}
                >
                  <InputNumber
                    size="large"
                    min={0}
                    // max={10}
                    placeholder="Total Child"
                    style={{ width: 90 }}
                  />
                  {/* <MasterInputChild getChild={getChild} /> */}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Infant"
                  name="infant"

                  // onChange={form.setFieldsValue({
                  //   infant: state.infant ? state.infant : 0,
                  // })}
                >
                  <InputNumber
                    min={0}
                    size="large"
                    // max={10}
                    placeholder="Total Infant"
                    style={{ width: 90 }}
                  />
                  {/* <MasterInputInfant getInfant={getInfant} /> */}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Guest Status"
                  name="guest_category"
                  style={{ minWidth: "100%" }}
                >
                  <MasterGuestCat
                    getCategory={getCategory}
                    guest_status={
                      reservationData?.guest_category
                        ? reservationData.guest_category
                        : ""
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider style={{ margin: "0px 0px 15px" }} />

            <Row
              justify="space-bet"
              align="top"
              className="row-button-next-tab-pane"
            >
              <Col span={12} className="text-col">
                <Typography
                  className="res-text"
                  style={{ fontSize: 15, fontWeight: 750 }}
                >
                  {reservationData?.reservation_id}
                </Typography>
              </Col>

              <Col
                span={12}
                className="text-col"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={move}
                  style={{ marginRight: 30 }}
                >
                  {`Cancel`}
                </Button>

                <Button
                  className="next-btn"
                  type="primary"
                  onClick={() => onKeyChange("2")}
                >
                  {`Next`}
                </Button>
              </Col>
            </Row>
          </Content>
        </>
        // </TabPane>
      ),
    },
    {
      label: (
        <>
          <Row
            justify="start"
            align="middle"
            style={{ width: 297, height: 80 }}
          >
            <Col style={{ margin: "10px 20px 10px 10px" }}>
              <Button
                className="2nd-icon-tab-reservation-btn"
                disabled
                // icon={<RiNumber1 />}
                type="primary"
                style={activeKey == 2 ? styleActive : styleInactive}
              >
                2
              </Button>
            </Col>

            <Col>
              <Row style={{ height: 20 }}>
                <p style={error == true ? { color: "#f64e60" } : {}}>
                  Edit Reservation
                </p>
              </Row>
              <Row style={{ height: 20 }}>
                <h4>Room Details</h4>
              </Row>
            </Col>
          </Row>
        </>
      ),
      key: "2",
      disabled: true,
      forceRender: true,
      children: (
        <>
          <Content
            className="tab-pane-layout"
            key={"tab-pane-layout"}
            style={{
              // padding: "60px 249px",
              // padding: screen.lg == false ? "60px 249px" : 30,
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 5px 5px",
            }}
          >
            <Row gutter={30} className="room-segmentations">
              <Col span={6}>
                <Form.Item
                  label="Compliment Type"
                  name="compliment_type"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Compliment Type!",
                    },
                  ]}
                  style={{
                    marginRight: 30,
                    minWidth: "100%",
                  }}
                >
                  <MasterComplimentType
                    getCompliment={getCompliment}
                    compl={"No Compliment"}
                    is_disable={true}
                  />
                </Form.Item>
              </Col>
              {/* </Row>

            <Row gutter={30} className="room-segmentations"> */}
              <Col span={6}>
                <Form.Item
                  label="Segment"
                  name="segment"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Segment!",
                    },
                  ]}
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <MasterSegment
                    getSegment={getSegment}
                    is_render={isRender}
                    is_rerender={setIsRender}
                    sgmnt={
                      state?.state?.segment
                        ? state.state.segment
                        : reservationData?.segment
                    }
                    is_disable={true}
                  />
                </Form.Item>
              </Col>

              {/* <Col span={8}>
                <Form.Item
                  label="Sub Segment"
                  name="sub_segment"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Sub Segment!",
                    },
                  ]}
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <MasterSubSegment
                    getSubSegment={getSubSegment}
                    subSeg={
                      reservationData?.sub_segment_id
                        ? reservationData.sub_segment_id
                        : null
                    }
                    is_render={isRender}
                    is_rerender={setIsRender}
                    is_disable={true}
                  />
                </Form.Item>
              </Col> */}

              <Col
                span={12}
                className="abf-room-row"
                // style={{ minWidth: "100%" }}
              >
                <Row
                  className="row-room-details"
                  justify="space-between"
                  // style={{ minWidth: "100%" }}
                >
                  <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      label="Rate Code"
                      name="rate_code"
                      rules={[
                        {
                          required: true,
                          message: "Please, Select a Rate Code!",
                        },
                      ]}
                      style={{
                        marginRight: 30,
                        minWidth: "100%",
                      }}
                    >
                      <MasterRateCode
                        getRateCode={getRateCode}
                        segment_id={segmentID}
                        // sub_segment_id={subSegment}
                        is_render={isRender}
                        is_rerender={setIsRender}
                        rate_code={
                          reservationData
                            ? reservationData.room_rate_id[0]
                            : null
                        }
                        is_disable={true}
                        getOptions={() => {}}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      label="Total Breakfast"
                      // valuePropName="checked"
                      name="abf_total"
                      style={{
                        // marginTop: 30,
                        margin: "0px  30px",
                        // minWidth: "100%",
                      }}
                    >
                      <InputNumber
                        placeholder="Total Breakfast"
                        disabled
                        min={0}
                        style={{
                          minWidth: "75%",
                        }}
                      />
                      {/* <Checkbox
                        key={"check-breakfast"}
                        className="ro-rbf-select"
                        onChange={(e) => {
                          console.log(e.target.checked);

                          setIsBreakfast(e.target.checked);
                        }}
                      >
                        Includes Breakfast
                      </Checkbox> */}
                    </Form.Item>

                    <Form.Item
                      label="IS ABF?"
                      // valuePropName="checked"
                      hidden
                      name="is_abf"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="rate-desc" style={{ minWidth: "100%" }}>
              <Alert
                className="desc-alert"
                type="info"
                closable={false}
                showIcon
                message={"Segments & Rate Code Descriptions:"}
                description={
                  <Typography className="desc-text" style={{ fontWeight: 500 }}>
                    {`SEGMENTS: ${
                      segmentation ? segmentation.toUpperCase() : ""
                    } --- ${informations || ""}`}
                  </Typography>
                }
                style={{ minWidth: "100%" }}
              />
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
            />

            <Row className="row-room-price">
              <Col span={24}>
                <Form.Item
                  name="room_price"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please, make sure Room Category, Segment, Sub-Segment and Rate Code to Get Rate!",
                    },
                  ]}
                >
                  <EditRoomRateTable
                    dataGuest={state.state}
                    setRoomPrice={setRoomPrice}
                    getPrice={getPrice}
                    night={totalNight}
                    // is_breakfast={isBreakfast}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
            />

            <Row gutter={30} className="guest-name-reservation">
              <Col span={8}>
                <Form.Item
                  label="Reservation Status"
                  name="reservation_status"
                  style={{
                    marginRight: 30,
                    minWidth: "100%",
                  }}
                >
                  <MasterReservationStatus
                    getResStatus={getResStatus}
                    res={
                      reservationData?.reservation_status
                        ? reservationData.reservation_status
                        : null
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Bill Receiver" name="bill_receiver">
                  <Input
                    placeholder="Insert Bill Receiver"
                    style={{ minWidth: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Bill Instruction" name="bill_instruction">
                  <Input
                    placeholder="Insert Bill Instruction"
                    style={{ minWidth: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="flight-arrival-reservation">
              <Col span={8}>
                <Form.Item
                  label="Currency"
                  name="currency"
                  style={{
                    minWidth: "100%",
                  }}
                >
                  <MasterCurrency
                    getCurrency={getCurrency}
                    pay={reservationData ? reservationData.currency : null}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Payment Status"
                  name="payment_status"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Payment Status!",
                    },
                  ]}
                  style={{
                    marginRight: 30,
                    minWidth: "100%",
                  }}
                >
                  <Radio.Group
                    onChange={onRadioChange}
                    value={payment_status}
                    disabled={reservationData?.group > 0 ? true : false}
                  >
                    <Space direction="vertical">
                      <Radio value={1}>{`Cash Basis`}</Radio>
                      <Radio value={2}>{`Charge To Room`}</Radio>
                      {/* <Radio value={3}>{`Deposit`}</Radio> */}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
            />

            <Row
              justify="space-bet"
              align="top"
              className="row-button-next-tab-pane"
            >
              <Col span={12} className="text-col">
                <Typography
                  className="res-text"
                  style={{ fontSize: 15, fontWeight: 750 }}
                >
                  {reservationData?.reservation_id}
                </Typography>
              </Col>

              <Col
                span={12}
                className="text-col"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={move}
                  style={{ marginRight: 30 }}
                >
                  {`Cancel`}
                </Button>

                <Button
                  type="primary"
                  className="next-btn"
                  onClick={() => onKeyChange("1")}
                  style={{ marginRight: 30 }}
                >
                  {`Back`}
                </Button>

                <Button
                  className="next-btn"
                  type="primary"
                  onClick={() => onKeyChange("3")}
                >
                  {`Next`}
                </Button>
              </Col>
            </Row>
          </Content>
        </>
      ),
    },
    {
      label: (
        <>
          <Row
            justify="start"
            align="middle"
            style={{ width: 297, height: 80 }}
          >
            <Col style={{ margin: "10px 20px 10px 10px" }}>
              <Button
                className="3rd-icon-tab-reservation-btn"
                disabled
                // icon={<RiNumber1 />}
                type="primary"
                style={activeKey == 3 ? styleActive : styleInactive}
              >
                3
              </Button>
            </Col>

            <Col>
              <Row style={{ height: 20 }}>
                <p>Edit Reservation</p>
              </Row>

              <Row style={{ height: 20 }}>
                <h4>Room Memo Details</h4>
              </Row>
            </Col>
          </Row>
        </>
      ),
      key: "3",
      disabled: true,
      forceRender: true,
      children: (
        <>
          <Content
            className="tab-pane-layout"
            key={"tab-pane-layout"}
            style={{
              // padding: "60px 249px",
              // padding: screen.lg == false ? "60px 249px" : 30,
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 5px 5px",
            }}
          >
            <Row gutter={30} className="group-segment-reservation">
              <Col span={8}>
                <Form.Item
                  label="Group Name"
                  name="group_name"
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <Input placeholder="Group Name" />
                  {/* <MasterGroup getGroup={getGroup} /> */}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Deposit"
                  name="deposit"
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <InputNumber
                    placeholder="Input Deposit"
                    addonBefore={currency}
                    formatter={(deposit) => {
                      return ` ${deposit}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      );
                    }}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Source"
                  name="source"
                  style={{ minWidth: "100%" }}
                >
                  <MasterSource
                    getSource={getSource}
                    srce={
                      reservationData?.source ? reservationData.source : null
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
            />

            <Row gutter={30} className="sales-info-reservation">
              <Col xxl={16} xl={16} lg={16} md={24} sm={24}>
                <Row className="flight-row" gutter={30}>
                  <Col span={12}>
                    <Form.Item
                      label="Arrival Flight"
                      name="arrival_flight"
                      style={{
                        marginRight: 30,
                        minWidth: "100%",
                      }}
                    >
                      <Input placeholder="Arrival Flight" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="ETA" name="eta">
                      <TimePicker
                        onChange={onChangeTime}
                        value={
                          state?.state?.eta
                            ? moment(
                                LocalizationDate(state?.state?.eta)
                              ).format("HH:mm:ss")
                            : null
                        }
                        format={"HH:mm:ss"}
                        // defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="flight-row" gutter={30}>
                  <Col span={12}>
                    <Form.Item
                      label="Departure Flight"
                      name="departure_flight"
                      style={{
                        marginRight: 30,
                        minWidth: "100%",
                      }}
                    >
                      <Input placeholder="Departure Flight" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="ETD" name="etd">
                      <TimePicker
                        onChange={onChangeTime}
                        // defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Letter"
                  name="letter"
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <Select
                    showSearch
                    value={reservationData ? reservationData.source : null}
                    placeholder="Select an Option"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: "1",
                        label: "1",
                      },
                      {
                        value: "2",
                        label: "2",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  label="Sales"
                  name="sales"
                  style={{ minWidth: "100%" }}
                >
                  <MasterSalesman
                    getSalesman={getSalesman}
                    segmentId={segmentID}
                    salesman={
                      reservationData?.sales ? reservationData.sales : null
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 30px", backgroundColor: "#EBEDF3" }}
            />

            <Row gutter={30} className="row-reserve-guest-memo">
              {/* <Col span={8}>
                <Form.Item
                  // label="Master Bill Active"
                  name="master_bill_active"
                  valuePropName="checked"
                >
                  <Checkbox>Master Bill Active</Checkbox>
                </Form.Item>

                <Form.Item
                  // label="Cash Basis"
                  name="cash_basis"
                  valuePropName="checked"
                >
                  <Checkbox>Cash Basis</Checkbox>
                </Form.Item>
              </Col> */}

              <Col span={16}>
                <Form.Item label="Memo" name="memo_room">
                  <Input.TextArea
                    showCount
                    maxLength={100}
                    style={{
                      height: 100,
                      minWidth: "100%",
                    }}
                    // onChange={onChangeTextArea}
                    placeholder="Descriptions"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
            />

            <Row
              justify="space-bet"
              align="top"
              className="row-button-next-tab-pane"
            >
              <Col span={12} className="text-col">
                <Typography
                  className="res-text"
                  style={{ fontSize: 15, fontWeight: 750 }}
                >
                  {reservationData?.reservation_id}
                </Typography>
              </Col>

              <Col
                span={12}
                className="text-col"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={move}
                  style={{ marginRight: 30 }}
                >
                  {`Cancel`}
                </Button>

                <Button
                  type="primary"
                  className="next-btn"
                  onClick={() => onKeyChange("2")}
                  style={{ marginRight: 30 }}
                >
                  {`Back`}
                </Button>

                <Button
                  className="submit-btn"
                  type="primary"
                  key={"submit"}
                  htmlType="submit"
                  loading={isLoading}
                >
                  {`Submit`}
                </Button>
              </Col>
            </Row>
          </Content>
        </>
      ),
    },
  ];

  // RETURN
  return (
    <>
      {/* FORM */}
      <Form
        form={form}
        name="guest-modal-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        className="guest-modal-form"
        key="guest-modal-form"
        autoComplete="off"
        initialValues={{
          arrival: state?.state?.arrival
            ? moment(state.state.arrival)
            : reservationData?.arrival || null,
          departure: state?.state?.departure
            ? moment(state.state.departure)
            : reservationData?.departure || null,
          night: state?.state?.night
            ? state.state.night
            : reservationData?.night || 0,
          room_category: state?.state.room_category
            ? state.state.room_category
            : reservationData?.room_category,
          room_category_id: state?.state?.room_category_id
            ? state.state.room_category_id
            : reservationData?.room_category_id,
          room_number:
            state?.state?.room_number > 0
              ? state.state.room_number
              : reservationData?.room_number || 0,
          room_qty: state?.state?.room_qty
            ? state.state.room_qty
            : reservationData?.room_qty || 0,
          deposit:
            state?.state?.deposit > 0
              ? state.state.deposit
              : reservationData?.deposit || 0,
          adult: state?.state?.adult
            ? state.state.adult
            : reservationData?.adult || 0,
          child: state?.state?.child ? state.state.child : 0,
          infant: state?.state?.infant ? state.state.infant : 0,
          reservation_status: state?.state?.reservation_status
            ? state.state.reservation_status
            : reservationData?.reservation_status || "",
          arrangement: state?.state?.arrangement
            ? state.state.arrangement
            : reservationData?.arrangement,
          rate_code: state?.state?.rate_code
            ? state.state.rate_code
            : reservationData?.rate_code || null,
          compliment_type: state?.state?.compliment_type
            ? state?.state?.compliment_type
            : reservationData?.compliment_type || null,
          currency: state?.state?.currency
            ? state.state.currency
            : reservationData?.currency || null,
          // room_type: state?.state?.room_type
          //   ? state.state.room_type
          //   : reservationData?.room_type || null,
          guest_name: state?.state?.guest_name
            ? state.state.guest_name
            : reservationData?.guest_name || null,
          bill_receiver: state?.state?.bill_receiver
            ? state.state.bill_receiver
            : reservationData?.bill_receiver || null,
          bill_instruction: state?.state?.bill_receiver
            ? state.state.bill_receiver
            : reservationData?.bill_receiver || null,
          is_abf: state?.state?.is_abf
            ? state.state.is_abf
            : reservationData?.is_abf || false,

          segment: state?.state?.segment
            ? state.state.segment
            : reservationData?.segment || null,
          // sub_segment: state?.state?.sub_segment_id
          //   ? state.state.sub_segment_id
          //   : reservationData?.sub_segment_id || null,
          source: state?.state?.source
            ? state.state.source
            : reservationData?.source || null,
          letter: state?.state?.letter
            ? state?.state?.letter
            : reservationData?.letter || null,
          sales: state?.state?.sales
            ? state.state.sales
            : reservationData?.sales || null,
          payment_type: state?.state?.payment_type
            ? state.state.payment_type
            : reservationData?.payment_type || null,
          payment_details: state?.state?.payment_details
            ? state.state.payment_details
            : reservationData?.payment_details || null,
          payment_status: state?.state?.payment_status
            ? state.state.payment_status
            : reservationData?.payment_status || null,
          guest_category: state?.state?.guest_category
            ? state.state.guest_category
            : reservationData?.guest_category || null,

          group_name: state?.state?.group_name
            ? state.state.group_name
            : reservationData?.group_name || null,

          memo_room: state?.state?.memo_room
            ? state.state.memo_room
            : reservationData?.memo_room || null,
          arrival_flight: state?.state?.arrival_flight || "",
          // eta: state?.state?.eta || null,
          departure_flight: state?.state?.departure_flight || "",

          eta: state?.state?.eta
            ? moment(LocalizationDate(state?.state?.eta), "HH:mm").subtract(
                7,
                "hours"
              )
            : null,
          etd: state?.state?.etd
            ? moment(LocalizationDate(state?.state?.etd), "hh:mm:ss").subtract(
                7,
                "hours"
              )
            : null,
          // etd: state?.state?.etd || null,
          // is_dummy: true,
          // is_group: false,
          // nationality: null,
          // created_at: "2023-03-14T10:21:23.198951",
        }}
        style={{ padding: 0 }}
      >
        <Tabs
          className="add-reserv-tabs"
          name="add-reserv-tabs"
          key="add-reserv-tabs"
          defaultActiveKey="1"
          type="card"
          activeKey={activeKey}
          onChange={onKeyChange}
          // onTabClick={disabled}
          items={items}
        />
      </Form>
    </>
  );
}
