// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons

// CODE
export default function MasterPaymentTypeEdit(props) {
  // PROPS
  const { getPaymentType, pay } = props;

  // CONTEXT
  const { getMasterPaymentType } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [paymentType, setPaymentType] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getMasterPaymentType({
      payment_type: "payment-type",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Payment Type => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev?.payment_type_name,
            label: dev?.payment_type_name,
            code: dev?.payment_type_code,
            id: dev?.id,
          }));
          // console.log(option);

          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (pay && optionValue.length > 0 && !cleanUp) {
      console.log("Payment Type: ", pay);
      setPaymentType(pay);

      handleFilter(pay);
    }

    return () => {
      cleanUp = true;
    };
  }, [pay, optionValue]);

  // Handle Filter
  const handleFilter = (value) => {
    const _pay = value;

    let _existed = optionValue.filter((val) => {
      return val.value === _pay;
    });

    console.log("Payment Type Selected: ", _existed);

    if (_existed?.length > 0) {
      setPaymentType(_existed[0].value);

      getPaymentType({ name: _existed[0].value, id: _existed[0].id });
      // getPayTypeId(optionValue[0]?.id);
    }
  };

  // Handle Change
  const handleChange = (val, all) => {
    const _name = val;
    const _allKeys = all;

    setPaymentType(_name);

    getPaymentType({ name: _name, id: _allKeys?.id });
  };

  // Handle Clear
  const handleClear = (value) => {
    console.log("On Clear: ", value);

    setPaymentType(null);
  };

  return (
    <>
      <Select
        key={"payment-type-select-edit"}
        className={"payment-type-select-edit"}
        allowClear
        showSearch
        placeholder="Select Payment Type!"
        value={paymentType}
        onChange={(e, allKey, index) => {
          handleChange(e, allKey);
        }}
        options={optionValue}
        onClear={handleClear}
        style={{ width: "100%" }}
      />
    </>
  );
}
