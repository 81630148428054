// Import React Components
import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Image,
  Space,
  Checkbox,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
  SyncOutlined,
} from "@ant-design/icons";

// Import Notification
// import {
//   failedCheckOut,
//   failedFetch,
//   successCheckOut,
// } from "../../Reusable/Notification/Notification";

// Import Page Components
import { PreTotalPriceFunction } from "../../Reusable/Functions/TotalPrice/PreTotalPrice";
import { PrintCount } from "../../Reusable/Functions/PrintCount/PrintCount";
import MasterIdCardType from "../../Reusable/Master/IdCardType/IdCardType";
import MasterTitle from "../../Reusable/Master/Title/Title";

// Notifications
import { failedFetch } from "../../Reusable/Notification/Notification";

// Import Global Styles
import { textForm } from "../../../Style/Global/Global";
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function POSReceiptBillFormTrx({
  // Modal State
  openModal,
  closeModal,
  // Data
  payment_id,
}) {
  // USELOCATION STATE
  const { pathname, state } = useLocation();

  // CONTEXT
  const { getPreBillRoom, getPreBillArticle } = useContext(MainContext);
  const { getMasterPrint } = useContext(MasterContext);

  // USE REF
  const componentRef = useRef();

  // Data State
  const guest = state?.length > 0 ? state[0] : null;

  // Table's Column
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Quantity",
      dataIndex: "item_quantity",
      key: "item_quantity",
      sortDirections: ["descend", "ascend"],
      render: (itemQuantity, record) => {
        return itemQuantity;
      },
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        return (
          <p>
            {`Rp. ${record.unit_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
        );
      },
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        return (
          <p>
            {`Rp. ${record.unit_price * record.item_quantity}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )}
          </p>
        );
      },
    },
  ];
  // STATE MANAGEMENT

  // Data
  const [dataHeader, setDataHeader] = useState([]);
  const [articleRoom, setArticleRoom] = useState([]);
  const [articleStay, setArticleStay] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [data, setData] = useState([]);
  const [dataBill, setDataBill] = useState([]);
  // Total
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPrice, setTotalPrice] = useState({
    nett: 0,
    tax: 0,
    service: 0,
    gross: 0,
  });
  // Modal
  const [open, setOpen] = useState(false);
  const [formModal, setFormModal] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  // Print Count
  const [countPrint, setCountPrint] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // FETCH Data Header
  const fetchDataHeader = async () => {
    await axios
      .get(`${baseurl}/pos/order-header`, {})
      .then((response) => {
        console.log("HEADER FOR PRINT RECEIPT BILL: ", response);

        if (response.data?.msg?.length > 0) {
          let _res = response.data.msg?.filter((e) => {
            if (e.is_deleted == false && e.payment_id == payment_id) {
              return e;
            }
          });

          console.log("_res fetchDataHeader > ", _res);

          setDataHeader(_res);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {});
  };

  // FETCH Data Detail
  const fetchDataDetail = async () => {
    await axios
      .get(`${baseurl}/pos/order-detail`, {})
      .then((response) => {
        console.log("DETAIL FOR PRINT RECEIPT BILL: ", response);

        if (response.data?.msg?.length > 0) {
          let _res = response.data.msg?.filter((e) => {
            if (e.is_deleted == false && e.payment_id == payment_id) {
              return e;
            }
          });

          setDataDetail(_res);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {});
  };

  // FETCH PRINT FORM
  const fetchPrint = async () => {
    // third;
    await getMasterPrint({
      reservation_id: guest?.reservation_id || data?.reservation_id,
      price_id: guest?.price_id > 0 ? guest.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Count Print: ", response);

        if (response?.data?.msg?.length > 0) {
          setCountPrint(response.data.msg[0]);
        } else {
          PrintCount({
            data: guest || data,
            is_update: false,
          });
        }
      },
      onReject: (error) => {
        console.log("Failed get Print Count: ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      // console.log("State: ", state, payment_id);
      fetchDataHeader();
      fetchDataDetail();
      setLoading(false);
      setOpen(openModal);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // ON FINISH
  const onFinish = async () => {
    handlePrint();
  };

  // SEQUENTIAL BUTTON SUBMIT
  const [counter, setCounter] = useState(0);
  const myclick = () => {
    setCounter((counter) => counter + 1);
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
  };

  // HANDLE REACT PRINT
  const handlePrint = () => {
    PrintCount({
      data: data,
      is_update: true,
      counter_list: {
        pre_bill: countPrint?.pre_bill + 1,
        checkout_letter_history: countPrint?.checkout_letter_history,
        registration_form: countPrint?.registration_form,
        guarantee_letter: countPrint?.guarantee_letter,
        thankyou_letter: countPrint?.thankyou_letter,
        bill: countPrint?.bill,
        master_bill: countPrint?.master_bill,
        pre_master_bill: countPrint?.pre_master_bill,
        reservation_letter: countPrint?.reservation_letter,
      },
    });

    handlePrintOut();
  };

  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Transaction List ${
      data?.reservation_id ? data.reservation_id : " ---- "
    }`,
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
  });

  // CONSOLE LOG
  // console.log("DATA >>>", data);
  // console.log("DATA BILL >>>", dataBill);
  // console.log("DATA BILL Total >>>", total);
  console.log("DATA HEADER >>>", dataHeader);
  // console.log("DATA DETAIL >>>", dataDetail);

  return (
    <>
      <Modal
        className="res-form-modal"
        title={
          <>
            <Row className="row-modal-title">
              <FormOutlined style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                View Master Transactions
              </Typography>
            </Row>
          </>
        }
        // getContainer={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          name="resv-form-modal"
          className="resv-form-modal"
          form={form}
          labelCol={{ span: 8 }}
          labelWrap={{ span: 16 }}
          labelAlign="left"
          style={{
            width: "100%",
          }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          initialValues={{
            total_amount: totalAmount > 0 ? totalAmount : 0,
          }}
          //   onChange={handleChange}
          autoComplete="off"
          layout="horizontal"
        >
          <Content
            ref={componentRef}
            className="content-receipt-form"
            style={{ padding: "30px 80px 30px 80px" }}
          >
            <Row gutter={30} className="row-header">
              <Col span={12} className="col-title-form">
                <Typography.Title level={2} className="text-form-title">
                  {`Receipt-Bill Transaction`}
                </Typography.Title>
              </Col>

              <Col span={12} align="middle" className="col-img-logo">
                <Image
                  src="/looklogo(color).png"
                  className="img-form-logo"
                  preview={false}
                  style={{
                    width: "25%",
                  }}
                />

                <Typography.Title level={4} className="text-form-id">
                  {`Look Hotel Bandung`}
                </Typography.Title>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row
              className="row-receipt-no"
              style={{ height: 30, width: "100%", margin: 0 }}
            >
              <Form.Item
                label="No"
                className="receipt_number"
                style={{ width: "100%" }}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <Typography style={textForm}>
                  {`${
                    dataHeader[0]?.payment_id ? dataHeader[0]?.payment_id : ""
                  }`}
                </Typography>
              </Form.Item>
            </Row>

            <Row
              className="row-date"
              style={{ height: 30, width: "100%", margin: 0 }}
            >
              <Form.Item
                label="Date"
                className="date"
                style={{ width: "100%" }}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <Typography style={textForm}>
                  {dataHeader[0]?.payment_date
                    ? moment(
                        LocalizationDate(dataHeader[0]?.payment_date)
                      ).format("YYYY-MM-DD, HH:mm")
                    : ""}
                </Typography>
              </Form.Item>
            </Row>

            <Row className="row-titles" justify="start">
              <Col span={12}>
                <Typography
                  className="text-payment"
                  style={{
                    margin: "15px 0px 0px",
                    fontSize: 12,
                    fontWeight: 750,
                    // color: "grey",
                  }}
                >
                  {`Payment Data`}
                </Typography>
              </Col>

              <Col span={12}>
                <Typography
                  className="text-guest"
                  style={{
                    margin: "15px 0px 0px",
                    fontSize: 12,
                    fontWeight: 750,
                    // color: "grey",
                  }}
                >
                  {`Order Details`}
                </Typography>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: 0, backgroundColor: "#d9d9d9" }}
            />

            <Row
              className="row-guest-details"
              style={{
                width: "100%",
                // height: 40,
                // backgroundImage:
                //   countPrint?.pre_bill >= 1
                //     ? `url(/assets/logos/copyopac.png)`
                //     : null,
                // backgroundRepeat: "no-repeat",
                // backgroundSize: "auto",
                // backgroundPosition: "center",
              }}
            >
              <Col span={12}>
                <Form.Item
                  label="Payment Type"
                  className="payment_type"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-payment-type" style={textForm}>
                    {`${
                      dataHeader[0]?.payment_type
                        ? JSON.parse(dataHeader[0]?.payment_type).name
                        : ""
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Payment Details"
                  className="payment_details"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-payment-details" style={textForm}>
                    {`${
                      dataHeader[0]?.payment_details
                        ? JSON.parse(dataHeader[0]?.payment_details).name
                        : ""
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Payment Status"
                  className="payment_status"
                  // style={{ width: 450 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-payment-details" style={textForm}>
                    {`${
                      data?.payment_status == 1
                        ? "Cash Basis"
                        : "Charge To Room"
                    }`}
                  </Typography>
                </Form.Item>
              </Col>

              <Col span={12} className="col-res-id">
                <Form.Item
                  label="Order ID"
                  className="order_id_alias"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${
                      dataDetail[0]?.order_id_alias
                        ? dataDetail[0]?.order_id_alias
                        : "..."
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Customer Name"
                  className="customer_name"
                  style={{ margin: 0 }}
                  // labelCol={{ span:  }}
                  // wrapperCol={{ span: 20 }}
                >
                  <Typography className="text-guest-name" style={textForm}>
                    {`${
                      dataDetail[0]?.guest_name
                        ? dataDetail[0]?.guest_name
                        : "..."
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Sales Type"
                  className="sales_type"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${
                      dataHeader[0]?.sales_type
                        ? dataHeader[0]?.sales_type
                        : "..."
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Table No."
                  className="table_id"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${
                      dataDetail[0]?.table_id ? dataDetail[0]?.table_id : "..."
                    }`}
                  </Typography>
                </Form.Item>
              </Col>
            </Row>

            {/* <Row justify="start" className="row-guest">
              <Typography
                className="text-guest"
                style={{
                  margin: 0,
                  fontSize: 12,
                  fontWeight: 750,
                  // color: "grey",
                }}
              >
                Guest Name
              </Typography>
            </Row> */}
            {/* <Divider
              className="divider-form"
              style={{ margin: 0, backgroundColor: "#d9d9d9" }}
            /> */}
            {/* <Row
              className="row-guest-name"
              style={{ width: "100%", margin: 0 }}
            >
              <Col span={12}></Col>
            </Row> */}
            <Row justify="start">
              <Typography
                style={{
                  margin: "15px 0px 0px",
                  fontSize: 14,
                  fontWeight: 500,
                  color: "grey",
                }}
              >
                {`Buying List`}
              </Typography>
            </Row>
            <Divider
              className="divider-form"
              style={{ margin: 0, backgroundColor: "#d9d9d9" }}
            />
            <Row
              className="row-table"
              justify="start"
              style={{ padding: "10px 0px" }}
            >
              <Table
                className="transaction-table"
                key="transaction-table"
                name="transaction-table"
                loading={loading}
                columns={columns}
                dataSource={dataDetail}
                bordered={true}
                size="small"
                style={{
                  padding: 5,
                  border: "1px solid #000000",
                }}
                pagination={false}
                rowKey={(record) =>
                  record.id
                    ? record.id
                    : record.article_name
                    ? record.article_name
                    : record.total_price
                }
                summary={(pageData) => {
                  let totalPrice = 0;
                  let totalTax = 0;
                  let totalService = 0;
                  let gross = 0;

                  pageData.forEach((e) => {
                    totalPrice +=
                      (e.unit_price ?? 0) *
                      ((e.item_quantity || e.quantity) ?? 0);
                    totalTax += (e.item_quantity || e.quantity) * e.tax ?? 0;
                    totalService +=
                      (e.item_quantity || e.quantity) * e.service ?? 0;
                    gross += (e.item_quantity || e.quantity) * e.gross ?? 0;
                  });

                  return (
                    <>
                      <Table.Summary fixed>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={3} />

                          <Table.Summary.Cell index={3}>
                            <b>Subtotal</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4}>
                            {`Rp. ${totalPrice}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={3} />

                          <Table.Summary.Cell index={3}>
                            <b>Service</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4}>
                            {`Rp. ${totalService}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={3} />

                          <Table.Summary.Cell index={3}>
                            <b>Tax</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4}>
                            {`Rp. ${totalTax}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={3} />

                          <Table.Summary.Cell index={3}>
                            <b style={{ fontSize: 12 }}>Total Payment</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4}>
                            <b style={{ fontSize: 12 }}>
                              {`Rp. ${gross}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </b>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>
                    </>
                  );
                }}
              />
            </Row>

            <Typography className="text-notes" style={{ fontSize: 10 }}>
              *notes: please, recheck again of your transactions before paying!
            </Typography>

            {/* {is_checkOut == true ? (
            <Row
              className="signature"
              justify="end"
              style={{ padding: "30px 0px 0px" }}
            >
              <Col span={12} />

              <Col
                span={12}
                style={{
                  textAlign: "center",
                }}
              >
                {`Guest Signature`}

                <div
                  className="box-sign"
                  style={{
                    border: "1px solid #000000",
                    borderRadius: 5,
                    height: 60,
                  }}
                />
              </Col>
            </Row>
          ) : null} */}
          </Content>

          <Divider
            className="divider-form"
            style={{ margin: 0, backgroundColor: "#000000" }}
          />

          <Row
            justify="end"
            align="middle"
            className="row-form-btn"
            style={{
              padding: "30px 30px 15px",
              // margin: 30,
            }}
          >
            <Button
              className="refresh-btn"
              icon={<SyncOutlined />}
              type="default"
              // onClick={fetchData}
              style={{
                margin: "0px 30px 0px 0px",
              }}
            >
              {`Refresh`}
            </Button>

            <Button
              className="print-btn"
              type="primary"
              htmlType="submit"
              icon={<PrinterOutlined />}
              // onClick={() => {
              //   handlePrint();
              //   if (counter == 0) {
              //     myclick();
              //   }
              // }}
              // style={{
              // backgroundColor: "#1BC5BD",
              // borderColor: "#1BC5BD",
              //   marginRight: 30,
              // }}
            >
              {`Print & Download`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
