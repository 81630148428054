// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons

// CODE
export default function MasterPaymentType(props) {
  // PORPS
  const { getPaymentType, pay, getPayTypeId } = props;

  // CONTEXT
  const { getMasterPaymentType } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [paymentType, setPaymentType] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getMasterPaymentType({
      payment_type: "payment-type",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Payment Type => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev?.payment_type_name,
            label: dev?.payment_type_name,
            code: dev?.payment_type_code,
            id: dev?.id,
          }));

          console.log("map Option", option);

          // setOptionValue(option?.length > 0 ? option : []);

          setOptionValue(option?.length > 0 ? option : []);
          // setPaymentType(option[1]);
          // getPayTypeId(option[1]?.id);

          if (pay == null) {
            const find = option.filter((items) => {
              return items.value.toLowerCase().includes("cash");
            });

            // console.log("Find Cash: ", find);

            setPaymentType(find.length > 0 ? find[0]?.value : option[0]?.value);
            getPaymentType({
              name: find.length > 0 ? find[0]?.value : option[0]?.value,
              code: find.length > 0 ? find[0]?.code : option[0]?.code,
              id: find.length > 0 ? find[0]?.id : option[0]?.id,
            });
            // getPayTypeId(option[0]?.id);
          }
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // Fetch Edit
  const fetchEdit = async () => {
    await getMasterPaymentType({
      payment_type: "payment-type",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Payment Type => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filter = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              if (filt.payment_type_name == pay) {
                return filt;
              }
            }
          });

          console.log("Filter Edit --> ", filter);
          getPayTypeId(filter.payment_type_id);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
    // setPaymentType(selectedPaymentType)
  }, []);

  useEffect(() => {
    let cleanUp = false;
    // IF EDIT
    if (pay && !cleanUp) {
      console.log("Edit Payment Card: ", pay);

      setPaymentType(pay);
    }

    // if (paymentType && !cleanUp) {
    //   console.log("Clean Up False --> ", paymentType);
    //   // getPaymentType(paymentType);
    //   // getPayTypeId(paymentType?.id);
    //   getPaymentType(paymentType);
    // }

    return () => {
      cleanUp = true;
    };
  }, [pay]);

  // Handle Change
  const handleChange = (val, all) => {
    const _names = val;
    const _allKeys = all;

    console.log("Names: ", _names);
    console.log("Alls: ", _allKeys);

    setPaymentType(_names);
    getPaymentType({
      name: val,
      code: _allKeys?.code,
      id: _allKeys?.id,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setPaymentType(null);
  };

  return (
    <>
      <Select
        key={"paymenttype_name"}
        allowClear
        showSearch
        style={{ width: "100%" }}
        value={paymentType != null ? paymentType : pay}
        onChange={(e, allKey, index) => {
          // setPaymentType(e);
          // console.log(e, allKey, index);

          // getPaymentType(e, allKey?.id);
          // getPayTypeId(allKey?.id);
          // //  if (onSet) onSet(deviceid);
          handleChange(e, allKey);
        }}
        onClear={handleClear}
        options={optionValue}
      />
    </>
  );
}
