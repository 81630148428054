// Import React Components
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// Import React Components
import {
  Button,
  Divider,
  Form,
  Modal,
  Row,
  Typography,
  Table,
  Tag,
} from "antd";

// Import React Icons
import { AiOutlineSave } from "react-icons/ai";

// Notifications
import { failedFetch } from "../../../Reusable/Notification/Notification";
import CaptainModalJoinDestination from "./CaptainJoinDestination";

export default function POSJoinModal(props) {
  // PROPS
  const { openModal, closeModal, selectedTable, getClear } = props;

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname } = useLocation();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();
  const [selectKey, setSelectKey] = useState([]);
  const [select, setSelect] = useState([])

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5)

  // Modal
  const [open, setOpen] = useState(false);
  const [joinDestination, setJoinDestination] = useState(false)
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  useEffect(() => {
    fetchDataHeader();
  }, [openModal]);

  // FETCH Data Header
  const fetchDataHeader = async () => {
    await axios
      .get(`${baseurl}/pos/order-header`, {})
      .then((response) => {
        console.log("HEADER FOR SAVED BILL: ", response);

        if (response.data?.msg?.length > 0) {
          let _res = response.data.msg?.filter((e) => {
            if (
              e.is_deleted == false &&
              e.is_paid == false &&
              e.bill_status != 2
            ) {
              return e;
            }
          });

          let filter = _res.filter((x) => {
            if(x.name?.slice(-1) == 1) {
              return true;
            } else {
              return false;
            }
          })

          setData(filter);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {});
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      render: (value, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // Before
      render: (amount) => amount,
      // End Before
      // Modify
      // render: (text) => {
      //   return (text.substr(text.length - 3)) == "pay" ? text.substring(0, text.length - 3) : text
      // },
      // End Modify
    },
    {
      title: "Table",
      dataIndex: "table_id",
      key: "table_id",
      render: (amount) => amount,
    },
    // Modify
    {
      title: "Status",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        let color = "";
        let txt = "";

        switch ((text.substr(text.length - 1))) {
          case "1":
            color = "#1890ff";
            txt = "Close";
            break;

          default:
            color = "#87d068";
            txt = "Open";
            break;
        }

        return (
          <Tag color={color}>
            {txt ? txt.toUpperCase() : "-"}
          </Tag>
        );
      },
    },
    // End Modify
    {
      title: "Order ID",
      dataIndex: "order_id_alias",
      key: "order_id_alias",
      render: (amount) => amount,
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (amount) => {
        return moment(LocalizationDate(amount)).format("YYYY-MM-DD, HH:mm");
      },
    },
    {
      title: "Article ID",
      dataIndex: "id_article",
      key: "id_article",
      hidden: true,
      render: (amount) => amount,
    },
  ].filter((item) => !item.hidden);

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);
    setJoinDestination(false);
    setSelectKey([])
    setSelect([]);

    form.resetFields();
  };

  const handleRefresh = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);
    setJoinDestination(false);
    setSelectKey([])
    setSelect([]);
    getClear();
  } 

  const rowSelection = {
    columnTitle: "Select",
    selectedRowKeys: selectKey,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectKey(selectedRowKeys);
      setSelect(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => {
      return {
        // disabled: record.item_quantity == (0 || null || undefined), //disable the first 4 rows only
      };
    },
  };

  return (
    <>
      <Modal
        className="saved-bill-modal"
        title={
          <>
            <Row className="saved-bill-modal-row">
              <AiOutlineSave
                style={{
                  color: "#1BC5BD",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Join Order`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <Row
          className="saved-bill-modal-form-serve-row"
          justify="start"
          gutter={[30, 30]}
          style={{ padding: 20 }}
        >
          <Table
            className="saved-bill-modal-table"
            name="saved-bill-modal-table"
            key="saved-bill-modal-table"
            columns={columns}
            dataSource={data}
            bordered
            pagination={{
              defaultPageSize: 5,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} entries`,
              onChange(current, pageSize) {
                setPage(current);
                setPaginationSize(pageSize);
              },
            }}
            scroll={{
              x: 800,
            }}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "odd" : "even";
            }}
            rowKey={(record) => record.id}
          />
        </Row>

        <Divider
          className="saved-bill-modal-form-divider-form"
          style={{ margin: "15px 0px" }}
        />

        <Row justify="end" align="middle">
          <Button
            type="primary"
            onClick={(e) => {
              setJoinDestination(true);
            }}
            loading={loading}
            className="submit-btn"
            style={{
              marginRight: 30,
            }}
          >
            {`Submit`}
          </Button>

          <Button className="cancel-btn" type="default" onClick={handleCancel}>
            {`Cancel`}
          </Button>
        </Row>
      </Modal>
      <CaptainModalJoinDestination
        openModal={joinDestination}
        closeModal={setJoinDestination}
        options={select}
        getClear={handleRefresh}

      />
    </>
  );
}
