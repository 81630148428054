// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Steps,
  Progress,
  Alert,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaMoneyBills } from "react-icons/fa6";

// Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  failedPayCard,
  masterIncomplete,
  successPayCard,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Functions
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Master Components
import MasterPaymentType from "../../../Reusable/Master/PaymentType/PaymentType";

// Import Global Styles
import { roles, department, user_name } from "../../../../API/Global/Payload";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// CONST
const { Text, Link, Title } = Typography;

// CODE
export default function NARoomRateTable({ dataNARoomRate }) {
  // Params
  const currency = CurrencySymbols().code;

  // COLUMN
  const columns = [
    {
      title: "Reservation No.",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    {
      title: "Room No.",
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: "Bill No.",
      dataIndex: "bill_alias",
      key: "bill_alias",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: `Price ( ${currency} )`,
      dataIndex: "price",
      key: "price",
      render: (unit, record) => {
        return `${unit > 0 ? unit : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Trx Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (date, record) => {
        return moment(LocalizationDate(date)).format("HH:mm:ss, DD-MM-YYYY");
      },
      sort: ["ascend", "descend"],
      sorter: (a, b) => a.created_date.localeCompare(b.created_date),
    },
  ];

  // CONTEXT
  const { getReservation } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // Fetch Data Night Audit Room Rate
  const fetchData = async () => {
    await getReservation({
      reservation: "bill",
      type: "outstanding",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          setData(_response);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  const move = () => {
    navigate("master-folio");
  };

  return (
    <>
      <Row className="table-main-row" justify="center" style={mainBody}>
        <Col className="table-main-col" span={24} style={{ padding: 30 }}>
          <Row
            justify="space-between"
            className="row-refresh-btn"
            gutter={30}
            style={{
              margin: "0px 0px 30px",
            }}
          >
            <Button className="search-btn" type="primary" onClick={move}>
              {`Go To Master Folio`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
            >
              {`Refresh`}
            </Button>
          </Row>

          <Table
            className="bill-outstanding-table"
            key="bill-outstanding-table"
            name="bill-outstanding-table"
            loading={loading}
            columns={columns}
            dataSource={data}
            bordered
            pagination={{
              pageSizeOptions: [5, 10, 25, 50, 100],
              defaultPageSize: 5,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            }}
            scroll={{
              x: 1000,
            }}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "odd" : "even";
            }}
            rowKey={(record) => (record.id ? record.id : record.key)}
          />
        </Col>
      </Row>
    </>
  );
}
