// Import React Components
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";

// AUTH
import { roles, token, user_name } from "../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  TimePicker,
  Checkbox,
  Space,
  Radio,
} from "antd";

// Import React Icons
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";

// Import Notification
import {
  failedAddReserv,
  incomplete,
  successAddReserv,
} from "../../Reusable/Notification/Notification";

// Import Page Components
import MasterGuestCat from "../../Reusable/Master/GuestCat/GuestCat";
import MasterCurrency from "../../Reusable/Master/Currency/Currency";
import MasterReservationStatus from "../../Reusable/Master/ResStatus/ResStatus";
import MasterSource from "../../Reusable/Master/Source/Source";
import MasterPaymentCardEdit from "../../Reusable/Master/PaymentCard/PaymentCardEdit";
import MasterPaymentTypeEdit from "../../Reusable/Master/PaymentType/PaymentTypeEdit";
import MasterSalesman from "../../Reusable/Master/Salesman/Salesman";

import MasterSegment from "../../Reusable/Master/Segment/Segment";
import MasterPaymentCard from "../../Reusable/Master/PaymentCard/PaymentCard";
import MasterPaymentType from "../../Reusable/Master/PaymentType/PaymentType";
import { FiEdit } from "react-icons/fi";
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";

// MODAL
const { confirm } = Modal;

// CODE
export default function ModalGuestInHouseEdit(props) {
  // PROPS
  const {
    // Modal State
    openModal,
    closeModal,
    // Data
    dataGuest,
    // Refresh State
    is_refresh,
  } = props;

  // CONTEXT
  const { getArticleRoom } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data Guest About to Edit
  const [editData, setEditData] = useState(null);
  // Payment Type Code
  const [payment, setPayment] = useState({
    name: "",
    id: 0,
  });
  const [cards, setCards] = useState({
    name: "",
    id: 0,
  });
  // Room Price
  const [price, setPrice] = useState(null);
  const [arrange, setArrange] = useState({
    segment_id: 0,
    payment_card_id: 0,
    payment_type_id: 0,
    rate_code: "",
    rate_code_id: 0,
    room_category_id: 0,
    room_id: 0,
    sales_id: 0,
    source_id: 0,
  });
  // Modal
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async (value) => {
    const content = value;

    await getArticleRoom({
      articleRoomPrice: "reservation",
      type: "article-room-single",
      res_id: content?.reservation_id
        ? content.reservation_id
        : dataGuest?.reservation_id,
      prc_id: content?.price_id ? content.price_id : dataGuest?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Article Room => ", response);

        // setOptionValue(option);
        // setLoading(false);
        setPrice(response?.data?.length > 0 ? response?.data[0].price : null);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dataGuest && !cleanUp) {
      console.log("Data Guest --> ", dataGuest);

      fetchData(dataGuest);
      setEditData(dataGuest);
      setArrange(dataGuest?.arrangement);
      setFieldsForm(dataGuest);
    }

    if (openModal === true && !cleanUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataGuest]);

  useEffect(() => {
    let cleanUp = false;

    if (open == true && editData && !cleanUp) {
      setTimeout(() => {
        setLoading(false);
      }, 1250);
    }

    return () => {
      cleanUp = true;
    };
  }, [open, editData]);

  // SET FIELD FORMS
  const setFieldsForm = (value) => {
    const _data = value;

    form.setFieldsValue({
      adult: _data?.adult,
      infant: _data?.infant,
      child: _data?.child,
      reservation_id: _data?.reservation_id,
      guest_name:
        _data?.guest_category != "INCOGNITO" ? _data?.guest_name : "PRIVATE",
      bill_receiver: _data?.bill_receiver,
      bill_instruction: _data?.bill_instruction,
      arrival_flight: _data?.arrival_flight,
      // eta: dataGuest?.eta ? moment(dataGuest?.eta).format("HH:mm:ss") : "",
      departure_flight: _data?.departure_flight,
      // etd: dataGuest?.etd ? moment(dataGuest?.etd).format("HH:mm:ss") : "",
      group_name: _data?.group_name,
      letter: _data?.letter,
      // deposit: dataGuest?.deposit,
      sales: _data?.sales,
      memo_room: _data?.memo_room,
      payment_details: _data?.payment_details,
      payment_type: _data?.payment_type,
      guest_category: _data?.guest_category,
      currency: _data?.currency,
      reservation_status: _data?.reservation_status,
      segment: _data?.segment,
      source: _data?.source,
      payment_status: _data?.payment_status,
      // master_bill_active: _data?.master_bill_active,
      // cash_basis: _data?.cash_basis,
    });
  };

  // HANDLE SELECTOR MASTER
  // Get Payment Type
  const getPaymentType = (value, code) => {
    console.log("payment type", value, code);

    setPayment({
      id: value?.id,
      name: value?.name,
    });

    setArrange({
      ...arrange,
      payment_type_id: value?.id,
    });

    form.setFieldsValue({
      payment_type: value?.name,
    });
  };
  // Guest Status
  const getCategory = (value) => {
    form.setFieldsValue({
      guest_category: value,
    });
  };
  // Currency
  const getCurrency = (value) => {
    form.setFieldsValue({
      currency: value,
    });
  };
  // Reservation Status
  const getResStatus = (value) => {
    form.setFieldsValue({
      reservation_status: value,
    });
  };
  // Segment
  const getSegment = (value) => {
    form.setFieldsValue({
      segment: value,
    });
  };
  // Compliment Type
  const getSource = (value) => {
    console.log("Sources: ", value);

    setArrange({
      ...arrange,
      source_id: value?.source_id > 0 ? value?.source_id : arrange.source_id,
    });

    form.setFieldsValue({
      source: value?.name,
    });
  };
  // Payment Details/Card
  const getPayCard = (value, index, id) => {
    console.log(value, index, id);

    setCards({
      id: value?.id,
      name: value?.name,
    });

    setArrange({
      ...arrange,
      payment_card_id: value?.id,
    });

    form.setFieldsValue({
      payment_details: value?.name,
    });
  };
  // RADIO STATUS PAYMENTS
  const onRadioChange = async (e) => {
    console.log("radio checked", e.target.value);

    // setPayment_status(e.target.value);

    form.setFieldsValue({
      payment_status: e.target.value,
    });
  };

  // Sales
  const getSalesman = (value, opt) => {
    console.log("Sales: ", value, opt);
    // console.log("OPT: ", opt);

    setArrange({
      ...arrange,
      sales_id: opt?.sales_id > 0 ? opt?.sales_id : arrange.sales_id,
    });

    form.setFieldsValue({
      sales: value,
    });
  };

  // GET DEPOSIT
  // const getDeposit = (value) => {
  //   console.log(value);

  //   form.setFieldsValue({
  //     deposit: value,
  //   });
  // };

  // ON SUBMIT FINISH
  const onFinish = async (e) => {
    const content = e;

    console.log("DATA SUBMIT >>> ", content);
    console.log("New Arrangement: ", arrange);
    console.log("Old Arrangement: ", dataGuest.arrangement);

    // SHOW MODAL CONFIRM
    showModalConfirm(content);
    setIsLoading(true);
  };

  // ON SUBMIT FINISH FAILED
  const onFinishFailed = async (error) => {
    console.log("Data Incomplete >>> ", error);

    incomplete(error);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (val) => {
    const dataModal = val;

    confirm({
      // icon: <ExclamationCircleTwoTone />,
      className: "modal-confirm",
      title: `Are you sure want to Update a Reservations of ${dataModal?.reservation_id}?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(dataModal);
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("EDITED > ", e);
    let field = form.getFieldsValue();

    console.log("GET FIELDS VALUE : ", field);

    // const id = edit.id;
    const contentEdit = e;

    await axios
      .put(
        `${baseurl}/guest-in-house/stay/`,
        {
          reservation_id: dataGuest?.reservation_id
            ? dataGuest.reservation_id
            : editData.reservation_id,
          id: dataGuest?.id ? dataGuest.id : editData.id,
          arrival: dataGuest?.arrival ? dataGuest.arrival : editData.arrival,
          departure: dataGuest?.departure
            ? dataGuest.departure
            : editData.departure,
          night: dataGuest?.night ? dataGuest.night : editData.night,
          room_category: dataGuest?.room_category
            ? dataGuest.room_category
            : editData.room_category,
          room_number: dataGuest?.room_number
            ? dataGuest.room_number
            : editData.room_number,
          room_qty: dataGuest?.room_qty
            ? dataGuest.room_qty
            : editData.room_qty,
          arrangement: arrange,
          // rate_code: dataGuest?.rate_code
          //   ? dataGuest.rate_code
          //   : editData.rate_code,
          // compliment_type: dataGuest?.compliment_type
          //   ? dataGuest.compliment_type
          //   : editData.compliment_type,
          group: dataGuest?.group ? dataGuest.group : editData.group,
          // room_rate_id: dataGuest?.room_rate_id
          //   ? dataGuest.room_rate_id
          //   : editData.room_rate_id,
          price_id: dataGuest?.price_id
            ? dataGuest.price_id
            : editData.price_id,
          is_dummy: dataGuest?.is_dummy
            ? dataGuest.is_dummy
            : editData.is_dummy,
          is_group: dataGuest?.is_group
            ? dataGuest.is_group
            : editData.is_group,

          adult: contentEdit?.adult ? contentEdit.adult : editData.adult,
          child: contentEdit?.child ? contentEdit.child : editData.child,
          infant: contentEdit?.infant ? contentEdit.infant : editData.infant,
          reservation_status: contentEdit?.reservation_status
            ? contentEdit.reservation_status
            : editData.reservation_status,
          currency: contentEdit?.currency
            ? contentEdit.currency
            : editData.currency,
          guest_name: contentEdit?.guest_name
            ? contentEdit?.guest_name
            : editData.guest_name,
          bill_receiver: contentEdit?.bill_receiver
            ? contentEdit.bill_receiver
            : editData.bill_receiver,
          bill_instruction: contentEdit?.bill_instruction
            ? contentEdit.bill_instruction
            : editData.bill_instruction,
          arrival_flight: contentEdit?.arrival_flight
            ? contentEdit.arrival_flight
            : editData.arrival_flight,
          eta: contentEdit?.eta ? contentEdit?.eta : null,
          departure_flight: contentEdit?.departure_flight
            ? contentEdit.departure_flight
            : editData.departure_flight,
          etd: contentEdit?.etd ? contentEdit?.etd : null,
          // segment: contentEdit?.segment
          //   ? contentEdit.segment
          //   : editData.segment,
          source: contentEdit?.source ? contentEdit.source : editData.source,
          letter: contentEdit?.letter ? contentEdit.letter : editData.letter,
          sales: contentEdit?.sales ? contentEdit.sales : editData.sales,
          payment_type: contentEdit?.payment_type
            ? contentEdit.payment_type
            : editData.payment_type,
          payment_details: contentEdit?.payment_details
            ? contentEdit.payment_details
            : editData.payment_details,
          payment_status: contentEdit?.payment_status
            ? contentEdit.payment_status
            : editData.payment_status,
          memo_room: contentEdit?.memo_room
            ? contentEdit.memo_room
            : editData.memo_room,
          group_name: contentEdit?.group_name
            ? contentEdit.group_name
            : editData.group_name,
          guest_category: contentEdit?.guest_category
            ? contentEdit.guest_category
            : editData.guest_category,
          // room_price: price ? price : 0,
          // guest_id: guestId ? guestId : editData.guest_id,
          updated_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Update Guest in House: ", response);

        if (response.status === 200) {
          successAddReserv(response, { method: 1 });

          is_refresh(true);
          setOpen(false);
          closeModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
        failedAddReserv(error, { method: 1 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // ON CHANGE
  const onChangeTime = (time, timeString) => {
    console.log(time, timeString);
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setLoading(true);
    setPayment({
      id: 0,
      name: "",
    });
    setCards({
      id: 0,
      name: "",
    });

    setEditData(null);
    setPrice(null);

    form.resetFields();
    setOpen(false);
    closeModal(false);
  };

  // CONSOLE LOG
  // console.log("DATA IN MODAL EDIT > ", editData);

  return (
    <>
      <Modal
        className="edit-modal-guest"
        title={
          <>
            <Row>
              <FiEdit style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Guest In House Data`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        {!loading ? (
          <Form
            name="edit-res-modal-form"
            className="edit-res-modal-form"
            key="edit-res-modal-form"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            style={{ padding: "0px 36px" }}
          >
            <Row gutter={30} className="row-guest-pay">
              <Col span={12}>
                <Form.Item
                  label="Reservation No."
                  name="reservation_id"
                  style={{
                    width: "100%",
                  }}
                >
                  <Input placeholder="Resv No." disabled />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Guest Name"
                  name="guest_name"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Name"
                    disabled={dataGuest?.group == 2 ? false : true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px" }}
            />

            <Row gutter={30} className="row-guest-pay">
              <Col span={8}>
                <Form.Item
                  label="Payment Type"
                  name="payment_type"
                  rules={[
                    {
                      required: true,
                      message: "Please, Choose a Payment Type!",
                    },
                  ]}
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <MasterPaymentTypeEdit
                    getPaymentType={getPaymentType}
                    pay={open ? dataGuest?.payment_type : null}
                    // getPayTypeId={setPayment}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Payment Details"
                  name="payment_details"
                  rules={[
                    {
                      required: true,
                      message: "Please, Choose a Payment Details!",
                    },
                  ]}
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <MasterPaymentCardEdit
                    getPayCard={getPayCard}
                    payCard={open ? dataGuest?.payment_details : null}
                    getPayTypeId={payment.id}
                    // getCode={setCardCode}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Guest Status"
                  name="guest_category"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <MasterGuestCat
                    getCategory={getCategory}
                    guest_status={open == true ? dataGuest?.guest_category : ""}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="row-reserv-guest">
              <Col span={4}>
                <Form.Item
                  label="Adult"
                  name="adult"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input Total Guest!",
                    },
                  ]}
                  style={{ margin: "0px 30px 0px 0px" }}
                >
                  <InputNumber
                    min={1}
                    max={10}
                    placeholder="Total Adult"
                    // defaultValue={dataGuest?.adult}
                    style={{ width: 90 }}
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item
                  label="Child"
                  name="child"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please, Input Total Guest!",
                  //   },
                  // ]}
                  // style={{ margin: "0px 30px 0px 0px" }}
                >
                  <InputNumber
                    min={0}
                    max={10}
                    placeholder="Total Child"
                    // defaultValue={dataGuest?.child}
                    //   onChange={(e) => {
                    //     getChild(e);
                    //   }}
                    style={{ width: 90 }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Infant"
                  name="infant"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please, Input Total Guest!",
                  //   },
                  // ]}
                >
                  <InputNumber
                    min={0}
                    max={10}
                    placeholder="Total Infant"
                    // defaultValue={dataGuest?.infant}
                    style={{ width: 90 }}
                  />
                  {/* {getInfant(dataGuest?.infant)} */}
                  {/* <MasterInputInfant
                  getInfant={getInfant}
                  is_infant={dataGuest?.infant}
                /> */}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="group-segment-reservation">
              <Col span={8}>
                <Form.Item
                  label="Source"
                  name="source"
                  rules={[
                    {
                      required: true,
                      message: "Please, Choose a Source!",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <MasterSource
                    getSource={getSource}
                    srce={open ? dataGuest?.source : ""}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Group"
                  name="group_name"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <Input placeholder="Group Name" />
                  {/* <MasterGroup getGroup={getGroup} /> */}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Reservation Status"
                  name="reservation_status"
                  rules={[
                    {
                      required: true,
                      message: "Please, Choose a Reservation Status!",
                    },
                  ]}
                  style={{
                    marginRight: 30,
                    width: "100%",
                  }}
                >
                  <MasterReservationStatus
                    getResStatus={getResStatus}
                    res={open ? dataGuest?.reservation_status : ""}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="guest-name-reservation">
              <Col span={8} className="col-bill-receiver">
                <Form.Item
                  label="Bill Receiver"
                  name="bill_receiver"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input Bill Receiver!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Insert Bill Receiver"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Bill Instruction"
                  name="bill_instruction"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input Bill Instruction!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Insert Bill Instruction"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Currency"
                  name="currency"
                  style={{
                    width: "100%",
                  }}
                >
                  <MasterCurrency
                    getCurrency={getCurrency}
                    pay={open ? dataGuest?.currency : ""}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="sales-info-reservation">
              <Col span={8}>
                <Form.Item
                  label="Letter"
                  name="letter"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <Input placeholder="Letter" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Sales"
                  name="sales"
                  rules={[
                    {
                      required: true,
                      message: "Please, select a Salesman!",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  {/* <Input placeholder="Sales Name" /> */}
                  <MasterSalesman
                    getSalesman={getSalesman}
                    salesman={open ? dataGuest?.sales : null}
                    // segmentId={segmentID}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row
              gutter={30}
              className="flight-arrival-reservation"
              justify="end"
              style={{
                width: "100%",
              }}
            >
              <Col span={16}>
                <Row className="flight-row" justify="space-around" gutter={30}>
                  <Col span={12}>
                    <Form.Item
                      label="Arrival Flight"
                      name="arrival_flight"
                      style={{
                        // marginRight: 30,
                        width: "100%",
                      }}
                    >
                      <Input placeholder="Arrival Flight" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="ETA" name="eta">
                      <TimePicker
                        onChange={onChangeTime}
                        // defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="flight-row" gutter={30}>
                  <Col span={12}>
                    <Form.Item
                      label="Departure Flight"
                      name="departure_flight"
                      style={{
                        // marginRight: 30,
                        width: "100%",
                      }}
                    >
                      <Input placeholder="Departure Flight" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="ETD" name="etd">
                      <TimePicker
                        onChange={onChangeTime}
                        // defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Payment Status"
                  name="payment_status"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Payment Status!",
                    },
                  ]}
                  // valuePropName="checked"
                >
                  <Radio.Group
                    onChange={onRadioChange}
                    value={
                      open == true
                        ? editData?.payment_status
                        : dataGuest?.payment_status
                    }
                    disabled
                  >
                    <Space direction="vertical">
                      <Radio value={1}>{`Cash Basis`}</Radio>
                      <Radio value={2}>{`Charge To Room`}</Radio>
                      {/* <Radio value={3}>{`Deposit`}</Radio> */}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="row-reserve-guest-memo">
              <Col span={16}>
                <Form.Item label="Memo" name="memo_room">
                  <Input.TextArea
                    showCount
                    maxLength={100}
                    style={{
                      height: 100,
                      width: "100%",
                    }}
                    // onChange={onChangeTextArea}
                    placeholder="Write Memo"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider style={{ margin: "0px 0px 15px" }} />

            <Row
              justify="end"
              align="middle"
              style={{ padding: "0px 0px 30px" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                key={"submit"}
                // onClick={onFinish}
                loading={isLoading}
                className="submit-btn"
                style={{
                  // backgroundColor: "#1BC5BD",
                  // borderColor: "#1BC5BD",
                  marginRight: 15,
                }}
              >
                {`Submit`}
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                onClick={handleCancel}
              >
                {`Cancel`}
              </Button>
            </Row>
          </Form>
        ) : (
          <LoadingComponents />
        )}
      </Modal>
    </>
  );
}
