// REACT COMPONENTS
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL STYLES
import { textForm } from "../../../Style/Global/Global";

// REACT ICONS
import { BiSolidFilePdf } from "react-icons/bi";

// PARAMS
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";

// PAGE COMPONENTS
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";
import { useReactToPrint } from "react-to-print";
import { PreTotalPriceFunction } from "../../Reusable/Functions/TotalPrice/PreTotalPrice";

export default function MasterFolioForm(props) {
  // PROPS
  const { openModal, closeModal, billData, guestData } = props;

  // Use Ref
  const componentRef = useRef();

  // Currency
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [totalPrice, setTotalPrice] = useState({
    nett: 0,
    service: 0,
    tax: 0,
    gross: 0,
  });
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(true);
  // Print Count
  const [countPrint, setCountPrint] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    if (openModal == true) {
      setOpen(!open);
    }

    if (billData) {
      console.log("Master Folio ->> ", billData);
      setData(billData);
      handleSummaryTotal(billData);
    }

    setIsLoading(false);
  }, [openModal, billData]);

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setData([]);
    // setEdit(null);

    setIsLoading(true);

    form.resetFields();
  };

  // HANDLE SUMMARY TOTAL
  const handleSummaryTotal = (billData) => {
    const _total = PreTotalPriceFunction(billData);

    console.log("TOTAL: ", _total);

    setTotalPrice({
      nett: _total.nett,
      service: _total.service,
      tax: _total.tax,
      gross: _total.gross,
    });
  };

  // HANDLE PRINT OUT
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Master Folio ${
      data[0]?.reservation_id ? data[0]?.reservation_id : " ---- "
    }`,
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
    // onafterprint: () => alert("Print Success"),
  });

  // Table's Column
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      align: "right",
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        return (
          <p>{`${record.unit_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        );
      },
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      align: "right",
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        return (
          <p>{`${record.unit_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        className="res-form-modal"
        title={
          <>
            <Row className="row-modal-title">
              <BiSolidFilePdf style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Master Folio`}
              </Typography>
            </Row>
          </>
        }
        getContainer={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          name="resv-form-modal"
          className="resv-form-modal"
          form={form}
          labelCol={{ span: 12 }}
          labelWrap={{ span: 12 }}
          labelAlign="left"
          style={{
            maxWidth: 1000,
          }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          //   initialValues={handleShow}
          //   onChange={handleChange}
          autoComplete="off"
          layout="horizontal"
        >
          {!isLoading ? (
            <Content
              ref={componentRef}
              className="content-receipt-form"
              style={{ padding: "30px 80px 30px 80px" }}
            >
              <Row gutter={30} className="row-header">
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Master Folio`}
                  </Typography.Title>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    src="/ideas.png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "20%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Idea's Hotel`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-receipt-no"
                style={{ height: 30, width: "100%", margin: 0 }}
              >
                <Form.Item
                  label="No"
                  className="receipt_number"
                  style={{ width: "100%" }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                >
                  {}
                </Form.Item>
              </Row>

              <Row
                className="row-date"
                style={{ height: 30, width: "100%", margin: 0 }}
              >
                <Form.Item
                  label="Date"
                  className="date"
                  style={{ width: "100%" }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                >
                  <Typography className="date-text" style={textForm}>
                    {moment().format("HH:mm, DD-MM-YYYY")}
                  </Typography>
                </Form.Item>
              </Row>

              <Row className="row-titles" justify="start">
                <Col span={12}>
                  <Typography
                    className="text-payment"
                    style={{
                      margin: "15px 0px 0px",
                      fontSize: 12,
                      fontWeight: 750,
                    }}
                  >
                    {`Payment Data`}
                  </Typography>
                </Col>

                <Col span={12}>
                  <Typography
                    className="text-guest"
                    style={{
                      margin: "15px 0px 0px",
                      fontSize: 12,
                      fontWeight: 750,
                    }}
                  >
                    {`Data Guest`}
                  </Typography>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: 0, backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-guest-details"
                style={{
                  width: "100%",
                  backgroundImage:
                    countPrint?.pre_master_bill >= 1
                      ? `url(/assets/logos/copyopac.png)`
                      : null,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "auto",
                  backgroundPosition: "center",
                }}
              >
                <Col span={12}>
                  <Form.Item
                    label="Payment Type"
                    className="payment_type"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-payment-type" style={textForm}>
                      {guestData?.payment_type ? guestData?.payment_type : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Payment Details"
                    className="payment_details"
                    style={{ margin: 0 }}
                  >
                    <Typography
                      className="text-payment-details"
                      style={textForm}
                    >
                      {guestData?.payment_details
                        ? guestData?.payment_details
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item label="Payment Status" className="payment_status">
                    <Typography
                      className="text-payment-details"
                      style={textForm}
                    >
                      {`${
                        guestData?.payment_status == 1
                          ? "Cash Basis"
                          : "Charge To Room"
                      }`}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={12} className="col-res-id">
                  <Form.Item
                    label="Res. No"
                    className="reservation_id"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {guestData?.reservation_id
                        ? guestData?.reservation_id
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Guest Name"
                    className="guest_name"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-guest-name" style={textForm}>
                      {guestData?.guest_name
                        ? guestData?.guest_name?.replace(/\d+$/, "").trim()
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Arrival"
                    className="arrival"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {guestData?.arrival &&
                      guestData?.arrival !== "Invalid date"
                        ? `${moment(guestData?.arrival).format("DD-MM-YYYY")}`
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Departure"
                    className="departure"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {guestData?.departure &&
                      guestData?.departure !== "Invalid date"
                        ? `${moment(guestData?.departure).format("DD-MM-YYYY")}`
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Night(s)"
                    className="night"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {"-"}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="start">
                <Typography style={textForm}>{`Buying List`}</Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: 0, backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-table"
                justify="start"
                style={{ padding: "10px 0px" }}
              >
                <Table
                  className="transaction-table"
                  key="transaction-table"
                  name="transaction-table"
                  loading={isLoading}
                  columns={columns}
                  dataSource={data}
                  bordered={true}
                  size="small"
                  style={{
                    padding: 5,
                    border: "1px solid #000000",
                  }}
                  pagination={false}
                  rowKey={(record) => (record.id > 0 ? record.id : record.key)}
                  summary={() => {
                    return (
                      <>
                        <Table.Summary fixed>
                          {bill_type ? (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4} />

                                <Table.Summary.Cell index={4}>
                                  <b>Subtotal</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={4} align="right">
                                  {`${currency} ${totalPrice.nett}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>

                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4} />

                                <Table.Summary.Cell index={4}>
                                  <b>Service</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={4} align="right">
                                  {`${currency} ${totalPrice.service}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>

                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4} />

                                <Table.Summary.Cell index={4}>
                                  <b>Tax</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={4} align="right">
                                  {`${currency} ${totalPrice.tax}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          ) : null}

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={4} />

                            <Table.Summary.Cell index={4}>
                              <b>Grand Total</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={4} align="right">
                              <b>
                                {`${currency} ${totalPrice.gross}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              </b>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      </>
                    );
                  }}
                />
              </Row>

              <Typography className="text-notes" style={{ fontSize: 10 }}>
                *notes: please, recheck your transactions again before paying!
              </Typography>

              <Typography className="text-notes" style={{ fontSize: 10 }}>
                *notes: all prices shown are already include tax & service
              </Typography>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            justify="end"
            align="middle"
            className="row-form-btn"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="print-btn"
              type="primary"
              htmlType="submit"
              icon={<PrinterOutlined />}
              onClick={() => {
                handlePrintOut();
              }}
              style={{
                marginRight: 30,
              }}
            >
              {`Print & Download`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
