// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

// GLOBAL STYLES
import { mainBody } from "../../../../Style/Global/Global";
import { failedFetch } from "../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";
import { Value } from "sass";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";
import axios from "axios";
import { baseurl } from "../../../../API/Config/Api";
import { cleanup } from "@testing-library/react";
import BillArticleShow from "../../../Reusable/Functions/BillType/BillType";
import { FrontOfficeParams } from "../../../Reusable/Functions/Parameters/ParamsFO";

export default function ListBillTable({
  returnArticleData,
  getArticleData,
  getGuestData,
  getReturnArticleData,
  is_search,
  searchKey,
}) {
  // CONTEXT
  const { getReservation } = useContext(MainContext);

  // Data
  //const [open, setOpen] = useState(false);
  const [bulks, setBulks] = useState([]);
  const [data, setData] = useState([]);
  const [optionValue, setOptionValue] = useState([]);
  const [resId, setResId] = useState(null);
  const [detailData, setDetailData] = useState(null);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [selectorValue, setSelectorValue] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // Currency
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  // USE EFFECT FETCHING DATA
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT FETCHING DATA
  useEffect(() => {
    let cleanUp = false;

    if (resId && !cleanUp) {
      fetchDetailData();
    }

    return () => {
      cleanUp = true;
    };
  }, [resId]);

  // USEEFFECT RETURN ARTICLE DATA
  useEffect(() => {
    let cleanUp = false;

    if (returnArticleData && !cleanUp) {
      setData((state) => [...state, returnArticleData]);
    }

    return () => {
      cleanUp = true;
    };
  }, [returnArticleData]);

  // USEEFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  //  FETCH DATA MASTER FOLIO
  const fetchData = async () => {
    await getReservation({
      reservation: "bill",
      type: "master-folio",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;
          console.log("Response Master Folio => ", _response);

          const filterUniqueReservations = (data) => {
            const uniqueReservations = [];

            data.forEach((item) => {
              if (
                !uniqueReservations.some(
                  (reservation) =>
                    reservation.reservation_id === item.reservation_id
                )
              ) {
                uniqueReservations.push(item);
              }
            });

            return uniqueReservations;
          };

          const _filtered = filterUniqueReservations(_response);

          const mappedOptions = _filtered.map((item) => {
            return {
              label: item?.reservation_id,
              value: item?.reservation_id,
              reservation_id: item?.reservation_id,
              guest_name: item?.guest_name,
              arrival: item?.arrival,
              departure: item?.departure,
              payment_status: item?.payment_status,
              payment_details: item?.payment_details,
              payment_type: item?.payment_type,
            };
          });

          setOptionValue(mappedOptions);
        } else {
          setOptionValue([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  // FETCH DETAIL DATA
  const fetchDetailData = async () => {
    setLoading(true);

    await axios
      .post(`${baseurl}/bill/master-folio`, {
        reservation_id: resId,
      })
      .then((response) => {
        console.log("response: ", response);
        if (response?.data?.msg) {
          const _res = response.data.msg[0];

          handleManipulateAPIResponse(_res);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log(error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // HANDLE MANIPULATE API RESPONSE
  const handleManipulateAPIResponse = (data) => {
    const roomStay = data.get_master_folio_ar;
    const articleStay = data.get_master_folio_as;

    const newRoom = roomStay?.map((e) => ({
      key: generateUniqueKey(),
      id: e?.id,
      article_name: `Room ${e?.room_category} ${e?.room_number}`,
      quantity: 1,
      unit_price: bill_type ? e?.room_price : e?.gross,
      total_price: bill_type ? e?.room_price : e?.gross,
      price_id: e?.price_id,
      gross: e?.gross,
      tax: e?.tax,
      service: e?.service,
      date: e?.date,
    }));

    const newArticle = articleStay?.map((e) => {
      return {
        key: generateUniqueKey(),
        id: e?.id,
        article_name: `${e.article_name} ${e.room_number}`,
        unit_price: bill_type ? e?.unit_price : e?.gross,
        total_price: bill_type
          ? e?.unit_price * e?.item_quantity
          : e?.total_price,
        gross: e?.total_price,
        price_id: e?.price_id,
        quantity: e?.item_quantity || 0,
        tax: e?.tax * e?.item_quantity,
        service: e?.service * e?.item_quantity,
        date: e?.order_date,
      };
    });

    setData([...newRoom, ...newArticle]);
    setBulks([...newRoom, ...newArticle]);
  };

  // GENERATE UNIQUE KEY FOR OPTION VALUE DATA
  const generateUniqueKey = () => {
    const timestamp = Date.now();
    const randomNum = Math.random().toString(36).substring(2, 10);

    return `key_${timestamp}_${randomNum}`;
  };

  // HANDLE CHANGE SELECTOR
  const handleChange = (event, allKeys) => {
    console.log("Event ->> ", event);
    console.log("Allkeys ->> ", allKeys);

    // setSelectorValue(allKeys);

    // setData(allKeys?.detail);
    // setBulks(allKeys?.detail);
    getGuestData(allKeys);
    setDetailData(allKeys);
    setResId(event);
    getArticleData([]);
  };

  // HANDLE SEARCH
  const handleSearch = async () => {
    const queryName = searchKey ? searchKey.toLowerCase() : null;

    let updatedList = bulks.filter((item) => {
      const name = item?.article_name ? item.article_name.toLowerCase() : "";

      return name.indexOf(queryName) !== -1;
    });

    setData(updatedList);
  };

  // HANDLE CLEAR
  const handleClear = () => {
    setData([]);
    setBulks([]);
    getArticleData([]);
    setDetailData({
      guest_name: "",
      arrival: "",
      departure: "",
      night: "",
      room_qty: "",
    });
  };

  // HANDLE REFRESH
  const handleRefresh = () => {
    fetchDetailData();
    // setData([]);
    // setBulks([]);
    // setDetailData({
    //   guest_name: "",
    //   arrival: "",
    //   departure: "",
    //   night: "",
    //   room_qty: "",
    // });
    getArticleData([]);
    getReturnArticleData(null);
  };

  // HANDLE MOVE
  const handleMove = (record) => {
    const filtered = data.filter((item) => item.key !== record.key);

    setData(filtered);
    setBulks(filtered);
    // getReturnArticleData(filtered);
    getArticleData((state) => [...state, record]);
  };

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 30,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-master-folio-table-btn"
              style={{ width: "100%" }}
            >
              <Button
                className="next-btn"
                type="primary"
                onClick={() => {
                  handleMove(record);
                  console.log(record);
                }}
              >
                {`Move`}
                <ArrowRightOutlined />
              </Button>
            </Row>
          </>
        );
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    // {
    //   title: "Quantity",
    //   dataIndex: "quantity",
    //   key: "quantity",
    // },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit, record) => {
        return `${unit > 0 ? unit : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    // {
    //   title: "Total Price",
    //   dataIndex: "unit_price",
    //   key: "unit_price",
    // },
  ];

  return (
    <>
      <Row
        className="master-folio-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} className="col-master-folio" style={{ padding: 30 }}>
          <Row
            className="row-modal-btn"
            justify="end"
            style={{ width: "100%" }}
          >
            <Col span={12}>
              Select Reservation:
              <Select
                // defaultValue={optionValue[0]?.value}
                style={{
                  width: "100%",
                }}
                onChange={handleChange}
                options={optionValue}
                allowClear
                placeholder="Reservation"
                showSearch
                onClear={handleClear}
              />
            </Col>

            <Col span={12}>
              <Row className="row-modal-btn" justify="end">
                <Button
                  className="refresh-btn"
                  // onClick={handleRefresh}
                  onClick={() => (resId ? handleRefresh() : null)}
                  type="default"
                >{`Refresh`}</Button>
              </Row>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "15px 0px", backgroundColor: "#d9d9d9" }}
          />

          <Typography style={{ fontWeight: "bold" }}>Data Guest</Typography>

          <Row
            className="row-modal-btn"
            justify="end"
            style={{ width: "100%" }}
          >
            <Col span={12}>
              <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography>Guest Name:</Typography>
                </Col>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {detailData?.guest_name?.replace(/\d+$/, "").trim() || "-"}
                  </Typography>
                </Col>
              </Row>

              <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography>Arrival:</Typography>
                </Col>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {detailData?.arrival &&
                    detailData?.arrival !== "Invalid date"
                      ? moment(detailData?.arrival).format("DD MMMM YYYY")
                      : "-"}
                  </Typography>
                </Col>
              </Row>

              <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography>Departure:</Typography>
                </Col>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {detailData?.departure &&
                    detailData?.departure !== "Invalid date"
                      ? moment(detailData?.departure).format("DD MMMM YYYY")
                      : "-"}
                  </Typography>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography>Payment Type:</Typography>
                </Col>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    IDR Cash
                  </Typography>
                </Col>
              </Row>

              <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography>Payment Details:</Typography>
                </Col>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Cash - IDR
                  </Typography>
                </Col>
              </Row>

              <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12}>
                  <Typography style={{ paddingTop: "5px" }}>
                    Payment Status:
                  </Typography>
                </Col>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Charge To Room
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "15px 0px", backgroundColor: "#d9d9d9" }}
          />

          <Typography style={{ fontWeight: "bold" }}>Bill List</Typography>

          <Row className="row-table" style={{ marginTop: 15, width: "100%" }}>
            <Table
              className="master-folio-table"
              key="master-folio-table"
              name="master-folio-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                // y: 270,
                x: true,
              }}
              // rowKey={(record) => (record.id ? record.id : record.key)}
              rowKey={(record) => record.key}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}
