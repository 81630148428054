// REACT COMPONENTS
import { useState } from "react";

// ANTD COMPONENTS
import { Button, Col, Modal, Popover, Row, Tooltip, Typography } from "antd";

// REACT ICONS
import { TfiMenuAlt } from "react-icons/tfi";
import {
  AiOutlineSplitCells,
} from "react-icons/ai";
import {
  MdHistory,
  MdOutlineLocalPrintshop,
} from "react-icons/md";
import { RiMergeCellsHorizontal } from "react-icons/ri";

// NOTIFICATIONS
import ModalSplitBill from "../../Modals/Outlet/Order/SplitBillModal";
import ModalHistoryPaidBill from "../../Modals/Outlet/Order/HistoryPaidBillModal";
import ModalJoinBill from "../../Modals/Outlet/Order/JoinBillModal";
import ModalPrintPreBill from "../../Modals/Outlet/Order/PrintPreBillModal";
import ModalSavedBill from "../../Modals/Outlet/Order/SavedBillModal";
import POSJoinModal from "../../Modals/Outlet/Order/POSJoinModal";

// MODALS
const { confirm } = Modal;

export default function POSPopover(props) {
  // Props
  const { headers, details, is_refresh, is_save, selectedTable, getAddNewState, selectedData, getClear } = props;
  // Open Popover
  const [open, setOpen] = useState(false);
  // Open Modal
  const [openModalSavedOrderList, setOpenModalSavedOrderList] = useState(false);
  const [openModalJoinBill, setOpenModalJoinBill] = useState(false);
  const [openModalSplitBill, setOpenModalSplitBill] = useState(false);
  const [openModalHistoryPaidBill, setOpenModalHistoryPaidBill] = useState(false);
  const [openModalPrintPreBill, setOpenModalPrintPreBill] = useState(false);
  const [openJoin, setOpenJoin] = useState(false);

  const handleClose = () => {
    setOpen(!open);
  };

  const handleModalJoinBill = () => {
    setOpenModalJoinBill(true);
  };

  const handleModalSplitBill = () => {
    setOpenModalSplitBill(true);
  };

  const handleModalHistoryPaidBill = () => {
    setOpenModalHistoryPaidBill(true);
  };

  const handleModalPrintPreBill = () => {
    setOpenModalPrintPreBill(true);
  };

  const handleOpenJoin = () => {
    setOpenJoin(true);
  };

  const handleRefresh = () => {
    setOpen(false);
    setOpenJoin(false);
    getClear();
  }

  // console.log(details);

  return (
    <>
      <Tooltip title="Option Menu" className="vendor-management-tooltip">
        <Popover
          className="pop-over-vendor-management"
          name="pop-over-vendor-management"
          key="pop-over-vendor-management"
          placement="right"
          title={
            <Row>
              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  {`POS Cashier Option`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            <>
              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <MdHistory
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleModalHistoryPaidBill();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`History Paid Bill`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <RiMergeCellsHorizontal
                      style={{
                        color: "#1890FF",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    // handleModalJoinBill();
                    handleOpenJoin();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Join Bill`}
                </Button>
              </Row>
              {/* old_version */}
              {/* <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <AiOutlineSplitCells
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleModalSplitBill();
                    handleClose();
                  }}
                  disabled={details.some((e) => e.id === null) ? true : false}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Split Bill`}
                </Button>
              </Row> */}
              {/* end_old_version */}

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <AiOutlineSplitCells
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    getAddNewState();
                    handleClose();
                  }}
                  disabled={selectedData?.order_id_alias ? false : true}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Split Bill`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <MdOutlineLocalPrintshop
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleModalPrintPreBill();
                    handleClose();
                  }}
                  disabled={selectedData?.order_id_alias ? false : true}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Print Pre Bill`}
                </Button>
              </Row>
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{ margin: 0, padding: 0 }}
        >
          <TfiMenuAlt
            className="primary-btn"
            style={{
              fontSize: 20,
              color: "1890FF",
              display: "flex",
              justifyContent: "center",
              marginRight: 15,
              cursor: "pointer",
            }}
          />
        </Popover>
      </Tooltip>

      <ModalJoinBill
        openModal={openModalJoinBill}
        closeModal={setOpenModalJoinBill}
      />
      <ModalSplitBill
        openModal={openModalSplitBill}
        closeModal={setOpenModalSplitBill}
        dataHeader={headers}
        dataDetail={details}
      />
      <ModalHistoryPaidBill
        openModal={openModalHistoryPaidBill}
        closeModal={setOpenModalHistoryPaidBill}
      />
      <ModalPrintPreBill
        openModal={openModalPrintPreBill}
        closeModal={setOpenModalPrintPreBill}
        header={headers}
        detail={details}
      />

      <ModalSavedBill
        openModal={openModalSavedOrderList}
        closeModal={setOpenModalSavedOrderList}
        selectedTable={selectedTable}
      />

      <POSJoinModal
        openModal={openJoin}
        closeModal={setOpenJoin}
        selectedTable={selectedTable}
        getClear = {handleRefresh}
      />
    </>
  );
}
