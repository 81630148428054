// Import React Components
import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  authorized_by,
  authorized_token,
  handleAuthorization,
} from "../../../API/Global/Permission";
import { roles, token, user_name } from "../../../API/Global/Payload";

// Import React Print
import { useReactToPrint } from "react-to-print";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Image,
  Space,
  Checkbox,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { BiSolidFilePdf } from "react-icons/bi";
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

// Import Notification
import {
  failedCheckIn,
  successCheckIn,
} from "../../Reusable/Notification/Notification";
import { FaSmoking, FaSmokingBan } from "react-icons/fa";
import {
  MdOutlineBedroomChild,
  MdOutlineBedroomParent,
  MdOutlineKingBed,
} from "react-icons/md";

// Import Page Components
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";
// import ArticleRoomPrice from "../../Reusable/Reservation/ArticleRoomPrice";

// Import Functions
import { PrintCount } from "../../Reusable/Functions/PrintCount/PrintCount";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";

// MODAL CONFIRM
const { confirm } = Modal;

// Text Styles
const textForm = {
  fontWeight: 750,
  padding: "5px 0px 0px",
};

// CODE
export default function RegistrationGroupForm(props) {
  // PROPS
  const {
    // Modal State
    openModal,
    closeModal,
    // Data Reservation
    dataRes,
    // Data Guest
    dataGuest,
    // Data COA
    dataCoa,
    // Price
    totalArticle,
    // Room
    dataRoom,
    // Is Refresh???
    is_refresh,
    // In No Show ?
    noShow,
    is_checkin,
    dataForm,
  } = props;

  // CONTEXT
  const { getMasterPrint } = useContext(MasterContext);

  // Use Ref
  const componentRef = useRef();

  // Params
  const currency = CurrencySymbols().code;
  const param_fo = FrontOfficeParams();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [guest, setGuest] = useState(null);
  const [leaders, setLeaders] = useState([]);
  const [dataPrice, setDataPrice] = useState([]);
  const [totalPeople, setTotalPeople] = useState(0);
  // Data Room
  const [smoke, setSmoke] = useState(null);
  const [room, setRoom] = useState([]);
  const [category, setCategory] = useState([]);
  const [price, setPrice] = useState(null);
  // Chart Of Account
  const [coa, setCoa] = useState(null);
  // Modal
  const [open, setOpen] = useState(false);
  // Print Count
  const [countPrint, setCountPrint] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // FETCH PRINT FORM
  const fetchPrint = async (val) => {
    const _data = val;

    await getMasterPrint({
      reservation_id: _data?.reservation_id || leaders?.reservation_id,
      price_id: _data?.price_id > 0 ? _data.price_id : leaders?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Count Print: ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg[0];

          setCountPrint(_res);
        } else {
          PrintCount({
            data: leaders || leaders,
            is_update: false,
          });
        }
      },
      onReject: (error) => {
        console.log("Failed get Print Count: ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      console.log("Modal Open --> ", openModal);

      setLoading(true);
      setOpen(true);
    } else {
      setOpen(false);
    }

    if (dataCoa) {
      console.log("COA Number --> ", dataCoa);
      setCoa(dataCoa);
    } else {
      handleCoa();
    }

    if (dataGuest && !cleanUp) {
      console.log("Data Guest --> ", dataGuest);

      setGuest(dataGuest);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataGuest, dataCoa]);

  // DATA GUEST FORMS
  useEffect(() => {
    let cleanUp = false;

    if (dataRes?.length > 0 && !cleanUp) {
      console.log("Data Reservations --> ", dataRes);

      setData(dataRes);

      // Handling Data
      handleLeader(dataRes);
      handleMember(dataRes);
    }

    if (is_checkin && !cleanUp) {
      if (dataRoom?.length > 0 && !cleanUp) {
        console.log("Data Room --> ", dataRoom);

        // Handling Data
        handleRoom(dataRoom);
      }
    } else {
      // console.log("Data Form: ", is_checkin, " = ", dataForm);
      if (open) {
        handleIsCheck(dataForm);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [open, dataRes, dataRoom, dataCoa, dataForm]);

  useEffect(() => {
    let cleanUp = false;

    if (totalArticle?.length > 0 && data?.length > 0 && !cleanUp) {
      // console.log("HANDLE ARTICLE FORM: ", totalArticle);
      handleArticle(totalArticle, dataRes);
      // handlePrice(totalArticle);
    }

    return () => {
      cleanUp = true;
    };
  }, [totalArticle, data]);

  // HANDLE ARTICLE
  const handleArticle = (val, res) => {
    const _array = val;
    const _dataRes = res;

    let _arrResults = [];

    for (let i = 0; i < _array?.length; i++) {
      _dataRes.filter((result) => {
        if (result.price_id == _array[i]?.price_id) {
          return _arrResults.push(_array[i]);
        }
      });
    }

    console.log("Article Filtered: ", _arrResults);

    if (_arrResults.length > 0 && is_checkin) {
      console.log("Merged: ", _arrResults);
      handlePrice(_arrResults);
      // handleRoom(_arrResults);
    } else {
      handlePrice(_array);
    }
    // setPrice(_arrResults);
    // setOpenForm(true);

    setTimeout(() => {
      setLoading(false);
    }, 1250);
  };

  // HANDLE ROOM
  const handleRoom = (value) => {
    const _room = value;
    const _roomCat = [];

    console.log("ROOM: ", _room);

    // let sumCategory = _room.filter((init) => {
    //   return init?.room_category;
    // });

    // if (_room?.length > 0) {
    //   _room.reduce((acc, init) => {
    //     let _cat = init?.room_category;

    //     // console.log("acc: ", acc);
    //     // console.log("init: ", init?.room_category);
    //     // console.log(_cat);

    //     if (_cat != undefined || _cat != null) {
    //       return _roomCat.push(_cat);
    //     }
    //   });
    // }

    // console.log("Room Category: ", sumCategory);

    for (const item of _room) {
      // 👇 "name" and "location" used for duplicate check
      const duplicate = _roomCat.find(
        (obj) => obj.room_category === item.room_category
      );

      if (!duplicate) {
        _roomCat.push(item);
      }
    }

    if (_roomCat.length > 0) {
      function mapped(value) {
        return value.map((value) => value.room_category);
      }
      console.log("Room Category: ", _roomCat);
      setCategory(mapped(_roomCat));
    }
  };

  // HANDLE LEADER
  const handleLeader = (val) => {
    const _lead = val;

    const leaders = _lead.filter((e) => {
      return e.group == 1;
    });

    console.log("Leaders Data: ", leaders?.length, leaders[0]);
    if (leaders?.length > 0) {
      const _ldrs = leaders[0];

      setLeaders(_ldrs);
      fetchPrint(_ldrs);
    } else {
      setLeaders(_lead);
      fetchPrint(_lead);
    }
  };

  // HANDLE TOTAL GUEST
  const handleMember = (val) => {
    const _total = val;
    const _roomNumb = [];

    let sumPeople = _total.reduce((accumulator, initValues) => {
      // console.log("ACC ", accumulator);
      // console.log("Init ", initValues);
      //   const adult = accumulator + initValues.adult + initValues.child;
      //   const infant = accumulator + initValues.infant;

      return accumulator + initValues.adult + initValues.child;
    }, 0);

    let sumRoom = _total.reduce((acc, init) => {
      // return init?.room_number;
      let _no = init?.room_number;

      if (acc != _no) {
        return _roomNumb.push(_no);
      }
    }, 0);

    console.log("Total Guest: ", sumPeople);
    console.log("Room Total: ", _total);
    console.log("Room Number: ", _roomNumb);

    setTotalPeople(sumPeople);
    setRoom(_roomNumb);
  };

  // HANDLE TOTAL PRICE
  const handlePrice = (val) => {
    const _room = val;

    let total = _room.reduce((accumulator, initValues) => {
      // console.log("ACC ", accumulator);
      // console.log("Init ", initValues);

      return accumulator + initValues.total_price;
    }, 0);

    console.log("Total Price >> ", total);
    setPrice(total);
  };

  // HANDLE COA
  const handleCoa = () => {
    if (dataCoa) {
      setCoa(dataCoa);
    }
    // else {
    //   alert("COA TIDAK ADA!");
    // }
  };

  // Handle Check-In
  const handleIsCheck = (value) => {
    const _data = value;

    let _total_guest = 0;
    let _total_article = 0;
    let _room_no = [];
    let _room_category = [];

    _total_guest =
      _data?.total_adults + _data?.total_children + _data?.total_infants;
    _total_article = _data?.total_room + _data?.total_stay || 0;
    _room_no = _data?.room_numbers;
    _room_category = _data?.room_category;

    setTotalPeople(_total_guest);
    setGuest(_data);
    setPrice(_total_article);
    setCategory(_room_category);
    setRoom(_room_no);

    console.log("Rooms: ", _total_guest);

    setTimeout(() => {
      setLoading(false);
    }, 1250);
  };

  // ON FINISH
  const onFinish = async () => {
    setIsLoading(true);

    console.log("COA: ", coa);
    console.log("Data: ", data);

    if (coa) {
      showSubmitModal();
    }
    // else {
    //   alert("COA TIDAK ADA!");
    // }
  };

  // SEQUENTIAL BUTTON SUBMIT
  const [counter, setCounter] = useState(0);

  const myclick = () => {
    // console.log(counter);
    setCounter(counter + 1);
    handlePrint();
    setIsLoading(false);

    // if (counter == 1) {
    //   // showSubmitModal();
    //   setCounter(0);
    // } else {
    //   setCounter(1);
    // }
  };

  // SHOW SUBMIT CONFIRMATION
  const showSubmitModal = async () => {
    const submitContent = data;

    // console.log(deleteContent);
    console.log("Coa ==> ", coa);

    confirm({
      className: "submit-reservation-confirm",
      title: `Are you sure want to Check-In with guest named ${
        guest?.full_name?.toUpperCase() || "-----"
      } and his/her members with total Guest are ${
        totalPeople > 0 ? totalPeople : 0
      } People and ${data?.length || 0} Rooms?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Submit",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(submitContent);
      },

      onCancel() {
        setIsLoading(false);
        setCounter(0);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentsubmit = value;

    console.log("ON OK ==> ", contentsubmit);
    console.log("Coa ==> ", coa);

    const allBody = [];

    for (let i = 0; i < contentsubmit.length; i++) {
      allBody.push({
        res_id: contentsubmit[i]?.reservation_id
          ? contentsubmit[i].reservation_id
          : "",
        room_num: contentsubmit[i]?.room_number
          ? contentsubmit[i]?.room_number
          : 0,
        prc_id: contentsubmit[i]?.price_id ? contentsubmit[i]?.price_id : 0,
        coa: coa ? coa : dataCoa,
        is_no_show: noShow ? noShow : false,
        created: authorized_by ? authorized_by : user_name,
      });
    }

    console.log("All Body Check In Group >>>>>", allBody);

    await axios
      .post(
        `${baseurl}/guest-in-house/checkin-reservation`,
        {
          data: allBody,
        },
        {
          headers: {
            Authorization: `Bearer ${
              authorized_token ? authorized_token : token
            }`,
          },
        }
      )
      .then((response) => {
        console.log("Check-In Group = ", response);

        handleCancel();
        is_refresh(true);
        successCheckIn(response, { method: 2 });

        localStorage.clear();
        handleAuthorization("");
      })
      .catch((error) => {
        console.log("Check-in Group Failed = ", error);
        failedCheckIn(error, { method: 2 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setIsLoading(false);
    setCounter(0);
    setOpen(false);

    closeModal(false);

    setRoom(null);
    setData([]);
    setGuest(null);
    setPrice(null);
    setSmoke(null);

    form.resetFields();
    setLoading(false);
    setIsLoading(false);
    // setEdit(null);
  };

  // HANDLE REACT PRINT
  const handlePrint = () => {
    PrintCount({
      data: data?.length > 0 ? data[0] : data,
      is_update: true,
      counter_list: {
        registration_form: countPrint?.registration_form + 1,
        guarantee_letter: countPrint?.guarantee_letter,
        pre_bill: countPrint?.pre_bill,
        thankyou_letter: countPrint?.thankyou_letter,
        checkout_letter_history: countPrint?.checkout_letter_history,
        bill: countPrint?.bill,
        master_bill: countPrint?.master_bill,
        pre_master_bill: countPrint?.pre_master_bill,
        reservation_letter: countPrint?.reservation_letter,
      },
    });

    handlePrintOut();
  };

  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Registration Form (${
      smoke?.smoking == 1 ? "Smoking" : "Non-Smoking"
    }) ${data?.length > 0 ? data[0]?.reservation_id : "---"}`,
    onafterprint: () => alert("Print Success"),
  });

  // CONSOLE LOG
  // console.log("DATA RESERVATION >> ", data);

  return (
    <>
      <Modal
        className="reg-form-modal"
        name="reg-form-modal"
        key="reg-form-modal"
        title={
          <>
            <Row className="row-title-modal">
              <BiSolidFilePdf style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Registration Form`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          name="registration-form"
          className="registration-form"
          key="registration-form"
          form={form}
          labelAlign="left"
          labelCol={{ span: 8 }}
          labelWrap={{ span: 16 }}
          onFinish={onFinish}
          style={{
            maxWidth: 1000,
          }}
          autoComplete="off"
          layout="horizontal"
        >
          {!loading ? (
            <Content
              ref={componentRef}
              style={{
                padding: "30px 30px 0px",
                backgroundImage:
                  countPrint?.registration_form >= 1
                    ? "url(/assets/logos/copyopac.png)"
                    : null,
                backgroundRepeat: "no-repeat",
                backgroundSize: "auto",
                backgroundPosition: "center",
              }}
            >
              <Row className="row-header">
                <Col span={12} className="col-title-form">
                  <Row>
                    <Typography.Title level={3} className="text-form-title">
                      {`Registration Group Form`}
                    </Typography.Title>
                  </Row>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    src="/ideas.png"
                    // src="/looklogo(color).png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "25%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Idea's Hotel`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px 5px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-room-details"
                style={{ width: "100%", margin: 0, height: 35 }}
              >
                <Col span={4}>
                  <Form.Item
                    label="Room Qty "
                    name="room_qty"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-room-qty" style={textForm}>
                      {`${
                        room?.length > 0
                          ? room.length
                          : data?.length > 0
                          ? data.length
                          : 0
                      }`}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={20} className="col-room-no">
                  <Form.Item
                    label="Room No"
                    className="room_number"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Typography className="text-room-no" style={textForm}>
                      {room?.length ? room.join(", ") : 0}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "5px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-room-details"
                style={{ width: "100%", margin: 0, height: 35 }}
              >
                <Col span={16}>
                  <Form.Item
                    label="Room Category"
                    name="room_type"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                  >
                    <Typography className="text-room-cat" style={textForm}>
                      {/* {room?.length > 0
                      ? [
                          ...new Set(
                            room.map(({ room_category }) => room_category)
                          ),
                        ].join(", ")
                      : ""} */}
                      {category?.length > 0 ? category.join(", ") : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Total Price"
                    name="room_price"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-room-price" style={textForm}>
                      {`${currency} ${price > 0 ? price : 0}.00`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "5px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-room-details"
                style={{ width: "100%", margin: 0, height: 35 }}
              >
                <Col span={4}>
                  <Form.Item
                    label="Night"
                    name="total-night"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-night" style={textForm}>
                      {`${
                        leaders?.night ? leaders.night : data[0]?.night || 0
                      }`}
                      {/* {data?.folio ? data.folio : ""} */}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item
                    label="Total Guest"
                    className="total-guest"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-adult" style={textForm}>
                      {totalPeople ? totalPeople : 0}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Rate Code"
                    className="room_rate_id"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-rate-code" style={textForm}>
                      {leaders?.room_rate_id?.length > 0
                        ? leaders?.room_rate_id[0]
                        : dataRes[0]?.rate_code || ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Breakfast/Room Only"
                    name="is_abf"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-receipt" style={textForm}>
                      {data[0]?.is_abf ? "BREAKFAST" : "ROOM ONLY"}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "5px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row className="row-guest" style={{ height: 30, width: "100%" }}>
                <Col span={16} className="col-guest-name">
                  <Form.Item
                    label="Name"
                    className="guest_name"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Typography
                      className="text-guest-name"
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                      }}
                    >
                      {`${
                        guest?.full_name ? guest.full_name : data[0]?.guest_name
                      }, ${
                        guest?.guest_title
                          ? guest.guest_title
                          : guest?.sex == "Male"
                          ? "Mr."
                          : guest?.sex == "Female"
                          ? "Mrs."
                          : " "
                      }`}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-eta">
                  <Form.Item
                    label="Arrival Time"
                    className="arrival_time"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-eta" style={textForm}>
                      {!is_checkin && leaders?.checkin_date
                        ? moment(LocalizationDate(leaders.checkin_date)).format(
                            "HH:mm"
                          )
                        : `${moment().format("HH:MM")}`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-guest-group"
                style={{ height: 25, width: "100%" }}
              >
                <Col span={16} className="col-group-name">
                  <Form.Item
                    label="Group / Company"
                    className="group_name"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ width: "100%" }}
                  >
                    <Typography
                      className="text-group"
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                      }}
                    >
                      {data?.group_name
                        ? data.group_name
                        : guest?.master_comp
                        ? guest.master_comp
                        : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-arrival">
                  <Form.Item
                    label="Arrival Date"
                    className="arrival_date"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-arrival" style={textForm}>
                      {leaders?.arrival
                        ? moment(leaders.arrival).format("DD-MM-YYYY")
                        : moment(data?.arrival).format("DD-MM-YYYY")}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                style={{ height: 25, margin: 0 }}
                className="row-guest-details"
              >
                <Col span={8} className="col-nation">
                  <Form.Item
                    label="Nationality"
                    name="nationality"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Typography className="text-nation" style={textForm}>
                      {guest?.nationality ? guest.nationality : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-id-card">
                  <Form.Item
                    label="ID Card"
                    className="id_card_number"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Typography className="text-id-card" style={textForm}>
                      {guest?.id_card_number ? guest.id_card_number : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-departure">
                  <Form.Item
                    label="Departure Date"
                    name="departure_date"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-depart" style={textForm}>
                      {`${moment(
                        leaders?.departure
                          ? leaders.departure
                          : data[0]?.departure || ""
                      ).format("DD-MM-YYYY")}`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                style={{
                  margin: "10px 0px",
                  backgroundColor: "#d9d9d9",
                }}
              />

              <Row
                justify="start"
                style={{ width: "100%", height: 30, textAlign: "start" }}
              >
                <Col span={16} className="col-email">
                  <Form.Item
                    label="Email Address"
                    className="email"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ width: "100%" }}
                  >
                    <Typography
                      className="text-email"
                      style={{
                        fontSize: 15,
                        fontWeight: 750,
                      }}
                    >
                      {data?.email
                        ? data.email
                        : guest?.email
                        ? guest.email
                        : null}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Check Out Time"
                    className="departure_time"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    // labelAlign="right"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography className="text-room-price" style={textForm}>
                      {/* {`${moment(data?.departure ? data.departure : "").format(
                    "DD-MM-YYYY"
                  )}, 12.00`} */}
                      {/* 12:00 */}
                      {`${moment(param_fo?.checkout_time).format("HH:mm")}`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row className="row-guest-address" style={{ height: 30 }}>
                <Col span={8} className="col-address">
                  <Form.Item
                    label="Address"
                    className="address"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                  >
                    <Typography
                      className="text-address"
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      {guest?.address ? guest.address : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-birth">
                  <Form.Item
                    label="Birth Date"
                    className="birthdate"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-birthdate" style={textForm}>
                      {guest?.birthdate
                        ? moment(guest.birthdate).format("DD-MM-YYYY")
                        : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-phone">
                  <Form.Item
                    label="Phone"
                    className="phone_number"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Typography className="text-phone" style={textForm}>
                      {`(+) ${
                        guest?.phone_number ? guest.phone_number : " - "
                      }`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                style={{
                  margin: "10px 0px",
                  backgroundColor: "#d9d9d9",
                }}
              />

              <Row
                className="row-payment"
                style={{ width: "100%", height: 60, margin: 0 }}
              >
                <Col span={8} className="col-payment-type">
                  <Form.Item
                    label="Payment Type"
                    className="payment_type"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-pay-type" style={textForm}>
                      {leaders?.payment_type
                        ? leaders.payment_type
                        : data[0]?.payment_type || ""}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Member Card No."
                    className="member_card_no"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-id-card" style={textForm}>
                      {guest?.member_id ? guest.member_id : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-payment-details">
                  <Form.Item
                    label="Payment Details"
                    className="payment-details"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: 0 }}
                  >
                    <Typography
                      className="text-payment-details"
                      style={textForm}
                    >
                      {leaders?.payment_details
                        ? leaders.payment_details
                        : data[0]?.payment_details || ""}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Credit Card No. "
                    className="credit_card"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-id-card" style={textForm}>
                      {/* {data?.payment_details ? data.payment_details : ""} */}
                      {`____________`}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col
                  span={8}
                  className="col-checkbox"
                  style={{ width: "100%", margin: 0 }}
                >
                  <Checkbox.Group
                    className="checkbox-group-list"
                    // disabled
                    value={false}
                    style={{
                      width: "100%",
                      // padding: 10,
                      fontSize: 18,
                    }}
                  >
                    <Row
                      className="row-body-checkbox"
                      style={{ margin: 0, width: "100%" }}
                    >
                      <Col span={12} className="1st-row-1st-col">
                        <Checkbox checked={false} className="visa">
                          VISA
                        </Checkbox>
                      </Col>

                      <Col span={12} className="1st-row-2nd-col">
                        <Checkbox checked={false} className="master-card">
                          Master
                        </Checkbox>
                      </Col>

                      <Col span={12} className="2nd-row-1st-col">
                        <Checkbox checked={false} className="Dinners">
                          JBF
                        </Checkbox>
                      </Col>

                      <Col span={12} className="2nd-row-2nd-col">
                        <Checkbox checked={false} className="other">
                          Other
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Payment Status:"
                    className="payment_status"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-check-out" style={textForm}>
                      {` ${
                        data[0]?.payment_details == 1
                          ? "CASH BASIS"
                          : "CHARGE TO ROOM"
                      }`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-sign-guest"
                gutter={30}
                style={{
                  padding: "0px 0px 10px",
                }}
              >
                <Col
                  className="col-statement-guest"
                  span={16}
                  style={{
                    width: "100%",
                    fontSize: 12,
                  }}
                >
                  <Row
                    className="row-statement-to"
                    style={{
                      width: "100%",
                      fontWeight: 750,
                      // padding: "0px 30px 0px 0px",
                    }}
                  >
                    <Typography className="text-to">
                      {`To : Idea's Hotel Bandung`}
                    </Typography>
                  </Row>

                  <Row
                    className="row-statement"
                    style={{
                      margin: 0,
                      fontSize: 12,
                      textAlign: "justify",
                    }}
                  >
                    <Typography className="text-statement">
                      I Knowledge that I'm jointly and severally liable with the
                      fore-going person, company or association (and if more
                      than one all of them) for payment of the amount of any
                      charges payable or incurred in connecting with all service
                      provided by you under registration.
                    </Typography>
                  </Row>

                  <Row
                    className="row-statement"
                    style={{
                      margin: 0,
                      fontSize: 12,
                      textAlign: "justify",
                    }}
                  >
                    <Typography className="text-statement">
                      Please be informed that you are not allowed to bring
                      Durian in to your room. For your valuable belonging
                      (jewels, money, etc) could safe in the safe deposit box in
                      your room or in the front office.
                    </Typography>
                  </Row>
                </Col>

                <Col
                  className="col-sign"
                  span={8}
                  style={{
                    width: "100%",
                    height: 100,
                    // border: "1px solid #000000",
                    padding: "0px 30px",
                  }}
                >
                  <Row className="row-sign" justify="center">
                    <Typography
                      className="text-guest-sign"
                      style={{
                        fontWeight: 750,
                        // border: "1px solid #000000",
                      }}
                    >
                      Guest Signature
                    </Typography>
                  </Row>

                  <Typography
                    className="text-box"
                    style={{
                      fontWeight: 750,
                      height: 75,
                      border: "1px solid #000000",
                      textAlign: "center",
                    }}
                  ></Typography>
                </Col>
              </Row>

              {/* <Divider
              className="divider-form"
              style={{
                margin: "0px 0px 10px 0px",
                backgroundColor: "#d9d9d9",
              }}
            /> */}

              <Row
                className="row-notes"
                style={{
                  height: 60,
                  border: "1px solid #000000",
                  padding: "0px 30px",
                  margin: "5px 0px",
                }}
              >
                <Form.Item
                  label="Notes"
                  className="notes"
                  style={{ width: "100%", margin: 0 }}
                >
                  <Typography className="text-notes" style={textForm}>
                    {data[0]?.memo_room
                      ? data[0].memo_room
                      : leaders?.memo_room || ""}
                  </Typography>
                </Form.Item>
              </Row>

              {/* <Divider
              className="divider-form"
              style={{
                margin: "10px 0px",
                backgroundColor: "#d9d9d9",
              }}
            /> */}

              <Row
                className="row-travel"
                style={{
                  width: "100%",
                  margin: "10px 0px 0px",
                  fontSize: 12,
                  fontWeight: 750,
                }}
              >
                <Typography
                  className="text-questions"
                  style={{ margin: "0px 50px 0px 0px" }}
                >
                  Have you travelled out of this country in the last day 14 days
                  ?
                </Typography>

                <Checkbox checked={false}>Yes</Checkbox>

                <Checkbox checked={false}>No</Checkbox>
              </Row>

              <Row
                className="row-where"
                style={{ margin: "5px 0px", fontSize: 12 }}
              >
                <Typography className="text-where">
                  If so, where to? ________________________________
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              {/*
              <Row
                className="row-desc"
                style={{ width: "100%", margin: 0, height: 30 }}
              >
                {/* <Col span={8} className="col-depo">
                <Form.Item
                  label="Deposit Box Number"
                  className="deposit_box_number"
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                  style={{ width: "100%" }}
              />
              </Col> 

                <Col span={8}>
                <Form.Item
                    label="Issued By"
                    name="created_by"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-issued" style={textForm}>
                      {leaders?.created_by
                        ? leaders.created_by
                        : data[0]?.created_by || user_name}
                    </Typography>
                  </Form.Item> 
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Date"
                    name="date"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-date" style={textForm}>
                      {`${moment().format("DD-MM-YYYY")}`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
                />
                */}

              <Row
                className="row-signature"
                justify="start"
                // gutter={30}
                style={{
                  width: "100%",
                  fontWeight: 750,
                }}
              >
                <Col span={12} className="col-check-in-by" />

                <Col span={12} className="col-check-in-by">
                  <Typography className="text-check-in">{`Checked-In & Issued By`}</Typography>

                  <Typography
                    className="text-box"
                    style={{ height: 60, border: "1px solid #000000" }}
                  />
                  {/* {data?.payment_type ? data.payment_type : ""} */}

                  <Typography
                    className="text-box"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {`( ${is_checkin ? user_name : leaders?.checkin_by} )`}
                  </Typography>
                </Col>
              </Row>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 0px" }}
          >
            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Button
              type="primary"
              icon={<PrinterOutlined />}
              key={"print"}
              onClick={myclick}
              className="print-guest"
              loading={loading}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Print & Download`}
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              hidden={!is_checkin}
              // onClick={myclick}
              loading={isLoading}
              disabled={counter > 0 ? false : true}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {/* {counter === 0 ? "Download" : "Check In"} */}
              {`Check-In`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
