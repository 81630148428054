// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Popover,
  Typography,
  Tag,
  Input,
  Tooltip,
  // Search,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import { FaConciergeBell, FaUserFriends } from "react-icons/fa";
import { IoIosClipboard } from "react-icons/io";
import {
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CheckSquareOutlined,
  DeleteFilled,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined,
  SyncOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons/lib/icons";
import { TbChecklist } from "react-icons/tb";

// Import Elements
import ReservationPopover, {
  content,
  title,
} from "../../../Popover/Reservation/Popover";

// Import Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";

// import AddReservation from "../../../Tabs/Reservation/AddReservationTabs";

// Import Global Styles
import "../../../../Layout/MainLayout/MainLayout.css";
import { mainBody } from "../../../../Style/Global/Global";

// CODE
export default function ReservationTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      fixed: "left",
      width: 100,

      render: (_, record) => {
        return (
          <>
            <Row className="action-reserve-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      editRes(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <ReservationPopover details={record} is_refresh={setRefresh} />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
      fixed: "left",
      width: 150,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.id - b.id,
      render: (_, record) => {
        if (record.group == 2) {
          return "";
        } else {
          return record.reservation_id;
        }
      },
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guestname",
      fixed: "left",
      width: 250,
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">{`PRIVATE`}</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 75,
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      // width: 120,
      render: (arrival, record) => {
        return moment(arrival).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.arrival) - moment(b.arrival),
    },
    {
      title: "Reservation Status",
      dataIndex: "reservation_status",
      key: "reservation_status",
      // width: 150,
    },
    {
      title: "Breakfast",
      dataIndex: "is_abf",
      key: "is_abf",
      align: "center",
      render: (abf) => {
        return (
          <Tag key={"abf-keytag"} color={abf === true ? `green` : `geekblue`}>
            {abf === true ? `RB` : `RO`}
          </Tag>
        );
      },
      // width: 150,
    },
    {
      title: "Guest Status",
      dataIndex: "guest_category",
      key: "guest_category",
      // width: 150,
      filters: [
        {
          text: "REGULAR",
          value: "REGULAR",
        },
        {
          text: "VVIP",
          value: "VVIP",
        },
        {
          text: "VIP",
          value: "VIP",
        },
        {
          text: "INCOGNITO",
          value: "INCOGNITO",
        },
        {
          text: "RED ALERT",
          value: "RED ALERT",
        },
        {
          text: "UNASSIGNED",
          value: "",
        },
      ],
      onFilter: (value, record) => {
        // console.log("VALUE = ", value);
        return record.guest_category === value;
      },
      render: (category, record) => {
        let gst = "";
        let clr = "";

        switch (category) {
          case "VIP":
            gst = "VIP";
            clr = "geekblue";
            break;

          case "VVIP":
            gst = "VVIP";
            clr = "green";
            break;

          case "INCOGNITO":
            gst = "INCOGNITO";
            clr = "default";
            break;

          case "RED ALERT":
            gst = "RED ALERT";
            clr = "magenta";
            break;

          case "REGULAR":
            gst = "REGULAR";
            clr = "gold";
            break;

          case "":
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          case null:
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          default:
            break;
        }

        return (
          <Tag
            color={clr}
            style={{
              fontSize: 13,
              fontWeight: 500,
              borderColor: "transparent",
            }}
          >
            {gst}
          </Tag>
        );
      },
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      render: (depart, record) => {
        return moment(depart).format("DD MMM YYYY");
      },
      // width: 120,
      sorter: (a, b) => moment(a.departure) - moment(b.departure),
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room Number",
      dataIndex: "room_number",
      key: "roomno",
      sortDirections: ["descend", "ascend"],
    },
    // {
    //   title: "Room Revenue",
    //   dataIndex: "deposit",
    //   key: "roomrevenue",
    //   sortDirections: ["descend", "ascend"],
    // },
    // {
    //   title: "Arrangement Code",
    //   dataIndex: "arrangement",
    //   key: "arrangementcode",
    // },
    {
      title: "Guest Type",
      dataIndex: "group",
      key: "group",
      width: 200,
      render: (group, record) => {
        let grp = "";

        switch (group) {
          case 0:
            grp = "Individual Guest";
            break;

          case 1:
            grp = "Group Leader Guest";
            break;

          case 2:
            grp = "Group Member Guest";
            break;

          default:
            break;
        }

        return grp;
      },
    },
    {
      title: "Memo",
      dataIndex: "memo_room",
      key: "memo_room",
      width: 300,
    },
  ];

  //CONTEXT
  const { getReservation } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [dataSource, setDataSource] = useState([]);
  const [filteredList, setFilteredList] = useState(dataSource);
  // Loading
  const [loading, setLoading] = useState(false);
  // Set Refresh
  const [refresh, setRefresh] = useState(false);

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const location = useLocation();

  // MOVE TO PAGE ADD RESERVATION
  const move = () => {
    // console.log(location);
    navigate(`${location.pathname}/add-reservation`);
  };

  // EDIT DATA
  const editRes = (value) => {
    console.log(value);
    let edit = value;

    navigate(`${location.pathname}/edit-reservation`, {
      state: { state: edit, type: 0 },
    });
  };

  // GET DATA FUNCTION
  const fetchData = async () => {
    setLoading(true);

    await getReservation({
      reservation: "reservation",
      type: "stay",
      onAwait: () => {
        "on Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          setDataSource(response.data.msg);
          setFilteredList(response.data.msg);
        } else {
          setDataSource([]);
          setFilteredList([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT Fetching Data
  useEffect(() => {
    fetchData();

    if (refresh == true) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // CHECK COMPLETENESS
  const checkAvailability = (param) => {
    return !Object.keys(
      (({
        group_name,
        guest_category,
        arrival_flight,
        eta,
        departure_flight,
        etd,
        is_dummy,
        is_group,
        //tak tambah iki sitik kelewat ning ngisor
        letter,
        sales,
        created_at,
        created_by,
        nationality, ///???
        // reservation_id, ///???
        memo_room,
        room_type,
        arrangement,
        room_rate_id,
        deposit,
        reason,
        // guest_id,
        // res_created_by,
        // res_created,

        is_cm,
        is_deleted,
        updated_by,
        updated_date,
        // rate_code,
        sub_segment_id,

        step_by,
        step_date,
        is_checkin,
        is_step,

        checkin_by,
        checkin_date,
        deleted_by,
        deleted_date,

        is_nonstay,
        nonstay_by,
        nonstay_count,
        nonstay_date,

        is_late_checkout,
        late_checkout_by,
        late_checkout_date,

        is_audit,
        audit_at,
        audit_by,

        ...o
      }) => o)(param)
    ).some((key) => {
      // console.log(
      //   "FILTERED ==> ",
      //   param[key] === "" || param[key] === null || param[key] === undefined
      // );
      // console.log("Params KEY ==> ", [key] + ": " + param[key]);
      // console.log("Params ==> ", param);

      return (
        param[key] === "" || param[key] === null || param[key] === undefined
      );
    });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    console.log("Type = ", query);

    if (dataSource.length > 0) {
      let updatedList = dataSource.filter((item) => {
        let _name = item?.guest_name.toLowerCase();

        return (
          item.guest_category != "INCOGNITO" && _name.indexOf(query) !== -1
        );
      });
      // Trigger render with updated values
      setFilteredList(updatedList);
    } else {
      setFilteredList([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  // Handle Move
  const handleMove = () => {
    const _path = "/report/front-office/cancel-reservation";

    // console.log("Locations: ", location);

    navigate(_path, { state: { from_reservations: true } });
  };

  // console.log(searchKey);
  // console.log("Refresh => ", refresh);

  return (
    <>
      <Row className="reservation-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: "30px" }} className="col-table">
          <Row justify="start" className="row-btn" gutter={[0, 30]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
              align="start"
              // direction="horizontal"
            >
              <Tooltip
                className="redirect-tooltip"
                title="Wants to Re-Activate Cancelled Reservations?"
              >
                <Button
                  type="primary"
                  className="next-btn"
                  icon={<CheckCircleOutlined />}
                  onClick={handleMove}
                  style={{
                    minWidth: 50,
                    // margin: "4px 0px 0px",
                  }}
                >
                  {`Re-Activate Reservations`}
                </Button>
              </Tooltip>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
              align="start"
              // direction="horizontal"
            >
              <Row justify="end" className="row-btn" gutter={[0, 30]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={move}
                  icon={<PlusOutlined />}
                >
                  {`Add Reservation`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="table--main-row" style={{ width: "100%" }}>
            <Table
              className="table-reservation"
              name="table-reservation"
              key="table-reservation"
              loading={loading}
              columns={columns}
              dataSource={filteredList}
              bordered={true}
              size="large"
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return checkAvailability(record) ? "completed" : "incompleted";
              }}
              scroll={{
                x: 2000,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              style={{
                margin: "30px 0px 0px",
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}
