// REACT COMPONENTS
import { useState } from "react";

// ANTD COMPONENTS
import { Button, Col, Modal, Popover, Row, Tooltip, Typography } from "antd";

// REACT ICONS
import { TfiMenuAlt } from "react-icons/tfi";
import {
  AiOutlineSave,
  AiOutlineSplitCells,
} from "react-icons/ai";
import {
  MdHistory,
  MdOutlineLocalPrintshop,
} from "react-icons/md";

// NOTIFICATIONS
import ModalHistoryPaidBill from "../../Modals/Outlet/Order/HistoryPaidBillModal";
import CaptainModalSavedBill from "../../Modals/Outlet/Order/CaptainSavedBillModal";
import { BiBowlHot } from "react-icons/bi";
import CaptainModalPrintPreBill from "../../Modals/Outlet/Order/CaptainPrintPreBillModal";
import CaptainModalJoin from "../../Modals/Outlet/Order/CaptainJoinModal";
import { FaRightLeft, FaRightToBracket } from "react-icons/fa6";

// MODALS
const { confirm } = Modal;

export default function CaptainOrderPopover(props) {
  // Props
  const { headers, details, selectedTable, newOrder, editState, getTransferState, getAddNewState, orderStatus, getClear } = props;
  // Open Popover
  const [open, setOpen] = useState(false);
  // Open Modal
  const [openModalSavedOrderList, setOpenModalSavedOrderList] = useState(false);
  const [openModalHistoryPaidBill, setOpenModalHistoryPaidBill] = useState(false);
  const [openModalPrintPreBill, setOpenModalPrintPreBill] = useState(false);
  const [openJoin, setOpenJoin] = useState(false);

  const handleClose = () => {
    setOpen(!open);
  };

  const handleRefresh = () => {
    setOpen(false);
    setOpenJoin(false);
    getClear();
  }

  const handleModalSavedOrderList = () => {
    setOpenModalSavedOrderList(true);
  };

  const handleOpenJoin = () => {
    setOpenJoin(true);
  };

  const handleModalHistoryPaidBill = () => {
    setOpenModalHistoryPaidBill(true);
  };

  const handleModalPrintPreBill = () => {
    setOpenModalPrintPreBill(true);
  };

  // console.log(details);

  return (
    <>
      <Tooltip title="Option Menu" className="vendor-management-tooltip">
        <Popover
          className="pop-over-vendor-management"
          name="pop-over-vendor-management"
          key="pop-over-vendor-management"
          placement="right"
          title={
            <Row>
              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  {`Options`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            <>
              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  // htmlType="submit"
                  // key={"submit"}
                  icon={
                    <AiOutlineSave
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleModalSavedOrderList();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Captain Order List`}
                </Button>
              </Row>

              {/* <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <BiBowlHot
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleModalHistoryPaidBill();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Close Order List`}
                </Button>
              </Row> */}

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FaRightLeft
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleOpenJoin();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Join Order`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FaRightToBracket
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  disabled={newOrder || editState || orderStatus == 2}
                  onClick={() => {
                    getTransferState();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Transfer Item`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <AiOutlineSplitCells
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  disabled={newOrder || editState || orderStatus == 2}
                  onClick={() => {
                    getAddNewState();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Add Item to New Order`}
                </Button>
              </Row>
              
              {/* <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <MdOutlineLocalPrintshop
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  disabled={newOrder || editState}
                  onClick={() => {
                    handleModalPrintPreBill();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Print Order`}
                </Button>
              </Row> */}
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{ margin: 0, padding: 0 }}
        >
          <TfiMenuAlt
            className="primary-btn"
            style={{
              fontSize: 20,
              color: "1890FF",
              display: "flex",
              justifyContent: "center",
              marginRight: 15,
              cursor: "pointer",
            }}
          />
        </Popover>
      </Tooltip>

      <ModalHistoryPaidBill
        openModal={openModalHistoryPaidBill}
        closeModal={setOpenModalHistoryPaidBill}
      />
      <CaptainModalPrintPreBill
        openModal={openModalPrintPreBill}
        closeModal={setOpenModalPrintPreBill}
        header={headers}
        detail={details}
      />

      <CaptainModalSavedBill
        openModal={openModalSavedOrderList}
        closeModal={setOpenModalSavedOrderList}
        selectedTable={selectedTable}
      />

      <CaptainModalJoin
        openModal={openJoin}
        closeModal={setOpenJoin}
        selectedTable={selectedTable}
        getClear = {handleRefresh}
      />
    </>
  );
}
