// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterPaymentCard(props) {
  // PROPS
  const {
    getPayCard,
    payCard,
    // CODE
    getPayTypeId,
    getCode,
  } = props;

  // CONTEXT
  const { getMasterPaymentCard } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [paycard, setPaycard] = useState(null);
  const [optionValue, setOptionValue] = useState([]);
  const [payTipe, setPayTipe] = useState(null);

  // Fetch Data
  const fetchData = async () => {
    await getMasterPaymentCard({
      paymentCard: "payment-card",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0 || response?.data != null) {
          let filtered = response.data.msg.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev?.name,
            label: dev?.name,
            pay_code: dev?.code,
            code: dev?.payment_card_id,
            id: dev?.id,
            coa: dev?.chart_of_account,
          }));

          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // Fetch Edit
  const fetchEdit = async () => {
    await getMasterPaymentCard({
      paymentCard: "payment-card",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Response => ", response);

        if (response?.data?.msg?.length > 0 || response?.data != null) {
          response.data.msg.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              if (filt.name == payCard) {
                getCode(filt.payment_card_id);
              }
            }
          });
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // Fetch Data
  const fetchFilter = async (value) => {
    const _payTypeId = value;

    console.log("Payment Type ID --> ", _payTypeId);

    await getMasterPaymentCard({
      paymentCard: "payment-card",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Filter => ", response);

        if (response?.data?.msg?.length > 0 || response?.data != null) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (!filt.is_deleted) {
              if (filt.payment_type_id == getPayTypeId) {
                return filt;
              }
            }
          });

          let option = filtered.map((dev) => ({
            value: dev.name,
            label: dev.name,
            pay_code: dev.code,
            code: dev.payment_card_id,
            id: dev?.id,
            coa: dev?.chart_of_account,
          }));

          console.log("Filtered --> ", option);

          setOptionValue(option);

          setPaycard(option[0]?.value);
          getPayCard({
            name: option[0]?.value,
            id: option[0]?.id,
            coa: option[0]?.coa,
          });

          // console.log(option.includes(''))
        }

        // console.log("filter is running...");

        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    // IF EDIT
    if (payCard && !cleanUp) {
      console.log("Clean Up False --> ", payCard);
      fetchEdit();
    }

    return () => {
      cleanUp = true;
    };
  }, [payCard]);

  useEffect(() => {
    let cleanUp = false;

    if (getPayTypeId && !cleanUp) {
      console.log("Pay Type ID: ", getPayTypeId, " =? ", payTipe);
      // cleanUp++;

      if (getPayTypeId != payTipe) {
        setPayTipe(getPayTypeId);
        fetchFilter(getPayTypeId);
      }
    }

    if ((payCard != null || payCard != undefined) && !cleanUp) {
      console.log("Pay Card: ", payCard);

      // let _coa = optionValue.filter((val) => {
      //   return val?.
      //  })

      getPayCard({ name: paycard });
    }

    return () => {
      cleanUp = true;
    };
  }, [getPayTypeId, paycard]);

  // Handle Change
  const handleChange = (value, alls) => {
    const _name = value;
    const _allkeys = alls;

    setPaycard(_name);

    getPayCard({
      id: _allkeys?.id,
      name: _allkeys?.name,
      code: _allkeys?.code,
      coa: _allkeys?.coa,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setPaycard(null);
  };

  // useEffect(() => {
  //   if (paycard) {
  //     getPayCard(paycard?.value, paycard?.code);
  //   }
  // }, [paycard]);

  // console.log("Pay Code = ", getPayTypeId);
  // console.log(getPayTypeId);

  // console.log("Pay Code = ", getPayTypeId);
  // console.log(optionValue);

  return (
    <>
      <Select
        key={"select-payment"}
        placeholder="Payment Method"
        value={paycard != null ? paycard : payCard}
        allowClear
        showSearch
        onChange={(value, allKey, index) => {
          // setPaycard(value);
          // console.log(value, allKey);
          // let payCode = optionValue.filter((val) => {
          //   if (val?.value == value) {
          //     return val;
          //   }
          // });
          // console.log(payCode);
          // getPayCard(value, allKey?.id, allKey?.coa);

          handleChange(value, allKey);
        }}
        onClear={handleClear}
        options={optionValue}
        style={{ width: "100%" }}
      />
    </>
  );
}
