// Import React Components
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
// React-Redux
import { Provider } from "react-redux";

// Import CSS STYLING
// Bootstrap CSS
// import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import "antd/dist/antd.css";

/* eslint-disable */
import "./index.css";
import "antd/dist/antd.min.css";

// PROVIDER & CONTEXT
import { Provider as MasterProvider } from "./API/Context/MasterContext/MasterContext";
import { Provider as MainProvider } from "./API/Context/MainContext/MainContext";
import { Provider as FinanceProvider } from "./API/Context/FinanceContext/FinanceContext";
import { Provider as ManagerProvider } from "./API/Context/ManagerContext/ManagerContext";
import { Provider as InventoryProvider } from "./API/Context/InventoryContext/InventoryContext";

// CSS
import App from "./App";
import store from "./store";

// TURN OFF CONSOLE LOG
// if (process.env.REACT_APP_NODE_ENV == "production") {
if (process.env.REACT_APP_NODE_ENV == "development") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <MainProvider>
      <MasterProvider>
        <FinanceProvider>
          <ManagerProvider>
            <InventoryProvider>
              <App />
            </InventoryProvider>
          </ManagerProvider>
        </FinanceProvider>
      </MasterProvider>
    </MainProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
