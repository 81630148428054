// Import React Components
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// AUTH
import { sub_children } from "../../API/Global/Payload";

// Import Routes Components
import MICEPages from "../../Pages/FandB/MICE/MICE";
import MICEHistoryPage from "../../Pages/MICE/History/MICEHistory";
import MICEReservationsPage from "../../Pages/MICE/Reservations/ReservationsMICE";
import UnAuthorized from "../../Pages/Unauthorized/Unauthorized";
import NotFound from "../../Pages/NotFound/NotFound";

// List Route
const grandRoute = [
  {
    path: "mice-reservations",
    name: "mice-reservations",
    id_modules: 57,
    element: <MICEReservationsPage />,
  },
  {
    path: "history",
    name: "mice-history",
    id_modules: 58,
    element: <MICEHistoryPage />,
  },
];

// CODE
export default function MICERoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_children?.length > 0 ? sub_children : []; // values.module;

    const proRoute = grandRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    // console.log("Protected Routes BO: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<MICEPages />} />
        <Route path="*" element={<NotFound />} />

        {protectedRoutes}
      </Routes>
      <Outlet />
    </>
  );
}
