// Import React's Component
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Card,
  Image,
} from "antd";

// Import React Icons
import {
  FormOutlined,
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Page Components
// import ModalReportOutlet from "../../../Modals/Outlet/ReportOutlet/Modal";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

const { Meta } = Card;

// TASKS
const tasks = [
  {
    id: "1",
    article: "Donut",
    table: "T1",
    waitress: "Jessica",
    oc: "ORD/123/123",
    ot: "2023-03-04T00:00:00",
    notes: "test",
    assets: "/assets/img/foodiesfeed.com_raspberry-donut-close-up.jpg",
  },
  {
    id: "2",
    article: "Hamburger",
    table: "T2",
    waitress: "Jessica",
    oc: "ORD/123/123",
    ot: "2023-03-04T00:00:00",
    notes: "test",
    assets:
      "/assets/img/tasty-burger-isolated-white-background-fresh-hamburger-fastfood-with-beef-cheese.jpg",
  },
  {
    id: "3",
    article: "Donut",
    table: "T3",
    waitress: "Jessica",
    oc: "ORD/123/123",
    ot: "2023-03-04T00:00:00",
    notes: "test",
    assets: "/assets/img/foodiesfeed.com_raspberry-donut-close-up.jpg",
  },
  {
    id: "4",
    article: "Hamburger",
    table: "T4",
    waitress: "Jessica",
    oc: "ORD/123/123",
    ot: "2023-03-04T00:00:00",
    notes: "test",
    assets:
      "/assets/img/tasty-burger-isolated-white-background-fresh-hamburger-fastfood-with-beef-cheese.jpg",
  },
  {
    id: "5",
    article: "Donut",
    table: "T5",
    waitress: "Jessica",
    oc: "ORD/123/123",
    ot: "2023-03-04T00:00:00",
    notes: "test",
    assets: "/assets/img/foodiesfeed.com_raspberry-donut-close-up.jpg",
  },
  //   { id: "6", article: "First task" },
  //   { id: "7", article: "Second task" },
  //   { id: "8", article: "Third task" },
  //   { id: "9", article: "Fourth task" },
  //   { id: "10", article: "Fifth task" },
];

const taskStatus = {
  first: {
    name: "1",
    items: tasks,
  },
  second: {
    name: "2",
    items: [],
  },
  third: {
    name: "3",
    items: [],
  },
  fourth: {
    name: "4",
    items: [],
  },
  fifth: {
    name: "5",
    items: [],
  },
  inProgress: {
    name: "In Progress",
    items: [],
  },
  done: {
    name: "Done",
    items: [],
  },
};

// HANDLE DRAG & DROP
const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
    // if (destination.droppableId !== "done") {
    //   const sourceColumn = columns[source.droppableId];
    //   const destColumn = columns[destination.droppableId];
    //   const sourceItems = [...sourceColumn.items];
    //   const destItems = [...destColumn.items];
    //   const [removed] = sourceItems.splice(source.index, 1);
    //   destItems.splice(destination.index, 0, removed);
    //   setColumns({
    //     ...columns,
    //     [source.droppableId]: {
    //       ...sourceColumn,
    //       items: sourceItems,
    //     },
    //     [destination.droppableId]: {
    //       ...destColumn,
    //       items: destItems,
    //     },
    //   });
    // } else {
    //   const sourceColumn = columns[source.droppableId];
    //   const destColumn = columns[destination.droppableId];
    //   const sourceItems = [...sourceColumn.items];
    //   const destItems = [...destColumn.items];
    //   sourceItems.splice(source.index, 1);
    //   destItems.splice(destination.index, 1);
    //   setColumns({
    //     ...columns,
    //     [source.droppableId]: {
    //       ...sourceColumn,
    //       items: sourceItems,
    //     },
    //     [destination.droppableId]: {
    //       ...destColumn,
    //       items: destItems,
    //     },
    //   });
    // }
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

// HANDLE DELETE
const handleDelete = (columns, index, setColumns) => {
  const doneColumns = columns["done"];
  const doneItems = [...doneColumns.items];
  doneItems.splice(index, 1);
  setColumns({
    ...columns,
    ["done"]: {
      ...doneColumns,
      items: doneItems,
    },
  });
};

// CODE
export default function KitchenOrderTable() {
  // STATE MANAGEMENT
  // const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState(taskStatus);
  const [cardIndex, setCardIndex] = useState();

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // console.log("TEST DND >>>>>>>>>>", cardIndex);

  return (
    // <>
    //   <Row
    //     className="lostfound-main-table"
    //     justify="center"
    //     style={{ backgroundColor: "#FFFFFF", minHeight: "100%" }}
    //   >
    //     <Col span={24} style={{ padding: "30px" }}>
    //       <Row style={{ marginTop: 30, width: "100%" }}>
    //         <Col
    //           span={3}
    //           className="border-color-table-kitchen-order"
    //           style={{ backgroundColor: "#3699FF26" }}
    //         >
    //           <Row justify="center" style={{ padding: "20px" }}>
    //             <h2 style={{ color: "#5EADFF" }}>1</h2>
    //           </Row>
    //         </Col>
    //         <Col
    //           span={3}
    //           className="border-color-table-kitchen-order"
    //           style={{ backgroundColor: "#3699FF26" }}
    //         >
    //           <Row justify="center" style={{ padding: "20px" }}>
    //             <h2 style={{ color: "#5EADFF" }}>2</h2>
    //           </Row>
    //         </Col>
    //         <Col
    //           span={3}
    //           className="border-color-table-kitchen-order"
    //           style={{ backgroundColor: "#3699FF26" }}
    //         >
    //           <Row justify="center" style={{ padding: "20px" }}>
    //             <h2 style={{ color: "#5EADFF" }}>3</h2>
    //           </Row>
    //         </Col>
    //         <Col
    //           span={3}
    //           className="border-color-table-kitchen-order"
    //           style={{ backgroundColor: "#3699FF26" }}
    //         >
    //           <Row justify="center" style={{ padding: "20px" }}>
    //             <h2 style={{ color: "#5EADFF" }}>4</h2>
    //           </Row>
    //         </Col>
    //         <Col
    //           span={3}
    //           className="border-color-table-kitchen-order"
    //           style={{ backgroundColor: "#3699FF26" }}
    //         >
    //           <Row justify="center" style={{ padding: "20px" }}>
    //             <h2 style={{ color: "#5EADFF" }}>5</h2>
    //           </Row>
    //         </Col>
    //         <Col
    //           span={4}
    //           className="border-color-table-kitchen-order"
    //           style={{ backgroundColor: "#3699FF26" }}
    //         >
    //           <Row justify="center" style={{ padding: "20px" }}>
    //             <h2 style={{ color: "#5EADFF" }}>Progress</h2>
    //           </Row>
    //         </Col>
    //         <Col
    //           span={5}
    //           className="border-color-table-kitchen-order"
    //           style={{ backgroundColor: "#3699FF26" }}
    //         >
    //           <Row justify="center" style={{ padding: "20px" }}>
    //             <h2 style={{ color: "#5EADFF" }}>Finish</h2>
    //           </Row>
    //         </Col>
    //       </Row>
    //       <Row style={{ width: "100%", height: "500px" }}>
    //         <Col span={3} className="border-color-table-kitchen-order">
    //           <Row justify="center" style={{ padding: "20px" }}></Row>
    //         </Col>
    //         <Col span={3} className="border-color-table-kitchen-order">
    //           <Row justify="center" style={{ padding: "20px" }}></Row>
    //         </Col>
    //         <Col span={3} className="border-color-table-kitchen-order">
    //           <Row justify="center" style={{ padding: "20px" }}></Row>
    //         </Col>
    //         <Col span={3} className="border-color-table-kitchen-order">
    //           <Row justify="center" style={{ padding: "20px" }}></Row>
    //         </Col>
    //         <Col span={3} className="border-color-table-kitchen-order">
    //           <Row justify="center" style={{ padding: "20px" }}></Row>
    //         </Col>
    //         <Col span={4} className="border-color-table-kitchen-order">
    //           <Row justify="center" style={{ padding: "20px" }}></Row>
    //         </Col>
    //         <Col span={5} className="border-color-table-kitchen-order">
    //           <Row justify="center" style={{ padding: "20px" }}></Row>
    //         </Col>
    //       </Row>
    //     </Col>
    //   </Row>
    //   <ModalReportOutlet openModal={open} closeModal={setOpen} />
    // </>
    <Row className="lostfound-main-table" justify="center" style={mainBody}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            padding: "30px",
          }}
        >
          {/* DragDropContext used for coverage all of the DND area */}
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  key={columnId}
                >
                  <Row
                    span={24}
                    justify="center"
                    style={{
                      backgroundColor: "#3699FF26",
                      minWidth: 200,
                      height: 100,
                      padding: "30px 0px 25px 0px",
                    }}
                  >
                    <h2 style={{ color: "#5EADFF" }}>{column.name}</h2>
                  </Row>

                  <div style={{ padding: 10 }}>
                    {/* Droppable used as the drop area from the draggable content */}
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="border-color"
                            style={{
                              background: snapshot.isDraggingOver
                                ? "#5EADFF"
                                : "white",
                              padding: 0,
                              minWidth: 200,
                              minHeight: `${cardIndex * 500}px`,
                            }}
                          >
                            {column.items.map((item, index) => {
                              setCardIndex(tasks.length);
                              return (
                                // The content that wanted to move
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          userSelect: "none",
                                          padding: 16,
                                          margin: "0 0 8px 0",
                                          minHeight: "50px",
                                          //   backgroundColor: snapshot.isDragging
                                          //     ? "#ffccc7"
                                          //     : "#ffccc7",
                                          //   color: "black",
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        <Card
                                          hoverable
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#F64E6026",
                                            borderRadius: 15,
                                          }}
                                          cover={
                                            <Image
                                              // className="img-fluid"
                                              src={item.assets}
                                              preview={false}
                                              //   height={"500%"}
                                              style={{
                                                height: "150px",
                                                width: "100%",
                                              }}
                                            />
                                          }
                                        >
                                          <Meta
                                            title={item.article}
                                            description={
                                              <>
                                                <Row>
                                                  <b>Table : {item.table}</b>
                                                  <p>
                                                    Waitress : {item.waitress}
                                                  </p>
                                                  <p>OC : {item.oc}</p>
                                                  <p>OT : {item.ot}</p>
                                                  <p>Notes : {item.notes}</p>
                                                </Row>
                                                <Row>
                                                  <Button
                                                    type="danger"
                                                    onClick={() => {
                                                      handleDelete(
                                                        columns,
                                                        index,
                                                        setColumns
                                                      );
                                                    }}
                                                    disabled={
                                                      column.name === "Done"
                                                        ? false
                                                        : true
                                                    }
                                                  >
                                                    Is Reserved ?
                                                  </Button>
                                                </Row>
                                              </>
                                            }
                                          />
                                        </Card>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {/* Placeholder used for automatically fill the empty area after content moved */}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>
                </div>
              );
            })}
          </DragDropContext>
        </div>
      </div>
    </Row>
  );
}
